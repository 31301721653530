import React from 'react';
import './Guide.scss';
import NavTiles from '../NavTiles/NavTiles';

const Guide = ({ history }) => {

    return (
        <section className="guide container">
                <h3 className="guide__heading center">Let us guide you</h3>
                <NavTiles history={history} />
        </section>
    );

}

export default Guide;