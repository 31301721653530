import React from 'react'
import EventsSummary from '../EventsSummary/EventsSummary'
import './Events.scss'

const Events = ({setCurrentPage}) => {

    return (
        <div className="events">
            <EventsSummary setCurrentPage={setCurrentPage} />
        </div>
    )
}

export default Events
