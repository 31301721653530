import React from 'react'
import './AdminUsers.scss'

const AdminUsers = () => {
    return (
        <div>
           <h1>Coming Soon...</h1>
        </div>
    )
}

export default AdminUsers
