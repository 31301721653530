import React, { useEffect } from 'react'
import './SearchBar.scss'
import M from 'materialize-css'
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';

const SearchBar = ({ data, setData, active, setActive, setAwaitingQuote, setAwaitingAcceptance, setAcceptedNoContractID, setUnregistered, selectedQualID, setSelectedQualID, setShowNatFileErrors, allQuals, setIsDisabledStudent, setShowDontKnowYet, setShowSelfFunded }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    }, [])
    
    const onSearchChange = (e) => {
        if (!data.length) return

        const { value } = e.target
        if (!value.trim().length) {
            setData(data)
            return
        }

        const valueToFilterBy = value.toLowerCase().trim()
        const filteredData = data.filter(datum => {
            const studentName = datum.FirstName + " " + datum.Surname
            return studentName.toLowerCase().includes(valueToFilterBy)
        })

        setData(filteredData)
    }

    //show only students who have an active qualification
    const onActiveChange = (e) => { 
        if (e.target.checked) {
            setActive(true);
        } else {
            setActive(false);
        }
        
        setSelectedQualID("0")
        resetQualSelectors()

    }

    const onAwaitingQuoteChange = (e) => {
        if (e.target.checked) {
            setAwaitingQuote(true);
        } else {
            setAwaitingQuote(false);
        }
    }

    const onAwaitingAcceptanceChange = (e) => {
        if (e.target.checked) {
            setAwaitingAcceptance(true);
        } else {
            setAwaitingAcceptance(false);
        }
    }

    const onAcceptedNoContractIDChange = (e) => {
        if (e.target.checked) {
            setAcceptedNoContractID(true);
        } else {
            setAcceptedNoContractID(false);
        }
    }


     //show only students who are unregistered
     const onUnregChange = (e) => {
        if (e.target.checked === true){
            setUnregistered(true);
        }else{
            setUnregistered(false);
        }
    }

    const onHasNatFileErrorsChange = (e) => {
        if (e.target.checked === true){
            setShowNatFileErrors(true)
        }else{
            setShowNatFileErrors(false);
        }
    }

    const onDeletedStudentChange = (e) => {
        if (e.target.checked === true){
            setIsDisabledStudent(true)
        }else{
            setIsDisabledStudent(false);
        }
    }

    const onDontKnowChange = (e) => {
        if (e.target.checked === true){
            setShowDontKnowYet(true)
        }else{
            setShowDontKnowYet(false);
        }
    }

    const onSelfFundedChange = (e) => {
        if (e.target.checked === true){
            setShowSelfFunded(true)
        }else{
            setShowSelfFunded(false);
        }
    }

    const onQualChange = (e) => {
        setSelectedQualID(e.target.value)
    }

    // Reset the values of each selector as a qual may be in one list but not the other (i.e. superseded quals)
    const resetQualSelectors = () => {
        const allQualsSelector = document.querySelector("#AllQualsSelector")
        const filteredQualsSelector = document.querySelector("#FilteredQualsSelector")
        
        if (allQualsSelector) {
            allQualsSelector.value = "0"
        }

        if (filteredQualsSelector) {
            filteredQualsSelector.value = "0"
        }

        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    }

    return (
        <section>
            <div className="search-bar row">
                <div className="search-bar__input input-field col s12">
                    <input className="searchBar" type="text" onChange={onSearchChange} />
                    <label htmlFor="searchBar">Search for a student...</label>
                </div>
                <div className="search-bar__filters">
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" id="cbActive" name="active" defaultChecked={true} onChange={onActiveChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Active</span>
                    </div>
                    <div className="search-bar__switch switch">
                        
                        <label>
                            <input type="checkbox" id="cbAwaiting" name="awaiting" onChange={onAwaitingQuoteChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Awaiting Quote</span>
                    </div>
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" id="cbAcceptance" name="acceptance" onChange={onAwaitingAcceptanceChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Awaiting Acceptance</span>
                    </div>
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" id="cbContract" name="contract" onChange={onAcceptedNoContractIDChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Accepted no ContractID</span>
                    </div>
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" onChange={onUnregChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Unregistered</span>
                    </div>
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" onChange={onHasNatFileErrorsChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Has Nat File Errors</span>
                    </div>
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" onChange={onDeletedStudentChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Deleted Students</span>
                    </div>
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" onChange={onDontKnowChange} />
                            <span className="lever"></span>
                        </label>
                        <span>Funding: Don't Know Yet</span>
                    </div>
                    <div className="search-bar__switch switch">
                        <label>
                            <input type="checkbox" onChange={onSelfFundedChange}/>
                            <span className="lever"></span>
                        </label>
                        <span>Funding: Self-Funded</span>
                    </div>
                </div>
            </div>
            {/* Created two separate qual filters as for some reason this component wouldn't refresh when state changed (i.e. when active changed, the rendering wouldn't refresh) */}
            {/* This shows all quals in dropdown */}
            <div className="row" style={{ display: active ? "none" : "block" }}>
                <div className="input-field col m6 s12">
                    <select id="AllQualsSelector" defaultValue={selectedQualID} name="QualFilter" onChange={onQualChange}>
                        <option value="0">Any</option>
                        {
                            allQuals.map(qual => (
                                <option key={qual.Id} value={qual.Id.toString()}>{`${qual.AntaId} ${capitaliseEachWordOfQualName(qual)}`}</option>
                            ))  
                        }
                        
                    </select>
                    <label htmlFor="AllQualsSelector">Qualification</label>
                </div>
            </div>
            {/* This shows only superseded quals in dropdown */}
            <div className="row" style={{ display: active ? "block" : "none" }}>
                <div className="input-field col m6 s12">
                    <select id="FilteredQualsSelector" defaultValue={selectedQualID} name="QualFilter" onChange={onQualChange}>
                        <option value="0">Any</option>
                        {
                            allQuals.filter(qual => !qual.Superseded).map(qual => (
                                <option key={qual.Id} value={qual.Id.toString()}>{`${qual.AntaId} ${capitaliseEachWordOfQualName(qual)}`}</option>
                            ))
                        }
                    </select>
                    <label htmlFor="FilteredQualsSelector">Qualification</label>
                </div>
            </div>
        </section>
    )
}

export default SearchBar
