import React from 'react'
import Leader from '../../assets/illustrations/leader.svg'
import './SmartAndSkilled.scss'

const SmartAndSkilled = () => {
    return (
        <section className="smart-skilled container">
            <h2>Smart and Skilled NSW</h2>
            <article className="smart-skilled__article">
                <figure className="smart-skilled__article--figure">
                    <img src={Leader} alt="Leader" className="responsive-img" />
                </figure>
                <article className="smart-skilled__article--description">
                    <h3>Get the skills you need</h3>
                    <p>Smart and Skilled is a reform of the NSW Vocational Education and Training (VET) system. It's helping people in NSW get the skills they need to find a job and advance their careers.</p>
                    <a href="https://smartandskilled.nsw.gov.au"><button className="btn">Learn More</button></a>
                </article>
            </article>
        </section>
    )
}

export default SmartAndSkilled
