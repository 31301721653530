import React, { useState } from 'react'
import './AdminLocations.scss'
import AdminProgramStreams from '../AdminProgramStreams/AdminProgramStreams';
import AdminLocationsDelivery from '../AdminLocationsDelivery/AdminLocationsDelivery';
import AdminLocationsOffice from '../AdminLocationsOffice/AdminLocationsOffice';
import AdminRegions from '../AdminRegions/AdminRegions';
import { useThemeContext } from '../../ThemeContext';

const AdminLocations = () => {

    const { authToken } = useThemeContext()

    const [currOffice, setCurrOffice] = useState(0);

    return (
        <div className="admin-locations">
            <div className="admin-locations__navigation-buttons">
                <a className="btn-floating mbh-green" onClick={()=>{document.getElementById("scrollToOffice").scrollIntoView({behavior: "smooth"})}}>
                    <i className="material-icons">location_city</i>
                </a>
                <a className="btn-floating mbh-green" onClick={()=>{document.getElementById("scrollToDelivery").scrollIntoView({behavior: "smooth"})}}>
                    <i className="material-icons">business</i>
                </a>
                <a className="btn-floating mbh-green" onClick={()=>{document.getElementById("scrollToProgramStream").scrollIntoView({behavior: "smooth"})}}>
                    <i className="material-icons">assignment</i>
                </a>
                <a className="btn-floating mbh-green" onClick={()=>{document.getElementById("scrollToRegions").scrollIntoView({behavior: "smooth"})}}>
                    <i className="material-icons">terrain</i>
                </a>
            </div>
            <div id="scrollToOffice" className="admin-locations__component">
                <AdminLocationsOffice authToken={authToken} setCurrOffice={setCurrOffice}  />
            </div>
            <div id="scrollToDelivery" className="admin-locations__component">
                <AdminLocationsDelivery authToken={authToken} currOffice={currOffice} />
            </div>
            <div id="scrollToProgramStream" className="admin-locations__component">
                <AdminProgramStreams authToken={authToken} />
            </div>
            <div id="scrollToRegions" className="admin-locations__component">
                <AdminRegions authToken={authToken} />
            </div>
        </div>
    )
}

export default AdminLocations
