import React from 'react';

import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import Home from './pages/Home/Home';
import Conditions from './pages/Conditions/Conditions';
import Privacy from './pages/Privacy/Privacy';
import StudentPortal from './pages/StudentPortal/StudentPortal';
import CodeOfPractice from './pages/CodeOfPractice/CodeOfPractice';
import RegConfirmation from './pages/RegConfirmation/RegConfirmation';
import Qualifications from './pages/Qualifications/Qualifications';
import LogIn from './components/LogIn/LogIn';
import StepForm from './components/StepForm/StepForm';
import DiplomaInPM from './pages/DiplomaInPM/DiplomaInPM';
import ContactUs from './pages/ContactUs/ContactUs';
import RenderRoute from './components/RenderRoute/RenderRoute';
import CareerPathways from './pages/CareerPathways/CareerPathways';
import CertIIBusiness from './pages/CertIIBusiness/CertIIBusiness';
import CertIIIBusiness from './pages/CertIIIBusiness/CertIIIBusiness';
import CertIVProjectManagement from './pages/CertIVProjectManagement/CertIVProjectManagement';
import WhyMBH from './pages/WhyMBH/WhyMBH';
import SmartAndSkilled from './pages/SmartAndSkilled/SmartAndSkilled';
import CertIIIBizAdmin from './pages/CertIIIBizAdmin/CertIIIBizAdmin';
import CertIIIBizAdminEdu from './pages/CertIIIBizAdminEdu/CertIIIBizAdminEdu';
import CaseStudies from './pages/CaseStudies/CaseStudies';
import Testimonials from './components/Testimonials/Testimonials';
import SetPassword from './pages/SetPassword/SetPassword';
import JobTrainer from './pages/JobTrainer/JobTrainer';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import { ThemeProvider } from './ThemeContext';


export const ACTIONS = {
  CHANGE_ROUTE: 'change-route',
  SET_STUDENT_ID: 'set-student-id'
}



function App() {

  return (
    <div className="App">

        <Router>
          <Switch>
            <Route path='/' exact render={({history}) => <RenderRoute Component={<Home history={history}  />} history={history} renderType={'All'} />}/>
            <Route path='/about-us' render={({history}) => <RenderRoute Component={<WhyMBH history={history} />} history={history} renderType={'All'} />}/>
            <Route path='/testimonials' render={({history}) => <RenderRoute Component={<Testimonials history={history}  />} history={history} renderType={'All'} />}/>
            
            <Route path='/login' render={({history}) => <RenderRoute Component={<LogIn history={history} />} history={history} renderType={'navFooter'} />} />
            <Route path='/register' render={({history}) => <RenderRoute Component={<StepForm history={history} />} history={history} renderType={'navFooter'} />} />
            <Route path='/registration-confirmation' render={({history}) => <RenderRoute Component={<RegConfirmation />} history={history} renderType={'navFooter'} />} />
            <Route path='/reset-password' render={({history}) => <RenderRoute Component={<ResetPassword history={history} />} history={history} renderType={'navFooter'} />} />
            <Route exact path='/forgot-password' render={({history}) => <RenderRoute Component={<ForgotPassword history={history} />} history={history} renderType={'navFooter'} />} />
            
            <Route path='/conditions' render={({history}) => <RenderRoute Component={<Conditions />} history={history} renderType={'All'} />} />
            <Route path='/privacy' render={({history}) => <RenderRoute Component={<Privacy />} history={history} renderType={'All'} />} />
            <Route path='/code-of-practice' render={({history}) => <RenderRoute Component={<CodeOfPractice />} history={history} renderType={'All'} />} />
            <Route path='/case-studies' render={({history}) => <RenderRoute Component={<CaseStudies />} history={history} renderType={'All'} />} />
            <Route path='/smart-and-skilled' render={({history}) => <RenderRoute Component={<SmartAndSkilled history={history} />} history={history} renderType={'All'} />}/>
           
            <Route path='/career-pathways' render={({history}) => <RenderRoute Component={<CareerPathways history={history} />} history={history} renderType={'All'} />}/>
            <Route exact path='/qualifications' render={({history}) => <RenderRoute Component={<Qualifications history={history} />} history={history} renderType={'All'} />} />
            <Route path='/qualifications/certificate-II-in-workplace-skills' render={({history}) => <RenderRoute Component={<CertIIBusiness history={history} />} history={history} renderType={'navFooter'} />}/>
            <Route path='/qualifications/certificate-III-in-business' render={({history}) => <RenderRoute Component={<CertIIIBusiness history={history} />} history={history} renderType={'navFooter'} />}/>
            <Route path='/qualifications/certificate-III-in-business-administration' render={({history}) => <RenderRoute Component={<CertIIIBizAdmin history={history} />} history={history} renderType={'navFooter'} />}/>
            <Route path='/qualifications/certificate-III-in-business-administration-education' render={({history}) => <RenderRoute Component={<CertIIIBizAdminEdu history={history} />} history={history} renderType={'navFooter'} />}/>
            <Route path='/qualifications/certificate-IV-in-project-management' render={({history}) => <RenderRoute Component={<CertIVProjectManagement history={history} />} history={history} renderType={'navFooter'} />}/>
            <Route path='/qualifications/diploma-of-project-management' render={({history}) => <RenderRoute Component={<DiplomaInPM history={history} />} history={history} renderType={'navFooter'} />}/>

            <Route path='/student-portal' render={({history}) => <ThemeProvider><StudentPortal route='studentPortal' history={history} /></ThemeProvider> } />
            <Route path='/admin-portal' render={({history}) => <ThemeProvider><StudentPortal route='studentPortal' history={history} /></ThemeProvider> } />

            <Route path='/contact-us' render={({history}) => <RenderRoute Component={<ContactUs history={history}/>} history={history} renderType={'navFooter'} />}/>

            <Route path='/set-password' render={({history}) => <RenderRoute Component={<SetPassword history={history} />} history={history} renderType={'navFooter'} />} />
          </Switch>
        </Router>
    </div>
  );
}

export default App;
