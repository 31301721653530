export const findStudentsWithNatFileErrors = (natFileErrors) => {
    const students = natFileErrors
        .filter((item, index, self) =>
            index === self.findIndex((t) => (
            t.FirstName === item.FirstName && t.Surname === item.Surname
            ))
        ).map(student => (
            {
                Name: `${student.FirstName} ${student.Surname}`,
                StudentId: student.StudentId
            }
        ))
    
    return students
}

export const createObjectOfStudentsWithNatFileErrors = (natFileErrors) => {
    const students = natFileErrors
    .filter((item, index, self) =>
        index === self.findIndex((t) => (
        t.FirstName === item.FirstName && t.Surname === item.Surname
        ))
    )

    return arrayToObject(natFileErrors)
}

const arrayToObject = (array) =>
    array.reduce((obj, item) => {
    obj[item.StudentId] = item
    return obj
}, {})