import React from 'react'
import '../../pages/ResetPassword/ResetPassword.scss'

const ResetPasswordNoTokenFound = ({history}) => {

    return (
        <section className="reset-password">
            <div className="reset-password__card reset-password__success container">
                <h5>No reset token found</h5>
                <br/><br/>
                <div>
                    <p>You may need to get a new reset link</p>
                    <br/>
                    <button 
                        className="btn-large mbh-green" 
                        style={{ boxShadow: 'none' }}
                        onClick={() => history.push("/forgot-password")}
                    >
                        Send Reset Link
                    </button>
                </div>
                <hr />
                <p 
                    className="btn-large transparent mbh-green-text" 
                    style={{ boxShadow: 'none' }}
                    onClick={() => history.push('/login')}
                >
                    LOG IN
                </p>
            </div>
        </section>
    )
}

export default ResetPasswordNoTokenFound
