import React from 'react'
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';
import { dateTimeToDateString } from '../../functions/dateTimeToDateString';
import { useThemeContext } from '../../ThemeContext';
import TrainingPlanComments from '../TrainingPlanComments/TrainingPlanComments';

const TrainingPlanTable = ({ studentQual, deliveryMode, hoursUnitsObject, trainers, handleUpdateTrainer, handleUpdateComments }) => {

    const { userRole } = useThemeContext()

    return (
        <div className="student-training-plan__section">
                    <>
                    <h5 className="mbh-green-text">Training Record Plan</h5>
                    <ul className="collection" style={{overflow: "visible"}}>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Student Name</span ><span className="col s12 m8">{studentQual.Student.FirstName} {studentQual.Student.Surname}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Student Identifier</span ><span className="col s12 m8">{studentQual.Student.SaStudentId}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">MBH Student ID</span ><span className="col s12 m8">{studentQual.Student.Id}</span></li> 
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Commitment ID</span ><span className="col s12 m8">{studentQual.ContractId}</span></li> 
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Participating Organisation (if applicable)</span ><span className="col s12 m8"></span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Registered Training Organisation (RTO)</span ><span className="col s12 m8">MBH Management Pty Ltd</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">National Qualification Code</span ><span className="col s12 m8">{studentQual.Qualification.AntaId}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Title of Qualification</span ><span className="col s12 m8">{capitaliseEachWordOfQualName(studentQual.Qualification)}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Training Enrolment Date</span ><span className="col s12 m8">{dateTimeToDateString(studentQual.ContractCreatedAt)}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Total Fees</span ><span className="col s12 m8">{studentQual.TotalFees}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Total Number of Hours for Qualification</span ><span className="col s12 m8">{hoursUnitsObject[studentQual.Qualification.AntaId] ? hoursUnitsObject[studentQual.Qualification.AntaId].totalHours : 0}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Total Number of Units for Qualification</span ><span className="col s12 m8">{hoursUnitsObject[studentQual.Qualification.AntaId] ? hoursUnitsObject[studentQual.Qualification.AntaId].totalUnits : 0}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Nominal hours per unit</span ><span className="col s12 m8">{hoursUnitsObject[studentQual.Qualification.AntaId] ? hoursUnitsObject[studentQual.Qualification.AntaId].hrsPerUnit : 0}</span></li>
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Method of delivery</span ><span className="col s12 m8"> {deliveryMode[studentQual.Qualification.DeliveryMode]}</span></li>
                        {
                            userRole === "Administrator" ?
                            <>
                                <li className="collection-item row">
                                    <span className="col s12 m4 mbh-green-text">Trainer</span >
                                    <span className="col s12 m8">
                                        <div className="input-field col s12">
                                            <select defaultValue="-1" name="TrainerID" onChange={handleUpdateTrainer} value={studentQual.TrainerID ? studentQual.TrainerID : -1  }>
                                            <option value="-1" disabled>Select Trainer</option>
                                            {
                                                trainers.map((trainer, index) => {
                                                    return <option key={index} value={trainer.Id}>{trainer.FirstName} {trainer.Surname}</option>
                                                })
                                            }
                                            </select>
                                            <label>Trainer</label>
                                        </div>
                                    </span>
                                </li>
                            </>
                            :
                            <>
                                <li className="collection-item row">
                                    <span className="col s12 m4 mbh-green-text">Trainer</span >
                                    <span className="col s12 m8">{studentQual.Trainer === null ? "TBD" : `${studentQual.Trainer.FirstName} ${studentQual.Trainer.Surname}`}</span>
                                </li>
                            </>
                        }
                        <li className="collection-item row"><span className="col s12 m4 mbh-green-text">Comments</span >
                           <TrainingPlanComments studentQual={studentQual} handleUpdateComments={handleUpdateComments} />
                        </li>
                    </ul>
                    </>
                
                
            
        </div>
    )
}

export default TrainingPlanTable
