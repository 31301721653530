import React, { useState, useEffect } from 'react'
import M from 'materialize-css';
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import Spinner from '../../assets/spinner.svg'
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import FormSpinner from '../../assets/form-spinner.svg'
import './EventAddList.scss'


const EventAddList = ({ registrations, eventId, attendeeList, setAttendeeList, authToken }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    })

    const studentGroupsUrl = `${baseUrl}/api/studentgroups?$expand=Students`;
    const [isLoadingGroups, studentGroups, errorGroups] = useDataFetch(studentGroupsUrl, authToken)

    const [listToAdd, setListToAdd] = useState(null)
    const [studentsAdded, setStudentsAdded] = useState(false)
    
    let registrationsObj = {}
    if (registrations) {
        registrationsObj = arrayToObjectWithKey(registrations, "StudentID")
    }
    
    const handleGroupSelect = (e) => {
        if (studentGroups[e.target.value]) {
            const studentsToAdd = checkForDuplicates(studentGroups[e.target.value].Students)
            setListToAdd(studentsToAdd)
        }
    }

    const checkForDuplicates = (studentList) => {
        if (!attendeeList) {
            return studentList
        } 

        const studentObj = arrayToObjectWithKey(attendeeList, "Id")
        return studentList.filter(student => studentObj[student.Id] === undefined)
    }

    const handleAttendeeListChange = (listToAdd) => {
        const listWithoutDuplicates = checkForDuplicates(listToAdd)

        if (!listWithoutDuplicates.length) {
            M.toast({html: `These students are already attending`, displayLength: 1000})
            setStudentsAdded(true)
            return;
        }

        const tempAttendeeList = attendeeList === null ? [] : attendeeList

        setAttendeeList([...tempAttendeeList, ...listWithoutDuplicates])
        setStudentsAdded(true)
        M.toast({html: `Students have been added`, classes: "mbh-green", displayLength: 2000})
    }

    const addListOfStudents = () => {
        // Remove students already in the list so they don't get added twice
        let combinedListToAdd = []
        if (attendeeList && attendeeList.length) {
            combinedListToAdd = [...attendeeList, ...listToAdd]
        } else {
            combinedListToAdd = listToAdd
        }
        
        const registrationList = combinedListToAdd.filter(attendee => registrationsObj[attendee.Id] === undefined)

        handleAttendeeListChange(registrationList)
    }

    return (
        <div className="events-add-list row">
            <div class="col s12">
                <div className="events-add-list__card">
                    <h5 className="mbh-black-text">Add a List of Students</h5>
                    <br />
                    {
                        isLoadingGroups ?
                        <img src={Spinner} alt="Spinner" />
                        :
                        <div className="input-field col s12">
                            <select defaultValue="-1" name="GroupSelector" onChange={handleGroupSelect}>
                            <option value="-1" disabled>Select a Group</option>
                            {
                                studentGroups.map((group, index) => (
                                    <option key={group.Id} value={index}>{group.GroupName}</option>
                                ))
                            }
                            </select>
                            <label>Group</label>
                        </div>
                    }
                    {
                        listToAdd && listToAdd.length && !studentsAdded ?
                        <div className="center-align">
                            {
                                registrations ?
                                <button className="btn mbh-green" onClick={addListOfStudents}>Add {listToAdd.length} Students</button>
                                :
                                <button className="btn mbh-green" onClick={() => handleAttendeeListChange(listToAdd)}>Add {listToAdd.length} Students</button>
                            }
                        </div>
                        :
                        <div className="center-align">
                            <button className="btn" disabled>Add Students</button>
                        </div>

                    }
                </div>
            </div>
        </div>
    )
}

export default EventAddList