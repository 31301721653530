import React, {useState} from 'react'
import './AdminResults.scss'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetchRefresh } from '../../hooks/useDataFetchRefresh';
import M from 'materialize-css';
import { useThemeContext } from '../../ThemeContext';

const AdminResults = () => {

    const { authToken } = useThemeContext()

    const [refreshData, setRefreshData] = useState(false);

    const resultsUrl = `${baseUrl}/api/competencyResults`;
    const [isLoading, results, error] = useDataFetchRefresh(resultsUrl, authToken, refreshData, setRefreshData);

    const updateResult = (e, obj) => {
        const { name, value, checked } = e.target;
        if (name === "Claimable" || name === "NotYetStarted" || name ==="IsCompleted"){
            obj[name] = checked;
        }else{
            obj[name] = value;
        }
     
        fetch(`${baseUrl}/api/competencyResults/${obj['Id']}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const resultObj = {
        "Claimable": false,
        "Result": "",
        "Mode": "",
        "VetRefId": "",
        "NotYetStarted": false,
        "IsCompleted": false
    }
    const updateResultObj = (e) => {
        const { name, value, checked } = e.target;
        if (name === "Claimable" || name === "NotYetStarted" || name ==="IsCompleted"){
            resultObj[name] = checked;
        }else{
            resultObj[name] = value;
        }
    }

    const addResult = () => {
        
        if(document.getElementById("newResult").value.trim() !== ""){
           fetch(`${baseUrl}/api/competencyResults`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(resultObj)
            })
            .then(response => response.json())
            .catch(err => console.log(err))

            if(document.getElementById("addNewResultForm")){
                document.getElementById("addNewResultForm").reset();
            }
           
            setRefreshData(true);
       }else{
            M.toast({html: "Please provide the result description", displayLength: 2000});
       }
    }

    const deleteResult = (obj) => {
        fetch(`${baseUrl}/api/competencyResults/${obj['Id']}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
        .then(response => response.json())
        .catch(err => console.log(err))

        setRefreshData(true);
    }

    return (
        <div className="admin-results">
            <h5 className="mbh-green-text">Result List</h5>
            
            <table className="admin-results__table">
                <thead>
                    <tr>
                        <th className="bold">Result</th>
                        <th className="bold">Mode (NAT)</th>
                        <th className="bold">VetRefId (NAT)</th>
                        <th className="bold">Claimable</th>
                        <th className="bold">Not Yet Started</th>
                        <th className="bold">Completed</th>
                        <th className="bold">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        results.map((result, index) => (
                            <tr key={result.Id}>    
                                <td className=""><input name="Result" type="text" defaultValue={result.Result} onChange={(e) => updateResult(e, result)} /></td>   
                                <td className="admin-results__column-small"><input name="Mode" type="text" defaultValue={result.Mode} onChange={(e) => updateResult(e, result)} /></td>
                                <td className="admin-results__column-small"><input name="VetRefId" type="text" maxLength="2" defaultValue={result.VetRefId} onChange={(e) => updateResult(e, result)} /></td>
                                <td className="admin-results__column-small">
                                    <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                        <input name="Claimable" type="checkbox" className="filled-in " defaultChecked={result.Claimable?true:false} onClick={(e) => updateResult(e, result)} />
                                        <span></span>
                                    </label>
                                </td>
                                <td className="admin-results__column-small">
                                    <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                        <input name="NotYetStarted" type="checkbox" className="filled-in " defaultChecked={result.NotYetStarted?true:false} onClick={(e) => updateResult(e, result)} />
                                        <span></span>
                                    </label>
                                </td>
                                <td className="admin-results__column-small">
                                    <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                        <input name="IsCompleted" type="checkbox" className="filled-in "  defaultChecked={result.IsCompleted?true:false} onClick={(e) => updateResult(e, result)} />
                                        <span></span>
                                    </label>
                                </td>
                                <td className="admin-results__column-small" title="DELETE"> <a class="btn-flat" style={{padding:"0",display:"flex", justifyContent:"center"}} onClick={() => deleteResult(result)}><i className="material-icons admin-program-streams__delete">delete</i></a></td>                               
                            </tr>
                        ))
                    }
                    
                </tbody>
            </table>
            <br/><br/>
            <h6 className="mbh-green-text">Add New Result</h6>
            <form id="addNewResultForm">
                <table className="admin-results__table">
                <thead>
                    <tr>
                        <th className="bold">Result</th>
                        <th className="bold">Mode (NAT)</th>
                        <th className="bold">Vet Ref Id (NAT)</th>
                        <th className="bold">Claimable</th>
                        <th className="bold">Not Yet Started</th>
                        <th className="bold">Completed</th>
                        <th className="bold"></th>
                    </tr>
                </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input id="newResult" name="Result" type="text" placeholder="add new result" autoComplete="new-password" onChange={updateResultObj}></input>
                        </td>
                        <td className="admin-results__column-small">
                            <input id="newMode"name="Mode" type="text" autoComplete="new-password" onChange={updateResultObj}></input>
                        </td>
                        <td className="admin-results__column-small">
                            <input id="newVetRefId"name="VetRefId" type="text"  maxLength="2" autoComplete="new-password" onChange={updateResultObj}></input>
                        </td>
                        <td className="admin-results__column-small">
                            <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                    <input id="newClaimable" name="Claimable" type="checkbox" className="filled-in " onClick={updateResultObj} />
                                    <span></span>
                            </label>
                        </td>
                        <td className="admin-results__column-small">
                            <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                    <input id="newNotYetStarted" name="NotYetStarted" type="checkbox" className="filled-in " onClick={updateResultObj} />
                                    <span></span>
                            </label>
                        </td>
                        <td className="admin-results__column-small">
                            <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                    <input id="newIsCompleted" name="IsCompleted" type="checkbox" className="filled-in " onClick={updateResultObj} />
                                    <span></span>
                            </label>
                        </td>
                        <td className="admin-results__column-small"><a className="btn-floating mbh-green right" onClick={addResult}><i className="material-icons" style={{userSelect:"none"}}>add</i></a></td>
                    </tr>
                    </tbody>
                </table>
            </form>
            <br/><br/><br/><br/><br/><br/>
        </div>
    )
}

export default AdminResults
