import React from 'react'
import FormTypes from '../FormTypes/FormTypes';
import './VETForm.scss';

const VETForm = ({vetQuestions, vetObject, studentVetAnswers, handleVetChange, route}) => {

    let prevGroup = ""

    const showQuestion = (answers, selectedAnswer) => {
       

        const answer = answers.filter(currAnswer => currAnswer.Id === +selectedAnswer);

        const activatedQuestions = answer[0].VisibilityActivators
        const deactivatedQuestions = answer[0].VisibilityDeactivators

        if (activatedQuestions.length) {
            for (let i = 0; i < activatedQuestions.length; i++) {
                const idOfQuestionToChange = activatedQuestions[i];               
                document.getElementById(`id${idOfQuestionToChange}`).style.display = 'block'
            }
        }
        
        if (deactivatedQuestions.length) {
            for (let i = 0; i < deactivatedQuestions.length; i++) {
                const idOfQuestionToChange = deactivatedQuestions[i];               
                document.getElementById(`id${idOfQuestionToChange}`).style.display = 'none'

            }
        }
    }

    return (
        <div className="vetForm">
           {
               vetQuestions.map((question, i) => {
                  return (
                    <div key={i} >
                        {
                            prevGroup !== question.Group.Name &&
                            <h5 className="mbh-green-text vetForm__heading">{question.Group.Name}</h5>
                        }
                        {
                            <FormTypes currentQuestion = {question} studentVetAnswers={studentVetAnswers} vetAnswersObject={vetObject} handleVetChange={(e) => handleVetChange(e, vetObject)} showQuestion={showQuestion} route={route}/>
                        }
                        {/* This is just for updating the prevGroup variable */}
                        <div style={{display: "none"}}>
                            {
                                prevGroup = question.Group.Name
                            }
                        </div>                        
                    </div>)
               })
           }
        </div>
    )
}

export default VETForm
