import React from 'react';
import ReactDOM from 'react-dom';
import './sass/materialize.scss';
import 'materialize-css/dist/js/materialize.min.js';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import { HashRouter as Router } from 'react-router-dom'

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#00703c',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

ReactDOM.render(
  
    <React.StrictMode>
        <Router>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
