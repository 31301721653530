import React, { useState, useEffect } from 'react'
import './ContactUs.scss';
import M from 'materialize-css';
import { baseUrl } from '../../functions/globalVariables';
import Spinner from '../../assets/spinner.svg';


const ContactUs = ({ history }) => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [isSending, setIsSending] = useState(false)

    useEffect(() => {
        var elems = document.querySelectorAll('.tooltipped');
        var instances = M.Tooltip.init(elems, {});
    }, [])

    const validateFields = () => {
        if (!name.trim().length || !email.trim().length || !message.trim().length) {
            return false
        }

        return true
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!validateFields()) {
            M.toast({ html: "Please fill in all fields", displayLength: 2000 })
            setIsSending(false)
            return
        }

        fetch(`${baseUrl}/api/auth/ContactUs`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Name: name,
                Email: email,
                Message: message
            })
        })
        .then(() => {
            M.toast({ html: "Success! Thank you for contacting mbh training. We will be in contact shortly.", displayLength: 2000, classes: 'mbh-green', })
            resetFields()
            M.updateTextFields()
            setIsSending(false)
        })
        .catch(() => {
            M.toast({ html: "An error has occurred", displayLength: 1500 })
            setIsSending(false)
        })
    }

    const resetFields = () => {
        setName("")
        setEmail("")
        setMessage("");
    }

    return (
        <section className="contactUs">
            <div className="contactUs__card container">
                <h3 className="center">Contact Us</h3>
                <form>
                    <div className="row contactUs__row">
                        <div className="input-field col s12">
                            <input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            <label htmlFor="name">Name</label>
                        </div>
                    </div>
                    <div className="row contactUs__row">
                        <div className="input-field col s12">
                            <input id="email" type="email" className="validate" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    <div id="commentSection" class="row">
                        <div class="input-field col s12">
                        <textarea id="comment" className="materialize-textarea" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        <a class="tooltipped mbh-green-text" data-position="left" data-tooltip={`Where are you at in your work career?<br/> What are you hoping to do next?<br/> Ask us about Smart and Skilled funding?<br/> Tell us and we'll strive to help!`}>
                            <i id="info-icon" class="small material-icons">info_outline</i>
                        </a>
                        <label for="comment">Message</label>
                        </div>
                    </div>
                    {
                        isSending ?
                        <figure id="spinner">
                            <img src={Spinner} alt="Spinner" className="responsive-img" />
                        </figure>
                        :
                        <button className="btn-large mbh-green" type='submit' onClick={(e) => { setIsSending(true); onSubmit(e)}}>Submit</button>
                    }
                    <hr />
                    <button className="btn-large transparent mbh-green-text" 
                            style={{ boxShadow: 'none' }}
                            onClick={() => history.push('/privacy')}
                    >Privacy</button>
                </form>
            </div>
        </section>
    )
}

export default ContactUs
