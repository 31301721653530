export const createVETQuestionObject = (vetQuestions, studentId) => {
    if (vetQuestions === undefined || studentId === undefined) return

    const tempObject = {}

    for (let i  = 0; i < vetQuestions.length; i++) {
        const name = vetQuestions[i].Text;
        tempObject[name] = {
            StudentId: studentId,
            VetQuestionId: vetQuestions[i].Id,
            Answers: [],
        }
    }

    return tempObject
}