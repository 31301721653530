export const sortStudentCompetenciesTable = (studentCompetencies, sortConfig) => {
    const copyOfStudentCompetencies = studentCompetencies.slice();

    if (sortConfig.direction === "ascending") {
        switch(sortConfig.fieldToSort) {
            case "Result":
                return copyOfStudentCompetencies.sort((a, b) => {
                    return a.Result.Result.localeCompare(b.Result.Result)
                })
            case "CompID":
            case "compDsc":
                return copyOfStudentCompetencies.sort((a, b) => {
                    return a.Competency[sortConfig.fieldToSort].localeCompare(b.Competency[sortConfig.fieldToSort])
                })
            case "AcitivityStartDate":
            case "ActivityEndDate":
                return copyOfStudentCompetencies.sort((a, b) => {
                    if (!a[sortConfig.fieldToSort] || !b[sortConfig.fieldToSort]) return 0
                    return a[sortConfig.fieldToSort].localeCompare(b[sortConfig.fieldToSort])
                })
            default:
                return studentCompetencies
        }
    } else if (sortConfig.direction === "descending") {
        switch(sortConfig.fieldToSort) {
            case "Result":
                return copyOfStudentCompetencies.sort((a, b) => {
                    return b.Result.Result.localeCompare(a.Result.Result)
                })
            case "CompID":
            case "compDsc":
                return copyOfStudentCompetencies.sort((a, b) => {
                    return b.Competency[sortConfig.fieldToSort].localeCompare(a.Competency[sortConfig.fieldToSort])
                })
            case "AcitivityStartDate":
            case "ActivityEndDate":
                return copyOfStudentCompetencies.sort((a, b) => {
                    if (!a[sortConfig.fieldToSort] || !b[sortConfig.fieldToSort]) return 0
                    return b[sortConfig.fieldToSort].localeCompare(a[sortConfig.fieldToSort])
                })
            default:
                return studentCompetencies
        }
    } else {
        return studentCompetencies
    }
}