import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import DragDropZone from '../DragDropZone/DragDropZone';
import Spinner from '../../assets/spinner.svg';
import './StudentTrainingPlan.scss';
import TrainingPlanInfoBoxes from '../TrainingPlanInfoBoxes/TrainingPlanInfoBoxes';
import AdministerStudentMenuItems from '../AdministerStudentMenuItems/AdministerStudentMenuItems';
import TrainingPlanNav from '../TrainingPlanNav/TrainingPlanNav';
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';
import TrainingPlanTableContainer from '../TrainingPlanTableContainer/TrainingPlanTableContainer';
import { getCurrentQual, getQualsAwaitingQuotes } from '../../functions/filterStudentQuals';
import StudentEvents from '../StudentEvents/StudentEvents';
import { useThemeContext } from '../../ThemeContext';
import { useHistory, useParams } from 'react-router-dom';

const StudentTrainingPlan = () => {

    let history = useHistory()
    const { studentID, selectedStudentId, authToken, userRole } = useThemeContext()

    const { courseId } = useParams()

    let currentStudentID = userRole === "Administrator" ? selectedStudentId : studentID

    const [currentQualId, setCurrentQualId] = useState(null)

    const [currentStudentQual, setCurrentStudentQual] = useState(null)
    const [qualsAwaitingQuotes, setQualsAwaitingQuotes] = useState([])

    const [trainingPlanNumber, setTrainingPlanNumber] = useState(0)

    const studentQualsUrl = `${baseUrl}/api/studentqualifications?$expand=*&$filter=StudentId eq ${currentStudentID} and Disabled eq false and Active eq true`;
    const [ isLoadingStudentQuals, studentQuals, errorStudentQuals ] = useDataFetch(studentQualsUrl, authToken);

    useEffect(() => {
        if (studentQuals.length) {
            let qualId = courseId === undefined ? studentQuals[0].QualId : courseId
            if (courseId === undefined) {
                setCurrentStudentQual(studentQuals[0])
                
            } else {
                const qualToDisplay = studentQuals.filter((qual, index) => {
                    if (qual.QualId === +courseId) {
                        setTrainingPlanNumber(index)
                        return true
                    }

                    return false
                })[0]
    
                setCurrentStudentQual(qualToDisplay)
            }

            setQualsAwaitingQuotes(getQualsAwaitingQuotes(studentQuals))

            if (userRole === "Administrator") {
                history.push(`/admin-portal/administer-student/student-training-plan/${qualId}`)
            } else {
                history.push(`/student-portal/training-plan/${qualId}`)
            }

        }
    }, [studentQuals])


    let studentCompetetenciesUrl = ""

    if (studentQuals.length) {
        studentCompetetenciesUrl = `${baseUrl}/api/studentcompetencies?$filter=StudentQualification/Id eq ${studentQuals[trainingPlanNumber].Id}&$expand=*&$orderBy=Competency/CompID`;
    }
    const [isLoadingComp, studentCompetencies, errorComp] = useDataFetch(studentCompetetenciesUrl, authToken);

    // Get core/elective data and create competencies object that contains this info
    const coreElectiveUrl = `${baseUrl}/api/qualificationcompetencies`;
    const [ isLoadingCoreElective, studentCoreElective, errorCoreElective ] = useDataFetch(coreElectiveUrl, authToken);
  

    // Grabbing all trainers
    const trainerUrl = `${baseUrl}/api/trainers`;
    const [isLoadingTrainers, trainers, trainersErr] = useDataFetch(trainerUrl, authToken)
    const [trainersToSelect, setTrainersToSelect] = useState([])

    useEffect(() => {
        if (trainers.length && studentQuals.length) {
            setTrainersToSelect(trainers.filter(trainer => {
                if (!trainer.IsDisabled) {
                    return true
                } else if (trainer.IsDisabled && trainer.Id === studentQuals[trainingPlanNumber].TrainerID && userRole === "Administrator") {
                    // Asterisk means trainer is disabled in database
                    trainer.Surname += " *"
                    return true
                }

                return false
            }))
        }
    }, [trainers, studentQuals, trainingPlanNumber])

    const deliveryMode = {
        10: "Classroom",
        20: "Online",
        30: "Work-based",
        40: "Mixed Mode"
    }

    const handleAcceptQuote = (object) => {
        
        object['ConfirmedCost'] = true;
        fetch(`${baseUrl}/api/studentqualifications/${object.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(object)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const handleCancelQuote = (object) => {
        object['Disabled'] = true;
      
        fetch(`${baseUrl}/api/studentqualifications/${object.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(object)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const handleUpdateTrainer = (e) => {

        const { value } = e.target

        const selectedTrainer = trainers.filter(trainer => trainer.Id === value)

        fetch(`${baseUrl}/api/studentqualifications/${studentQuals[trainingPlanNumber].Id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({...studentQuals[trainingPlanNumber], TrainerID: value})
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const handleUpdateComments = (value, qual) => {

        const object = { 
            "TotalFees": 0,
            "Id": 0,
            "Active": false,
            "Disabled": false,
            "StudentId": 0,
            "QualId": 0,
            "SaStudentId": null,
            "PASNumber": null,
            "DeliveryLocId": null,
            "OfficeId": null,
            "CertificateAttainedDate": null,
            "CertificateIssued": "No",
            "CertificateId": null,
            "QuoteId": null,
            "QuoteCreatedAt": null,
            "ContractId": null,
            "ContractCreatedAt": null,
            "ProgStreamId": null,
            "Funding": null,
            "TuitionFee": null,
            "Subsidy": null,
            "OtherSubsidy": null,
            "ConfirmedCost": false,
            "Comments": null
        }      

        for (const [key, value] of Object.entries(object)) {
            object[key] = qual[key]
        }

        object['Comments'] = value;
        studentQuals[trainingPlanNumber].Comments = value;
        
        fetch(`${baseUrl}/api/studentqualifications/${object.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(object)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }
   
    const [hoursUnitsObject, setHoursUnitsObject] = useState({})

    return ( 
        isLoadingStudentQuals || isLoadingComp || isLoadingTrainers ?<img src={Spinner} alt="Spinner"/>:
        <div className="student-training-plan">
            {
                userRole === 'Administrator'? <AdministerStudentMenuItems page="Training" />:null
            }
           
            {
                currentStudentQual && userRole==='Administrator'?
                <h3 className="hide-on-small-only">MBH Training Plan for {currentStudentQual.Student.FirstName} {currentStudentQual.Student.Surname}</h3>
                :<h3 className="hide-on-small-only">MBH Training Plan</h3>
            }
            {
                currentStudentQual && userRole==='Administrator'?
                <h5 className="hide-on-med-and-up center">MBH Training Plan for {currentStudentQual.Student.FirstName} {currentStudentQual.Student.Surname}</h5>
                :<h5 className="hide-on-med-and-up center">MBH Training Plan</h5>
            }

            {
                studentQuals.length ?
                <>
                    {
                        studentQuals.length > 1 
                        ?
                        <TrainingPlanNav allStudentQuals={studentQuals} trainingPlanNumber={trainingPlanNumber} setTrainingPlanNumber={setTrainingPlanNumber} setCurrentQualId={setCurrentQualId} />
                        :
                        null
                    }
                    <h4 className="student-training-plan__qual-heading mbh-green-text">{studentQuals[trainingPlanNumber].Qualification.AntaId} {capitaliseEachWordOfQualName(studentQuals[trainingPlanNumber].Qualification)}</h4>
                    <TrainingPlanInfoBoxes currentQual={studentQuals[trainingPlanNumber].Qualification} />  
                    <TrainingPlanTableContainer studentQual={studentQuals[trainingPlanNumber]} deliveryMode={deliveryMode} hoursUnitsObject={hoursUnitsObject} handleUpdateComments={handleUpdateComments} trainers={trainersToSelect} handleUpdateTrainer={handleUpdateTrainer} currentQualId={currentQualId} studentID={currentStudentID} />
                    
                    {
                        studentCompetencies.length ?
                        <DragDropZone studentCompetencies={studentCompetencies} studentCoreElective={studentCoreElective} currentQualification={studentQuals[trainingPlanNumber]} hoursUnitsObject={hoursUnitsObject} setHoursUnitsObject={setHoursUnitsObject} antaID={studentQuals[trainingPlanNumber].Qualification.AntaId} qualName={studentQuals[trainingPlanNumber].Qualification.Name} />
                        :
                        <img src={Spinner} alt="Spinner" />
                    }
                </>
                :
                qualsAwaitingQuotes.length ?
                qualsAwaitingQuotes.map((qualification, index) => (
                    qualification.QuoteId===null?
                    <div className="student-training-plan__quote"> 
                        <h6 key={index}>Your chosen qualification <strong>{qualification.Qualification.AntaId} {qualification.Qualification.Name}</strong> is awaiting a quote.</h6>
                        <button className="btn mbh-red student-training-plan__quote--btn" onClick={() => handleCancelQuote(qualification)}>Cancel</button>
                    </div>
                    :
                        !qualification.ConfirmedCost?
                        <div className="student-training-plan__quote"> 
                            <h6>The quote for <strong>{qualification.Qualification.AntaId} {qualification.Qualification.Name}</strong> is ${qualification.TuitionFee.toFixed(2)} </h6>
                            <div className="student-training-plan__quote--btn-group">
                                <button className="btn mbh-green student-training-plan__quote--btn" onClick={() => handleAcceptQuote(qualification)}>Accept Quote</button>
                                <button className="btn mbh-red student-training-plan__quote--btn" onClick={() => handleCancelQuote(qualification)}>Cancel</button>
                            </div>
                        </div>
                        :
                        null
                ))
                :
                <h5>Not currently enrolled in a qualification</h5>
            }
            {
                currentStudentQual &&
                <StudentEvents studentFirstName={currentStudentQual.Student.FirstName} />
            }
            </div>
    )
}

export default StudentTrainingPlan
