export const dateTimeToDateString = (date, format) => {
    let stringDate = "";
    if(date){                          
        const d = new Date(date)
        const year = d.getFullYear()
        const month = d.getMonth()+1
        const day = d.getDate()
        let zeroM = "";
        let zeroD = "";
        if (month < 10){
            zeroM = "0";
        }
        if (day < 10){
            zeroD = "0";
        }

        if(format === 1){
            stringDate = `${year}-${zeroM}${month}-${zeroD}${day}`;
        }else{
            stringDate = `${zeroD}${day}-${zeroM}${month}-${year}`;
        }
    }
    return stringDate;
}