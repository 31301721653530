import React, {useState, useEffect} from 'react'
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import './EventsCreateNew.scss'
import M from 'materialize-css';
import DatePickerComp from '../DatePickerComp/DatePickerComp';
import TextEditor from '../TextEditor/TextEditor';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment'
import dayjs from 'dayjs'
import EventAddList from '../EventAddList/EventAddList';
import EventAttendeeList from '../EventAttendeeList/EventAttendeeList';
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';

const EventsCreateNew = ({currentPage}) => {

    let history = useHistory()
    const { authToken } = useThemeContext()

    const studentUrl = `${baseUrl}/api/students?$filter=IsDisabled eq false`;
    const [isLoadingStudents, students, studentsErr] = useDataFetch(studentUrl, authToken)

    const trainerUrl = `${baseUrl}/api/trainers?$filter=IsDisabled eq false`;
    const [isLoadingTrainers, trainers, trainersErr] = useDataFetch(trainerUrl, authToken)

    const typeUrl = `${baseUrl}/api/eventtypes`;
    const [isLoadingTypes, types, typesErr] = useDataFetch(typeUrl, authToken)

    const locationUrl = `${baseUrl}/api/deliverylocations?&$Filter=IsDisabled ne true&$orderBy=DeliveryLocation`;
    const [isLoadingLocation, locations, locationsErr] = useDataFetch(locationUrl, authToken)

    const [newEventId, setNewEventId] = useState(null)

    const [showAddListForm, setShowAddListForm] = useState(false)

    const [newAttendeeList, setNewAttendeeList] = useState(null)

    const appendCurrentTimezone = () => {
        // Checks if it is currently daylight saving time (DST)
        if (moment().isDST()) {
            return ":00+11:00"
        } else {
            return ":00+10:00"
        }
    }

    //initial dates should default to tomorrow at 8:30am
    const initialDateTime = dayjs().add(1, 'day').format().substring(0,11)+ "08:30" + appendCurrentTimezone();
    
    const [eventObj, setEventObj] = useState({
        Subject: "",
        Description: "",
        StartDateTime: initialDateTime,
        EndDateTime: initialDateTime,
        DeliveryLocationID: null,
        EventTypeID:null,
        TrainerID:null,
        SupportID:null,
        IsCancelled:false
    })    
   
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        M.updateTextFields();

        var tooltippedElems = document.querySelectorAll('.tooltipped');
        var tooltippedInstances = M.Tooltip.init(tooltippedElems, {});
    })

    //this is to get rid of the unnoying underline in the attendees box 
    useEffect(() => {
        const input = document.getElementById("grouped-demo");
        if (input && !input.classList.contains("browser-default")) {
          input.classList.add("browser-default");
        }
      });

    useEffect(() => {
        setShowAddListForm(false)
    }, [newAttendeeList])

    const handleNameCategorisation = (option) => {
        const surname = option.Student ? option.Student.Surname.trim() : option.Surname.trim()
          if (surname.length) {
              let firstLetter = surname.charAt(0).toUpperCase()
              if (firstLetter.toLowerCase() != firstLetter.toUpperCase()) {
                  return {
                      firstLetter: firstLetter,
                      ...option,
                    };
              }
      
              return {
                firstLetter: "Other",
                ...option,
              };
          }
      }
    
    let options = students
                .sort((a, b) => a.Surname.trim() > b.Surname.trim())
                .map(option => handleNameCategorisation(option))
                .filter(option => option !== undefined)
                

    const onCloseEventCreation = () => {
        if (currentPage === "summary") {
            history.push('/admin-portal/events')
        } else {
            history.push('/admin-portal/events/calendar')
        }
    }

    const updateeventObj=(e) => {
        const {name, value} = e.target;
        setEventObj(prev => ({...prev, [name]: value }))
    }

    const handleAttendeeListChange = (newStudent) => {
        const studentToAdd = newStudent.Student ? newStudent.Student : newStudent

        if (!studentToAdd.firstLetter) {
            studentToAdd.firstLetter = studentToAdd.Surname.charAt(0)
        }

        if (!newAttendeeList) {
            setNewAttendeeList([studentToAdd])
        } else {
            const listObj = arrayToObjectWithKey(newAttendeeList, "Id")
            if (studentToAdd!==undefined && listObj[studentToAdd.Id] !== undefined) {
                M.toast({html: "Student(s) is already attending", displayLength: 2000});
                return; 
            }
            setNewAttendeeList(prev => ([...prev, studentToAdd]))
        }
    }

    const handleDateChange = (e, date) => {
       
        const dateString = dayjs(date).format("YYYY-MM-DD");     
        const currentDate = eventObj[e]
        let newDate = dateString + currentDate.substring(10,25)
        if (dayjs(newDate).isValid()){
            setEventObj(prev => ({...prev, [e]: newDate }))
        }
        else{
            M.toast({html: "Please select a valid Date", displayLength: 1000});
        }
    }

    const handleTimeChange = (e) => {
        const {name, value} = e.target;

        let currentDate; 
        let newDate;

        if (name === "startTime"){
            currentDate = eventObj["StartDateTime"];
            newDate = currentDate.substring(0,11)+ value + appendCurrentTimezone();
            setEventObj(prev => ({...prev, "StartDateTime": newDate }))
        }
        if (name === "endTime"){
            currentDate = eventObj["EndDateTime"];
            newDate = currentDate.substring(0,11)+ value + appendCurrentTimezone();
            setEventObj(prev => ({...prev, "EndDateTime": newDate }))
        }

    }
    
    const handleDescriptionChange = (name, content) =>{
        setEventObj(prev => ({...prev, [name]: content }))
    }

    const checkAPIResponse = (data, status, sender) => {

        if (status === 200 || status === 201){
            if (sender === 'createevent' && data.Id !== undefined){
                setNewEventId(data.Id)
            }else{
                history.push('/admin-portal/events/calendar')
            }
        }else{
            if(data.Message){
                M.toast({html: data.Message, displayLength: 2000});
            }
        }
    }

    const validateEventDetails = () => {
        //validation
        if (eventObj["Subject"].trim() === ""){
            M.toast({html: "Please enter a subject for the event", displayLength: 1000});
            return false
        }
        if (!moment(eventObj["StartDateTime"]).isValid()){
            M.toast({html: "Please enter a valid Date and Time", displayLength: 1000});
            return false
        }
        if(moment(eventObj["StartDateTime"]) > moment(eventObj["EndDateTime"])){
            M.toast({html: "Event cannot end before it begins :-p", displayLength: 1000});
            return false
        }

        return true
    }

    const onCreateEvent = () => {
        let status;
     
        if (validateEventDetails()){
            fetch(`${baseUrl}/api/events`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                body: JSON.stringify(eventObj)
            })
            .then(response => {
                status = response.status;
                checkAPIResponse("", status, "createevent")
                return response.json()
            })
            .then(data => {
                if (newAttendeeList) {
                    postAttendeeList(data.Id, status)
                }
            })
            .catch(err => console.log("Error: ", err)) 
        }
    }
    
    const postAttendeeList = (eventID, status) => {
        const formattedList = newAttendeeList.map(attendee => ({
            EventID: +eventID,
            StudentID: attendee.Student ? attendee.Student.Id : attendee.Id
        }))

        fetch(`${baseUrl}/api/eventregistrations/addlist`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify({List: formattedList})
        })
        .then(response => {
            status = response.status;
            checkAPIResponse("", status, "post-attendees")
            return response.json()
        })
    }

    const deleteAttendee = (id) => {

        const filteredList = newAttendeeList
                                    .filter(attendee => attendee.Student ? attendee.Student.Id !== id : attendee.Id !== id)
                                    .sort((a, b) => {
                                        const firstSurname = a.Student ? a.Student.Surname : a.Surname
                                        const secondSurname = b.Student ? b.Student.Surname : b.Surname
                                        
                                        return firstSurname > secondSurname
                                    })
        if (filteredList.length) {
            setNewAttendeeList(filteredList)
        } else {
            setNewAttendeeList(null)
        }
    }
    
    const [attendeeValue, setAttendeeValue] = useState("")

    return (
        <div className="events-create-new">

            <article className="events-create-new__header">
                <h3 className="mbh-green-text">Create Event</h3>
                <div className="events-create-new__btns">
                    <a className="btn-floating mbh-green" style={{marginRight:"5px"}} onClick={onCreateEvent}><i className="material-icons notextselect">save</i></a>
                    <a className="btn-floating mbh-green" onClick={onCloseEventCreation}><i className="material-icons notextselect">close</i></a> 
                </div>
            </article>
            {
                newAttendeeList &&
                <EventAttendeeList allAttendees={newAttendeeList} deleteAttendee={deleteAttendee} />
            }
            <div className="events-create-new__form">
                <div className="row events-edit__add-attendees">
                    <div className="input-field col s12 m10">
                        <Autocomplete
                            id="grouped-demo"
                            style={{width: "100%"}}
                            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => `${option.FirstName.trim()} ${option.Surname.trim()}`}
                            renderInput={(params) => <TextField {...params} variant="outlined" label="Add Attendees" />}
                            clearOnBlur={true}
                            clearOnEscape={true}
                            inputValue={attendeeValue}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'reset') {
                                  setAttendeeValue('')
                                  return
                                } else {
                                    setAttendeeValue(newInputValue)
                                }
                              }}
                            onChange={(event, newValue) => {
                                if (newValue !== null){
                                    handleAttendeeListChange(newValue);
                                }
                            }}
                        />
                    </div>
                    <div className="col s12 m2">
                        {
                            showAddListForm ?
                            <button className="btn-floating btn-large right" onClick={() => setShowAddListForm(false)}><i className="material-icons">close</i></button>
                            :
                            <button className="btn-floating btn-large right" onClick={() => setShowAddListForm(true)}><i className="material-icons">format_list_bulleted</i></button>
                        }
                    </div>
                </div>
                {
                    showAddListForm &&
                    <>
                        <EventAddList
                        eventId={newEventId}
                        attendeeList={newAttendeeList}
                        setAttendeeList={setNewAttendeeList} 
                        authToken={authToken} />
                        <br />
                        <br />
                    </>
                }
                <div className="row">
                    <div className="input-field col s12">
                        <input id="subject" type="text" name="Subject" onChange={updateeventObj} />
                        <label htmlFor="subject">Subject</label>
                    </div>
                </div>

                <div className="row">
                    <div className="input-field col s12 l6">
                        <select defaultValue="0" name="EventTypeID" onChange={updateeventObj}>
                        <option value="0" disabled>Select Event Type</option>
                       {
                           types.map((type, index) => {
                                return <option key={index} value={type.Id}>{type.Type}</option>
                           })
                       }
                        </select>
                        <label>Type</label>
                    </div>
                    <div className="input-field col s12 l6">
                        <select defaultValue="0" name="DeliveryLocationID" onChange={updateeventObj}>
                            <option value="0" disabled>Select Location</option>
                            {
                                locations.map((location, index) => {
                                    return <option key={index} value={location.Id}>{location.DeliveryLocation}</option>
                                })
                            }
                        </select>
                        <label>Location</label>
                    </div>
                </div>

                <div className="row">
                    <div className="input-field col s12 l6">
                        <select defaultValue="0" name="TrainerID" onChange={updateeventObj}>
                        <option value="0" disabled>Select Trainer</option>
                        {
                            trainers.map((trainer, index) =>{
                                return <option key={index} value={trainer.Id}>{trainer.FirstName} {trainer.Surname }</option>
                            })
                        }
                        </select>
                        <label>Trainer</label>
                    </div>
                    <div className="input-field col s12 l6">
                    <select defaultValue="0" name="SupportID" onChange={updateeventObj}>
                        <option value="0" disabled>Select Support</option>
                        {
                            trainers.map((trainer, index) =>{
                                return <option key={index} value={trainer.Id}>{trainer.FirstName} {trainer.Surname }</option>
                            })
                        }
                        </select>
                        <label>Support</label>
                    </div>
                </div>

                <div className="row">
                    <div className="col s6 l2">
                        <label htmlFor="startDate">From</label>
                        <DatePickerComp tabindex ="7" name="StartDateTime" defaultDate={Date.parse(dayjs(initialDateTime).format("YYYY-MM-DD"))}  handleDateUpdate={handleDateChange} />
                    </div>
                    <div className="input-field col s6 l2" style={{marginTop:"22px"}}>
                        <TextField
                                id="startTime"
                                name="startTime"
                                type="time"
                                defaultValue="08:30"
                                InputLabelProps={{
                                shrink: true,
                                }}
                                inputProps={{
                                step: 300, // 5 min
                                }}
                                onChange={handleTimeChange}
                            />
                    </div>
                    
                    <div className="col s12 l2 center hide-on-med-and-down"></div>           
                    
                    <div className="col s6 l2">
                        <label htmlFor="endDate">To</label>
                        <DatePickerComp tabindex ="9" name="EndDateTime" defaultDate={Date.parse(dayjs(initialDateTime).format("YYYY-MM-DD"))}  handleDateUpdate={handleDateChange} />
                    </div>
                    <div className="input-field col s6 l2" style={{marginTop:"22px"}}>
                        <TextField
                            id="endTime"
                            name="endTime"
                            type="time"
                            defaultValue="08:30"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            step: 300, // 5 min
                            }}
                            onChange={handleTimeChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <label htmlFor="details">Details for the Event</label>
                        <TextEditor id="Description" name="Description" initValue={""} handleDescriptionChange={handleDescriptionChange} descriptionField="Description" addOrEditQual="add" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventsCreateNew
