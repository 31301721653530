import React, {useState, useEffect} from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './StudentPortal.scss';
import M from "materialize-css";
import StudentPortalHome from '../../components/StudentPortalHome/StudentPortalHome';
import StudentProfile from '../../components/StudentProfile/StudentProfile';
import StudentQualifications from '../../components/StudentQualifications/StudentQualifications';
import StudentTrainingPlan from '../../components/StudentTrainingPlan/StudentTrainingPlan';
import AdminPortalHome from '../../components/AdminPortalHome/AdminPortalHome';
import StudentMenu from '../../components/StudentMenu/StudentMenu';
import AdminMenu from '../../components/AdminMenu/AdminMenu';
import DownloadPage from '../../components/DownloadPage/DownloadPage';
import EditCompetencies from '../../components/EditCompetencies/EditCompetencies';
import EditQualifications from '../../components/EditQualifications/EditQualifications';
import QualCompEdit from '../QualCompEdit/QualCompEdit';
import AddQual from '../../components/AddQual/AddQual';
import AddComp from '../../components/AddComp/AddComp';
import AdministerStudent from '../../components/AdministerStudent/AdministerStudent';
import AdminSettings from '../../components/AdminSettings/AdminSettings';
import AdminLocations from '../../components/AdminLocations/AdminLocations';
import AdminUsers from '../../components/AdminUsers/AdminUsers';
import Events from '../../components/Events/Events';
import AdminDuplicates from '../../components/AdminDuplicates/AdminDuplicates';
import AdminResults from '../../components/AdminResults/AdminResults';
import AdminChangePassword from '../../components/AdminChangePassword/AdminChangePassword';
import ManageFeeWaiverCodes from '../../pages/ManageFeeWaiverCodes/ManageFeeWaiverCodes';
import EventsTrainers from '../../components/EventsTrainers/EventsTrainers';
import EventsCalendar from '../../components/EventsCalendar/EventsCalendar';
import EventsCreateNew from '../../components/EventsCreateNew/EventsCreateNew';
import EventsEdit from '../../components/EventsEdit/EventsEdit';
import EventDetails from '../../components/EventDetails/EventDetails';
import EventsCreateType from '../../components/EventsCreateType/EventsCreateType';
import EventsGroups from '../../components/EventsGroups/EventsGroups';
import EventsReport from '../../components/EventsReport/EventsReport';
import { useThemeContext } from '../../ThemeContext';
import TrainingPlanAcceptanceReport from '../../components/TrainingPlanAcceptanceReport/TrainingPlanAcceptanceReport';

const StudentPortal = ({route}) => {

    let history = useHistory()
    const { userRole } = useThemeContext()

    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        var instances = M.Sidenav.init(elems, { edge: 'right' });
    }, [])

    const [menuSmall, setMenuSmall] = useState(true);

    const handleMenuState = () => {
        setMenuSmall(!menuSmall);
    }

    const onStudentSelect = (e, page) => {
        //open student in new tab
        if(page === 'administerStudent'){
            const studentId = e.target.dataset.id;
            const newPageUrl = "/#/admin-portal/administer-student";
            window.sessionStorage.setItem('currentStudent', studentId);
            window.open(newPageUrl, "_blank") 
        }
    }

    const menuStyle = menuSmall? "student-portal__nav--small" : "student-portal__nav--big";
    const btnSize = menuSmall? "btn-small" : "btn-large";

    // Tries to grab authorization token if it exists
    const isAuth = !!sessionStorage.getItem('mbhToken')

    // When adding a new event, this keeps track of 
    const [currentPage, setCurrentPage] = useState("summary")

    return (
        <div className="student-portal">
            <nav className="student-portal__nav" style={{width: menuSmall? "90px": "300px"}}>
                {
                    userRole === 'Administrator'? 
                    <AdminMenu menuStyle={menuStyle} menuSmall={menuSmall} history={history} />: 
                    <StudentMenu menuStyle={menuStyle} menuSmall={menuSmall} history={history} />
                }
                
            
                <div className="student-portal__nav-arrow student-portal__menu-btn">
                    <a className={`btn-floating ${btnSize} white`} onClick={handleMenuState}>
                        <i className="material-icons mbh-green-text">{menuSmall? `keyboard_arrow_right` : `keyboard_arrow_left`}</i>
                    </a>
                </div>
            </nav>
                <div className="student-portal__content container">
                    {
                        !isAuth ?
                        <Redirect to="/login" />
                        : 
                        <Switch>
                            <Route exact path='/student-portal' render={() => <StudentPortalHome />} />
                            <Route path='/student-portal/profile' render={() => <StudentProfile route={route} />} />
                            <Route path='/student-portal/qualifications' render={() => <StudentQualifications />} />
                            <Route path='/student-portal/training-plan/:courseId?' render={() => <StudentTrainingPlan />} />
                            <Route exact path='/student-portal/events' render={() => <Events setCurrentPage={setCurrentPage} />} />
                            <Route path='/student-portal/events/calendar' render={() => <EventsCalendar setCurrentPage={setCurrentPage} />} />
                            <Route path='/student-portal/events/event-details/:eventId' render={() => <EventDetails currentPage={currentPage} setCurrentPage={setCurrentPage} />} />
                            <Route exact path='/admin-portal' render={() => <AdminPortalHome onStudentSelect={onStudentSelect} />} />
                            <Route path='/admin-portal/reports' render={() => <TrainingPlanAcceptanceReport />} />
                            <Route path='/admin-portal/download' render={() => <DownloadPage onStudentSelect={onStudentSelect} />} />
                            <Route path='/admin-portal/student-training-plan' render={() => <StudentTrainingPlan />} />
                            <Route exact path='/admin-portal/qualifications-and-competencies' render={() => <QualCompEdit />} />
                            <Route path='/admin-portal/qualifications-and-competencies/add-qualification' render={() => <AddQual />} />
                            <Route path='/admin-portal/qualifications-and-competencies/add-competency' render={() => <AddComp />} />
                            <Route path='/admin-portal/qualifications-and-competencies/edit-qualifications' render={() => <EditQualifications />} />
                            <Route path='/admin-portal/qualifications-and-competencies/edit-competencies' render={() => <EditCompetencies />} />
                            <Route exact path='/admin-portal/administer-student' render={() => <AdministerStudent />} />
                            <Route path='/admin-portal/administer-student/student-profile' render={() => <StudentProfile route={route} />} />
                            <Route exact path='/admin-portal/administer-student/student-training-plan' render={() => <StudentTrainingPlan />} />
                            <Route path='/admin-portal/administer-student/student-training-plan/:courseId' render={() => <StudentTrainingPlan />} />
                            <Route path='/admin-portal/administer-student/student-qualifications' render={() => <StudentQualifications />} />
                            <Route exact path='/admin-portal/admin' render={() => <AdminSettings />} />
                            <Route path='/admin-portal/admin/locations' render={() => <AdminLocations />} />
                            <Route path='/admin-portal/admin/results' render={() => <AdminResults />} />
                            <Route path='/admin-portal/admin/users' render={() => <AdminUsers />} />
                            <Route path='/admin-portal/admin/duplicates' render={() => <AdminDuplicates />} />
                            <Route path='/admin-portal/admin/change-password' render={() => <AdminChangePassword />} />
                            <Route path='/admin-portal/admin/manage-waiver-codes' render={() => <ManageFeeWaiverCodes />} />
                            <Route exact path='/admin-portal/events' render={() => <Events setCurrentPage={setCurrentPage} />} />
                            <Route path='/admin-portal/events/calendar' render={() => <EventsCalendar setCurrentPage={setCurrentPage} />} />
                            <Route path='/admin-portal/events/create' render={() => <EventsCreateNew currentPage={currentPage} />} />
                            <Route path='/admin-portal/events/report' render={() => <EventsReport />} />
                            <Route path='/admin-portal/events/create-type' render={() => <EventsCreateType setCurrentPage={setCurrentPage} />} />
                            <Route path='/admin-portal/events/manage-groups' render={() => <EventsGroups setCurrentPage={setCurrentPage} />} />
                            <Route path='/admin-portal/events/edit/:eventId' render={() => <EventsEdit />} />                         
                            <Route path='/admin-portal/events/trainers' render={() => <EventsTrainers setCurrentPage={setCurrentPage} />} />
                            <Route path='/admin-portal/events/event-details/:eventId' render={() => <EventDetails currentPage={currentPage} setCurrentPage={setCurrentPage} />} />
                        </Switch>
                    }
                </div>
        </div>
    )
}

export default StudentPortal
