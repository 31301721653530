import React from 'react'
import TeamSpirit from '../../assets/illustrations/team-spirit.svg'
import YouTubeTutorial from '../../assets/illustrations/tutorial.svg'
import Graduation from '../../assets/illustrations/graduation.svg'
import Certificate from '../../assets/illustrations/certificate.svg'
import ProjectTeam from '../../assets/illustrations/project-team.svg'
import PersonalGoals from '../../assets/illustrations/personal-goals.svg'

const StageThreeCertIVPM = ({ history }) => {
    return (
        <section id="path-one-stage-three" className="career-pathways__stages">
            <article className="career-pathways__stage-three--headings">
                <h4><span className="mbh-green-text bold">Stage Three</span><br/><br/>BSB40920 Certificate IV in Project Management Practice</h4>
            </article>
            <section className="career-pathways__stage-two--pathway-section">
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={YouTubeTutorial} alt="YouTube Tutorial" />
                    </figure>
                    <p>Attend Certificate IV Qualification</p>
                </article>
                <hr />
                <article className="career-pathways__article reverse-flex">
                    <p>Be mentored by Diploma students and mbh staff</p>
                    <figure>
                        <img className="responsive-img" src={TeamSpirit} alt="Team Spirit" />
                    </figure>
                </article>
                <hr />
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={ProjectTeam} alt="Project Team" />
                    </figure>
                    <p>Mentor Cert III people through their assessment</p>
                </article>
                <hr />
                <article className="career-pathways__article reverse-flex">
                    <p>Deliver a real life project that matters</p>
                    <figure>
                        <img className="responsive-img" src={PersonalGoals} alt="Personal Goals" />
                    </figure>
                </article>
                <hr />
                <article className="career-pathways__article career-pathways__graduation">
                    <p>Gain your qualification</p>
                    <figure>
                        <img className="responsive-img" src={Graduation} alt="Graduation" />
                    </figure>
                    <br/>
                    <div className="career-pathways__buttons-container">
                        <button className="btn career-pathways__stage-four--headings__button mbh-black lighten-5 black-text" onClick={() => history.push('/contact-us')}>Smart and Skilled</button>
                        <button className="btn career-pathways__stage-four--headings__button mbh-green" onClick={() => history.push('/qualifications/certificate-IV-in-project-management')}>View Qualification</button>
                    </div>
                
                <hr />
                <article className="career-pathways__article reverse-flex">
                    <p>Become a Project Manager</p>
                    <figure>
                        <img className="responsive-img" src={Certificate} alt="Certificate" />
                    </figure>
                </article>
                <button className="btn mbh-black lighten-5 black-text career-pathways__sas-contact-us--button" onClick={() => history.push('/contact-us')}>Contact Us</button>
                </article>
                <hr />
            </section>
        </section>
    )
}

export default StageThreeCertIVPM
