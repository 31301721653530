import React, { useEffect } from 'react'
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import M from 'materialize-css';
import './AdministerStudentMenuItems.scss'
import { useThemeContext } from '../../ThemeContext';
import { useHistory } from 'react-router-dom';


const AdministerStudentMenuItems = ({page}) => {

    const { selectedStudentId, authToken } = useThemeContext()
    let history = useHistory()

    const validateNatFormsUrl = `${baseUrl}/api/natforms/validatedates/${selectedStudentId}?$orderBy=AntaId`;
    const [isLoadingValNat, validateNatforms, errorValNat] = useDataFetch(validateNatFormsUrl, authToken)

    const validateSTSUrl = `${baseUrl}/api/stsbulkquotedata/validatedata/${selectedStudentId}`;
    const [isLoadingSTS, validateSTS, errorSTS] = useDataFetch(validateSTSUrl, authToken)

    useEffect(() => {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
    })

    return (
        <>
            <div className="administer-student-menu-items">
                <a className="btn-floating btn-large mbh-green" disabled={page==='Profile'?true:false} onClick={(e) => {history.push('/admin-portal/administer-student/student-profile')}}><i className="material-icons">person</i></a>
                <a className="btn-floating btn-large mbh-green" disabled={page==='Training'?true:false} onClick={(e) => {history.push('/admin-portal/administer-student/student-training-plan')}}><i className="material-icons">assignment</i></a>
                <a className="btn-floating btn-large mbh-green" disabled={page==='Qualifications'?true:false} onClick={(e) => {history.push('/admin-portal/administer-student/student-qualifications')}}><i className="material-icons">school</i></a>
                <a className="btn-floating btn-large mbh-red modal-trigger" href="#modal1" style={{ display:(validateNatforms.length || validateSTS.length) ?"inline-block": "none"}}><i className="material-icons">warning</i></a>
            </div>
            <div id="modal1" className="modal modal-fixed-footer">
                <div class="modal-content">
                    <h4>Warnings</h4>
                    {
                        validateSTS.length ?
                        <>
                            <h5>STS File Potential Issues</h5>
                            {
                                validateSTS.map((qual, index) => (
                                    <div>
                                        <p key={index}>Qualification <strong>{qual.AntaId}</strong>: </p>
                                        {qual.DOB === null && <p className="black-text">- Date of birth missing</p>}
                                        {qual.StartDate === null && <p className="black-text">- Start date is missing</p>}
                                        {qual.EndDate === null && <p className="black-text">- End date is missing</p>}
                                        {qual.ATSI === null && <p className="black-text">- Aboriginal or Torres Strait Islander field missing</p>}
                                        {qual.ResidencyStatusAnswer === null && <p className="black-text">- Residency Status missing</p>}
                                        {qual.WorkInNSWAnswer === null && <p className="black-text">- Do you work in NSW field missing</p>}
                                        {qual.TraineeAnswer === null && <p className="black-text">- Registered NSW apprentice or trainee field missing</p>}
                                        {qual.SocialHousingAnswer === null && <p className="black-text">- Social housing field missing </p>}
                                        {qual.LTUAnswer === null && <p className="black-text">- Unemployed for 52 weeks or more field missing</p>}
                                        <br />
                                    </div>
                                ))
                            }
                        </>
                        :
                        null
                    }
                    
                    {
                        validateNatforms.length ?
                        <>
                            <h5>NAT File potential issues</h5>
                            {
                                validateNatforms.map((issue, index, arr)=>(
                                    <div key={index}>
                                        {
                                            index > 0 && issue.AntaId === arr[index-1].AntaId ? null: <><br/><p>Qualification <strong>{issue.AntaId}</strong>:</p></>
                                        }
                                        <p className="black-text">{issue.CompetencyId}:  <span>{issue.Reason}</span></p>
                                    </div>
                                ))
                            }
                        </>
                        :
                        null
                    }
                </div>
                <div class="modal-footer">
                    <button class="modal-close btn-flat mbh-red white-text">Close</button>
                </div>
            </div>
        </>
    )
}

export default AdministerStudentMenuItems
