import React, {useEffect} from 'react';
import M from 'materialize-css';
import './Testimonials.scss';

const Testimonials = () => {
    var elems;
    var instance;
   useEffect(()=>{
        elems = document.querySelector('.carousel');
        var instances = M.Carousel.init(elems, {indicators: true, duration: 200, numVisible: 3});
        instance = M.Carousel.getInstance(elems);
   });

   return(
        <div className="container testimonials" id="testimonials">
            
            <h3 className="center-align">Client Testimonials</h3>
            
            <div className="testimonials__carousel-container">
                <button className="btn carousel-btn" onClick={() => instance.prev()}><i class="material-icons">keyboard_arrow_left</i></button>
                <div className="carousel carousel-slider center testimonials__carousel">
                    <div className="carousel-item mbh-black lighten-5 mbh-green-text testimonials__text" href="#one!">
                        <p className="testimonials__feedback" style={{fontSize: "1.2rem"}}>“Working with mbh is a real and enjoyable partnership, the qualification tools are immediately implementable and this has delivered positive outcomes for our organisation”</p>
                        <p className="testimonials__person">Pam White A&amp;B Mylec | Business Improvement Manager</p>
                    </div>
                    <div className="carousel-item mbh-black lighten-5 mbh-red-text testimonials__text" href="#two!">
                        <p className="testimonials__feedback" style={{fontSize: "1.2rem"}}>“In engaging mbh to deliver this training, their expertise in Project Management impressed us. I would highly recommend mbh as a provider of Project Management training”</p>
                        <p className="testimonials__person">Neva Jones City of Marion | Learning and Development Partner</p>
                    </div>
                    <div className="carousel-item mbh-black lighten-5 mbh-green-text testimonials__text" href="#three!">
                        <p className="testimonials__feedback" style={{fontSize: "1.2rem"}}>“The service provided once you have completed the qualification is unquestionable - just being able to pick up the phone when you need clarification speaks for itself”</p>
                        <p className="testimonials__person">Luisa McKay | Project Manager</p>
                    </div>
                    <div className="carousel-item mbh-black lighten-5 mbh-red-text testimonials__text" href="#four!">
                        <p className="testimonials__feedback" style={{fontSize: "1.2rem"}}>“I really appreciated the detailed and constructive feedback on my assessment pieces that was provided with such prompt and thoughtful attention”</p>
                        <p className="testimonials__person">Laura Hall BUPA | IT Project Office Coordinator</p>
                    </div>
                    <div className="carousel-item mbh-black lighten-5 mbh-green-text testimonials__text" href="#five!">
                        <p className="testimonials__feedback" style={{fontSize: "1.2rem"}}>“The qualification was streamlined and less formal than other institutes whilst supporting individuals and the group in developing their skills”</p>
                        <p className="testimonials__person">John Visser</p>
                    </div>
                    <div className="carousel-item mbh-black lighten-5 mbh-red-text testimonials__text" href="#six!">
                        <p className="testimonials__feedback" style={{fontSize: "1.2rem"}}>“Sarah’s ongoing support throughout the training was invaluable – Sarah was able to clarify any questions, be a sounding board to run ideas past and encourage ideas into practice”</p>
                        <p className="testimonials__person">Donna O'Connor | NSW council employee</p>
                    </div>
                </div>
                <button className="btn carousel-btn" onClick={() => instance.next()}><i class="material-icons">keyboard_arrow_right</i></button>
            </div>
        </div>
    ); 
}

export default Testimonials;