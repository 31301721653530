import React from 'react'
import './NavTiles.scss'

const NavTiles = ({ history }) => {

    return (
        <section className="navTiles">
            <div className="navTiles__tile navTiles__active" data-value="home" onClick={() => history.push('/register')}>
                <i className="navTiles__icon material-icons" data-value="home" data-id="0">assignment_ind</i>
                <p data-value="home" data-id="0">Book Online</p>
            </div>
            <div className="navTiles__tile" data-value="courses" onClick={() => history.push('/about-us')}>
                <i className="navTiles__icon material-icons" data-value="profile" data-id="4">people</i>
                <p data-value="profile" data-id="4">About Us</p>
            </div>
            <div className="navTiles__tile" onClick={() => history.push('/qualifications')}>
                <i className="navTiles__icon material-icons" data-id="2">assignment</i>
                <p data-value="courses" data-id="2">Qualifications</p>
            </div>
            <div className="navTiles__tile" data-value="courses" onClick={() => history.push('/case-studies')}>
                <i className="navTiles__icon material-icons" data-value="profile" data-id="1">book</i>
                <p data-value="profile" data-id="1">Case Studies</p>
            </div>
            <div className="navTiles__tile" data-value="courses" onClick={() => history.push('/testimonials')}>
                <i className="navTiles__icon material-icons" data-value="profile" data-id="4">rate_review</i>
                <p data-value="profile" data-id="4">Testimonials</p>
            </div>
            <div className="navTiles__tile" data-value="courses" onClick={() => history.push('/smart-and-skilled')}>
                <i className="navTiles__icon material-icons" data-value="profile" data-id="3">grade</i>
                <p data-value="profile" data-id="3">Smart &amp; Skilled</p>
            </div>
        </section>
    )
}

export default NavTiles
