import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../functions/globalVariables'
import { useDataFetch } from '../../hooks/useDataFetch'
import { CSVLink } from "react-csv";
import M from 'materialize-css'
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from '../../assets/spinner.svg';
import { withStyles } from "@mui/styles";
import './EventsReport.scss'
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';

const EventsReport = () => {

    let history = useHistory()
    const { authToken } = useThemeContext()

    const studentsUrl = `${baseUrl}/api/students?$select=Id,FirstName,Surname,IsDisabled&$orderBy=Surname`;
    const [isLoadingStudents, students, errStudents] = useDataFetch(studentsUrl, authToken)

    const trainersUrl = `${baseUrl}/api/trainers`;
    const [isLoadingTrainers, trainers, errTrainers] = useDataFetch(trainersUrl, authToken)

    const eventsUrl = `${baseUrl}/api/events?$filter=IsCancelled eq false`;
    const [isLoadingEvents, events, errEvents] = useDataFetch(eventsUrl, authToken)

    const eventTypesUrl = `${baseUrl}/api/eventtypes`;
    const [isLoadingEventTypes, eventTypes, errEventTypes] = useDataFetch(eventTypesUrl, authToken)

    const registrationsUrl = `${baseUrl}/api/eventregistrations`;
    const [isLoadingRegistrations, registrations, errRegistrations] = useDataFetch(registrationsUrl, authToken)
    
    // These will hold object form of students and events arrays to make filtering faster
    const [studentsObj, setStudentsObj] = useState({})
    const [eventsObj, setEventsObj] = useState({})
    const [eventTypesObj, setEventTypesObj] = useState({})
    const [trainersObj, setTrainersObj] = useState({})

    const [selectedEventID, setSelectedEventID] = useState(null)
    const [selectedStudents, setSelectedStudents] = useState({})

    const [filteredStudents, setFilteredStudents] = useState([])
    const [filteredRegistrations, setFilteredRegistrations] = useState([])
    const [filteredRegistrationsForSpecificEvent, setFilteredRegistrationsForSpecificEvent] = useState([])

    const [filterByEvent, setFilterByEvent] = useState(true)
    const [filterByStudent, setFilterByStudent] = useState(false)

    const [reportData, setReportData] = useState([])
    const [showReport, setShowReport] = useState(false)
    const [studentHasRegistrations, setStudentHasRegistrations] = useState(true)

    const [eventFilterValue, setEventFilterValue] = useState("Choose")
    const [isEventSelected, setIsEventSelected] = useState(true)
    const [doesEventHaveRegistrations, setDoesEventHaveRegistrations] = useState(true)

    // Each item of arrow array relates to the three sortable columns (i.e. studentName, eventName, startDate)
    const [isArrowVisible, setIsArrowVisible] = useState([false, false, false])

    const [studentNameAscending, setStudentNameAscending] = useState(true)
    const [eventNameAscending, setEventNameAscending] = useState(true)
    const [startDateAscending, setStartDateAscending] = useState(true)

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});

        var tooltippedElems = document.querySelectorAll('.tooltipped');
        var tooltippedInstances = M.Tooltip.init(tooltippedElems, {});
    })

    useEffect(() => {
        if (students.length) {
            setStudentsObj(arrayToObjectWithKey(students, "Id"))
            setFilteredStudents(students)
        }
    }, [students])

    useEffect(() => {
        if (events.length) {
            setEventsObj(arrayToObjectWithKey(events, "Id"))
        }
    }, [events])

    useEffect(() => {
        if (eventTypes.length) {
            setEventTypesObj(arrayToObjectWithKey(eventTypes, "Id"))
        }
    }, [eventTypes])

    useEffect(() => {
        if (trainers.length) {
            setTrainersObj(arrayToObjectWithKey(trainers, "Id"))
        }
    }, [trainers])

    useEffect(() => {
        if (students.length) {
            if (selectedEventID) {
                const selectedEventRegistrations = registrations.filter(reg => reg.EventID.toString() === selectedEventID)
                setFilteredRegistrations(selectedEventRegistrations)

                let currentAttendees = {}
                const studentsToFilter = selectedEventRegistrations.map(reg => {
                    currentAttendees = { ...currentAttendees, [reg.StudentID]: studentsObj[reg.StudentID] }
                    return studentsObj[reg.StudentID]
                })

                setSelectedStudents(currentAttendees)
                setFilteredStudents(studentsToFilter)
            }
        }
    }, [selectedEventID])

    // Column headings for the report
    const headers = [
        { label: "Student Name", key: "studentName" },
        { label: "Event Name", key: "eventName" },
        { label: "Event Type", key: "eventType" },
        { label: "Start Date Time", key: "startDateTime" },
        { label: "End Date Time", key: "endDateTime" },
        { label: "Att. Marked", key: "attendanceMarked" },
        { label: "Att. Confirmed", key: "attendanceConfirmed" },
        { label: "Trainer", key: "trainer" },
        { label: "Support", key: "support" }
    ];
    
    // Config for exporting report
    const csvReport = {
        data: reportData,
        headers: headers,
        filename: selectedEventID ? `${eventsObj[selectedEventID].Subject}.csv` : "events-report.csv"
    }

    const handleEventSelect = (e) => {
        const eventId = e.target.value
        setShowReport(false)
        setSelectedEventID(eventId)
        setEventFilterValue(eventId)
        setIsEventSelected(true)
        setDoesEventHaveRegistrations(true)
    }

    
    const handleFilterToggle = () => {
        setFilterByStudent(!filterByStudent)
        setFilterByEvent(!filterByEvent)
        reset()
    }

    const reset = () => {
        setShowReport(false)
        setFilteredRegistrations([])
        setFilteredStudents(students)
        setSelectedEventID(null)
        setEventFilterValue("Choose")
        setDoesEventHaveRegistrations(true)
        setStudentHasRegistrations(true)
    }
    
    const handleStudentFilterSelect = (event, values) => {
        if (!registrations.length || !values.length) {
            setFilteredRegistrations([])
            setShowReport(false)
            return
        }
        
        const studentsToFilter = arrayToObjectWithKey(values, "Id")
        const studentRegistrations = registrations.filter(reg => studentsToFilter[reg.StudentID])

        setStudentHasRegistrations(studentRegistrations.length > 0)
        setFilteredRegistrations(studentRegistrations)
        setShowReport(false)
    }
    
    const handleStudentSelectForSpecificEvent = (event, values) => {
        if (!filteredRegistrations.length || !values.length) {
            setFilteredRegistrationsForSpecificEvent([])
            setShowReport(false)
            return
        }
        
        const studentsToAdd = arrayToObjectWithKey(values, "Id")
        const studentRegistrations = filteredRegistrations.filter(reg => studentsToAdd[reg.StudentID])
        setFilteredRegistrationsForSpecificEvent(studentRegistrations)
        setShowReport(false)
    }

    const getRequiredRegistrations = () => {
        if (filterByEvent && filteredRegistrationsForSpecificEvent.length) {
            return filteredRegistrationsForSpecificEvent
        } else {
            return filteredRegistrations
        }
    }

    const isDataInvalid = () => {
        if (filterByEvent && !selectedEventID) {
            setIsEventSelected(false)
            return true
        } else if (filterByStudent && !filteredRegistrations.length) {
            setStudentHasRegistrations(false)
            return true
        }else if (!filteredRegistrations.length) {
            setDoesEventHaveRegistrations(false)
            return true
        }

        return false
    }

    const generateReportData = () => {
        if(isDataInvalid()) return

        setIsArrowVisible([false, false, false])

        let listOfRegistrations = getRequiredRegistrations()

        const tableRows = listOfRegistrations
        .filter(reg => eventsObj[reg.EventID] !== undefined)
        .map(reg => {

            const student = studentsObj[reg.StudentID]
            const trainerID = eventsObj[reg.EventID].TrainerID
            const trainer = trainerID ? trainersObj[trainerID] : null
            const supportID = eventsObj[reg.EventID].SupportID
            const support = supportID ? trainersObj[supportID] : null
            const event = eventsObj[reg.EventID]
            const eventTypeID = eventTypesObj[event.EventTypeID]
            const eventType = eventTypeID ? eventTypesObj[event.EventTypeID] : null

            const rowOfTable = {
                eventId: reg.EventID,
                studentName: `${student.FirstName} ${student.Surname}`,
                eventName: event.Subject,
                eventType: eventType ? eventType.Type : "None",
                startDateTime: dayjs(event.StartDateTime).format('MMM D, YYYY h:mm A'),
                endDateTime: dayjs(event.EndDateTime).format('MMM D, YYYY h:mm A'),
                attendanceMarked: reg.HasAttended ? "Yes" : "No",
                attendanceConfirmed: reg.AttendanceConfirmed ? "Yes" : "No",
                trainer: trainer ? `${trainer.FirstName} ${trainer.Surname}` : "None",
                support: support ? `${support.FirstName} ${support.Surname}` : "None"
            }

            return rowOfTable
        })
        .sort((a, b) => {
            const firstSurname = a.studentName.split(" ").slice(-1).pop();
            const secondSurname = b.studentName.split(" ").slice(-1).pop();

            return firstSurname <  secondSurname
        })

        setReportData(tableRows)
        setShowReport(true)
    }

    const filterByColumn = (columnToFilterBy) => {
        if (reportData.length <= 1) return

        const copyOfReportData = reportData.slice();

        switch(columnToFilterBy) {
            case "studentName":
                copyOfReportData.sort((a, b) => {
                    const firstSurname = a.studentName.split(" ").slice(-1).pop()
                    const secondSurname = b.studentName.split(" ").slice(-1).pop()

                    return studentNameAscending ? firstSurname.localeCompare(secondSurname) : secondSurname.localeCompare(firstSurname)
                })
                setStudentNameAscending(!studentNameAscending)
                setIsArrowVisible([true, false, false])
                break
            case "eventName":
                copyOfReportData.sort((a, b) => {
                    const firstEvent = a.eventName
                    const secondEvent = b.eventName

                    return eventNameAscending ? firstEvent.localeCompare(secondEvent) : secondEvent.localeCompare(firstEvent)
                })
                setEventNameAscending(!eventNameAscending)
                setIsArrowVisible([false, true, false])
                break
            case "startDateTime":
                if (filterByEvent) break

                copyOfReportData.sort((a, b) => {
                    const firstDate = new Date(a.startDateTime)
                    const secondDate = new Date(b.startDateTime)

                    const ascendingOrder = firstDate.getTime() - secondDate.getTime()
                    const descendingOrder = secondDate.getTime() - firstDate.getTime()

                    return startDateAscending ? ascendingOrder : descendingOrder
                })
                setStartDateAscending(!startDateAscending)
                setIsArrowVisible([false, false, true])
                break
            default:
                return
        }

        setReportData(copyOfReportData)
    }

    const goToEventDetails = (eventId) => {
        const eventDetailsUrl = `/#/admin-portal/events/event-details/${eventId}`
        window.open(eventDetailsUrl, "_blank") 
    }

    // Apply mbh colours to student selector
    const CssTextField = withStyles({
        root: {
          '& label.Mui-focused': {
            color: '#00703c',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#8C8C8C',
            },
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#00703c',
            },
          },
        },
      })(TextField);
    
    return (
        <article className="events-report">
            {
                isLoadingEvents || isLoadingRegistrations || isLoadingStudents || isLoadingTrainers ?
                <img src={Spinner} alt="Spinner" />
                :
                <>
                    <article className="events-summary__header">
                        <h3 className="mbh-green-text">Events Report</h3>
                        {
                            <div className="events-summary__header--btns">
                                <a className="btn-floating mbh-green" onClick={() => { history.push('/admin-portal/events')}} style={{marginRight:"5px"}}><i className="material-icons notextselect">arrow_back</i></a>
                            </div>
                        }
                    </article>
                    <div className="row events-report__filter-btns">
                        <button className="btn-floating mbh-black lighten-2 tooltipped" style={{marginRight: "5px"}} onClick={handleFilterToggle} data-position="left" data-tooltip="Filter by event" disabled={filterByEvent}><i className="small material-icons">event_note</i></button>
                        <button className="btn-floating mbh-black lighten-2 tooltipped" onClick={handleFilterToggle} data-position="right" data-tooltip="Filter by student" disabled={filterByStudent}><i className="small material-icons">person</i></button>
                    </div>
                    <div className="row">
                        {
                            filterByEvent &&
                            <>
                                <div className="input-field col s12 m8">
                                    <select onChange={handleEventSelect} value={eventFilterValue}>
                                        <option value="Choose" disabled>Choose an event</option>
                                        {
                                            events.map(event => (
                                                <option key={event.Id} value={event.Id}>{event.Subject} {dayjs(event.StartDateTime).format('MMM D, YYYY h:mm A')}</option>
                                            ))
                                        }
                                    </select>
                                    <label>Event</label>
                                </div>
                                <div className="col s12 m8">
                                    <Autocomplete
                                        /* 
                                            When filteredRegistrations changes, this input field is cleared to ensure 
                                            only students attending the specified event can be selected
                                        */
                                        key={filteredRegistrations}
                                        multiple
                                        id="multi-student-select"
                                        options={filteredStudents.filter(student => !student.IsDisabled)}
                                        getOptionLabel={(option) => `${option.FirstName.trim()} ${option.Surname.trim()}`}
                                        filterSelectedOptions
                                        onChange={handleStudentSelectForSpecificEvent}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.Id} data-id={option.Id}>
                                                    {option.FirstName.trim() + " " + option.Surname.trim()}
                                                </li>
                                            )
                                        }}
                                        renderInput={(params) => (
                                        <CssTextField
                                            {...params}
                                            label="Select students (optional)"
                                            placeholder="Students"
                                        />
                                        )}
                                    />
                                </div>
                            </>
                        }
                        {
                            filterByStudent &&
                            <div className="col s12 m8">
                                <Autocomplete
                                    multiple
                                    id="multi-student-select"
                                    options={filteredStudents.filter(student => !student.IsDisabled)}
                                    getOptionLabel={(option) => `${option.FirstName.trim()} ${option.Surname.trim()}`}
                                    filterSelectedOptions
                                    onChange={handleStudentFilterSelect}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.Id} data-id={option.Id}>
                                                {option.FirstName.trim() + " " + option.Surname.trim()}
                                            </li>
                                        )
                                    }}
                                    renderInput={(params) => (
                                    <CssTextField
                                        {...params}
                                        label="Select Students"
                                        placeholder="Students"
                                    />
                                    )}
                                />
                            </div>
                        }
                        <div>
                            <button className="btn-floating btn-large mbh-green tooltipped events-report__report-btn" onClick={generateReportData} data-position="top" data-tooltip="Generate report"><i className="material-icons">assignment</i></button>
                            {
                                showReport &&
                                <CSVLink {...csvReport}><button className="btn-floating btn-large mbh-black lighten-2 tooltipped events-report__export-btn" data-position="right" data-tooltip="Export to Excel"><i className="large material-icons">file_download</i></button></CSVLink>
                            }
                        </div>
                    </div>
                    {
                        !isEventSelected &&
                        <p className="mbh-red-text">Please select an event</p>
                    }
                    {
                        !studentHasRegistrations &&
                        <p>No registrations exist for the student(s) selected</p>
                    }
                    {
                        !doesEventHaveRegistrations &&
                        <p>No registrations exist for this event</p>
                    }
                    {
                        showReport &&
                        <div className="events-report__table-container">
                            <table className="highlight events-report__table">
                                <thead>
                                <tr>
                                    <th className="mbh-green-text cursor" onClick={() => filterByColumn("studentName")}>
                                        <div className="events-report__table--column-filter">
                                            Student Name
                                            {
                                                isArrowVisible[0] &&
                                                <i className="material-icons events-report__table--arrow-icon mbh-green-text">
                                                    {
                                                        studentNameAscending ? "arrow_drop_down" : "arrow_drop_up"
                                                    }
                                                </i>
                                            }
                                        </div>
                                    </th>
                                    {
                                        filterByStudent ?
                                        <th className="mbh-green-text cursor" onClick={() => filterByColumn("eventName")}>
                                            <div className="events-report__table--column-filter">
                                                Event Name
                                                {
                                                    isArrowVisible[1] &&
                                                    <i className="material-icons events-report__table--arrow-icon mbh-green-text">
                                                    {
                                                        eventNameAscending ? "arrow_drop_down" : "arrow_drop_up"
                                                    }
                                                    </i>
                                                }
                                            </div>
                                        </th>
                                        :
                                        null
                                    }
                                    <th>Event Type</th>
                                    <th className={filterByEvent ? "large-column" : "mbh-green-text large-column cursor"} onClick={() => filterByColumn("startDateTime")}>
                                        <div className="events-report__table--column-filter">
                                            Start Date Time
                                            {
                                                isArrowVisible[2] &&
                                                <i className="material-icons events-report__table--arrow-icon mbh-green-text">
                                                    {
                                                        startDateAscending ? "arrow_drop_down" : "arrow_drop_up"
                                                    }
                                                </i>
                                            }
                                        </div>
                                    </th>
                                    <th>End Date Time</th>
                                    <th>Att. Marked</th>
                                    <th>Att. Confirmed</th>
                                    <th>Trainer</th>
                                    <th>Support</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData.map(row => (
                                            <tr key={row.eventID}>
                                                <td>{row.studentName}</td>
                                                {
                                                    filterByStudent &&
                                                    <td className="mbh-green-text cursor" onClick={() => goToEventDetails(row.eventId)}>{row.eventName}</td>
                                                }
                                                <td>{row.eventType}</td>
                                                <td>{row.startDateTime}</td>
                                                <td>{row.endDateTime}</td>
                                                <td className={row.attendanceMarked === "Yes" ? "center mbh-green-text" : "center mbh-red-text"}>
                                                    <i className="material-icons">{row.attendanceMarked === "Yes" ? "done" : "close"}</i>
                                                </td>
                                                <td className={row.attendanceConfirmed === "Yes" ? "center mbh-green-text" : "center mbh-red-text"}>
                                                    <i className="material-icons">{row.attendanceConfirmed === "Yes" ? "done" : "close"}</i>
                                                </td>
                                                <td>{row.trainer}</td>
                                                <td>{row.support}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            }
        </article>
    )
}

export default EventsReport
