import React, { useEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import Clients from '../../components/Clients/Clients';
import Value from '../../components/Value/Value';
import Testimonials from '../../components/Testimonials/Testimonials';

const Home = ({ history, setSelectedTestimonials }) => {

    const testimonialsElement = useRef()

    useEffect(() => {
        if (window.location.pathname === '/testimonials') {
            window.scrollTo(0, testimonialsElement.current.offsetTop)
        } else {
            window.scrollTo(0, 0)
        }
    })

    return(
        <div>
            <Header history={history} />
            <Value history={history} />
            <Clients />
            <div ref={testimonialsElement}>
                <Testimonials />
            </div>
        </div>
    );
}

export default Home;