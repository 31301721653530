import M from 'materialize-css';

export const formValidation = (contactObject) => {
    if (contactObject.FirstName.trim() ==="" || contactObject.Surname.trim() ==="" || (contactObject.Email.trim() ===""  && contactObject.AltEmail.trim() ==="")) {
        if(contactObject.FirstName.trim() ===""){
            M.toast({html: 'Please provide your first name', displayLength: 2000});
        }
        if(contactObject.Surname.trim() ===""){
            M.toast({html: 'Please provide your surname', displayLength: 2000});
        }
        if(contactObject.Email.trim() ==="" && contactObject.AltEmail.trim() ===""){
            M.toast({html: 'Please provide your email', displayLength: 2000});
        }  
        return false;
    }else{
        return true;
    }
}

