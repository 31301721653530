import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../functions/globalVariables'
import { useDataFetch } from '../../hooks/useDataFetch'
import { CSVLink } from "react-csv";
import M from 'materialize-css'
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from '../../assets/spinner.svg';
import { withStyles } from "@mui/styles";
import './TrainingPlanAcceptanceReport.scss'
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';

const TrainingPlanAcceptanceReport = () => {

    let history = useHistory()
    const { authToken, userEmail } = useThemeContext()

    const studentQualsUrl = `${baseUrl}/api/studentqualifications?$expand=Student, Qualification&$filter=Active eq true and Disabled eq false`
    const [isLoadingStudentQuals, studentQuals, errorStudentQuals] = useDataFetch(studentQualsUrl, authToken)
    
    const studentsUrl = `${baseUrl}/api/students?$filter=IsDisabled eq false`
    const [isLoadingStudents, students, errorStudents] = useDataFetch(studentsUrl, authToken)
    
    const qualsUrl = `${baseUrl}/api/qualifications?$filter=Superseded eq false`
    const [isLoadingQuals, quals, errorQuals] = useDataFetch(qualsUrl, authToken)
    
    const trainersUrl = `${baseUrl}/api/trainers`
    const [isLoadingTrainers, trainers, errorTrainers] = useDataFetch(trainersUrl, authToken)
    
    // These will hold object form of students and events arrays to make filtering faster
    const [studentQualsObj, setStudentQualsObj] = useState({})
    const [trainersObj, setTrainersObj] = useState({})

    const [reportData, setReportData] = useState([])
    const [showReport, setShowReport] = useState(false)

    const [filterByQual, setFilterByQual] = useState(true)
    const [filterByStudent, setFilterByStudent] = useState(false)

    const [qualFilterValue, setQualFilterValue] = useState("Any")

    // This will hold the filtered data when filtering by qual or student
    const [filteredStudentQuals, setFilteredStudentQuals] = useState([])

    // Each item of arrow array relates to the three sortable columns (i.e. studentName, eventName, startDate)
    const [isArrowVisible, setIsArrowVisible] = useState([false, false, false])

    const [studentNameAscending, setStudentNameAscending] = useState(true)

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});

        var tooltippedElems = document.querySelectorAll('.tooltipped');
        var tooltippedInstances = M.Tooltip.init(tooltippedElems, {});
    })

    useEffect(() => {
        if (studentQuals.length) {
            setFilteredStudentQuals(studentQuals)
            setStudentQualsObj(arrayToObjectWithKey(studentQuals, "Id"))
        }
    }, [studentQuals])

    useEffect(() => {
        if (trainers.length) {
            setTrainersObj(arrayToObjectWithKey(trainers, "Email"))
        }
    }, [trainers])

    // Column headings for the report
    const headers = [
        { label: "Student Name", key: "studentName" },
        { label: "Qualification", key: "qualification" },
        { label: "Plan Accepted By", key: "planAcceptedBy" },
        { label: "Plan Accepted At", key: "planAcceptedAt" },
    ];
    
    // Config for exporting report
    const csvReport = {
        data: reportData,
        headers: headers,
        filename: "training-plan-acceptance-report.csv"
    }

    const handleQualSelect = (e) => {
        const qualId = e.target.value
        if (qualId === "Any") {
            reset()
            return
        }
        setQualFilterValue(qualId)
        // When the qual filter is changed, the acceptance filter resets so that, when changed, it applies to results related to the current qual selection
        const filteredQuals = studentQuals.filter(qual => qual.QualId === +qualId)
        setFilteredStudentQuals(filteredQuals)
        setShowReport(false)
    }

    const handleStudentSelect = (event, values) => {
        if (!values.length) {
            reset()
            return
        }
        const selectedStudentsObj = arrayToObjectWithKey(values, "Id")
        const filteredStudents = studentQuals.filter(qual => selectedStudentsObj[qual.StudentId])
        setFilteredStudentQuals(filteredStudents)
        setShowReport(false)
    }
    
    const handleFilterToggle = () => {
        setFilterByQual(!filterByQual)
        setFilterByStudent(!filterByStudent)
        reset()
    }

    const handleAcceptPlan = (e, qualId) => {
        const qual = studentQualsObj[qualId]
        console.log(e.target.checked)
        if (e.target.checked) {
            qual.PlanAcceptedBy = userEmail
            qual.PlanAcceptedAt = dayjs().format()
        } else {
            qual.PlanAcceptedBy = null
            qual.PlanAcceptedAt = null
        }

        fetch(`${baseUrl}/api/studentqualifications/${qualId}`, {
            method: "PUT",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(qual)
        })
    }

    const reset = () => {
        setFilteredStudentQuals(studentQuals)
        setQualFilterValue("Any")
        setShowReport(false)
    }

    const generateReportData = () => {
        const tableRows = filteredStudentQuals
        .map(qual => {

            const student = qual.Student
            const studentQual = qual.Qualification

            let personWhoAccepted = ""

            if (qual.PlanAcceptedBy) {
                if (trainersObj[qual.PlanAcceptedBy]) {
                    personWhoAccepted = `${trainersObj[qual.PlanAcceptedBy].FirstName} ${trainersObj[qual.PlanAcceptedBy].Surname}`
                } else if (qual.PlanAcceptedBy === qual.Student.Email || qual.PlanAcceptedBy === qual.Student.AltEmail) {
                    personWhoAccepted = `${student.FirstName} ${student.Surname}`
                } else {
                    personWhoAccepted = qual.PlanAcceptedBy
                }
            }

            const rowOfTable = {
                qualId: qual.Id,
                studentName: `${student.FirstName} ${student.Surname}`,
                qualification: `${studentQual.AntaId} ${capitaliseEachWordOfQualName(studentQual)}`,
                planAcceptedBy: personWhoAccepted,
                planAcceptedAt: qual.PlanAcceptedAt ? dayjs(qual.PlanAcceptedAt).format('ddd, D MMM YYYY ') : "",
            }

            return rowOfTable
        })
        .sort((a, b) => {
            const firstSurname = a.studentName.toLowerCase().split(" ").slice(-1).pop();
            const secondSurname = b.studentName.toLowerCase().split(" ").slice(-1).pop();

            return firstSurname.localeCompare(secondSurname)
        })

        setReportData(tableRows)
        setShowReport(true)
    }

    const goToStudentDetails = (studentId) => {
        window.sessionStorage.setItem('currentStudent', studentId)
        const studentDetailsUrl = `/#/admin-portal/administer-student`
        window.open(studentDetailsUrl, "_blank") 
    }

    // Apply mbh colours to student selector
    const CssTextField = withStyles({
        root: {
          '& label.Mui-focused': {
            color: '#00703c',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#8C8C8C',
            },
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#00703c',
            },
          },
        },
      })(TextField);

    return (
        <article className="events-report">
            {
                isLoadingStudentQuals || isLoadingQuals || isLoadingTrainers || students === undefined ?
                <img src={Spinner} alt="Spinner" />
                :
                <>
                    <article className="events-summary__header">
                        <h3 className="mbh-green-text">Plan Acceptance Report</h3>
                        {
                            <div className="events-summary__header--btns">
                                <a className="btn-floating mbh-green" onClick={() => { history.push('/admin-portal')}} style={{marginRight:"5px"}}><i className="material-icons notextselect">arrow_back</i></a>
                            </div>
                        }
                    </article>
                    <div className="row events-report__filter-btns">
                        <button className="btn-floating mbh-black lighten-2 tooltipped" style={{marginRight: "5px"}} onClick={handleFilterToggle} data-position="left" data-tooltip="Filter by qual" disabled={filterByQual}><i className="small material-icons">assignment</i></button>
                        <button className="btn-floating mbh-black lighten-2 tooltipped" onClick={handleFilterToggle} data-position="right" data-tooltip="Filter by student" disabled={filterByStudent}><i className="small material-icons">person</i></button>
                    </div>
                    <div className="row">
                        {
                            filterByQual &&
                            <>
                                <div className="input-field col s12 m8">
                                    <select onChange={handleQualSelect} value={qualFilterValue}>
                                        <option value="Any">Any</option>
                                        {
                                            quals.map(qual => (
                                                <option key={qual.Id} value={qual.Id}>{qual.AntaId} {capitaliseEachWordOfQualName(qual)}</option>
                                            ))
                                        }
                                    </select>
                                    <label>Qualification</label>
                                </div>
                            </>
                        }
                        {
                            filterByStudent &&
                            <div className="col s12 m8">
                                <Autocomplete
                                    multiple
                                    id="multi-student-select"
                                    options={students.sort((a, b) => -b.Surname.trim().localeCompare(a.Surname.trim()))}
                                    getOptionLabel={(option) => `${option.FirstName.trim()} ${option.Surname.trim()}`}
                                    groupBy={(option) => option.Surname.charAt(0).toUpperCase()}
                                    filterSelectedOptions
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.Id} data-id={option.Id}>
                                                {option.FirstName.trim() + " " + option.Surname.trim()}
                                            </li>
                                        )
                                    }}
                                    onChange={handleStudentSelect}
                                    renderInput={(params) => (
                                    <CssTextField
                                        {...params}
                                        label="Select Students"
                                        placeholder="Students"
                                    />
                                    )}
                                />
                            </div>
                        }
                        <div>
                            <button className="btn-floating btn-large mbh-green tooltipped events-report__report-btn" onClick={generateReportData} data-position="top" data-tooltip="Generate report"><i className="material-icons">assignment</i></button>
                            {
                                showReport &&
                                <CSVLink {...csvReport}><button className="btn-floating btn-large mbh-black lighten-2 tooltipped events-report__export-btn" data-position="right" data-tooltip="Export to Excel"><i className="large material-icons">file_download</i></button></CSVLink>
                            }
                        </div>
                    </div>
                    
                    {
                        showReport &&
                        <div className="events-report__table-container">
                            <table className="highlight events-report__table">
                                <thead>
                                <tr>
                                    <th className="mbh-green-text cursor">
                                        <div className="events-report__table--column-filter">
                                            Student Name
                                            {
                                                isArrowVisible[0] &&
                                                <i className="material-icons events-report__table--arrow-icon mbh-green-text">
                                                    {
                                                        studentNameAscending ? "arrow_drop_down" : "arrow_drop_up"
                                                    }
                                                </i>
                                            }
                                        </div>
                                    </th>
                                    <th>Qualification</th>
                                    <th>Plan Accepted By</th>
                                    <th>Plan Accepted At</th>
                                    <th>Accept Plan</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData.map(row => (
                                            <tr key={row.qualId}>
                                                <td className="mbh-green-text cursor" onClick={() => goToStudentDetails(studentQualsObj[row.qualId].Student.Id)}>{row.studentName}</td>
                                                <td>{row.qualification}</td>
                                                <td>{row.planAcceptedBy}</td>
                                                <td>{row.planAcceptedAt}</td>
                                                <td>
                                                    <p>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={row.planAcceptedBy.length} onChange={(e) => handleAcceptPlan(e, row.qualId)} />
                                                            <span></span>
                                                        </label>
                                                    </p>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            }
        </article>
    )
}

export default TrainingPlanAcceptanceReport
