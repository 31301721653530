import React from 'react'


const HomePageButtons = ({ history }) => {
    return (
        <div className="header__buttons">
            <button id="pathwaysButton" className="btn mbh-black lighten-4 mbh-black-text" onClick={() => history.push('/career-pathways')}>Career Pathways</button>  
            <button id="pathwaysButton" className="btn mbh-green" onClick={() => history.push('/contact-us')}>Contact Us</button>                      
        </div>
    )
}

export default HomePageButtons
