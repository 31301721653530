import React, {useState, useEffect} from 'react'
import './AddQual.scss';
import { baseUrl } from '../../functions/globalVariables'
import {validateCostField} from '../../functions/validateCostField'
import M from 'materialize-css'
import TextEditor from '../TextEditor/TextEditor';
import { useThemeContext } from '../../ThemeContext';

const AddQual = () => {

    const { authToken } = useThemeContext()

    const qualificationsUrl = `${baseUrl}/api/qualifications`
 
    const qualObject = {
        "Name": "",
        "AntaId": "",
        "Description": "",
        "Cost": null,
        "Package": null,
        "Level": null,
        "IsPartQual": false,
        "Electives": null,
        "Superseded": false,
        "DeliveryMode": null,
        "CoursesDescription": null,
        "AssessmentSupportDescription": null,
        "AssessmentTimeframeDescription": null,
        "TrainingDeliveryDescription": null,
        "ProgramRecognitionId": null,
        "LevelOfEducationId": null,
        "ANZSCO": null,
        "VETFlag": null
    }

    const [submitted, setSubmitted] = useState(false);
  
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    })

    useEffect(() => {
        document.getElementById("addQualForm").reset();
    }, [submitted])

    const handleChange= (e) => {
        const {name, value, checked} = e.target;

        if (name === "IsPartQual") {
            if (checked) {
                qualObject[name] = true;
            } else {
                qualObject[name] = false;
            }
        } else {
            qualObject[name] = value;
        }
    }

    const handleDescriptionChange = (textareaName, content) => {
        qualObject[textareaName] = content;
    }

    const handleClick = (qualObject) => {
        let isFormValid = true;
        if(qualObject.Name.trim() === ""){
            M.toast({html: 'Qualification must have a name.'})
            isFormValid = false;
        }
        if(isNaN(qualObject.Cost) || qualObject.Cost === null){
            M.toast({html: 'Cost must be a Numerical value.'})
            isFormValid = false;
        }
        if(isNaN(qualObject.Level)|| qualObject.Level === null){
            M.toast({html: 'Level must be a Numerical value.'})
            isFormValid = false;
        }
        if(isNaN(qualObject.Electives)|| qualObject.Electives === null){
            M.toast({html: 'Electives must be a Numerical value.'})
            isFormValid = false;
        }

        if(isFormValid){
            fetch(qualificationsUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(qualObject)
            })
            .then(response => response.json())
            .then(data => {setSubmitted(true);M.toast({html: 'Qualification Created!', classes: 'mbh-green'});setSubmitted(false);})
            .catch(err => console.log(err))
        }
    }

    return (
        <div className='add-qual'>
            <h2>Add Qualification</h2>
            <br/>
            <div className="row">
                <form id="addQualForm" className="col s12">
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="Name" name="Name" type="text" className="validate" onChange={(e) => handleChange(e)} />
                            <label htmlFor="Name">Name</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <input id="AntaId" name="AntaId" type="text" className="validate" onChange={(e) => handleChange(e)} />
                            <label htmlFor="AntaId">AntaId</label>
                        </div>                  
                        <div className="input-field col s6">
                            <input id="Cost" name="Cost" type="text" className="validate" autocomplete="off" autoComplete="new-password" onChange={(e) => {handleChange(e); validateCostField(e)}} />
                            <label htmlFor="Cost">Cost</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <input id="Package" name="Package" type="text" className="validate" autocomplete="off" autoComplete="new-password" onChange={handleChange} />
                            <label htmlFor="Package">Package</label>
                        </div>
                        <div className="input-field col s6">
                            <input id="Level" name="Level" type="text" className="validate" autocomplete="off" autoComplete="new-password" onChange={(e) => {handleChange(e); validateCostField(e)}} />
                            <label htmlFor="Level">Level</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <input id="Electives" name="Electives" type="text" className="validate" autoComplete="new-password" onChange={(e) => {handleChange(e); validateCostField(e)}} />
                            <label htmlFor="Electives">Electives</label>
                        </div>
                        <div className="input-field col s6">
                            <select defaultValue="false" name="Superseded" autoComplete="new-password" onChange={handleChange}>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                            <label htmlFor="Superseded">Superseded</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <select defaultValue="10" id="DeliveryMode" name="DeliveryMode" onChange={handleChange}>
                                <option value="10">Classroom</option>
                                <option value="20">Online</option>
                                <option value="30">Work-based</option>
                                <option value="40">Mixed Mode</option>
                            </select>
                            <label htmlFor="DeliveryMode">Delivery Mode</label>
                        </div>
                        <div className="input-field col s6">
                            <input id="ProgramRecognitionId" name="ProgramRecognitionId" type="text" className="validate" maxLength="2" onChange={handleChange} />
                            <label htmlFor="ProgramRecognitionId">Program Recognition Id</label>
                        </div>                  
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <input id="LevelOfEducationId" name="LevelOfEducationId" type="text" className="validate" maxLength="3" autocomplete="off" autoComplete="new-password" onChange={handleChange} />
                            <label htmlFor="LevelOfEducationId">Level of Education Id</label>
                        </div>
                        <div className="input-field col s6">
                            <input id="FieldOfEducationId" name="FieldOfEducationId" type="text" className="validate" maxLength="4" onChange={handleChange} />
                            <label htmlFor="FieldOfEducationId">Field Of Education Id</label>
                        </div>                  
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <input id="ANZSCO" name="ANZSCO" type="text" className="validate" maxLength="6" autocomplete="off" autoComplete="new-password" onChange={handleChange} />
                            <label htmlFor="ANZSCO">ANZSCO</label>
                        </div>
                        <div className="input-field col s6">
                            <select defaultValue="N" id="VETFlag" name="VETFlag" onChange={handleChange}>
                                <option value="N">No</option>
                                <option value="Y">Yes</option>
                            </select>
                            <label htmlFor="VETFlag">VET Flag</label>
                        </div>
                    </div>
                    <div className="row">
                        <p className="col s6">
                            <label>
                                <input type="checkbox" id="IsPartQual" name="IsPartQual" onChange={handleChange} />
                                <span>Is A Part Qual</span>
                            </label>
                        </p>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <p style ={{color: "#999"}}>Description</p>
                            <TextEditor id="Description" name="Description" initValue={""} handleDescriptionChange={handleDescriptionChange} descriptionField="Description" addOrEditQual="add" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s12">
                            <p style ={{color: "#999"}}>Courses Description</p>
                            <TextEditor id="CoursesDescription" name="CoursesDescription" initValue={""} handleDescriptionChange={handleDescriptionChange} descriptionField="CoursesDescription" addOrEditQual="add" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s12">
                            <p style ={{color: "#999"}}>Training Delivery Description</p>
                            <TextEditor id="TrainingDeliveryDescription" name="TrainingDeliveryDescription" initValue={""} handleDescriptionChange={handleDescriptionChange} descriptionField="TrainingDeliveryDescription" addOrEditQual="add" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s12">
                            <p style ={{color: "#999"}}>Assessment Support Description</p>
                            <TextEditor id="AssessmentSupportDescription" name="AssessmentSupportDescription" initValue={""} handleDescriptionChange={handleDescriptionChange} descriptionField="AssessmentSupportDescription" addOrEditQual="add" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s12">
                            <p style ={{color: "#999"}}>Assessment Timeframe Description</p>
                            <TextEditor id="AssessmentTimeframeDescription" name="AssessmentTimeframeDescription" initValue={""} handleDescriptionChange={handleDescriptionChange} descriptionField="AssessmentTimeframeDescription" addOrEditQual="add" />
                        </div>
                    </div>
                
                </form>
            </div>
            <button className="btn mbh-green white-text right" onClick={() => handleClick(qualObject)}>Add Qualification</button>  
        </div>
    )
}

export default AddQual