import React, {useState} from 'react'
import { useThemeContext } from '../../ThemeContext';
import TextEditor from '../TextEditor/TextEditor';

const TrainingPlanComments = ({studentQual, handleUpdateComments}) => {

    const { userRole } = useThemeContext()

    const [editComments, setEditComments] = useState(true);
    
    return (
        <div className="TrainingPlanComments">
             {
                userRole === 'Administrator'?
                <div>{
                    !editComments?
                    <div className="row">
                        <div className="col s11 m7" dangerouslySetInnerHTML={{__html: studentQual.Comments}} />
                        <div className="col s1">    
                            <button class="btn-floating mbh-green white-text btn-small" onClick={() => setEditComments(true)}><i class="material-icons">edit</i></button>
                        </div>
                    </div>
                    :
                    <div className="row" >
                        <div className="col s11">
                            <TextEditor id="Comments" name="Comments" initValue={studentQual.Comments} handleDescriptionChange={handleUpdateComments} descriptionField="Comments" addOrEditQual="comments" object ={studentQual}/>
                        </div>
                        <div className="col s1">    
                            <button class="btn-floating mbh-green white-text btn-small" onClick={() => setEditComments(false)}><i class="material-icons">close</i></button>
                        </div>
                    </div>
                    }
                </div>
                :<span className="col s12 m8"> <div dangerouslySetInnerHTML={{__html: studentQual.Comments}} /></span>
            }
            
        </div>
    )
}

export default TrainingPlanComments
