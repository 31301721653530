export const addAssignedCompetency = (url, compObject, qualId, authToken) => {

    const id = compObject.CompID ? compObject.Id : compObject.CompetencyId

    const compToAdd = {
        "IsCore": true,
        "IsStandard": true,
        "QualId": qualId,
        "CompetencyId": id,
    }

    fetch(`${url}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        body: JSON.stringify(compToAdd)
    })
    .then(response => response.json())
    .catch(err => console.log(err))
}

export const deleteAssignedCompetency = (url, authToken) => {
    fetch(url, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${authToken}` }
    })
    .then(response => response.json())
    .catch(err => console.log(err))
}