import React, {useState, useEffect} from 'react'
import { baseUrl } from '../../functions/globalVariables';
import FormSpinner from '../../assets/form-spinner.svg'
import './AdminChangePassword.scss'
import { useThemeContext } from '../../ThemeContext';

const AdminChangePassword = () => {

    const { authToken } = useThemeContext()

    const [isChangingPassword, setIsChangingPassword] = useState(false)
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")

    const [isFormValid, setIsFormValid] = useState(true)

    // When editing fields, the error messages becomes hidden again
    const onCurrentPasswordChange = (e) => { setCurrentPassword(e.target.value); setIsFormValid(true); setIsPasswordChanged(false) }
    const onNewPasswordChange = (e) => { setNewPassword(e.target.value); setIsFormValid(true) }
    const onConfirmNewPasswordChange = (e) => { setConfirmNewPassword(e.target.value); setIsFormValid(true); setIsPasswordChanged(false) }

    const checkFormIsValid = (e) => {
        e.preventDefault()

        if (newPassword !== confirmNewPassword) {
            setIsFormValid(false)
            return false
        } else if (!newPassword.trim().length || !confirmNewPassword.trim().length) {
            setIsFormValid(false)
            return false
        }

        setIsFormValid(true)
        return true
    }

    const onPasswordSubmit = (e) => {
        if (!checkFormIsValid(e)) return

        setIsChangingPassword(true)
        fetch(`${baseUrl}/api/auth/changepassword`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            } ,
            body: JSON.stringify({ 
                OldPassword: currentPassword,
                NewPassword: newPassword,
                ConfirmPassword: confirmNewPassword
            })
        })
        .then(response => {
            setIsChangingPassword(false); 
            setIsPasswordChanged(true); 
            return response.json()
        })
        .catch(err => {
            setIsChangingPassword(false);
            setIsPasswordChanged(true);
            return console.log(err)
        })
    }

    return (
        <section className="changePassword">
            <div className="changePassword__card container">
                <h3 className="center">Change Password</h3>
                    <div className="row changePassword__row">
                        <div className="input-field col s12">
                            <input id="password" type="password" value={currentPassword} onChange={onCurrentPasswordChange} />
                            <label htmlFor="pasword">Current Password</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="new-password" type="password" value={newPassword} onChange={onNewPasswordChange} />
                            <label htmlFor="new-password">New Password</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="confirm-password" type="password" value={confirmNewPassword} onChange={onConfirmNewPasswordChange} />
                            <label htmlFor="confirm-password">Confirm New Password</label>
                        </div>
                        {
                            !isFormValid &&
                            <p className="mbh-red-text">New password fields must match</p>
                        }
                        {
                            isPasswordChanged &&
                            <p className="mbh-green-text">Password has been successfully changed!</p>
                        }
                    </div>
                    <div className="changePassword__button-container">
                        {
                            isChangingPassword ?
                            <figure>
                                <img src={FormSpinner} alt="Form spinner" />
                            </figure>
                            :
                            <button className="btn-large mbh-green" onClick={onPasswordSubmit}>Change Password</button>
                        }
                    </div>
            </div>
        </section>
        
    )
}

export default AdminChangePassword
