import React from 'react'
import mbhLogo from '../../assets/mbh-logo.png';
import { ACTIONS } from '../../App';

const AdminMenu = ({menuStyle, menuSmall, history, handleMenuClick}) => {
    return (
        <div>
              <ul className= {`mbh-green-text ${menuStyle}`} >
                    {
                        !menuSmall ?
                          <li className="student-portal__menu-item"> <img src={mbhLogo} className="student-portal__logo" alt="mbh logo" /></li>
                          : <li className="student-portal__menu-item white-text">.</li>
                    }
                    <li id="itemHome" className="student-portal__menu-item" onClick={() => history.push('/admin-portal')}><i className="material-icons student-portal__menu-icon">home</i>{menuSmall? "": " Home"}</li>
                    <li id="itemDownload" className="student-portal__menu-item" onClick={() => history.push('/admin-portal/download')}><i className="material-icons student-portal__menu-icon">cloud_download</i>{menuSmall? "": " Upload/Download Files"}</li>
                    <li id="itemCompetencies" className="student-portal__menu-item" onClick={() => history.push('/admin-portal/qualifications-and-competencies')}><i className="material-icons student-portal__menu-icon">dvr</i>{menuSmall? "": " Manage Competencies"}</li>
                    <li id="itemEvents" className="student-portal__menu-item" onClick={() => history.push('/admin-portal/events')}><i className="material-icons student-portal__menu-icon">insert_invitation</i>{menuSmall? "": " Upcoming Events"}</li>
                    <li id="itemHome" className="student-portal__menu-item" onClick={() => history.push('/admin-portal/reports')}><i className="material-icons student-portal__menu-icon">assignment</i>{menuSmall? "": " Reports"}</li>
                    <li id="itemAdmin" className="student-portal__menu-item" onClick={() => history.push('/admin-portal/admin')}><i className="material-icons student-portal__menu-icon">settings</i>{menuSmall? "": " Admin"}</li>
                    <li className="student-portal__menu-item" onClick={(e) => {history.push('/'); window.sessionStorage.clear()}}><i className="material-icons student-portal__menu-icon">exit_to_app</i>{menuSmall? "": " Logout"}</li>
                </ul>
        </div>
    )
}

export default AdminMenu
