import React, {useState} from 'react'
import './SetPassword.scss'
import { baseUrl } from '../../functions/globalVariables';
import M from 'materialize-css';

const SetPassword = ({history}) => {

    const [isSuccess, setIsSuccess] = useState(false);

    // const url_with_hash = "https://prerelease.mbhtraining.com.au/#/set-password?email=p.nentzos%40uniphi.com.au&token=b74022cb-ff17-4183-863e-bda695435e71"
    const url_with_hash = window.location.href;
    const url_without_hash= url_with_hash.replace("#/", "");
    const url = new URL(url_without_hash);
    const url_email = url.searchParams.get("email");
    const url_token = url.searchParams.get("token");
   


    const regObject = {
        "Email": url_email,
        "Password": "", 
        "ConfirmPassword": "",
        "Guid":url_token
    }
    

    const checkAPIResponse = (data, status) => {
        if (status === 200){
            setIsSuccess(true)
        }else{
            if(data.Message){
                M.toast({html: data.Message, displayLength: 2000});
            }
        }
    }

    const postData = (obj) => {     
       let status;
        fetch(`${baseUrl}/api/auth/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(obj)
        })
        .then(response => {
            status = response.status;
            checkAPIResponse("", status)
            return response.json()
        })
        .then(data => checkAPIResponse(data, status))
        .catch(err => console.log("Error: ", err)) 
    }


    const validatePassword = (e) =>{
        e.preventDefault();
        const password1 = document.getElementById('password1').value;
        const password2 = document.getElementById('password2').value;

        if(password1 === password2 && password1.trim() !==""){
        
            if(password1.length < 8){
                M.toast({html: 'Password must be at least 8 characters long', displayLength: 2000});
            }else{
                regObject["Password"] = password1;
                regObject["ConfirmPassword"] = password2;
               
                postData(regObject)
            }
        }else{
           
            M.toast({html: 'Passwords must match', displayLength: 2000});
        }
    }


    return (
        <section className="set-password container">
            <div className="set-password__card container" style={{display:isSuccess?'none':'block'}}>
                <h3 className="center">Create Password</h3>
                <p className="center">Password must be at least 8 characters long</p>
                <br/>
                <form className="set-password__form">
                    <div className="row set-password__row">
                        <div className="input-field col s12">
                            <input id="password1" type="password" minLength="8" className="validate" />
                            <label htmlFor="password1">Password</label>
                        </div>
                    </div>
                    <div className="row set-password__row">
                        <div className="input-field col s12">
                            <input id="password2" type="password" minLength="8" className="validate" />
                            <label htmlFor="password2">Confirm Password</label>
                        </div>
                    </div>
                    <button className="btn-large mbh-green" type='submit' onClick={(e) => validatePassword(e)}>Submit</button>
                    <hr />
                    <button className="btn-large transparent mbh-green-text" 
                            style={{ boxShadow: 'none' }}
                            onClick={() => history.push('/privacy')}>
                        Privacy
                    </button>
                </form>
            </div>
            <div className="set-password__card set-password__success container" style={{display:isSuccess?'block':'none'}}>
                <h5>Password successfully set!</h5>
                <br/><br/>
                <button className="btn-large mbh-green" 
                            style={{ boxShadow: 'none' }}
                            onClick={() => history.push('/login')}>
                        LOG IN
                    </button>
            </div>
        </section>
    )
}

export default SetPassword
