import React, {useState, useEffect} from 'react'
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import './EventsCalendar.scss'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';

const localizer = momentLocalizer(moment)

const EventsCalendar = ({setCurrentPage}) => {

    let history = useHistory()
    const { authToken, userRole } = useThemeContext()

    const eventsUrl = `${baseUrl}/api/events?$filter=IsCancelled eq false`;
    const [isLoadingEvents, events, eventsErr] = useDataFetch(eventsUrl, authToken);

    let eventsRegUrl ="";
    if (userRole === 'Student' && events.length > 0)
        eventsRegUrl = `${baseUrl}/api/eventregistrations`;
    const [isLoadingEventsReg, eventsReg, eventsRegErr] = useDataFetch(eventsRegUrl, authToken);
   
    const studentId = parseInt(window.sessionStorage.getItem('mbhStudentId'));
    const filteredEventsReg = eventsReg.filter(reg => reg.StudentID === studentId);
  
    let eventsRegIdList=[];
    filteredEventsReg.map(reg => eventsRegIdList.push(reg.EventID))  

    let eventsArr=[]

    events.map(event => {
        if (userRole !== 'Student' || eventsRegIdList.includes(event.Id)){
            let eventItem = {
                id:null,
                start: null,
                end: null,
                title: ""
            }
 
            eventItem["id"] = event.Id;
            eventItem["start"] = moment(event.StartDateTime).toDate();//dates need to be a date object or calendar crashes on week and day view
            eventItem["end"] = moment(event.EndDateTime).toDate();//dates need to be a date object or calendar crashes on week and day view
            eventItem["title"] = event.Subject;
        
            eventsArr.push(eventItem);
        }
    });
     
    const eventSelected = (e) => {
        setCurrentPage("calendar"); 
        
        if (userRole === "Administrator"){
            history.push(`/admin-portal/events/edit/${e.id}`);
        }
        else{
            history.push(`/student-portal/events/event-details/${e.id}`);
        }
        
    }

    const eventStyleGetter = (e) => {
        var style = {
            backgroundColor: '#00703c',
            color: 'white',
            fontSize: '0.8rem',
            textAlign: 'center',
            paddingTop:'5px',
            height: '30px'
        };
        return {
            style: style
        };
    }

    return (
        <div className="events-calendar">
            <article className="events-calendar__header">
                <h3 className="mbh-green-text">Events Calendar</h3>
                {
                    userRole === "Student" ?
                    <div className="events-calendar__btns">
                        <a className="btn-floating mbh-green" onClick={() => {setCurrentPage("calendar"); history.push('/student-portal/events')}}><i className="material-icons notextselect">arrow_back</i></a>
                    </div>
                    :
                    <div className="events-calendar__btns">
                        <a className="btn-floating mbh-green" onClick={() => {setCurrentPage("calendar"); history.push('/admin-portal/events')}} style={{marginRight:"5px"}}><i className="material-icons notextselect">arrow_back</i></a>
                        <a className='btn-floating mbh-green' onClick={() => {setCurrentPage("calendar"); history.push('/admin-portal/events/create')}}><i className="material-icons notextselect">add</i></a>
                    </div>
                }
            </article>
            <div className="row">
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={eventsArr}
                    style={{ height: "80vh"}}
                    onSelectEvent={(e) => eventSelected(e)}
                    eventPropGetter={(e) => eventStyleGetter(e)}
                />      
                {/* Find documentation here: https://jquense.github.io/react-big-calendar/examples/index.html */}
            </div>  

        </div>
    )
}

export default EventsCalendar
