import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './QualCompEdit.scss';

const QualCompEdit = () => {

    let history = useHistory()
    let match = useRouteMatch()

    return (
        <section className="qual-comp">
            <h2>Select an Option</h2>
            <article className="qual-comp__article">
                <article className="qual-comp__option" onClick={() => history.push(`${match.url}/add-qualification`)}>
                    <p>Add New Qualification</p>
                </article>
                <article className="qual-comp__option" onClick={() => history.push(`${match.url}/edit-qualifications`)}>
                    <p>Edit Qualifications</p>
                </article>
                <article className="qual-comp__option" onClick={() => history.push(`${match.url}/add-competency`)}>
                    <p>Add New Competency</p>
                </article>
                <article className="qual-comp__option" onClick={() => history.push(`${match.url}/edit-competencies`)}>
                    <p>Edit Competencies</p>
                </article>
            </article>
        </section>
    )
}

export default QualCompEdit
