import React from 'react';
import { Link } from 'react-router-dom';
import './Privacy.scss';

const Privacy = () => {
    return (
        <div className="privacy">
            <div className="container privacy__body">
                <h3 className="privacy__heading">Privacy &amp; Security Statement</h3>
                <h5 className="privacy__subheading mbh-green-text">Encryption</h5>
                <p>
                    Unencrypted credit card details will not be stored on our server, and will be sent only in encrypted form. We use industry-standard Secure Sockets Layer (SSL) technology to keep your transaction secure.
                </p>
                <h5 className="privacy__subheading mbh-green-text">Your Choice</h5>
                <p>
                    If you do not wish to place your order over the Internet or still feel it is unsafe to transfer your credit card details over the Internet, we offer you the option of faxing in your order and credit card details, or alternatively we are able to call you during business hours.
                    <br/><br/>
                    Phone: 1300 79 48 68
                </p>
                <h5 className="privacy__subheading mbh-green-text">Collecting Anonymous Information</h5>
                <p>
                    We automatically collect general website traffic information (such as how many people have visited the site, most popular pages etc). This information is collected into aggregate results in order to evaluate our service and business practice in an effort to provide you with a better online experience.
                </p>
                <h5 className="privacy__subheading mbh-green-text">Collecting Personal Information</h5>
                <p>
                    We collect personal information submitted by you to process and advise you of the status of your order. We do not disclose personal information for any other purpose. Under no circumstances will we sell or receive payment for licensing or disclosing your personal information.
                </p>
                <h5 className="privacy__subheading mbh-green-text">Privacy</h5>
                <p>
                    Personal information like address, phone, e-mail etc is only accessible by our staff. Any credit card / payment details are erased by our system when the transaction is cleared, your other details will be sent to our archive to keep a record of your results.
                </p>
                <h5 className="privacy__subheading mbh-green-text">Spam</h5>
                <p>
                    We will never knowingly send you electronic messages without your consent. For more information on the 'Spam Act 2003' please visit the Australian Government's website.
                </p>
                <h5 className="privacy__subheading mbh-green-text">Australian Law</h5>
                <p>
                    We comply with the Trade Practices Act 1974, state based Fair Trading Acts and Sale of Goods Acts, the Consumer Credit Code, the Electronic Transactions Act 1999 the Information Privacy Principles set out in the Commonwealth Privacy Act 1988; and the Privacy Amendment (Private Sector) Act 2000 (Cth).
                </p>
                <h5 className="privacy__subheading mbh-green-text">Privacy Links</h5>
                <p>
                    Office of the Australian Information Commissioner (<a href="https://www.oaic.gov.au/" target="_blank" rel="noopener noreferrer">OAIC</a>) <br/>
                    Australian Government -  <a href="https://treasury.gov.au/publication/the-australian-guidelines-for-electronic-commerce/the-australian-guidelines-for-electronic-commerce" target="_blank" rel="noopener noreferrer">The Treasury - Guidelines for Electronic Commerce</a> and <a href="https://consumerlaw.gov.au/consumers-and-acl" target="_blank">Australian Consumer Law</a> <br/>
                    MBH Management Pty Ltd ABN (Australian Business Number): 88 090 244 296
                </p>
                <h5 className="privacy__subheading mbh-green-text">Feedback and Complaints Handling Procedure</h5>
                <p>
                    We welcome any feedback you have to offer and will attend to all complaints promptly. Please <Link to="/contact-us">contact us</Link>  or write to us at Suite 3.02 St Martins Tower Level 3 / 31 Market St
Sydney, NSW, 2000.
                </p>
            </div>
        </div>
    )
}

export default Privacy;
