import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../functions/globalVariables';
import Spinner from '../../assets/spinner.svg'
import { useDataFetch } from '../../hooks/useDataFetch';
import M from 'materialize-css';

const AdminRegions = ({ authToken }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        M.updateTextFields();
    })
    
    // Grabbing all training regions to fill a dropdown list
    const trainingRegionsUrl = `${baseUrl}/api/trainingregions?$orderBy=Name asc`;
    const [isLoadingRegions, trainingRegions, errorRegions] = useDataFetch(trainingRegionsUrl, authToken);

    const [region, setRegion] = useState(null)
    const [showAddForm, setShowAddForm] = useState(false)

    const [isUpdatingRegion, setIsUpdatingRegion] = useState(false)
    const [isCreatingRegion, setIsCreatingRegion] = useState(false)
    const [isDeletingRegion, setIsDeletingRegion] = useState(false)

    const onRegionSelect = (e) => setRegion(trainingRegions[e.target.value])

    const onRegionInfoChange = (e, fieldToChange, newOrCurrentRegion) => {
        setRegion({...region, [fieldToChange]: e.target.value})
    }
    
    const createRegion = () => {
        setIsCreatingRegion(false)
        fetch(`${baseUrl}/api/trainingregions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({ Name: region.Name , VetRef: region.VetRef })
        })
        .then(response => {response.json(); setIsCreatingRegion(false); setShowAddForm(false); setRegion(null); window.location.reload()})
        .catch(err => {console.log(err); setIsCreatingRegion(false); setShowAddForm(false); setRegion(null); window.location.reload()})
    }

    const updateRegion = () => {
        setIsUpdatingRegion(true)
        fetch(`${baseUrl}/api/trainingregions/${region.Id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(region)
        })
        .then(response => {response.json(); setIsUpdatingRegion(false); setRegion(null); window.location.reload()})
        .catch(err => {console.log(err); setIsUpdatingRegion(false); setRegion(null); window.location.reload()})
    }

    const deleteRegion = () => {
        setIsDeletingRegion(true)
        fetch(`${baseUrl}/api/trainingregions/${region.Id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({ Name: region.Name , VetRef: region.VetRef })
        })
        .then(response => {response.json(); setIsDeletingRegion(false); setRegion(null); window.location.reload()})
        .catch(err => {console.log(err); setIsDeletingRegion(false); setRegion(null); window.location.reload()})
    }



    return (
        <section>
            <h5 className="mbh-green-text">Regions</h5>
            {
                isLoadingRegions || !trainingRegions.length ?
                <img src={Spinner} alt="Spinner" />
                :
                <>
                    {
                        !showAddForm &&
                        <div className="row">
                            <div className="input-field col s10">
                                <select id="chooseRegionDropDown" onChange={onRegionSelect}>
                                    <option value="choose" selected>Select A Region</option>
                                    {
                                        trainingRegions.map((region, index) => {
                                            return <option key={index} value={index}>{region.Name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col s2">
                                <a className="btn-floating mbh-green right" onClick={() => setShowAddForm(true)}>
                                    <i className="material-icons">add</i>
                                </a>
                            </div>
                        </div>
                    }
                    {
                        showAddForm &&
                        <>
                            <h6 className="mbh-green-text">New Region</h6>
                            <table className="admin-program-streams__table">
                                <thead>
                                    <tr>
                                        <th className="bold">VetRef</th>
                                        <th className="bold">Name</th>
                                        <th className="bold"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="admin-program-streams__table-column--id">
                                            <input 
                                                id="new-region-vetref" 
                                                className="col s1" 
                                                type="text" 
                                                autoComplete="new-password" 
                                                onChange={(e) => onRegionInfoChange(e, "VetRef", "new-region")}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                id="new-region-description" 
                                                className="col s9" 
                                                type="text"
                                                autoComplete="new-password" 
                                                style={{width: "100px;"}}
                                                onChange={(e) => onRegionInfoChange(e, "Name", "new-region")}
                                            />
                                        </td>
                                        <td style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                            <a className="btn-floating mbh-green" style={{ marginRight:"20px" }}><i className="material-icons notextselect" style={{userSelect:"none"}} onClick={createRegion}>save</i></a>
                                            <a className="btn-floating mbh-red"><i className="material-icons notextselect" style={{userSelect:"none"}} onClick={() => {setRegion(null); setShowAddForm(false)}}>cancel</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    }
                    {
                        isUpdatingRegion || isDeletingRegion ?
                        <img src={Spinner} alt="Spinner" />
                        :
                        (
                            region && !showAddForm &&
                            <table className="admin-program-streams__table">
                                <thead>
                                    <tr>
                                        <th className="bold">VetRef</th>
                                        <th className="bold">Name</th>
                                        <th className="bold"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="admin-program-streams__table-column--id">
                                            <input 
                                                id="region-vetref" 
                                                className="col s1" 
                                                type="text" 
                                                autoComplete="new-password" 
                                                value={region.VetRef}
                                                onChange={(e) => onRegionInfoChange(e, "VetRef", "current-region")}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                id="region-name" 
                                                className="col s9" 
                                                type="text"
                                                autoComplete="new-password" 
                                                style={{width: "100px;"}} 
                                                value={region.Name}
                                                onChange={(e) => onRegionInfoChange(e, "Name", "current-region")}
                                            />
                                        </td>
                                        <td style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                            <a className="btn-floating mbh-green" style={{ marginRight:"20px" }}><i className="material-icons notextselect" style={{userSelect:"none"}} onClick={updateRegion}>save</i></a>
                                            <a className="btn-floating mbh-red"><i className="material-icons notextselect" style={{userSelect:"none"}} onClick={deleteRegion}>delete</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                        
                    }
                </>
            }
        </section>
    )
}

export default AdminRegions
