import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName'
import './TrainingPlanNav.scss' 

const TrainingPlanNav = ({ allStudentQuals, trainingPlanNumber, setTrainingPlanNumber, setCurrentQualId }) => {

    let history = useHistory()
    const { courseId } = useParams()

    const handleTabClick = (e) => {
        const tabIndex = e.target.dataset.id
        setTrainingPlanNumber(tabIndex)
        setCurrentQualId(allStudentQuals[tabIndex].QualId)
        history.push(`/admin-portal/administer-student/student-training-plan/${allStudentQuals[tabIndex].QualId}`)
    }

    useEffect(() => {
        const tabs = document.getElementsByClassName("training-plan-nav__tab")
        const classToAdd = "training-plan-nav__active-tab"
        // Clear tab classes so that none are active
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].classList.contains(classToAdd)) {
                tabs[i].classList.remove(classToAdd)
            }
        }

        // Only set the relevant tab to be active
        for (let i = 0; i < allStudentQuals.length; i++) {
            if (allStudentQuals[i].QualId === +courseId) {
                if (!tabs[i].classList.contains(classToAdd)) {
                    tabs[i].classList.add(classToAdd)
                }
                break
            }
        }
    })

    return (
        <section className="training-plan-nav">
            {
                allStudentQuals.map((qual, index) => {

                    return trainingPlanNumber === 0 && index === 0 ?
                        <article 
                            key={qual.QualId} 
                            className="training-plan-nav__tab training-plan-nav__active-tab"
                            onClick={handleTabClick}
                            data-id={index}
                        >
                            <p onClick={handleTabClick} data-id={index}>{qual.Qualification.AntaId} {capitaliseEachWordOfQualName(qual.Qualification)}</p>
                        </article>
                        :
                        <article 
                            key={qual.QualId} 
                            className="training-plan-nav__tab"
                            onClick={handleTabClick}
                            data-id={index}
                        >
                            <p onClick={handleTabClick} data-id={index}>{qual.Qualification.AntaId} {capitaliseEachWordOfQualName(qual.Qualification)}</p>
                        </article>
                })
            }
        </section>
    )
}

export default TrainingPlanNav
