import React, { useState, useEffect } from 'react'
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import SearchBar from '../SearchBar/SearchBar';
import './AdminPortalHome.scss'
import Spinner from '../../assets/spinner.svg';
import { handleQualFilterChange } from '../../functions/handleFilterChanges';
import { createObjectOfStudentsWithNatFileErrors } from '../../functions/findStudentsWithNatFileErrors';
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import { useThemeContext } from '../../ThemeContext';

const AdminPortalHome = ({ onStudentSelect }) => {
    
    const { authToken } = useThemeContext()

    const [isDisabledStudent, setIsDisabledStudent] = useState(false);
    const studentUrl = `${baseUrl}/api/students?$filter=IsDisabled eq ${isDisabledStudent}&$orderBy=Surname`;
    const [isLoadingStudents, allStudents, error] = useDataFetch(studentUrl, authToken)
    const [students, setStudents] = useState([])

    const studentQualsURL = `${baseUrl}/api/studentqualifications`;
    const [isLoadingStudentQuals, studentQuals, errorStudentQuals] = useDataFetch(studentQualsURL, authToken);

    //get all studentsqualifications that are currently active
    const [studentQualUrl, setStudentQualUrl] = useState(`${baseUrl}/api/studentqualifications?$filter=Active eq true and Disabled eq false&$expand=Student`);
    const [isLoadingStudentQualActive, studentQualActive, errorSQ] = useDataFetch(studentQualUrl, authToken)
    const [active, setActive] = useState(true);
    const [allActiveStudents, setAllActiveStudents] = useState([])
    
    useEffect(() => {
        if (studentQualActive.length) {
            setAllActiveStudents(arrayToObjectWithKey(studentQualActive, "StudentId"))
        }
    }, [studentQualActive])

    const [awaitingQuote, setAwaitingQuote] = useState(false)
    const [studentsAwaitingQuote, setStudentsAwaitingQuote] = useState({})

    const [awaitingAcceptance, setAwaitingAcceptance] = useState(false)
    const [studentsAwaitingAcceptance, setStudentsAwaitingAcceptance] = useState({})

    const [acceptedNoContractID, setAcceptedNoContractID] = useState(false)
    const [studentsAcceptedNoContractID, setStudentsAcceptedNoContractID] = useState({})

    useEffect(() => {
        if (studentQuals.length) {
            const allStudentsAwaitingQuote = studentQuals.filter(qual => qual.Active && !qual.Disabled && qual.QuoteId === null)
            setStudentsAwaitingQuote(arrayToObjectWithKey(allStudentsAwaitingQuote, "StudentId"))
            
            const allStudentsAwaitingAcceptance = studentQuals.filter(qual => qual.Active && !qual.Disabled && !qual.ConfirmedCost && qual.QuoteId !== null)
            setStudentsAwaitingAcceptance(arrayToObjectWithKey(allStudentsAwaitingAcceptance, "StudentId"))

            const allStudentsAcceptedNoContractID = studentQuals.filter(qual => qual.Active && !qual.Disabled && qual.ConfirmedCost && qual.QuoteId !== null && qual.ContractId === null)
            setStudentsAcceptedNoContractID(arrayToObjectWithKey(allStudentsAcceptedNoContractID, "StudentId"))
        }
    }, [studentQuals])


    //fetch all unregistered students
    const unregisteredStudentsURL = `${baseUrl}/api/students/unregisteredstudents?$filter=CreatedAt ne null`;
    const [ isLoadingUnregistered, unregisteredStudents, errorUnregistered ] = useDataFetch(unregisteredStudentsURL, authToken);
    const [unregistered, setUnregistered] = useState(false);
    const [allUnregisteredStudents, setAllUnregisteredStudents] = useState([])
    //create an array of the unregistered student id's
    const unregStudents = [];
    unregStudents.push(unregisteredStudents.map(s => s.Id))
    if(unregStudents.length <1){
        unregStudents.push('preventNullError');
    }

    useEffect(() => {
        if (unregisteredStudents.length) {
            setAllUnregisteredStudents(arrayToObjectWithKey(unregisteredStudents, "Id"))
        }
    }, [unregisteredStudents])

    
    // Fetch all students with Nat File Errors
    const natFileErrorsURL = `${baseUrl}/api/natforms/validatedates`
    const [ isLoadingNatFileErrors, natFileErrors, errorNatFileErrors ] = useDataFetch(natFileErrorsURL, authToken);
    const [showNatFileErrors, setShowNatFileErrors] = useState(false)
    const [studentsWithNatFileErrors, setStudentsWithNatFileErrors] = useState(null)

    useEffect(() => {
        if (natFileErrors.length) {
            const erroredStudents = createObjectOfStudentsWithNatFileErrors(natFileErrors)
            setStudentsWithNatFileErrors(erroredStudents)
        }
    }, [natFileErrors])

    
    // Grabbing all qualifications so that the user can filter students by a particular qualification
    const qualificationsURL = `${baseUrl}/api/qualifications`
    const [isLoadingQuals, fetchedQuals, errorAllQuals] = useDataFetch(qualificationsURL, authToken);
    const [selectedQualID, setSelectedQualID] = useState("0")
    const [allQuals, setAllQuals] = useState([]);    
    
    const [filteredStudentObject, setFilteredStudentObject] = useState({});

    // Funding options
    const [showDontKnowYet, setShowDontKnowYet] = useState(false)
    const [showSelfFunded, setShowSelfFunded] = useState(false)
    const [dontKnowYetStudents, setDontKnowYetStudents] = useState({})
    const [selfFundedStudents, setSelfFundedStudents] = useState({})

    const handleStudentFiltering = (student) => {
        if ((!active || (active && allActiveStudents[student.Id])) &&
        (!awaitingQuote || (awaitingQuote && studentsAwaitingQuote[student.Id])) &&
        (!awaitingAcceptance || (awaitingAcceptance && studentsAwaitingAcceptance[student.Id])) &&
        (!acceptedNoContractID || (acceptedNoContractID && studentsAcceptedNoContractID[student.Id])) &&
        (!unregistered || (unregistered && allUnregisteredStudents[student.Id])) &&
        (!showNatFileErrors || (showNatFileErrors && studentsWithNatFileErrors[student.Id])) &&
        (!showDontKnowYet || (showDontKnowYet && dontKnowYetStudents[student.Id])) &&
        (!showSelfFunded || (showSelfFunded && selfFundedStudents[student.Id]))) {
            return true
        }
        
        return false
    }

    useEffect(() => {
        if (showDontKnowYet) {
            const dontKnowYetArray = studentQualActive.filter(qual => qual.Funding === "Don't know yet")
            setDontKnowYetStudents(arrayToObjectWithKey(dontKnowYetArray, "StudentId"))
        }

        if (showSelfFunded) {
            const selfFundedArray = studentQualActive.filter(qual => qual.Funding === "Self Funded")
            setSelfFundedStudents(arrayToObjectWithKey(selfFundedArray, "StudentId"))
        }
    }, [showDontKnowYet, showSelfFunded])
    
    useEffect(() => {
        if (selectedQualID && selectedQualID !== "0") {
            setFilteredStudentObject(handleQualFilterChange(selectedQualID, studentQuals, active));
        }
    }, [selectedQualID, active])

    useEffect(() => {
        setStudents(allStudents)
    }, [allStudents])
    
    useEffect(() => {
        if (fetchedQuals.length) {
            setAllQuals(fetchedQuals)
        }
    }, [fetchedQuals])
    
    return (
        <section>
                <section className="admin-home">
                    <h2>Admin Portal</h2>
                    {
                        allQuals.length ?
                        <>
                            <SearchBar 
                                data={allStudents} 
                                setData={setStudents}
                                setStudentQualUrl={setStudentQualUrl}
                                active={active}
                                setActive={setActive}
                                setAwaitingQuote={setAwaitingQuote}
                                setAwaitingAcceptance={setAwaitingAcceptance}
                                setAcceptedNoContractID={setAcceptedNoContractID}
                                allActiveStudents={allActiveStudents}
                                setUnregistered={setUnregistered}
                                selectedQualID={selectedQualID}
                                setSelectedQualID={setSelectedQualID}
                                setShowNatFileErrors={setShowNatFileErrors}
                                allQuals={allQuals}
                                setIsDisabledStudent={setIsDisabledStudent}
                                setShowDontKnowYet={setShowDontKnowYet}
                                setShowSelfFunded={setShowSelfFunded}
                            />
                        </>
                        :
                        null
                    }

                    <section className="admin-home__all-students">
                        {
                            isLoadingQuals && isLoadingNatFileErrors || isLoadingStudents || isLoadingStudentQualActive ?
                            <img src={Spinner} alt="Spinner"/>
                            :
                            (
                                students.length ?
                                (
                                    selectedQualID === "0" ?
                                    students
                                    .filter(student => handleStudentFiltering(student))
                                    .map(student => (
                                        <p key={student.Id} 
                                            className="admin-home__student" 
                                            data-id={student.Id}
                                            onClick={(e) => {onStudentSelect(e, 'administerStudent')} }
                                        >
                                            {`${student.FirstName} ${student.Surname}`}
                                        </p>
                                    ))
                                    :
                                    (
                                        filteredStudentObject !== null ?
                                        students 
                                        .filter(student => filteredStudentObject[student.Id] && handleStudentFiltering(student))
                                        .map(student => (
                                            <p key={student.Id} 
                                                className="admin-home__student"
                                                data-id={student.Id}
                                                onClick={(e) => {onStudentSelect(e, 'administerStudent')}}
                                            >
                                                {`${student.FirstName} ${student.Surname}`}
                                            </p>
                                        ))
                                        :
                                        <p>No students found</p>
                                    )
                                )
                                :
                                <p>No students found</p>
                            )
                        }
                    </section>
                </section>
        </section>
    )
}

export default AdminPortalHome
