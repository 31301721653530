import React from 'react'
import './EventAttendeeList.scss'

const EventAttendeeList = ({ allAttendees, deleteAttendee }) => {

    return (
        <div className="attendees row">
            <div className="attendees__box col s12 m10">
                <h5>Current Attendees ({allAttendees.length && allAttendees.length})</h5>
                <div className="attendees__box--attendees-container">
                {
                    allAttendees.length && 
                    allAttendees
                        .map(attendee => {
                            const isStudentObjectNested = attendee.Student !== undefined ? true : false
                            if (isStudentObjectNested) {
                                console.log(attendee)
                            }
                            return (
                                <div key={attendee.Student ? attendee.Student.Id : attendee.Id} className="attendees__box--attendee">
                                    {
                                        isStudentObjectNested ?
                                        <>
                                            <span>{`${attendee.Student.FirstName} ${attendee.Student.Surname}`}</span>
                                            <span className="attendees__box--delete" onClick={() => deleteAttendee(attendee.Student.Id)}><i className="material-icons mbh-red-text">delete</i></span>
                                        </>
                                        :
                                        <>
                                            <span>{`${attendee.FirstName} ${attendee.Surname}`}</span>
                                            <span className="attendees__box--delete" onClick={() => deleteAttendee(attendee.Id)}><i className="material-icons mbh-red-text">delete</i></span>
                                        </>
                                    }
                                </div>
                            )
                        })
                }
                </div>
            </div>
        </div>
    )
}

export default EventAttendeeList
