export const capitaliseEachWordOfQualName = (qual) => {
    return qual.Name.split(' ').map(word => {
        if (word === qual.AntaId || word === 'in' || word === 'of') {
            // Word might be the code of the qual, or an unimportant word
            return word
        } else if(word === 'ii' || word === 'iii' || word === 'iv' || word === 'v') {
            // Capitalise entire roman numeral
            return word.toUpperCase()
        } else {
            // Otherwise, just capitalise the first letter
            return `${word.charAt(0).toUpperCase()}${word.substring(1)}`
        }
    // Then, put the sentence back together
    }).join(' ')
}