import React, {useState} from 'react'
import './AdminProgramStreams.scss'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetchRefresh } from '../../hooks/useDataFetchRefresh';
import M from 'materialize-css';

const AdminProgramStreams = ({authToken}) => {

    const [refreshData, setRefreshData] = useState(false);

    const progUrl = `${baseUrl}/api/progstreams`;
    const [isLoading, progs, error] = useDataFetchRefresh(progUrl, authToken, refreshData, setRefreshData);

    const updateProgramStream = (e, obj) => {
       
        const {name, value} = e.target;
        obj[name] = value;
     
        fetch(`${baseUrl}/api/progstreams/${obj['ID']}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const addProgramStream = () => {

       const newProgStream = document.getElementById("newProgStream").value;
       const newProgStreamDescription = document.getElementById("newProgStreamDescription").value;

       if(isNaN(newProgStream)){
            M.toast({html: "ID must be a numeric value", displayLength: 2000});
       }else if(newProgStream.trim() !== "" && newProgStreamDescription.trim() != ""){
          
           const obj={
            "ProgStream": newProgStream,
            "Description": newProgStreamDescription
           }
          
           fetch(`${baseUrl}/api/progstreams`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(obj)
            })
            .then(response => response.json())
            .catch(err => console.log(err))

            document.getElementById("newProgStream").value = "";
            document.getElementById("newProgStreamDescription").value="";
            setRefreshData(true);
       }else{
            M.toast({html: "Please provide both an ID and a Description", displayLength: 2000});
       }
    }

    const deleteProgramStream = (obj) => {
        fetch(`${baseUrl}/api/progstreams/${obj['ID']}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
        .then(response => response.json())
        .catch(err => console.log(err))

        setRefreshData(true);
    }

    return (
        <div className="admin-program-streams">
            <h5 className="mbh-green-text">Program Streams</h5>
            
            <table className="admin-program-streams__table">
                <thead>
                    <tr>
                        <th className="bold">Id</th>
                        <th className="bold">Description</th>
                        <th className="bold"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        progs.map((prog, index) => (
                            <tr key={prog.ID}>
                                <td className="admin-program-streams__table-column--id"><input name="ProgStream" type="text" defaultValue={prog.ProgStream} onChange={(e) => updateProgramStream(e, prog)} /></td>
                                <td><input name="Description" type="text" defaultValue={prog.Description} onChange={(e) => updateProgramStream(e, prog)} /></td>   
                                <td className="admin-program-streams__table-column--id" title="DELETE"> <a class="btn-flat" style={{padding:"0",display:"flex", justifyContent:"center"}} onClick={() => deleteProgramStream(prog)}><i className="material-icons admin-program-streams__delete">delete</i></a></td>                               
                            </tr>
                        ))
                    }
                    <tr>
                        <td className="admin-program-streams__table-column--id"><input id="newProgStream" className="col s1" type="text" placeholder="id" autoComplete="new-password"></input></td>
                        <td><input id="newProgStreamDescription" className="col s9" type="text" placeholder="add new program stream" autoComplete="new-password" style={{width: "100px;"}}></input></td>
                        <td><a className="btn-floating mbh-green right" onClick={addProgramStream}><i className="material-icons" style={{userSelect:"none"}}>add</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default AdminProgramStreams
