import React, {useEffect, useState} from 'react'
import Checkbox from '../Checkbox/Checkbox'
import M from 'materialize-css';
import './FormTypes.scss';

const FormTypes = ({ currentQuestion, handleVetChange, showQuestion, vetAnswersObject, route, studentVetAnswers}) => {
   
    useEffect(()=>{
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    })

    // Updating dropdown fields
    const handleChange = (e, answers, selectedAnswer, token) => {
        showQuestion(answers, selectedAnswer);
        handleVetChange(e, token)
    }
    
   
    const questionDisplay = (route ==='studentPortal' || currentQuestion.DefaultVisibility) ? 'block' : 'none';

    let answersForCurrentQuestion = []
    let answerObject = {}

    if(route === 'studentPortal'){
        if (studentVetAnswers.length) {
            answersForCurrentQuestion = studentVetAnswers.filter(answer => {
                return answer.VetQuestionId === currentQuestion.Id
            })
            
            if (answersForCurrentQuestion.length) {
                for (let i = 0; i < answersForCurrentQuestion[0].Answers.length; i++) {
                    const id = answersForCurrentQuestion[0].Answers[i].VetAnswerId
                    if (id !== undefined) {
                        answerObject[id] = true;
                    }
                }
            }
        }
    }    
  
    return (
        <div id={`id${currentQuestion.Id}`} className="formTypes" style={{display:`${questionDisplay}`}}>

        {/* <div className="formTypes"> */}
            <p>{currentQuestion.Text}</p>
            {
                currentQuestion.Type === 'Checkbox' ?
                <div>
                    {
                        currentQuestion.Answers.map((answer, i) => {
                            return (
                            <p key={i} style={{textAlign: 'left'}} className="formTypes__checkbox">
                                <label>
                                    <Checkbox currentQuestion={currentQuestion} answersForCurrentQuestion={answersForCurrentQuestion} handleVetChange={handleVetChange} answerObject={answerObject} answer={answer} />
                                    <span className="black-text">{answer.Text}</span>
                                </label>
                            </p>
                            )
                        }
                        
                        )}
                </div>
                :
                (
                    currentQuestion.Type === 'Dropdown' || currentQuestion.Type === 'Radio' ?
                         <select name={currentQuestion.Text} onChange={(e) => handleChange(e, currentQuestion.Answers, e.target.value)} >
                            <option value="" disabled selected>Select one</option>
                            {
                                currentQuestion.Answers.map((answer, i) => 
                                    {
                                    return answersForCurrentQuestion.length?
                                    <option key={i} value={answer.Id} selected={answer.Id === (answersForCurrentQuestion[0].Answers.length && answersForCurrentQuestion[0].Answers[0].VetAnswerId)?true:false}  data-questionid={answersForCurrentQuestion[0].Id} data-answerid={answersForCurrentQuestion[0].Answers.length?answersForCurrentQuestion[0].Answers[0].Id:""}>{answer.Text}</option>
                                    :
                                    <option key={i} value={answer.Id} >{answer.Text}</option>
                                 
                                    }
                                )
                            }
                        </select>
                    :
                    (
                        currentQuestion.Type === 'Textbox' || currentQuestion.Type === 'Text'?
                        <div className="input-field col s12">

                            {
                                 answersForCurrentQuestion.length ?
                                     <>
                                         <input id={currentQuestion.Id} type="text" name={currentQuestion.Text}  onChange={handleVetChange} defaultValue={answersForCurrentQuestion[0].Answers.length?answersForCurrentQuestion[0].Answers[0].Text:"" } data-questionid={answersForCurrentQuestion[0].Id} data-answerid={answersForCurrentQuestion[0].Answers.length?answersForCurrentQuestion[0].Answers[0].Id:""}/>
                                         <label htmlFor={currentQuestion.Id} className="active"> </label>
                                     </>                                   
                                    :
                                    <>
                                    <input id={currentQuestion.Id} type="text" name={currentQuestion.Text}  onChange={handleVetChange}  />
                                    <label htmlFor={currentQuestion.Id} > Enter text here </label>
                                    </>
                            }
                             
                        </div>
                        :
                        (currentQuestion.Type ==='Date') ?
                        <div className="input-field col s12">
                            {
                             answersForCurrentQuestion.length ?
                            <>
                                <input id={currentQuestion.Id}  type="date" placeholder="dd/mm/yyyy" name={currentQuestion.Text} onChange={(e) => handleVetChange(e, vetAnswersObject, currentQuestion.Id)} data-questionid={answersForCurrentQuestion[0].Id} data-answerid={answersForCurrentQuestion[0].Answers.length?answersForCurrentQuestion[0].Answers[0].Id:""}/>
                                <label htmlFor={currentQuestion.Id}>  </label>
                            </>                                   
                            :
                            <>
                                <input id={currentQuestion.Id}  type="date" placeholder="dd/mm/yyyy" name={currentQuestion.Text} onChange={(e) => handleVetChange(e, vetAnswersObject, currentQuestion.Id)} />
                                <label htmlFor={currentQuestion.Id}>  </label>
                            </>
                            }
                        </div>
                        : null
                    )
                )
            }
        </div>
    )
}

export default FormTypes
