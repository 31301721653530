import React from 'react'
import Certification from '../../assets/illustrations/certification.svg'
import ViewOtherCourses from '../../components/ViewOtherCourses/ViewOtherCourses'
import Updates from '../../assets/illustrations/updates.svg'
import SharedGoals from '../../assets/illustrations/shared-goals.svg'
import Launching from '../../assets/illustrations/launching.svg'
import ProjectCompleted from '../../assets/illustrations/project-completed.svg'
import Brochure from '../../assets/brochures/Diploma of Project Management.pdf'
import './DiplomaInPM.scss'

const DiplomaInPM = ({ history }) => {
    return (
        <section className="diplomaInPM">
            <h2>BSB50820 Diploma of Project Management</h2>
            <article className="diplomaInPM__article">
                <figure className="diplomaInPM__figure">
                    <img src={Certification} alt="Certification unDraw illustration" className="responsive-img" />
                </figure>
                <article className="diplomaInPM__description">
                    <p>This qualification is the pinnacle qualification at mbh and is not for the feint hearted. We push beyond the training 
                        competencies to develop your skills and knowledge of all the things that really matter as a fully fledged 
                        project manager.
                    </p>
                    <div className="diplomaInPM__btn-group">
                        <button className="btn mbh-black lighten-4 mbh-black-text diplomaInPM__btn-group--2" onClick={() => history.push('/contact-us')}>Contact Us</button>
                        <button className="btn mbh-green diplomaInPM__btn-group--3" onClick={() => history.push('/register')}>Register</button>
                    </div>
                    
                </article>
            </article>
            <div className="diplomaInPM__btn-group">
                <button className="btn mbh-black lighten-5 mbh-black-text diplomaInPM__btn-group--1" onClick={() => history.push('/smart-and-skilled')}>Smart and Skilled</button>
                <a className="btn" href={Brochure} target="_blank" rel="noopener noreferrer">Download Brochure</a>
            </div>
            <article className="diplomaInPM__involve">
                <h3>What does it involve?</h3>
                <p>
                    Our approach goes beyond the functional areas of project management and on a journey through the project management lifecycle. 
                    <br /><br />
                    Drawing on the three spheres of Managing by Project - change management, investment management and project management - this qualification aims to develop the broad range of skills and expertise required of a modern day project manager.
                    <br />
                    <br/>
                    <a className="btn" href={Brochure} target="_blank" rel="noopener noreferrer">Download Brochure</a>
                </p>
            </article>
            <section className="career-pathways__stage-two--pathway-section">
                <article className="diplomaInPM__course-details--article">
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={Updates} alt="Updates" />
                    </figure>
                    <article>
                        <p>Attend diploma qualification delivered by trainers who have been there done that</p>
                    </article>
                </article>
                <hr />
                <article className="diplomaInPM__course-details--article reverse-flex">
                    <article>
                        <p>Lead a project to successful delivery</p>
                    </article>
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={ProjectCompleted} alt="Project Completed" />
                    </figure>
                </article>
                <hr />
                <article className="diplomaInPM__course-details--article">
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={SharedGoals} alt="Shared Goals" />
                    </figure>
                    <article>
                        <p>Be mentored by our experienced PM consultants</p>
                    </article>
                </article>
                <hr />
                <article className="diplomaInPM__course-details--article column-reverse-flex">
                    <article>
                        <p>Gain your Diploma of Project Management and start running projects every day</p>
                    </article>
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={Launching} alt="Launching" />
                    </figure>
                </article>
                <hr />
            </section>
            <ViewOtherCourses history={history} />
        </section>
    )
}

export default DiplomaInPM
