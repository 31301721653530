import React, {useEffect} from 'react'
import { useThemeContext } from '../../ThemeContext'

const StateDropdown = ({name, updateStudentDetails, studentDetails, studentUrl, state, refreshAddressState, setRefreshAddressState}) => {

    const { authToken } = useThemeContext()

    useEffect(()=>{
        setRefreshAddressState(false)
    },[refreshAddressState])
    
    return (
        name === "State"?
        <div>
             <select onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} name={name} value={studentDetails.State ? studentDetails.State : "0"}>
                <option value="0">Select State</option>
                {                          
                    state.map((state, i) => {
                        return <option key={i} defaultValue={state.Id} value={state.Id}>{state.StateCode?state.StateCode:state.Description}</option>
                    })
                }
                </select>
                <label>State</label>
        </div>
        ://"State_Pos"
        <div>
        <select onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} name={name} value={studentDetails.State_Pos ? studentDetails.State_Pos : "0"}>
           <option value="0">Select State</option>
           {                          
               state.map((state, i) => {
                   return <option key={i} defaultValue={state.Id} value={state.Id}>{state.StateCode?state.StateCode:state.Description}</option>
               })
           }
           </select>
           <label>State</label>
        </div>
    )
}

export default StateDropdown
