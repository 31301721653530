import React, { useEffect } from 'react'
import M from 'materialize-css';


const EventsAddButton = ({ history }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('.dropdown-trigger');
        var instances = M.Dropdown.init(elems, {});
    })

    const enlargeDropdown = () => {
        const dropdown = document.getElementById("create-options")
        dropdown.style.width = "140px"
        dropdown.style.height = "auto"

    }

    return (
        <>
            <a className='dropdown-trigger btn-floating mbh-green' href='#' data-target='create-options' style={{marginLeft: "5px"}} onClick={enlargeDropdown}><i className="material-icons notextselect">more_vert</i></a>
            <ul id='create-options' className='dropdown-content'>
                <li><a href="#/admin-portal/events/trainers" className="mbh-green-text">Trainers</a></li>
                <li><a href="#/admin-portal/events/create-type" className="mbh-green-text">Event Types</a></li>
                <li><a href="#/admin-portal/events/manage-groups" className="mbh-green-text">Groups</a></li>
            </ul>
        </>
    )
}

export default EventsAddButton
