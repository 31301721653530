import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Spinner from '../../assets/spinner.svg';
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import { useThemeContext } from '../../ThemeContext';

import './StudentEvents.scss'

const StudentEvents = ({ studentFirstName }) => {

    let history = useHistory()
    const { studentID, selectedStudentId, authToken, userRole } = useThemeContext()

    const currentStudentID = userRole === "Administrator" ? selectedStudentId : studentID    

    const studentEventsUrl = `${baseUrl}/api/eventregistrations?$filter=StudentID eq ${currentStudentID}`;
    const [isLoadingStudentEvents, studentEvents, studentEventsErr] = useDataFetch(studentEventsUrl, authToken)

    const allEventsUrl = `${baseUrl}/api/events`;
    const [isLoadingAllEvents, allEvents, allEventsErr] = useDataFetch(allEventsUrl, authToken)

    const trainersUrl = `${baseUrl}/api/trainers`;
    const [isLoadingTrainers, trainers, errorTrainers] = useDataFetch(trainersUrl, authToken)

    const [eventsAttendedByStudent, setEventsAttendedByStudent] = useState([])

    const [upcomingEvents, setUpcomingEvents] = useState([])
    const [pastEvents, setPastEvents] = useState([])

    useEffect(() => {
        const existingEvents = []

        if (studentEvents.length && allEvents.length) {
            const allEventsObj = arrayToObjectWithKey(allEvents, "Id")

            for (let i = 0; i < studentEvents.length; i++) {
                const id = studentEvents[i].EventID
                existingEvents.push(allEventsObj[id])
            }
        }

        setEventsAttendedByStudent(existingEvents)
    }, [studentEvents, allEvents])

    const isUpcomingEvent = (endDateTime) => {
        const currentDay = dayjs().format("YYYY-MM-DD");
        const currentTime = dayjs().format("dddd, MMMM D, YYYY h:mm A");

        const dayOfEvent = dayjs(endDateTime).format("YYYY-MM-DD")
        const endTimeOfEvent = dayjs(endDateTime).format("dddd, MMMM D, YYYY h:mm A")

        if (dayjs(currentDay).isAfter(dayOfEvent)) {
            return false
        } else if (dayjs(currentDay).isSame(dayOfEvent) && dayjs(currentTime).isAfter(endTimeOfEvent)) {
            return false
        }

        return true
    }

    useEffect(() => {
        if (eventsAttendedByStudent.length) {
            const todaysDate = dayjs().format()
            const filterUpcomingEvents = eventsAttendedByStudent.filter(event => isUpcomingEvent(event.EndDateTime));
            const filterPastEvents = eventsAttendedByStudent.filter(event => !isUpcomingEvent(event.EndDateTime))
                                                            .sort((a, b) => a.StartDateTime < b.StartDateTime)

            setUpcomingEvents(filterUpcomingEvents)
            setPastEvents(filterPastEvents)
        }
    }, [eventsAttendedByStudent])

    const formatEventDate = (startDate, endDate, typeOfField) => {
        if (typeOfField === "Date") {
            return new Date(startDate).toLocaleString('en-US', {
                weekday: 'long', // long, short, narrow
                day: 'numeric', // numeric, 2-digit
                month: 'long', // numeric, 2-digit, long, short, narrow
            })
        } else if (typeOfField === "Time") {
            const formattedStartDate = new Date(startDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })
            const formattedEndDate = new Date(endDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })

            return `${formattedStartDate} - ${formattedEndDate}`
        }
    }

    const displayTrainerName = (trainerId) => {
        if (trainers.length) {
            const trainer = trainers.filter(trainer => trainer.Id === trainerId)[0]
            return trainer ? `${trainer.FirstName} ${trainer.Surname}` : "No Trainer Selected"
        } else {
            return "No Trainer Selected"
        }
    }

    

    return (
        <section className="student-events">
            {
                isLoadingAllEvents || isLoadingStudentEvents ?
                <img src={Spinner} alt="Spinner"/>
                :
                (
                    upcomingEvents.length || pastEvents.length ?
                    <article className="student-events__container">
                        <h5 className="mbh-green-text student-events__container--title">Events for {studentFirstName}</h5>
                        {
                            upcomingEvents.length ?
                            <>
                                <h6 className="student-events__container--title">Upcoming events</h6>
                                <article class="student-events__event-cards">
                                    {
                                        upcomingEvents.map(event => (
                                                <article className="student-events__event-cards--event-details">
                                                    <h6 className="mbh-green-text cursor" onClick={() => history.push(`/admin-portal/events/event-details/${event.Id}`)}>{event.Subject}</h6>
                                                    <p>Date: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Date')}</p>
                                                    <p>Time: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Time')}</p>
                                                    <p>Taught by: {displayTrainerName(event.TrainerID)}</p>
                                                </article>
                                            )
                                        )
                                    }
                                </article>
                            </>
                            :
                            <h6 className="student-events__container--title">No upcoming events</h6>
                        }
                        {
                            pastEvents.length ?
                            <>
                                <div className="student-events__container--past-events">
                                    <h6 className="student-events__container--title">Past events</h6>
                                </div>
                                <article class="student-events__event-cards">
                                    {
                                        pastEvents.map(event => (
                                            <article className="student-events__event-cards--event-details">
                                                <h6 className="mbh-green-text cursor" onClick={() => history.push(`/admin-portal/events/event-details/${event.Id}`)}>{event.Subject}</h6>
                                                <p>Date: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Date')}</p>
                                                <p>Time: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Time')}</p>
                                                <p>Taught by: {displayTrainerName(event.TrainerID)}</p>
                                            </article>
                                        )
                                    )
                                    }
                                </article>
                            </>
                            :
                            <h6 className="student-events__container--title">No past events</h6>
                        }
                    </article>
                    :
                    <article className="student-events__container">
                        <h5 className="mbh-green-text student-events__container--title">Events for {studentFirstName}</h5>
                        <h6 className="student-events__container--title">No events at the moment</h6>
                    </article>
                )
            }
            
        </section>
    )
}

export default StudentEvents
