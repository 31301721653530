import { useState, useEffect } from 'react'

export const useDataFetchRefresh = (url, token, refreshData, setRefreshData, refreshId) => {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
        
    useEffect(() => {

        // const ac = new AbortController();
        const fetchData = async () => {
            setIsLoading(true)
            if (refreshId !== undefined) {
            }
            try {
                let response;
                if (token !== undefined){
                    response = await fetch(url, {headers: {'Authorization': `Bearer ${token}`}})
                }else
                {
                    response = await fetch(url)
                }
                
                const json = await response.json()
                json.value? setData(json.value) : setData(json)
                setRefreshData(false)
               
            } catch(error) {
                setError(error)
            }
            setIsLoading(false)
        }
        fetchData();
        // return () => ac.abort(); 
    }, [refreshData, refreshId])

    return [ isLoading, data, error ]

}
