import React, {useState} from 'react'
import './ResetPassword.scss'
import { baseUrl } from '../../functions/globalVariables';
import FormSpinner from '../../assets/form-spinner.svg'
import M from 'materialize-css';
import ResetTokenNotFound from '../../components/ResetTokenNotFound/ResetTokenNotFound';

const ResetPassword = ({history}) => {

    const [isSuccess, setIsSuccess] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false)
    const [doesEmailExist, setDoesEmailExist] = useState(true)
    const [isResettingPassword, setIsResettingPassword] = useState(false)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    // url is like this: "https://prerelease.mbhtraining.com.au/#/reset-password?token=b74022cb-ff17-4183-863e-bda695435e71"
    const splitUrl = window.location.href.split('token=');

    console.log(splitUrl)
    let token = ""
    if (splitUrl.length > 1 && splitUrl[1].trim() !== "") {
        token = splitUrl[1]
    } else {
        return( 
            <ResetTokenNotFound history={history} />
        )
    }

    const checkAPIResponse = (data, status) => {
        if (status === 200){
            setIsSuccess(true)
            setIsTokenExpired(false)
            setDoesEmailExist(true)
        }else{
            if(data.Message){
                if (data.Message === "Error while resetting the password") {
                    setIsTokenExpired(true)
                } else if (data.Message === "An error has occurred.") {
                    setDoesEmailExist(false)
                }
            }
        }
    }

    const postData = (e) => {
        e.preventDefault() 
        validateInfo()
        
        let status;
        setIsResettingPassword(true)
        fetch(`${baseUrl}/api/auth/ResetPassword`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Email: email,
                Password: password,
                ConfirmPassword: confirmPassword,
                Token: token
            })
        })
        .then(response => {
            status = response.status;
            checkAPIResponse("", status)
            return response.json()
        })
        .then(data => {checkAPIResponse(data, status); setIsResettingPassword(false)})
        .catch(err => {console.log("Error: ", err); setIsResettingPassword(false)}) 
    }


    const validateInfo = () =>{
        const emailInput = document.getElementById('email');

        if (emailInput.classList.contains("invalid") || email.trim().length === 0) {
            M.toast({html: 'Please enter a valid email address', displayLength: 2000});
        }

        if(password === confirmPassword && password.length < 8){
            M.toast({html: 'Password must be at least 8 characters long', displayLength: 2000});
        }

        if (password !== confirmPassword) {
            M.toast({html: 'Passwords must match', displayLength: 2000});
        }
    }


    return (
        <section className="reset-password">
            <div className="reset-password__card container" style={{display:isSuccess?'none':'block'}}>
                <h3 className="center">Reset Password</h3>
                <p className="center">Password must be at least 8 characters long</p>
                <br/>
                <form className="reset-password__form">
                    <div className="row reset-password__row">
                        <div className="input-field col s12">
                            <input id="email" type="email" className="validate" onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="email">Email</label>
                        </div>
                        {
                            !doesEmailExist &&
                            <p className="mbh-red-text">Looks like this email isn't in our system</p>
                        }
                    </div>
                    <div className="row reset-password__row">
                        <div className="input-field col s12">
                            <input id="password" type="password" minLength="8" className="validate" onChange={(e) => setPassword(e.target.value)} />
                            <label htmlFor="password">Password</label>
                        </div>
                    </div>
                    <div className="row reset-password__row">
                        <div className="input-field col s12">
                            <input id="confirmPassword" type="password" minLength="8" className="validate" onChange={(e) => setConfirmPassword(e.target.value)} />
                            <label htmlFor="confirmPassword">Confirm Password</label>
                        </div>
                    </div>
                    {
                        isTokenExpired &&
                        <p className="reset-password__token-expired">This token has expired. <span className="mbh-green-text cursor" onClick={() => history.push("/forgot-password")}>Get a new one</span></p>
                    }
                    {
                        isResettingPassword ?
                        <figure>
                            <img src={FormSpinner} alt="Form spinner" />
                        </figure>
                        :
                        <button className="btn-large mbh-green" type='submit' onClick={(e) => postData(e)}>Submit</button>
                    }
                    <hr />
                    <button className="btn-large transparent mbh-green-text" 
                            style={{ boxShadow: 'none' }}
                            onClick={() => history.push('/privacy')}>
                        Privacy
                    </button>
                </form>
            </div>
            
            <div className="reset-password__card reset-password__success container" style={{display:isSuccess?'block':'none'}}>
                <h5>Password successfully reset!</h5>
                <br/><br/>
                <button 
                    className="btn-large mbh-green"
                    onClick={() => history.push('/login')}
                >
                    LOG IN
                </button>
            </div>
        </section>
    )
}

export default ResetPassword
