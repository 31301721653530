import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../functions/globalVariables';
import M from 'materialize-css';
import Spinner from '../../assets/spinner.svg'
import { useDataFetch } from '../../hooks/useDataFetch';
import { useFetchStudents } from '../../hooks/useFetchStudents';
import EventsGroupDetails from '../EventsGroupDetails/EventsGroupDetails';

import './EventsGroups.scss'
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';

const EventsGroups = ({setCurrentPage}) => {

    let history = useHistory()
    const { authToken } = useThemeContext()

    const [shouldFetchData, setShouldFetchData] = useState(false)

    const [currentQualId, setCurrentQualId] = useState(null)
    const [currentGroupIndex, setCurrentGroupIndex] = useState(null)

    // Keeps track of whether is currently being created or edited
    const [currentGroupOperation, setCurrentGroupOperation] = useState(null)

    const [currentGroup, setCurrentGroup] = useState({
        Id: null,
        GroupName: "",
        QualificationId: null,
        Students: []
    })

    const resetCurrentGroupObject = () => setCurrentGroup({
        id: null,
        GroupName: "",
        QualificationId: null,
        Students: []
    })

    const fetchUrl = `${baseUrl}/api/studentqualifications?$expand=*&$filter=QualId eq ${currentQualId} and Active eq true and Disabled eq false`
    const [isLoadingStudentQuals, studentQuals, errorStudentQuals] = useFetchStudents(fetchUrl, authToken, shouldFetchData)

    const qualificationsUrl = `${baseUrl}/api/qualifications?$filter=Superseded eq false`
    const [isLoadingQuals, quals, errorQuals] = useDataFetch(qualificationsUrl, authToken)

    const studentGroupsUrl = `${baseUrl}/api/studentgroups?$expand=Students`
    const [isLoadingGroups, studentGroups, errorGroups] = useDataFetch(studentGroupsUrl, authToken)

    const [showCreateGroupSection, setShowCreateGroupSection] = useState(false)
    const [showEditGroupSection, setShowEditGroupSection] = useState(false)
    
    const [filteredStudentQuals, setFilteredStudentQuals] = useState(null)

    useEffect(() => {
        if (studentQuals.length && currentQualId) {
            const filteredArray = studentQuals.filter(qual => qual.QualId === +currentQualId)
            setFilteredStudentQuals(filteredArray)
            setShouldFetchData(false)
        } else if (!studentQuals.length) {
            setFilteredStudentQuals([])
        }
    }, [studentQuals, currentQualId])

    const handleQualSelect = (e) => {
        setCurrentQualId(e.target.value)
        setShouldFetchData(true)
    }

    const handleGroupSelect = (e) => {
        const {value} = e.target
        setCurrentGroupIndex(value)
        setShowCreateGroupSection(false)
        setShowEditGroupSection(true)
        setCurrentGroup(prev => ({
            ...prev,
            Id: studentGroups[value].Id,
            GroupName: studentGroups[value].GroupName, 
            QualificationId: studentGroups[value].QualificationId,
            Students: studentGroups[value].Students
        }))
    }

    const handleShowCreateGroupSection = () => {
        setShowCreateGroupSection(!showCreateGroupSection)
        setShowEditGroupSection(false)
        resetCurrentGroupObject()
    }

    const onStudentSelect = (e, studentToAdd, setIsStudentAdded, operation) => {
        let listObj = {}

        if (!currentGroup.Students) {
            listObj = arrayToObjectWithKey(currentGroup.Students, "Id")
        }

        if (listObj[studentToAdd.Id] !== undefined) {
            M.toast({html: "Student is already attending", displayLength: 2000});
            return;
        }

        if (operation === 'add') {
            if (!currentGroup.Students) {
                setCurrentGroup(prev => ({...prev, Students: [studentToAdd]}))
            } else {
                setCurrentGroup(prev => ({...prev, Students: [...prev.Students, studentToAdd]}))
            }
            setIsStudentAdded(true)
        } else {
            deleteAttendee(studentToAdd.Id)
            setIsStudentAdded(false)
        }
    }

    const getStudentIdObject = () => {
        if (currentGroup.Students.length) {
            console.log(currentGroup.Students)
            return arrayToObjectWithKey(currentGroup.Students, "Id")
        }
        
        return {}
    }

    // By default, currentGroup.Students is passed so that I don't have to pass it 
    // down to the component just for adding as a parameter
    const addAllStudentsFromQual = (studentsToAdd) => {
        if (!studentsToAdd.length) {
            return;
        }
        // Create object of all student ids to mke filtering easier
        const listObj = getStudentIdObject()
        const uniqueStudents = studentsToAdd
                                .filter(student => listObj[student.Student.Id] === undefined)
                                .map(currStudent => currStudent.Student)

        console.log(uniqueStudents)

        // If all students being added exist already, don't add them again
        if (!uniqueStudents.length) {
            M.toast({html: "Students are already attending", displayLength: 2000});
            return;
        }

        if (currentGroup.Students.length) {
            setCurrentGroup(prev => ({...prev, Students: [...prev.Students, ...uniqueStudents]}))
        } else {
            setCurrentGroup(prev => ({...prev, Students: uniqueStudents}))
        }


    } 

    const onGroupNameChange = (e) => {
        const { value } = e.target
        setCurrentGroup(prev => ({...prev, GroupName: value}))
    }

    const handleSaveGroup = (fetchMethod, groupId) => {

        if (!currentGroup.GroupName.length) {
            M.toast({html: "Please add a group name", displayLength: 1000})
            return
        }

        const url = fetchMethod === 'PUT' ? `${baseUrl}/api/studentgroups/${groupId}` : `${baseUrl}/api/studentgroups/`

        // When posting, we don't want an id in the object
        // This code removes grabs all the attributes of currentGroup, except for id
        // Now, restOfObject can be used as a normal variable
        const { id, ...restOfObject } = currentGroup

        console.log(currentGroup, restOfObject)

        fetch(url, {
            method: fetchMethod,
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}`},
            body: fetchMethod === 'PUT' ? JSON.stringify(currentGroup) : JSON.stringify(restOfObject)
        })
        .then(response => {
            response.json()
            window.location.reload()
        })
        .catch(err => console.log(err))
    }

    const handleDeleteGroup = (groupId) => {
        fetch(`${baseUrl}/api/studentgroups/${groupId}`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}`},
        })
        .then(response => {
            response.json()
            window.location.reload()
        })
        .catch(err => console.log(err))
    }

    const deleteAttendee = (id) => {

        if (!currentGroup.Students) {
            return
        }

        const filteredList = currentGroup.Students
            .filter(attendee => attendee.Student ? attendee.Student.Id !== id : attendee.Id !== id)
            .sort((a, b) => {
                const firstSurname = a.Student ? a.Student.Surname : a.Surname
                const secondSurname = b.Student ? b.Student.Surname : b.Surname
                
                return firstSurname > secondSurname
            })

        if (filteredList.length) {
            setCurrentGroup(prev => ({...prev, Students: filteredList}))
        } else {
            setCurrentGroup(prev => ({...prev, Students: []}))
        }
    }

    // Initialise dropdown and modal
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});

        var elemsModal = document.querySelectorAll('.modal');
        var instancesModal = M.Modal.init(elemsModal, {});
    });

    return (

        <div className="event-create-group">
            <article className="events-summary__header">
                <h3 className="mbh-green-text">Manage Groups</h3>
                <div className="events-summary__header--btns">
                    <button className="btn-floating mbh-green" onClick={() => {setCurrentPage("summary"); history.push('/admin-portal/events')}} style={{marginRight: "5px"}}><i className="material-icons notextselect">arrow_back</i></button>
                    <button className="btn-floating mbh-green" onClick={() => {setCurrentPage("calendar"); history.push('/admin-portal/events/calendar')}}><i className="material-icons notextselect">event</i></button>
                </div>
            </article>
            <div>

            {
                isLoadingGroups ?
                <img src={Spinner} alt="Spinner" />
                :
                <div className="row">
                    {
                        studentGroups ?
                        <div className="row col s12 m10">
                            <select id="GroupSelect" onChange={handleGroupSelect} value={currentGroupIndex ? currentGroupIndex : "0"}>
                                <option value="0" disabled>Select a Group</option>
                                {
                                    studentGroups.map((group, index) => (
                                        <option key={group.Id} value={index}>{group.GroupName}</option>
                                    ))
                                }
                            </select>
                        </div>
                        :
                        <h4>Could find any student groups</h4>
                    }
                    <div className="col s12 m2 event-create-group__save-name">
                        {
                            showCreateGroupSection ?
                            <button 
                                className="btn-floating mbh-red" 
                                onClick={handleShowCreateGroupSection}
                            >
                                <i className="material-icons notextselect">close</i>
                            </button>
                            :
                            <button 
                                className="btn-floating mbh-green" 
                                onClick={handleShowCreateGroupSection}
                            >
                                <i className="material-icons notextselect">add</i>
                            </button>

                        }
                    </div>
                </div>
            }

            {
                showEditGroupSection &&
                <>
                    <div className="row">
                        <h4 className="col s12 m10">Edit Group</h4>
                        
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m10">
                            <input value={currentGroup.GroupName} id="editGroupName" type="text" onChange={onGroupNameChange} />
                            <label htmlFor="editGroupName" className="active">Group Name</label>
                        </div>
                        <div className="col s12 m2 event-create-group__save-name">
                            <button 
                                className="btn-floating mbh-green"
                                onClick={() => handleSaveGroup('PUT', currentGroup.Id)}
                                style={{marginRight: "5px"}}
                            >
                                <i className="material-icons notextselect">save</i>
                            </button>
                            <button 
                                data-target="deleteGroupModal"
                                className="btn-floating mbh-red modal-trigger"
                            >
                                <i className="material-icons notextselect">delete</i>
                            </button>
                        </div>
                    </div>
                    <EventsGroupDetails
                        studentsInGroup={currentGroup.Students}
                        deleteAttendee={deleteAttendee}
                        handleQualSelect={handleQualSelect} 
                        currentQualId={currentQualId}
                        isLoadingQuals={isLoadingQuals}
                        quals={quals}
                        isLoadingStudentQuals={isLoadingStudentQuals} 
                        filteredStudentQuals={filteredStudentQuals}
                        studentQuals={studentQuals}
                        onStudentSelect={onStudentSelect}
                        addAllStudentsFromQual={addAllStudentsFromQual}
                    />
                </>
            }
            {

                showCreateGroupSection &&
                <>
                    <div className="row">
                        <h4 className="col s12 m10">Create a Group</h4>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m10">
                            <input id="groupName" type="text" onChange={onGroupNameChange} />
                            <label htmlFor="groupName">Group Name</label>
                        </div>
                        <div className="col s12 m2 event-create-group__save-name">
                            <button 
                                className="btn-floating mbh-green" 
                                onClick={() => handleSaveGroup('POST')}
                            >
                                <i className="material-icons notextselect">save</i>
                            </button>
                        </div>
                    </div>
                    <EventsGroupDetails
                        studentsInGroup={currentGroup.Students}
                        deleteAttendee={deleteAttendee}
                        handleQualSelect={handleQualSelect} 
                        currentQualId={currentQualId}
                        isLoadingQuals={isLoadingQuals}
                        quals={quals}
                        isLoadingStudentQuals={isLoadingStudentQuals} 
                        filteredStudentQuals={filteredStudentQuals}
                        studentQuals={studentQuals}
                        onStudentSelect={onStudentSelect}
                        addAllStudentsFromQual={addAllStudentsFromQual}
                    />
                </>
            }
        </div>

        <div id="deleteGroupModal" className="modal">
            <div class="modal-content">
                <h5>Are you sure you want to delete this group?</h5>
            </div>
            <div class="modal-footer">
                <button className="modal-close btn mbh-green" onClick={() => handleDeleteGroup(currentGroup.Id)}>Yes</button>
                <button href="#!" className="modal-close btn-flat">No</button>
            </div>
        </div>
        </div>
    )
}

export default EventsGroups
