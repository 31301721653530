import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../functions/globalVariables';
import { useCurrentQualFetch } from '../../hooks/useCurrentQualFetch';
import { useThemeContext } from '../../ThemeContext';
import TrainingPlanTable from '../TrainingPlanTable/TrainingPlanTable'

const TrainingPlanTableContainer = ({ studentQual, deliveryMode, hoursUnitsObject, trainers, handleUpdateTrainer, handleUpdateComments, currentQualId }) => {

    const { studentID, selectedStudentId, userRole, authToken } = useThemeContext()

    const currentStudentID = userRole === "Administrator" ? selectedStudentId : studentID

    const { courseId } = useParams();

    const getCurrentQualificationUrl = `${baseUrl}/api/studentqualifications?$expand=*&$filter=StudentId eq ${currentStudentID} and QualId eq ${courseId}`;
    const [isLoading, data, error] = useCurrentQualFetch(getCurrentQualificationUrl, authToken, courseId);

    return (
        <section>
            {
                isLoading && !data ? <h2>Loading...</h2>
                :
                <TrainingPlanTable studentQual={studentQual} currentQual={data} deliveryMode={deliveryMode} hoursUnitsObject={hoursUnitsObject} trainers={trainers} handleUpdateTrainer={handleUpdateTrainer} handleUpdateComments={handleUpdateComments} currentQualId={currentQualId} />
            }
            </section>
    )
}

export default TrainingPlanTableContainer
