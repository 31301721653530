import React, {useState, useEffect} from 'react'
import './AdminLocationsOffice.scss'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetchRefresh } from '../../hooks/useDataFetchRefresh';
import { useDataFetch } from '../../hooks/useDataFetch';
import {regionsObject, getIDfromRegion} from '../../functions/getRegions';

import M from 'materialize-css';

const AdminLocationsOffice = ({authToken, setCurrOffice}) => {

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        var elemsModal = document.querySelectorAll('.modal');
        var instancesModal = M.Modal.init(elemsModal, {});
        M.updateTextFields();
    })

    const [refreshData, setRefreshData] = useState(false);
    const [officeObj, setOfficeObj] = useState({});

    const stateUrl = `${baseUrl}/api/states`;
    const [ isLoadingState, state, stateError ] = useDataFetch(stateUrl)

    const officeLocationsUrl = `${baseUrl}/api/offices?&$orderBy=Name&$Filter=IsDisabled eq false`;
    const [isLoading, officeLocations, error] = useDataFetchRefresh(officeLocationsUrl, authToken, refreshData, setRefreshData);

    const deliveryLocationsUrl = `${baseUrl}/api/deliverylocations`;
    const [isLoadingDel, deliveryLocations, errorDel] = useDataFetchRefresh(deliveryLocationsUrl, authToken, refreshData, setRefreshData);

    const onChooseOffice = (e) => {
       
        if(document.getElementById("UpdateOfficeSection")){
            document.getElementById("UpdateOfficeSection").classList.remove("hide");
        }
        if(document.getElementById("updateOfficeLocation")){
            document.getElementById("updateOfficeLocation").reset();
        }

        if (e.target.value > 0){
            let obj = officeLocations.filter(o => o.OfficeId == e.target.value)
            setOfficeObj(obj)
        }else{
            if(document.getElementById("updateOfficeLocation")){
                document.getElementById("updateOfficeLocation").reset();
            }
        }

        setCurrOffice(e.target.value)
    }

    const addNewOfficeForm = (e) => {
        if(document.getElementById("SelectOfficeSection")){
            document.getElementById("SelectOfficeSection").classList.add("hide");
        }
        if(document.getElementById("UpdateOfficeSection")){
            document.getElementById("UpdateOfficeSection").classList.add("hide");
        }
        if(document.getElementById("AddOfficeSection")){
            document.getElementById("AddOfficeSection").classList.remove("hide");
        }
    }

    const cancelAddNewOfficeForm = (e) => {
        if(document.getElementById("SelectOfficeSection")){
            document.getElementById("SelectOfficeSection").classList.remove("hide");
        }
        if(document.getElementById("UpdateOfficeSection")){
            document.getElementById("UpdateOfficeSection").classList.remove("hide");
        }
        if(document.getElementById("AddOfficeSection")){
            document.getElementById("AddOfficeSection").classList.add("hide");
        }

        if(document.getElementById("addOfficeLocation")){
            document.getElementById("addOfficeLocation").reset();
        }
    }

    const updateOfficeLocation = (e, obj) =>{
        const { name, value } = e.target;
        obj[0][name] = value

        fetch(`${baseUrl}/api/offices/${obj[0].OfficeId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(obj[0])
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const addOfficeObj = {
        "CompanyId": null,
        "Name": "",
        "Address": "",
        "Address2": "",
        "Suburb": "",
        "StateId": "",
        "Postcode": 0,
        "ContactName": "",
        "Phone": "",
        "Fax": "",
        "Email": "",
        "IsDisabled":false,
        "RegionId": 0
    }
    const updateAddOfficeObj = (e) => {
        const { name, value } = e.target;
        addOfficeObj[name] = value;
    }

    const addOfficeLocation = (e) =>{

        let errorString = "Please Enter values for: ";
        let hasError = false;
        if (document.getElementById("OfficeName").value == ""){
           errorString += "Name ";
           hasError = true;
        }
        if (document.getElementById("OfficeAddress").value == ""){
            errorString += "Address ";
            hasError = true;
        }
        if (document.getElementById("OfficeSuburb").value == ""){
            errorString += "Suburb ";
            hasError = true;
        }
        if (document.getElementById("OfficeStateId").value == ""){
            errorString += "State ";
            hasError = true;
        }
        if (document.getElementById("OfficePostcode").value == ""){
            errorString += "Postcode ";
            hasError = true;
        }
        if (document.getElementById("OfficeRegionId").value == ""){
            errorString += "Region ";
            hasError = true;
        }    

        if(hasError){
            M.toast({html: errorString})
        }
        else{
            fetch(`${baseUrl}/api/offices`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(addOfficeObj)
            })
            .then(response => response.json())
            .catch(err => {console.log(err); })

            if(document.getElementById("SelectOfficeSection")){
                document.getElementById("SelectOfficeSection").classList.remove("hide");
            }
            if(document.getElementById("AddOfficeSection")){
                document.getElementById("AddOfficeSection").classList.add("hide");
            }
            if(document.getElementById("addOfficeLocation")){
                document.getElementById("addOfficeLocation").reset();
            }
            
            setRefreshData(true)
             window.location.reload();
        }
    }

    const deleteOfficeLocation = (obj) =>{
       obj[0]["IsDisabled"] = true;

       fetch(`${baseUrl}/api/offices/${obj[0].OfficeId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(obj[0])
        })
        .then(response => response.json())
        .catch(err => console.log(err))

        if(document.getElementById("UpdateOfficeSection")){
            document.getElementById("UpdateOfficeSection").classList.add("hide");
        }

        // document.getElementById("chooseOfficeDropDown").value = "choose";
        // document.getElementById("chooseDelOfficeDropDown").value = "choose";
        setRefreshData(true);
 
        let filtered = deliveryLocations.filter(location => location.OfficeId == obj[0].OfficeId)

        if (filtered.length>0){
            filtered.map(f => {
              
                f["IsDisabled"] = true;
               
                fetch(`${baseUrl}/api/deliverylocations/${f.Id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify(f)
                })
                .then(response => response.json())
                .catch(err => console.log(err))
            })
        }

        window.location.reload();
    }

    return (
        <div className="admin-office-locations">
            <h5 className="mbh-green-text">Program Locations</h5>
            <div id="SelectOfficeSection" className="admin-office-locations__select-add row">
                <div className="col s10">
                    <select id="chooseOfficeDropDown" onChange={onChooseOffice}>
                        <option value="choose" selected>Select an Office</option>
                        {
                            officeLocations.map((location, index) => (
                                <option key={index} value={location.OfficeId}>{location.Name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col s2">
                    <a className="btn-floating mbh-green right" onClick={addNewOfficeForm}>
                        <i className="material-icons">add</i>
                    </a>
                </div>
           </div>
          
            {
                officeObj[0] != undefined && 
                <div id="UpdateOfficeSection" className="admin-office-locations__update-office">
                    <form id="updateOfficeLocation">
                        <div className="row">
                            <div className="input-field col s4">
                                <input name="Name" type="text" defaultValue={officeObj[0].Name} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Name">Name</label>
                            </div>
                            <div className="input-field col s4">
                                <input className="validate" name="Address" type="text" defaultValue={officeObj[0].Address} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Address">Address 1</label>
                            </div>    
                            <div className="input-field col s4">
                                <input name="Address2" type="text" defaultValue={officeObj[0].Address2} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Address2">Address 2</label>
                            </div> 
                            </div>
                            <div className="row">
                            <div className="input-field col s3">
                                <input name="Suburb" type="text" defaultValue={officeObj[0].Suburb} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Suburb">Suburb</label>
                            </div> 
                            <div className="input-field col s3">
                                <select onChange={(e) => updateOfficeLocation(e, officeObj)} autoComplete="new-password" name="StateId" value={officeObj[0].StateId}>
                                {                          
                                    state.map((state, i) => {
                                        return <option key={i} defaultValue={state.Id} value={state.Id}>{state.StateCode?state.StateCode:state.Description}</option>
                                    })
                                }
                                </select>
                                <label>State</label>
                            </div> 
                            <div className="input-field col s3">
                                <input name="Postcode" type="text" defaultValue={officeObj[0].Postcode? officeObj[0].Postcode:""} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Postcode">Postcode</label>
                            </div> 
                            <div className="input-field col s3">
                                <select onChange={(e) => updateOfficeLocation(e, officeObj)} autoComplete="new-password" name="RegionId" value={officeObj[0].RegionId?officeObj[0].RegionId:""}>
                                {/* <option></option> */}
                                {                          
                                    Object.keys(regionsObject).map((region, i) => {
                                        return <option key={i} defaultValue={getIDfromRegion(region)} value={getIDfromRegion(region)}>{region}</option>
                                    })
                                }
                                </select>
                                <label>Region</label>
                            </div>   
                        </div>
                        <div className="row">
                            <div className="input-field col s3">
                                <input name="ContactName" type="text" defaultValue={officeObj[0].ContactName} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="ContactName">Contact Name</label>
                            </div> 
                            <div className="input-field col s3">
                                <input name="Phone" type="text" defaultValue={officeObj[0].Phone} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Phone">Phone</label>
                            </div>     
                        
                            <div className="input-field col s3">
                                <input name="Fax" type="text" defaultValue={officeObj[0].Fax?officeObj[0].Fax:""} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Fax">Fax</label>
                            </div>  
                            <div className="input-field col s3">
                                <input name="Email" type="text" defaultValue={officeObj[0].Email} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                <label htmlFor="Email">Email</label>
                            </div>                 
                        </div> 
                     </form>
                     <div className="col s2">
                        <a className="btn-floating mbh-red modal-trigger right" href="#modal1">
                            <i className="material-icons">delete</i>
                        </a>
                    </div>
                </div>
            }

                <div id="AddOfficeSection" className="admin-office-locations__update-office hide">
                    <form id="addOfficeLocation">
                        <div className="row">
                            <div className="input-field col s4">
                                <input id="OfficeName" name="Name" type="text"  autoComplete="new-password" onChange={updateAddOfficeObj} />
                                <label htmlFor="Name">Name</label>
                            </div>
                            <div className="input-field col s4">
                                <input id="OfficeAddress" name="Address" type="text" autoComplete="new-password" onChange={updateAddOfficeObj} />
                                <label htmlFor="Address">Address 1</label>
                            </div>    
                            <div className="input-field col s4">
                                <input name="Address2" type="text"  autoComplete="new-password" onChange={updateAddOfficeObj}  />
                                <label htmlFor="Address2">Address 2</label>
                            </div> 
                            </div>
                            <div className="row">
                            <div className="input-field col s3">
                                <input id="OfficeSuburb" name="Suburb" type="text"  autoComplete="new-password" onChange={updateAddOfficeObj}  />
                                <label htmlFor="Suburb">Suburb</label>
                            </div> 
                            <div className="input-field col s3">
                                <select id="OfficeStateId" name="StateId" onChange={updateAddOfficeObj}>
                                
                                {                          
                                    state.map((state, i) => {
                                        return <option key={i} defaultValue={state.Id} value={state.Id}  >{state.StateCode?state.StateCode:state.Description}</option>
                                    })
                                }
                                </select>
                                <label>State</label>
                            </div> 
                            <div className="input-field col s3">
                                <input id="OfficePostcode" name="Postcode" type="text"  autoComplete="new-password"  onChange={updateAddOfficeObj} />
                                <label htmlFor="Postcode">Postcode</label>
                            </div> 
                            <div className="input-field col s3">
                                <select id="OfficeRegionId" name="RegionId" onChange={updateAddOfficeObj}>
                                <option></option>
                                {                          
                                    Object.keys(regionsObject).map((region, i) => {
                                        return <option key={i} defaultValue={getIDfromRegion(region)} value={getIDfromRegion(region)}  >{region}</option>
                                    })
                                }
                                </select>
                                <label>Region</label>
                            </div>   
                        </div>
                        <div className="row">
                            <div className="input-field col s3">
                                <input name="ContactName" type="text" autoComplete="new-password"  onChange={updateAddOfficeObj} />
                                <label htmlFor="ContactName">Contact Name</label>
                            </div> 
                            <div className="input-field col s3">
                                <input name="Phone" type="text"  autoComplete="new-password"  onChange={updateAddOfficeObj} />
                                <label htmlFor="Phone">Phone</label>
                            </div>     
                        
                            <div className="input-field col s3">
                                <input name="Fax" type="text"  autoComplete="new-password"  onChange={updateAddOfficeObj} />
                                <label htmlFor="Fax">Fax</label>
                            </div>  
                            <div className="input-field col s3">
                                <input name="Email" type="text"  autoComplete="new-password"  onChange={updateAddOfficeObj} />
                                <label htmlFor="Email">Email</label>
                            </div>                 
                        </div> 
                     </form>
                     <div className="col s2">
                        <a className="btn-floating mbh-green right" style={{marginLeft:"5px"}} onClick={cancelAddNewOfficeForm}>
                            <i className="material-icons">close</i>
                        </a>
                        <a className="btn-floating mbh-green right" onClick={(e) => addOfficeLocation(e)}>
                            <i className="material-icons">save</i>
                        </a>
                    </div>
                </div>

                {/* <!-- Modal Structure --> */}
                <div id="modal1" className="modal">
                    <div className="modal-content">
                        <p>Are you sure you want to delete this Office?</p>
                    </div>
                    <div className="modal-footer">
                        <a  className="modal-close btn-flat">No</a>
                        <a  className="modal-close mbh-green white-text btn-flat" onClick={(e) => deleteOfficeLocation(officeObj)}>Yes</a>
                    </div>
                </div>
        </div>
    )
}

export default AdminLocationsOffice
