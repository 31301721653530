import React, { useEffect } from 'react'
import EventAttendeeList from '../EventAttendeeList/EventAttendeeList'
import Spinner from '../../assets/spinner.svg'
import EventsAssignQual from '../EventsAssignQual/EventsAssignQual'
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName'
import M from 'materialize-css';
import './EventsGroupDetails.scss'


const EventsGroupDetails = ({
    studentsInGroup, 
    deleteAttendee, 
    handleQualSelect, 
    currentQualId, 
    isLoadingQuals,
    quals, 
    isLoadingStudentQuals, 
    filteredStudentQuals,
    studentQuals,
    onStudentSelect,
    addAllStudentsFromQual
}) => {

    useEffect(() => {
        var elems = document.querySelectorAll('.tooltipped');
        var instances = M.Tooltip.init(elems, {});
    })

    useEffect(() => {
        if (studentsInGroup) {
            console.log(studentsInGroup)
        }
    }, [studentsInGroup])

    console.log(filteredStudentQuals)

    return (
        <div className="events-group-details">
            {
                studentsInGroup && studentsInGroup.length ?
                <>
                    <br />
                    <EventAttendeeList allAttendees={studentsInGroup} deleteAttendee={deleteAttendee} />
                    <br /><br />
                </>
                :
                null
            }
            {
                isLoadingQuals ?
                    <img src={Spinner} alt="Spinner" />
                :
                <>
                    {
                        quals.length ?
                        <div className="row events-group-details__add-students">
                            <div className="col s12 m10">
                                <h5>Add Students</h5>
                                <select id="QualSelect" onChange={handleQualSelect} value={currentQualId ? currentQualId : "0"}>
                                    <option value="0" disabled>Select a Qualification</option>
                                    {
                                        quals.map(qual => (
                                            <option key={qual.Id} value={qual.Id}>{qual.AntaId} {capitaliseEachWordOfQualName(qual)}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col s12 m2 event-create-group__save-name">
                                <button 
                                    className="btn-floating mbh-green tooltipped"
                                    data-position="top"
                                    data-tooltip="Add all"
                                    onClick={() => addAllStudentsFromQual(filteredStudentQuals)}
                                >
                                    <i className="material-icons notextselect">playlist_add</i>
                                </button>
                            </div>
                        </div>
                        :
                        <p>There are currently no qualifications</p>
                    }
                </>
            }

            {
                currentQualId &&
                (
                    isLoadingStudentQuals || !filteredStudentQuals ?
                    <img src={Spinner} alt="Spinner" />
                    :
                    (
                    filteredStudentQuals && !filteredStudentQuals.length ?
                    <p className="center">No students found</p>
                    :
                    <div className="event-create-group__student-list">
                        {
                            filteredStudentQuals
                            .sort((a, b) => {
                                const firstSurname = a.Student ? a.Student.Surname.trim() : a.Surname.trim()
                                const secondSurname = b.Student ? b.Student.Surname.trim() : b.Surname.trim()

                                return firstSurname > secondSurname
                            })
                            .map(qual => {
                                const email = !qual.Student.Email || !qual.Student.Email.trim().length  ? null : qual.Student.Email
                                const altEmail = !qual.Student.AltEmail || !qual.Student.AltEmail.trim().length  ? null : qual.Student.AltEmail

                                let emailToUse = ""

                                if (!email && !altEmail) {
                                    emailToUse = null
                                } else if (email) {
                                    emailToUse = email.trim()
                                } else {
                                    emailToUse = altEmail.trim()
                                }

                                return (
                                    <EventsAssignQual
                                        qual={qual} 
                                        emailToUse={emailToUse} 
                                        studentQuals={studentQuals} 
                                        onStudentSelect={onStudentSelect} 
                                    />
                                )
                            })
                        }
                    </div>
                    )
                )
            }
        </div>
    )
}

export default EventsGroupDetails
