import React from 'react'
import University from '../../assets/illustrations/university.svg'
import Workplace from '../../assets/illustrations/workplace.svg'
import StageTwoCertIIIBizAdmin from '../StageTwoCertIIIBizAdmin/StageTwoCertIIIBizAdmin'

const PathToUniOrWorkplace = ({ history }) => {
    return (
        <section className="career-pathways__stage-one--section">
            <StageTwoCertIIIBizAdmin history={history} />
            <section className="career-pathways__stage-two--pathway-section">
                <h4 id="path-two-stage-two"><span className="mbh-green-text bold">Stage Two</span><br/><br/>Love Back Office Work?</h4>
                <article className="career-pathways__article reverse-flex">
                    <div className="career-pathways__article--text">
                        <p>Attain the opportunity to study <br /> at university</p>
                        <button className="btn" onClick={() => history.push('/contact-us')}>Contact Us</button>
                    </div>
                    <figure>
                        <img className="responsive-img" src={University} alt="YouTube Tutorial" />
                    </figure>
                </article>
                <h4 id="path-two-stage-three"><span className="mbh-green-text bold">Stage Three</span><br/><br/>Have a Passion for People and Teaching?</h4>
                <br/>
               
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={Workplace} alt="YouTube Tutorial" />
                    </figure>
                    <div className="career-pathways__article--text">
                        <p>Certificate IV in Workplace Training</p>
                        <a href="https://smartandskilled.nsw.gov.au"><button className="btn">Smart and Skilled</button></a>
                    </div>
                    
                </article>
            </section>
        </section>
    )
}

export default PathToUniOrWorkplace
