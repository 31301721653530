import React from 'react';
import './Qualifications.scss';
import CareerPathways from '../../assets/career-pathways.gif'
import { Route, Switch } from 'react-router-dom';
import Testimonials from '../../components/Testimonials/Testimonials';

const Qualifications = ({ history }) => {

    return (
        <div>
            <Switch>
                <Route exact path="/qualifications">
                    <section className="qualifications container">
                        <h2>Qualifications</h2>
                        <figure className="qualifications__figure">
                            <img src={CareerPathways} alt="Career pathways" className="responsive-img" usemap="#workmap" />
                            {/* First bubble */}
                            <div className="qualifications__figure--markers" style={{ top: "42%", left: "12%"}} onClick={() => history.push('/qualifications/certificate-II-in-workplace-skills')}></div>

                            {/* Pathway to uni or workplace training */}
                            <div className="qualifications__figure--markers" style={{ top: "22%", left: "37%"}} onClick={() => history.push('/qualifications/certificate-III-in-business')}></div>
                            {/* <div className="qualifications__figure--markers" style={{ top: "10%", left: "57.5%"}} onClick={() => history.push('/career-pathways')}></div>
                            <div className="qualifications__figure--markers" style={{ top: "36%", left: "58%"}} onClick={() => history.push('/career-pathways')}></div> */}

                            {/* Pathway to project management glory */}
                            <div className="qualifications__figure--markers" style={{ top: "64%", left: "38%"}} onClick={() => history.push('/qualifications/certificate-III-in-business')}></div>
                            <div className="qualifications__figure--markers" style={{ top: "64%", left: "52.5%"}} onClick={() => history.push('/qualifications/certificate-IV-in-project-management')}></div>
                            <div className="qualifications__figure--markers" style={{ top: "64%", left: "67%"}} onClick={() => history.push('/qualifications/diploma-of-project-management')}></div>
                        </figure>
                    </section>
                </Route>
            </Switch>
            <Testimonials />
        </div>
       
    )
}

export default Qualifications
