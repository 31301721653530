import React from 'react'
import StageFourDiplomaPM from '../StageFourDiplomaPM/StageFourDiplomaPM'
import StageThreeCertIVPM from '../StageThreeCertIVPM/StageThreeCertIVPM'
import StageTwoCertIIIBiz from '../StageTwoCertIIIBiz/StageTwoCertIIIBiz'

const PathOneStages = ({ history }) => {
    return (
        <section id="stage-two" className="career-pathways__stages">
            <article className="career-pathways__stage-two--headings">
                <h4>Get employed and go to the <br className="career-pathways__stage-two--headings__br" /> next stage of your journey</h4>                
            </article>
            <StageTwoCertIIIBiz history={history} />
            <StageThreeCertIVPM history={history} />
            <StageFourDiplomaPM history={history} />
        </section>
    )
}

export default PathOneStages
