import React from 'react'
import { findStudentsWithNatFileErrors } from '../../functions/findStudentsWithNatFileErrors';
import './NatFileErrors.scss';

const NatFileErrors = ({ natFileErrors, onStudentSelect }) => {

    // Removing duplicate students so that we have a list of all students with errors
    const erroredStudents = findStudentsWithNatFileErrors(natFileErrors)

    return (
        <article className="natFileErrors">
            <p className="mbh-red-text">{`${natFileErrors.length} errors`}</p>
            <p className="mbh-red-text">{`Select a student for more details:`}</p>
            <ul className="natFileErrors__students">
                {
                    erroredStudents.map(student => (
                        <li 
                            className="black-text"
                            data-id={student.StudentId}
                            onClick={(e) => onStudentSelect(e, "administerStudent")}
                        >
                            {student.Name}
                        </li>
                    ))
                }
            </ul>
        </article>
    )
}

export default NatFileErrors
