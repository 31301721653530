import React from 'react'
import './CaseStudies.scss'
import PersonalGoals from '../../assets/illustrations/personal-goals.svg'
import ProjectTeam from '../../assets/illustrations/project-team.svg'
import Testimonials from '../../components/Testimonials/Testimonials';
import CaseStudy1 from '../../assets/brochures/case-study-design-landscapes.pdf'
import CaseStudy2 from '../../assets/brochures/case-study-a&b-mylec.pdf'


const CaseStudies = () => {
    return (
        <section className="case-studies container">
            <h2>Case Studies</h2>
            <article className="case-studies__blurb case-studies__blurb--1">
                <figure>
                    <img src={ProjectTeam} alt="ProjectTeam Image" className="responsive-img" />
                </figure>
                <div>
                    <p><strong>mbh</strong> have been training staff at Design Landscapes since 2010 in project management and have worked hand in hand with Design Landscapes HR manager to forge a trusted relationship. </p>
                    <br/>
                    <a href={CaseStudy1} target="_blank" rel="noopener noreferrer" className="btn">Download Brochure</a>
                </div>
            </article>
            <article className="case-studies__blurb case-studies__blurb--2">
                <div>
                    <p><strong>mbh</strong> trainers not only delivered training qualifications, they opened the mind of the participants to encourage them to develop their project management toolkits. Traditional and new theories were introduced, encouraging staff to collaborate, get it out of their heads share your knowledge, make it simple, focus on the project at hand– remove the communication haze. </p>
                    <br/>
                    <a href={CaseStudy2} target="_blank" rel="noopener noreferrer" className="btn">Download Brochure</a>
                </div>
                <figure>
                    <img src={PersonalGoals} alt="Personal Goals Image" className="responsive-img" />
                </figure>
            </article>
            <Testimonials />
            <div className="case-studies__video-section">
                <h3 className="case-studies__video-heading">Interviews with an mbh trainer: Jack Peck</h3>
                <div className="case-studies__videos">
                    <div className="case-studies__video case-studies__video--1">
                        <iframe title="What do you love about mbh training?" width="282" height="158" src="https://www.youtube.com/embed/JP-FOuXStQw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="case-studies__video case-studies__video--2">
                        <iframe title="What inspires you to train at mbh?" width="282" height="158" src="https://www.youtube.com/embed/tjTzmrVIK8Q" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="case-studies__video case-studies__video--3">
                        <iframe title="What does project management bring to the world?" width="282" height="158" src="https://www.youtube.com/embed/DaMWj4Wl1Bs" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div> 
                </div>
            </div>
        </section>
    )
}

export default CaseStudies
