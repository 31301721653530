export const handleQualFilterChange = (qualId, allStudentQuals, active) => {


    const filteredStudents = allStudentQuals.filter(qual => {
        if (active) {
            return qual.QualId === +qualId && qual.Active === true && qual.Disabled === false
        } else {
            return qual.QualId === +qualId && qual.Disabled === false
        }
    });

    if (!filteredStudents.length) return null;

    const studentObject = {}
    for (let i = 0; i < filteredStudents.length; i++) {
        const id = filteredStudents[i].StudentId;
        studentObject[id] = true;
    }

    return studentObject;
}