import React, {useState, useEffect} from 'react'
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import './EventsEdit.scss'
import M from 'materialize-css';
import DatePickerComp from '../DatePickerComp/DatePickerComp';
import TextEditor from '../TextEditor/TextEditor';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../assets/spinner.svg'
import EventAttendeeList from '../EventAttendeeList/EventAttendeeList';
import { arrayToObjectWithKey } from '../../functions/arrayToObject';
import EventAddList from '../EventAddList/EventAddList';
import { useThemeContext } from '../../ThemeContext';

const EventsEdit = () => {

    let history = useHistory()
    const { authToken } = useThemeContext()

    //if in edit mode get the event details
    const { eventId } = useParams();
    const eventUrl = `${baseUrl}/api/events/${eventId}`;
    const [isLoadingEvent, eventObject, eventErr] = useDataFetch(eventUrl, authToken);

    const studentUrl = `${baseUrl}/api/students`;
    const [isLoadingStudents, students, studentsErr] = useDataFetch(studentUrl, authToken)

    const trainerUrl = `${baseUrl}/api/trainers`;
    const [isLoadingTrainers, trainers, trainersErr] = useDataFetch(trainerUrl, authToken)

    const [trainersToSelect, setTrainersToSelect] = useState([])

    useEffect(() => {
        if (trainers.length && eventObject !== undefined) {
            setTrainersToSelect(trainers.filter(trainer => {
                if (!trainer.IsDisabled) {
                    return true
                } else if (trainer.IsDisabled && trainer.Id === eventObject.TrainerID) {
                    // Asterisk means trainer is disabled in database
                    trainer.Surname += " *"
                    return true
                }

                return false
            }))
        }
    }, [trainers, eventObject])

    const typeUrl = `${baseUrl}/api/eventtypes`;
    const [isLoadingTypes, types, typesErr] = useDataFetch(typeUrl, authToken)

    const locationUrl = `${baseUrl}/api/deliverylocations?&$Filter=IsDisabled ne true&$orderBy=DeliveryLocation`;
    const [isLoadingLocation, locations, locationsErr] = useDataFetch(locationUrl, authToken)

    const eventRegistrationsUrl = `${baseUrl}/api/eventregistrations?$filter=EventID eq ${eventId}`;
    const [isLoadingRegistrations, registrations, registrationsErr] = useDataFetch(eventRegistrationsUrl, authToken)
    
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        M.updateTextFields();
    })

    //this is to get rid of the unnoying underline in the attendees box 
    useEffect(() => {
        const input = document.getElementById("grouped-demo");
        if (input && !input.classList.contains("browser-default")) {
          input.classList.add("browser-default");
        }
      })

    const handleNameCategorisation = (option) => {
        const firstName = option.Surname.trim()
          if (firstName.length) {
              let firstLetter = firstName.charAt(0).toUpperCase()
              if (firstLetter.toLowerCase() != firstLetter.toUpperCase()) {
                  return {
                      firstLetter: firstLetter,
                      ...option,
                    };
              }
      
              return {
                firstLetter: "Other",
                ...option,
              };
          }
      }
    
    let options = students
                .sort((a, b) => a.Surname.trim() > b.Surname.trim())
                .map(option => handleNameCategorisation(option))
                .filter(option => option !== undefined && !option.IsDisabled) 

    
    const [attendeeList, setAttendeeList] = useState(null)
    const [eventHasNoAttendees, setEventHasNoAttendees] = useState(false)
    
    useEffect(() => {
        if (registrations.length && students.length) {
            const attendeeObj = arrayToObjectWithKey(registrations, "StudentID")
            const attendees = students.filter(student => attendeeObj[student.Id] !== undefined)
            setEventHasNoAttendees(false)
            setAttendeeList(attendees)
        } else if (!registrations.length && students.length) {
            setEventHasNoAttendees(true)
        }
    }, [registrations, students])

    useEffect(() => {
        if (attendeeList !== null && attendeeList.length) {
            setEventHasNoAttendees(false)
        } else {
            setEventHasNoAttendees(true)
        }

        setShowAddListForm(false)
    }, [attendeeList])
    
    const addAttendee = (studentToAdd) => {
        let listObj = {}

        if (attendeeList) {
            listObj = arrayToObjectWithKey(attendeeList, "Id")
        }
        
        // Find if this student already exists
        if (listObj[studentToAdd.Id] !== undefined) {
            M.toast({html: "Student is already attending", displayLength: 2000});
            return;
        }

        if (!attendeeList) {
            setAttendeeList([studentToAdd])
        } else {
            setAttendeeList(prev => ([...prev, studentToAdd]))
        }
        setEventHasNoAttendees(false)
    }

    const deleteAttendee = (id) => {
        const studentToDelete = registrations.filter(rego => rego.StudentID === id)
        const filteredList = attendeeList.filter(attendee => attendee.Id !== id)

        if (studentToDelete.length) {
            const registrationId = studentToDelete[0].Id
            fetch(`${baseUrl}/api/eventregistrations/${registrationId}`, {
                method: 'DELETE',
                headers: { 
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${authToken}` 
                }
            })
            .then(response => {
                setAttendeeList(filteredList)
                if (!filteredList.length) {
                    setEventHasNoAttendees(true)
                }
                return response.json() 
            })
            .catch(err => console.log(err))
        } else {
            setAttendeeList(filteredList)
            if (!filteredList.length) {
                setEventHasNoAttendees(true)
            }
        }
    }

    const onCloseEditEvent = () => {
        history.push('/admin-portal/events/calendar')
    }

    const updateEventObject=(e) => {
        const {name, value} = e.target;
        eventObject[name] = value;
    }

    const onViewEventDetails = () => {
        history.push(`/admin-portal/events/event-details/${eventId}`)
    }

    const handleDateChange = (e, date) => {
        const dateString = moment(date).format("YYYY-MM-DD");     
        const currentDate = eventObject[e]
        let newDate = dateString + currentDate.substring(10,25)
        if (moment(newDate).isValid()){
            eventObject[e] = newDate;
        }
        else{
            M.toast({html: "Please select a valid Date", displayLength: 1000});
        }
    }

    const appendCurrentTimezone = () => {
        // Checks if it is currently daylight saving time (DST)
        if (moment().isDST()) {
            return ":00+11:00"
        } else {
            return ":00+10:00"
        }
    }

    const handleTimeChange = (e) => {
        const {name, value} = e.target;

        let currentDate; 
        let newDate;

        if (name === "startTime"){
            currentDate = eventObject["StartDateTime"];
            newDate = currentDate.substring(0,11)+ value + appendCurrentTimezone();
            eventObject["StartDateTime"] = newDate;
        }
        if (name === "endTime"){
            currentDate = eventObject["EndDateTime"];
            newDate = currentDate.substring(0,11)+ value + appendCurrentTimezone();
            eventObject["EndDateTime"] = newDate;
        }
    }
    
    const handleDescriptionChange = (name, content) =>{
        eventObject[name] = content;
    }

    const checkAPIResponse = (data, status, sender) => {

        if (status >= 200 && status < 300){
            if (sender === 'cancelevent'){
                history.push('/admin-portal/events/calendar')
            }else{
                history.push('/admin-portal/events/calendar')
            }
        }else{
            if(data.Message){
                M.toast({html: data.Message, displayLength: 2000});
            }
        }
    }

    const onCancelEvent = () => {

        eventObject["IsCancelled"] = true;
        let status;

        fetch(`${baseUrl}/api/events/${eventId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(eventObject)
        })
        .then(response => {
            status = response.status;
            checkAPIResponse("", status, "cancelevent")
            return response.json()
        })
        .then(data => checkAPIResponse(data, status, "cancelevent"))
        .catch(err => console.log("Error: ", err)) 
    }

    const validateEventDetails = () => {
        //validation
        if (eventObject["Subject"].trim() === ""){
            M.toast({html: "Please enter a subject for the event", displayLength: 1000});
            return false
        }
        if (!moment(eventObject["StartDateTime"]).isValid()){
            M.toast({html: "Please enter a valid Date and Time", displayLength: 1000});
            return false
        }
        if(moment(eventObject["StartDateTime"]) > moment(eventObject["EndDateTime"])){
            M.toast({html: "Event cannot end before it begins :-p", displayLength: 1000});
            return false
        }

        return true
    }

    const onSaveEvent = () => {
        let status;

        if (validateEventDetails()){
            saveAttendeeList()

            fetch(`${baseUrl}/api/events/${eventId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                body: JSON.stringify(eventObject)
            })
            .then(response => {
                status = response.status;
                checkAPIResponse("", status, "updateevent")
                return response.json()
            })
            .then(data => checkAPIResponse(data, status, "updateevent"))
            .catch(err => console.log("Error: ", err)) 
        }
    }

    const saveAttendeeList = async () => {
        const registrationsObj = arrayToObjectWithKey(registrations, "StudentID")

        const formattedList = attendeeList.filter(attendee => registrationsObj[attendee.Id] === undefined).map(attendee => {
            return ({
                EventID: +eventId,
                StudentID: attendee.Id
            })
        })

        try {
            fetch(`${baseUrl}/api/eventregistrations/addlist`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${authToken}` },
                body: JSON.stringify({List: formattedList})
            })
        } catch(err) {
            console.log(err)
            M.toast("An error occurred")
        }
    }

    const [showAddListForm, setShowAddListForm] = useState(false)

    return (
        isLoadingEvent ?
        <img src={Spinner} alt="Spinner" />
        :
        <div className="events-edit">
            <article className="events-edit__header">
                <h3 className="mbh-green-text">Edit Event</h3>
                <div className="events-edit__btns">
                    <button className="btn-floating mbh-green" style={{marginRight:"5px"}} onClick={onSaveEvent}><i className="material-icons notextselect">save</i></button>
                    <button className="btn-floating mbh-green" style={{marginRight:"5px"}} onClick={onViewEventDetails}><i className="material-icons notextselect">assignment</i></button> 
                    <button className="btn-floating mbh-green" onClick={onCloseEditEvent}><i className="material-icons notextselect">close</i></button> 
                </div>
            </article>
            {
                !attendeeList && !eventHasNoAttendees ?
                <img src={Spinner} alt="Spinner" />
                :
                (
                    eventHasNoAttendees ?
                    <h5>There are no attendees</h5>
                    :
                    <EventAttendeeList allAttendees={attendeeList} deleteAttendee={deleteAttendee} />

                )
            }
            <div className="events-edit__form">
                <div className="row events-edit__add-attendees">
                    <div className="input-field col s12 m10">
                        <Autocomplete
                            id="grouped-demo"
                            style={{width: "100%"}}
                            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => `${option.FirstName.trim()} ${option.Surname.trim()}`}
                            renderInput={(params) => <TextField {...params} variant="outlined" label="Add Attendees" />}
                            onChange={(event, newValue) => {
                                // Only triggers the add function when a student is selected from the dropdown
                                // and not when we remove a student from this dropdown selector
                                if (event.target.tagName === "LI") {
                                    addAttendee(newValue)
                                }
                            }}
                        />
                    </div>
                    <div className="col s12 m2">
                        {
                            showAddListForm ?
                            <button className="btn-floating btn-large right" onClick={() => setShowAddListForm(false)}><i className="material-icons">close</i></button>
                            :
                            <button className="btn-floating btn-large right" onClick={() => setShowAddListForm(true)}><i className="material-icons">format_list_bulleted</i></button>
                        }
                    </div>
                </div>
                {
                    showAddListForm &&
                    <>
                        <EventAddList
                            registrations={registrations} 
                            eventId={eventId}
                            attendeeList={attendeeList}
                            setAttendeeList={setAttendeeList}
                            authToken={authToken} 
                        />
                        <br />
                        <br />
                    </>
                }
                <h4>Event Details</h4>
                <br />
                <div className="row">
                    <div className="input-field col s12">
                        <input id="subject" type="text" name="Subject" defaultValue={eventObject.Subject} onChange={updateEventObject} />
                        <label htmlFor="subject">Subject</label>
                    </div>
                </div>

                <div className="row">
                    <div className="input-field col s12 l6">
                        <select defaultValue="0" name="EventTypeID" onChange={updateEventObject} value={eventObject.EventTypeID ? eventObject.EventTypeID : "0"}>
                        <option value="0" disabled>Select Event Type</option>
                       {
                           types.map((type, index) => {
                                return <option key={index} value={type.Id}>{type.Type}</option>
                           })
                       }
                        </select>
                        <label>Type</label>
                    </div>
                    <div className="input-field col s12 l6">
                        <select defaultValue="0" name="DeliveryLocationID" onChange={updateEventObject} value={eventObject.DeliveryLocationID ? eventObject.DeliveryLocationID : "0"}>
                            <option value="0" disabled>Select Location</option>
                            {
                                locations.map((location, index) => {
                                    return <option key={index} value={location.Id}>{location.DeliveryLocation}</option>
                                })
                            }
                        </select>
                        <label>Location</label>
                    </div>
                </div>

                <div className="row">
                    <div className="input-field col s12 l6">
                        <select name="TrainerID" onChange={updateEventObject} value={eventObject.TrainerID ? eventObject.TrainerID : "0"}>
                        <option value="0" disabled>Select Trainer</option>
                        {
                            trainersToSelect.map((trainer, index) =>{
                                return <option key={index} value={trainer.Id}>{trainer.FirstName} {trainer.Surname }</option>
                            })
                        }
                        </select>
                        <label>Trainer</label>
                    </div>
                    <div className="input-field col s12 l6">
                    <select name="SupportID" onChange={updateEventObject} value={eventObject.SupportID ? eventObject.SupportID : "0"}>
                        <option value="0" disabled>Select Support</option>
                        {
                            trainersToSelect.map((trainer, index) =>{
                                return <option key={index} value={trainer.Id}>{trainer.FirstName} {trainer.Surname }</option>
                            })
                        }
                        </select>
                        <label>Support</label>
                    </div>
                </div>

                <div className="row">
                    <div className="col s6 l2">
                        <label htmlFor="startDate">From</label>
                        <DatePickerComp tabindex ="7" name="StartDateTime" defaultDate={Date.parse(moment(eventObject.StartDateTime).format("YYYY-MM-DD"))}  handleDateUpdate={handleDateChange} />
                    </div>
                    <div className="input-field col s6 l2" style={{marginTop:"22px"}}>
                        <TextField
                                id="startTime"
                                name="startTime"
                                type="time"
                                defaultValue={moment(eventObject.StartDateTime).format('HH:mm')}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                inputProps={{
                                step: 300, // 5 min
                                }}
                                onChange={handleTimeChange}
                            />
                    </div>
                    
                    <div className="col s12 l2 center hide-on-med-and-down"></div>           
                    
                    <div className="col s6 l2">
                        <label htmlFor="endDate">To</label>
                        <DatePickerComp tabindex ="9" name="EndDateTime" defaultDate={Date.parse(moment(eventObject.EndDateTime).format("YYYY-MM-DD"))}  handleDateUpdate={handleDateChange} />
                    </div>
                    <div className="input-field col s6 l2" style={{marginTop:"22px"}}>
                        <TextField
                            id="endTime"
                            name="endTime"
                            type="time"
                            defaultValue={moment(eventObject.EndDateTime).format('HH:mm')}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            step: 300, // 5 min
                            }}
                            onChange={handleTimeChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <label htmlFor="details">Details for the Event</label>
                        <TextEditor id="Description" name="Description" initValue={eventObject.Description} handleDescriptionChange={handleDescriptionChange} descriptionField="Description" addOrEditQual="add" />
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <button className="btn mbh-red white-text right" onClick={onCancelEvent}>Cancel Event</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventsEdit
