import React from 'react'
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import { useHistory, useParams } from 'react-router-dom';
import './EventDetails.scss'
import Spinner from '../../assets/spinner.svg';
import moment from 'moment'
import EventsAttendanceConfirmation from '../EventsAttendanceConfirmation/EventsAttendanceConfirmation';
import { useThemeContext } from '../../ThemeContext';

const EventDetails = ({currentPage, setCurrentPage}) => {

    let history = useHistory()
    const { authToken, userRole } = useThemeContext()

    let routingPortal = "admin-portal";
    if (userRole === "Student")
        routingPortal = "student-portal";

    const { eventId } = useParams();

    const eventUrl = `${baseUrl}/api/events/${eventId}`;
    const [isLoadingEvent, event, eventErr] = useDataFetch(eventUrl, authToken)
   
    const locationUrl = `${baseUrl}/api/deliverylocations/${event.DeliveryLocationID}`;
    const [isLoadinglocation, location, locationErr] = useDataFetch(locationUrl, authToken)
   
    const eventtypeUrl = `${baseUrl}/api/eventtypes/${event.EventTypeID}`;
    const [isLoadingEventType, eventtype, eventTypeErr] = useDataFetch(eventtypeUrl, authToken)
    
    const trainerUrl = `${baseUrl}/api/trainers/${event.TrainerID}`;
    const [isLoadingTrainer, trainer, trainerTypeErr] = useDataFetch(trainerUrl, authToken)

    const supportUrl = `${baseUrl}/api/trainers/${event.SupportID}`;
    const [isLoadingSupport, support, supportTypeErr] = useDataFetch(supportUrl, authToken)

    let eventDay = "";
    let eventTime = "";

    if (!isLoadingEvent){
        const startDateTime = event.StartDateTime;
        const endDateTime = event.EndDateTime;

        eventDay = moment(startDateTime).format('dddd, Do MMMM YYYY'); 
        eventTime = moment(startDateTime).format('LT') + " - " + moment(endDateTime).format('LT');  
    }
  
    return (
        isLoadingEvent || isLoadinglocation || isLoadingEventType || isLoadingTrainer || isLoadingSupport?<img src={Spinner} alt="Spinner"/>:
        <div className="event-details">

           <div className="event-details__header">
                <h3 className="mbh-green-text">Event Details</h3>
                <div className="event-details__btns">
                    <a className="btn-floating mbh-green tooltipped" onClick={() => {setCurrentPage("event-details"); history.push(`/${routingPortal}/events/${currentPage==="calendar"?"calendar":""}`)}}>
                        <i className="material-icons notextselect">arrow_back</i>
                    </a>
                    <a className="btn-floating mbh-green tooltipped" onClick={() => {setCurrentPage("event-details"); history.push(`/${routingPortal}/events/edit/${eventId}`)}}>
                        <i className="material-icons notextselect">edit</i>
                    </a>
                </div>
            </div>

            <div className="event-details__body">
                <div className="row">
                    <div className=" col s12">
                        <h5 className="mbh-green-text">{event.Subject}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6 l4 event-details__body--icons">
                        <i className="material-icons notextselect mbh-green-text">date_range</i>
                        <span>{eventDay}</span>
                    </div>
                    <div className="col s12 m6 l4 event-details__body--icons">
                        <i className="material-icons notextselect mbh-green-text">access_time</i>
                        <span>{eventTime}</span>
                    </div>
                    <div className="col s12 m6 l4 event-details__body--icons">
                        <i className="material-icons notextselect mbh-green-text">location_on</i>
                        <span>{location.DeliveryLocation}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6 l4">
                        <p>Trainer: {trainer.FirstName} {trainer.Surname}</p>
                    </div>
                    {
                        event.SupportID !== null?
                        <div className="col s12 m6 l4">
                            <p>Support: {support.FirstName} {support.Surname}</p>
                        </div>
                        :null
                    }
                </div>
                <div className="row">
                    <div className="col s12">
                        <p>Type: {eventtype.Type}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div dangerouslySetInnerHTML={{__html: event.Description}} />
                    </div>
                </div>
            </div>
            {
                userRole === "Administrator"?
                <EventsAttendanceConfirmation eventId={eventId} authToken={authToken} />
                :null
            }
           
        </div>
    )
}

export default EventDetails
