	export const regionsObject = {
	"":0,
    "Capital Region":1,
    "Central Coast":2,
	"Central West":3,
	"Coffs Harbour - Grafton":4,
	"Far West Orana":5,
	"Hunter Valley excluding Newcastle":6,
	"Illawarra":7,
	"Mid North Coast":8,
	"Murray":9,
	"New England and North West":10,
	"Newcastle and Lake Macquarie":11,
	"Richmond - Tweed":12,
	"Riverina":13,
	"Southern Highlands and Shoalhaven":14,
	"Sydney":15
	};

    export const getIDfromRegion = (region) => {
        return regionsObject[region];
    }

    export const getRegionfromID = (regionID) => {
        return Object.keys(regionsObject).find(key => regionsObject[key] === regionID);
    }