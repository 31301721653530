import React from 'react'
import { useHistory } from 'react-router-dom'
import './AdminSettings.scss'

const AdminSettings = () => {

    let history = useHistory()
   
    return (
        <div className="administer-student">
            <h3 className="mbh-green-text">Admin</h3>
            <div className="admin-settings__card" onClick={() => history.push('/admin-portal/admin/locations')}><i className="material-icons student-portal__menu-icon medium">location_city</i>Locations &amp; Program Streams</div>
            <div className="admin-settings__card" onClick={() => history.push('/admin-portal/admin/results')}><i className="material-icons student-portal__menu-icon medium">assessment</i>Edit Result List</div>
            <div className="admin-settings__card" onClick={() => history.push('/admin-portal/admin/manage-waiver-codes')}><i className="material-icons student-portal__menu-icon medium">assignment</i>Manage Waiver Codes</div>
            <div className="admin-settings__card" onClick={(e) => {history.push('/admin-portal/admin/users')}}><i className="material-icons student-portal__menu-icon medium">person</i>Add/Remove User</div>
            <div className="admin-settings__card" onClick={(e) => {history.push('/admin-portal/admin/duplicates')}}><i className="material-icons student-portal__menu-icon medium">people</i>Duplicate Students</div>
            <div className="admin-settings__card" onClick={(e) => {history.push('/admin-portal/admin/change-password')}}><i className="material-icons student-portal__menu-icon medium">lock</i>Change Password</div>
            
        </div>
    )
}

export default AdminSettings
