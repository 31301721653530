import React, { useState, useEffect } from 'react';
import './DownloadPage.scss';
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import * as XLSX from "xlsx";
import M from 'materialize-css';
import NatFileErrors from '../NatFileErrors/NatFileErrors';
import { filterNatFileErrors } from '../../functions/filterNatFileErrors';
import { useThemeContext } from '../../ThemeContext';
import { useHistory } from 'react-router-dom';

const DownloadPage = ({ onStudentSelect }) => {

    let history = useHistory()
    const { authToken } = useThemeContext()

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        M.updateTextFields();
    })

    const [natFileErrors, setNatFileErrors] = useState(null)
    const [stsFileErrors, setSTSNatFileErrors] = useState(null)
    const [fundingType, setFundingType] = useState("Government Funded"); // Added state for funding type

    let startPeriod = getDownloadPeriod();
    const [period, setPeriod] = useState(startPeriod);

    const qualificationsUrl = `${baseUrl}/api/qualifications?$filter=Superseded eq false`;
    const [isLoadingQualifications, allQualifications, qualsError] = useDataFetch(qualificationsUrl, authToken);

    const validateSTSUrl = `${baseUrl}/api/stsbulkquotedata/validatedata`;
    const [isLoadingSTS, validateSTS, errorSTS] = useDataFetch(validateSTSUrl, authToken)

    const [chosenQual, setChosenQual] = useState(-1);
    const [chosenQualAntaId, setChosenQualAntaId] = useState("");

    function getDownloadPeriod() {
        const startPeriod = 10;
        const startYear = 2024;
        const d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth();
        let i = 0;
        if (month >= 6)
            i = 1;
        let newPeriod = startPeriod + (year - startYear) + i;
        return newPeriod;
    }

    const handleQualChange = (e, qual) => {
        const selectedIndex = e.target.options.selectedIndex - 1;
        const qualID = qual[selectedIndex].Id;
        const anataID = qual[selectedIndex].AntaId;
        setChosenQual(qualID);
        setChosenQualAntaId(anataID);
        setNatFileErrors(null)
    }

    //*** Used for downloading the files generated from the results of api calls */
    const handleFileSave = (response, filename) => {
        const link = document.createElement("a");
        link.setAttribute("download", filename);
        const downloadUrl = window.URL.createObjectURL(new Blob([response]));
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    //*** Generate NAT Files */
    const generateNATFiles = () => {
        let url;
       
        if (chosenQual === -1) {
            url = `${baseUrl}/api/natforms?fundingType=${fundingType}`; // Modified URL
        } else {
            url = `${baseUrl}/api/natforms/${chosenQual}?fundingType=${fundingType}`; // Modified URL
        }

        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${authToken}`
            },
        })
        .then(response => response.blob())
        .then(data => {handleFileSave(data, `NATForms_${chosenQualAntaId}.zip`);})
        .catch(err => console.log("error", err))
    }

    const onDownloadNatFiles = () => {
        fetch(`${baseUrl}/api/natforms/validatedates`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${authToken}`
            },
        })
        .then(response => response.json())
        .then(data => {
            // if (!data.length) {
            //     generateNATFiles();
            // }
            console.log(data)
            if (chosenQualAntaId.length) {
                const filteredErrors = filterNatFileErrors(data, chosenQualAntaId);
                if (!filteredErrors.length) {
                    setNatFileErrors(null)
                } else {
                    setNatFileErrors(filteredErrors)
                }
            } else {
                setNatFileErrors(data)
            }
        })
        .catch(err => console.log(err))

        generateNATFiles()
    }

    //*** Generate either quote or contract file depending on which button is clicked in the Download Pending Qualifications section*/
    const generateExcelFile = (filename, activityPeriodId) => {
        if (validateSTS.length) {
           setSTSNatFileErrors(validateSTS)
        }

        //if it's an quote is false, otherwise if it's a contract it's true.
        let notification = false;
        
        if (filename === 'contract') {
            notification = true;
        }

        const url = `${baseUrl}/api/stsbulkquotedata?notification=${notification}&activityPeriodId=${activityPeriodId}`;

        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${authToken}`
            },
        })
        .then(response => response.blob())
        .then(data => {handleFileSave(data, `${filename}.xlsx`);})
        .catch(err => console.log("error", err))
    }

    //*** Creates the open dialog to select file and then Upload the STS Documents */
    const handleFileSelect = (e) => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('name', 'file')
        fileSelector.setAttribute('accept', '.xls, .xlsx');
        e.preventDefault();
        fileSelector.click();
        fileSelector.addEventListener("change", submitFile);
        // fileSelector.addEventListener("change", readFile);
    }

    // used to update message returned from POST of submitFile function
    const [uploadMsg, setUploadMsg] = useState("")
    //** Submits the selected file to the API */
    const submitFile = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setUploadMsg("")
        const selectedFile = e.target.files[0];

        const formData = new FormData();
        formData.append('file', selectedFile);
       
        fetch(`${baseUrl}/api/stsbulkquotedata`, {
            method: 'POST',
            headers: {
            'Authorization': `Bearer ${authToken}`
            },
            body: formData
        })
        .then(response => response.json())
        .then(data => {setUploadMsg(data);})
        .catch(err => console.log("errors" ,err))
    }

    //*** Reads the opened file and converts contents to JSON string. THIS IS NOT BEING USED AS WE'RE SUBMITTING FILE AS IS*/
    const readFile = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const selectedFile = e.target.files[0];

        const reader = new FileReader();
       
        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, {type: 'binary'});
                      
            workbook.SheetNames.forEach((sheetName) => {
                const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                // const json_object = JSON.stringify(XL_row_object);
                // const json_parsed = JSON.parse(json_object);
                // console.log("item: ", XL_row_object[0].National_Provider_ID, "number of rows:", XL_row_object.length);
            })
        };

        reader.onerror = function(event) {
            console.error("File could not be read! Code " + event.target.error.code);
        };

        reader.readAsBinaryString(selectedFile);
    }

    //*** Updates the period state when the number is changed */
    const handlePeriodChange = (e) => {
        setPeriod(e.target.value);
    }

    return (
        <div className="download-page container">
            <h3 className="mbh-green-text">NAT Files</h3>
            <div className="download-page__btn-group">
                <div className="download-page__card">
                    <h5>Generate NAT Files</h5>
                    <div className="input-field download-page__select">
                            <select name="qualification" onChange={(e) => handleQualChange(e, allQualifications)}>
                                <option value="Choose" disabled selected>Choose</option>
                                {
                                    allQualifications.map((qualification, index) => (
                                        <option id="qualOption" key={index} value={qualification.id}>{qualification.AntaId} {qualification.Name}</option>
                                    ))
                                }
                            </select>
                    </div>
                    <div className="input-field download-page__select">
                        <select name="fundingType" onChange={(e) => setFundingType(e.target.value)}>
                            <option value="Government Funded">Government Funded</option>
                            <option value="Self Funded">Self Funded</option>
                        </select>
                        <label>Select Funding Type</label>
                    </div>
                    {
                        natFileErrors &&
                        <NatFileErrors natFileErrors={natFileErrors} history={history} onStudentSelect={onStudentSelect} />
                    }
                    <a className="btn" onClick={onDownloadNatFiles}><i className="material-icons left">file_download</i>Download</a>
                </div>
                <div className="download-page__card">
                    <h5>Download Pending Qualifications</h5>
                    {
                        stsFileErrors ? <NatFileErrors natFileErrors={stsFileErrors} history={history} onStudentSelect={onStudentSelect} /> : null
                    }
                    <div className="download-page__btns">
                        <a className="btn" onClick={() => generateExcelFile("quote", period)}><i className="material-icons left">file_download</i>Quote</a>
                        <a className="btn" onClick={() => generateExcelFile("contract", period)}><i className="material-icons left">file_download</i>Contract</a>
                        <div className="input-field download-page__period">
                            <input id="period" type="number" name="Period" autoComplete="new-password" defaultValue={period} onChange={(e) => handlePeriodChange(e)} />
                            <label className="active" id="periodLabel" htmlFor="period">Period</label>
                        </div>
                    </div>
                </div>
                <div className="download-page__card">
                    <h5>Upload STS Document</h5>
                    <div className="download-page__btns">
                        <a className="btn" onClick={handleFileSelect}><i className="material-icons left">file_upload</i>Quote</a>
                        <a className="btn" onClick={handleFileSelect}><i className="material-icons left">file_upload</i>Contract</a>
                    </div>
                    {
                        uploadMsg.RowsUpdated > 0 ?
                        <p className="download-page__upload-msg mbh-green-text">{uploadMsg.RowsUpdated} record(s) have been updated.</p>
                        :
                        <p className="download-page__upload-msg mbh-red-text">{uploadMsg.Message}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default DownloadPage
