import React, {useState, useEffect} from 'react'
import './AddComp.scss';
import { baseUrl } from '../../functions/globalVariables'
import {validateCostField} from '../../functions/validateCostField'
import M from 'materialize-css'
import { useDataFetch } from '../../hooks/useDataFetch';
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';
import { useThemeContext } from '../../ThemeContext';

const AddComp = () => {

    const { authToken } = useThemeContext()

    const competenciesUrl = `${baseUrl}/api/competencies`
    const qualCompUrl = `${baseUrl}/api/qualificationcompetencies`
    
    const qualificationsUrl = `${baseUrl}/api/qualifications`
    const [isLoadingQuals, allQuals, qualsError] = useDataFetch(qualificationsUrl, authToken)

    const [isAddingCompetency, setIsAddingCompetency] = useState(false)
    const [showActiveOnly, setShowActiveOnly] = useState(true); // Added state to manage checkbox status

    const compObject = {
        "CompID": "",
        "compDsc": "",
        "CompeducationId": "",
        "VetFlag": "Y",
        "NomHrs": null,
        "Superseded": false
    }

    const qualCompObject = {
        "IsCore": true,
        "IsStandard": true,
        "QualId": null,
        "CompetencyId": null
    }

    const [quals, setQuals] = useState([])
    
    useEffect(() => {
        if (allQuals.length) {
            setQuals(allQuals)
        }
    }, [allQuals])

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    })

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        document.getElementById("addCompForm").reset();
    }, [submitted])

    const handleChange = (e) => {
        const {name, value} = e.target;
        compObject[name] = value;
    }

    const handleCompQualMapping = (e) => {
        const {name, value} = e.target;
        qualCompObject[name] = value;
    }

    const handleClick = (compObject) => {
        let isFormValid = true;
        
        if(compObject.CompID.trim() === ""){
            M.toast({html: 'Competency must have an ID.', displayLength: '1000'})
            isFormValid = false;
        }
        
        if(isNaN(compObject.NomHrs)){
            M.toast({html: 'Nominal Hours must be a Numerical value.', displayLength: '1000'})
            isFormValid = false;
        }
        
        if(isFormValid){
            setIsAddingCompetency(true)
            createCompetency(competenciesUrl)
        } else {
            setIsAddingCompetency(false)
        }
    }

    const createCompetency = (url) => {

        if (qualCompObject["QualId"] === null) {
            M.toast({html: 'Choose a qualification to map to', displayLength: '1000'});
            setIsAddingCompetency(false)
            return;
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(compObject)
        })
        .then(response => response.json())
        .then(data => {
            setSubmitted(true); 
            mapCompetencyToQual(qualCompUrl, data.Id);
            setIsAddingCompetency(false)
        })
        .catch(err => {
            console.log(err)
            setIsAddingCompetency(false)
        })
    }

    const mapCompetencyToQual = (url, compId) => {
        qualCompObject["CompetencyId"] = compId

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(qualCompObject)
        })
        .then(response => response.json())
        .then(data => {
            M.toast({html: 'Competency Created!', classes: 'mbh-green', displayLength: '1000'}); 
            setSubmitted(false);
        })
        .catch(err => console.log(err))
    }

    const handleCheckboxChange = () => {
        setShowActiveOnly(!showActiveOnly);
    } // Added function to handle checkbox state change

    const filteredQuals = showActiveOnly ? quals.filter(qual => !qual.Superseded) : quals; // Added filtered qualifications based on checkbox status

    return (
        <div className='add-comp'>
            <h2>Add Competency</h2>
            <br/>
            <div className="row">
                <form id="addCompForm" className="col s12">
                    <div className="row">
                        <div className="input-field col s12 m3">
                            <input id="CompID" name="CompID" type="text" className="validate" onChange={(e) => handleChange(e)} />
                            <label htmlFor="CompID">Comp ID</label>
                        </div>
                        <div className="input-field col s12 m9">
                            <input id="compDsc" name="compDsc" type="text" className="validate" onChange={(e) => handleChange(e)} />
                            <label htmlFor="compDsc">Description</label>
                        </div>                  
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <input id="CompeducationId" name="CompeducationId" type="text" className="validate" maxLength="6" onChange={(e) => handleChange(e) } />
                            <label htmlFor="CompeducationId">Education Id</label>
                        </div>
                        <div className="input-field col s6">
                            <select name="VetFlag" onChange={(e) => handleChange(e)}>
                                <option value="Y" selected={true}>Y</option>
                                <option value="N">N</option>
                            </select>
                            <label htmlFor="VetFlag">Vet Flag</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <select name="IsCore" onChange={(e) => handleCompQualMapping(e)} value="Core">
                                <option value="true">Core</option>
                                <option value="false">Elective</option>
                            </select>
                            <label htmlFor="IsCore">Core / Elective</label>
                        </div>
                        <div className="input-field col s6">
                            <select name="IsStandard" onChange={(e) => handleCompQualMapping(e)} value="true">
                                <option value="true" selected={true}>True</option>
                                <option value="false">False</option>
                            </select>
                            <label htmlFor="IsStandard">Is Standard</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <input id="NomHrs" name="NomHrs" type="text" className="validate" onChange={(e) => {handleChange(e); validateCostField(e)}} />
                            <label htmlFor="NomHrs">Nominal Hours</label>
                        </div>
                        <div className="input-field col s6">
                            <select name="Superseded" onChange={(e) => handleChange(e)}>
                                <option value="false" selected>False</option>
                                <option value="true" >True</option>
                            </select>
                            <label htmlFor="Superseded">Superseded</label>
                        </div>
                    </div>
                    <div className="row">
                        <label> 
                            <input type="checkbox" checked={showActiveOnly} onChange={handleCheckboxChange} />  
                            <span>Show active qualifications only</span>
                        </label> 
                    </div> 
                </form>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <select name="QualId" onChange={(e) => handleCompQualMapping(e)} value="none">
                        <option value="none" disabled>Choose qualification to map to</option>
                        {
                            filteredQuals.map((qual, index) => (
                                <option key={`${qual.AntaId}_${index}`} value={qual.Id}>{qual.AntaId} {capitaliseEachWordOfQualName(qual)}</option>
                            )) // Modified mapping to use filtered qualifications based on checkbox status
                        } 
                    </select>
                    <label htmlFor="Superseded">Map to Qualification</label>
                </div>
            </div>

            <button className="btn mbh-green white-text right" disabled={isAddingCompetency} onClick={() => handleClick(compObject)}>Add Competency</button>

        </div>
    )
}

export default AddComp
