import React from 'react'
import './ViewOtherCourses.scss'

const ViewOtherCourses = ({ history }) => {
    return (
        <article className="viewOtherCourses">
            <div className="viewOtherCourses__div">
                <h4>This qualification wasn't for you?</h4>
                <p>Check out our other qualifications</p>
            </div>
            <div className="viewOtherCourses__btns">
                <button className="btn" onClick={() => history.push('/qualifications')}>View Qualifications</button>
                <button className="btn mbh-black lighten-5 mbh-black-text" onClick={() => history.push('/career-pathways')}>Career Pathways</button>
            </div>
        </article>
    )
}

export default ViewOtherCourses
