import React from 'react'

const ShowSortArrow = ({ columnFieldName, sortConfig }) => {
    return (
        <>
            {   
                columnFieldName === sortConfig.fieldToSort && sortConfig.direction !== null &&
                (
                    sortConfig.direction === "ascending"  ?
                    <i class="small material-icons">arrow_drop_down</i>
                    :
                    <i class="small material-icons">arrow_drop_up</i>
                )

            }
        </>
    )
}

export default ShowSortArrow
