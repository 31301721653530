import React, { useContext } from 'react'

const ThemeContext = React.createContext()

export const useThemeContext = () => {
    return useContext(ThemeContext)
}

export const ThemeProvider = ({ children }) => {
    const studentID = parseInt(window.sessionStorage.getItem('mbhStudentId')) // When viewing info as a student
    const selectedStudentId = window.sessionStorage.getItem('currentStudent') // When viewing student info as an admin
    const authToken = window.sessionStorage.getItem('mbhToken')
    const userRole = window.sessionStorage.getItem('mbhUserRole')
    const userEmail = window.sessionStorage.getItem('mbhUserName')

    return (
        <ThemeContext.Provider value={{
            studentID: studentID, 
            selectedStudentId: selectedStudentId, 
            authToken: authToken, 
            userRole: userRole, 
            userEmail: userEmail}}
        >
            {children}
        </ThemeContext.Provider>
    )
}