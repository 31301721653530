import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../functions/globalVariables'
import { useDataFetchRefresh } from '../../hooks/useDataFetchRefresh';
import { useDoubleDataFetch } from '../../hooks/useDoubleDataFetch'
import {validateCostField} from '../../functions/validateCostField'
import M from 'materialize-css'
import './EditQualifications.scss'
import { addAssignedCompetency, deleteAssignedCompetency } from '../../functions/assigningCompetencies'
import { filterCompetencyArrays } from '../../functions/filterCompetencyArrays'
import TextEditor from '../TextEditor/TextEditor';
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';
import Spinner from '../../assets/spinner.svg'
import { useThemeContext } from '../../ThemeContext';

const EditQualifications = () => {

    const { authToken } = useThemeContext()

    const [fetchData, setFetchData] = useState(true);
    const [deleteQual, setDeleteQual] = useState(true);
  
    const qualificationsUrl = `${baseUrl}/api/qualifications`;
    const [isLoadingQualifications, allQualifications, qualsError] = useDataFetchRefresh(qualificationsUrl, authToken, deleteQual, setDeleteQual);

    const [chosenQual, setChosenQual] = useState(-1);
    const [filteredCompetencyArrays, setFilteredCompetencyArrays] = useState([])

    const competenciesUrl = `${baseUrl}/api/qualificationcompetencies?$expand=Competency`;
    const allCompetenciesUrl = `${baseUrl}/api/competencies`;
    const [isLoadingComp, assignedCompetencies, allCompetencies, compError] = useDoubleDataFetch(competenciesUrl, allCompetenciesUrl, fetchData, setFetchData, authToken);
    
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        M.updateTextFields();
    })

    useEffect(() => {
        if (chosenQual >= 0) {
            const twoFilteredArrays = filterCompetencyArrays(assignedCompetencies, allCompetencies, allQualifications[chosenQual].Id)
            setFilteredCompetencyArrays(twoFilteredArrays)
        }
        setFetchData(true)
    }, [chosenQual])

    useEffect(() => {
        console.log("All qualifications fetched")
    }, [allQualifications])
    

    const handleQualChange = (e) => {
        if(document.getElementById("addQualForm")){
            document.getElementById("addQualForm").reset();
        }
        if(document.getElementById("editCompsForm")){
            document.getElementById("editCompsForm").reset();
        }
        if( document.getElementById("deleteQualMsg")){
            document.getElementById("deleteQualMsg").textContent = "";
        }
           
        setChosenQual(e.target.options.selectedIndex-1);
        setFetchData(true);
    }

    const updateQualsArray = (e, rowIndex) => {
        const { name, value, checked } = e.target;

        if (name === 'Superseded') {
            if(!fetchData){
                allQualifications[rowIndex].Superseded = value === 'true' ? true : false;
            }
        } else if(name === 'DeliveryMode'){
            if(!fetchData){
                allQualifications[rowIndex].DeliveryMode = parseInt(value);
            }
        } else if (name === "IsPartQual") {
            if (checked) {
                allQualifications[rowIndex][name] = true;
            } else {
                allQualifications[rowIndex][name] = false;
            }
        }else {
            allQualifications[rowIndex][name] = value;
        }

        const id = allQualifications[rowIndex].Id
        
        fetch(`${qualificationsUrl}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(allQualifications[rowIndex])
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    //This is to update the API with the description field that uses the tinymce plugin
    const updateDescriptionField = (rowIndex, content, descriptionField) => {
       
        if(content=== undefined || content==="") return;

        allQualifications[rowIndex][descriptionField] = content;
        const id = allQualifications[rowIndex].Id
      
        fetch(`${qualificationsUrl}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(allQualifications[rowIndex])
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const handleCheckboxChange = (e, compObject, qualId) => {

        const { checked } = e.target;

        if (checked) {
            addAssignedCompetency(`${baseUrl}/api/qualificationcompetencies`, compObject, qualId, authToken)
        } else {
            deleteAssignedCompetency(`${baseUrl}/api/qualificationcompetencies/${compObject.Id}`, authToken)
        }
    }

    const handleDeleteQualResponse = (id, data, status) => {
        const messageEl = document.getElementById("deleteQualMsg");
        let errorMsg ="";
      
        if (status === 204){          
            setChosenQual(-1);
            setDeleteQual(true);
            document.getElementById("chooseQualDropDown").value=-1;
        }else{
            if (data.error){
                errorMsg = data.error.message;

                fetch(`${baseUrl}/api/studentqualifications?$filter=QualId eq ${id} &$expand=Student`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${authToken}` }
                })
                .then(response => {
                   
                    return response.json();
                })
                .then(data => {getStudentNames(data, messageEl, errorMsg);})
                .catch(err => console.log("error", err))
            }


        }

        messageEl.textContent = errorMsg;
    }

    const handleDeleteQualification = (e, qual) => {
        e.preventDefault();      
        let status;
        let id = qual.Id;
        
        fetch(`${baseUrl}/api/qualifications/${id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
        .then(response => {
            status = response.status;
            handleDeleteQualResponse(id, "status", status);
            return response.json();
        })
        .then(data => handleDeleteQualResponse(id, data, status))
        .catch(err => console.log("error", err))
    }

    const getStudentNames = (data, el, errorMsg) =>
    {
        let studentNames = "";
        for (let i=0; i<data.value.length; i++){
            studentNames += ` ${data.value[i].Student.FirstName} ${data.value[i].Student.Surname}, `;
        }
        el.innerHTML = errorMsg + "<br />"+  "<strong>" + studentNames + "<strong/>";    
    }
    const handleDeleteCompetencyResponse = (id, data, status, type) => {
        const messageEl = document.getElementById(`deleteCompMsg${id}`);
        let errorMsg ="";
        
        if (status === 204){
            let tempArray;
            if (type === "available"){
                tempArray = filteredCompetencyArrays[1].filter(comp => comp.Id !== id );
                filteredCompetencyArrays[1] = tempArray;
            }else{
                tempArray = filteredCompetencyArrays[0].filter(comp => comp.Competency.Id !== id );
                filteredCompetencyArrays[0] = tempArray;
            }
            
            setFetchData(true);
        }else{
            if (data.error){
                errorMsg = data.error.message;

                fetch(`${baseUrl}/api/studentcompetencies?$filter=CompetencyId eq ${id} &$expand=Student`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${authToken}` }
                })
                .then(response => {
                   
                    return response.json();
                })
                .then(data => {getStudentNames(data, messageEl, errorMsg);})
                .catch(err => console.log("error", err))
            }
        }

        messageEl.textContent = errorMsg;
    }

    const handleDeleteCompetency = (e, comp, type) => {
        e.preventDefault();
        let status;
        let id;
        if(type === "available"){
            id = comp.Id;
        }else{
            id = comp.Competency.Id;
        }
        
        fetch(`${baseUrl}/api/competencies/${id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
        .then(response => {
            status = response.status;
            handleDeleteCompetencyResponse(id, "status", status, type);
            return response.json();
        })
        .then(data => handleDeleteCompetencyResponse(id, data, status, type))
        .catch(err => console.log("error", err))
    }

    return (
        <div className="edit-quals">
            <h2>Edit Qualifications</h2>
            {
                isLoadingQualifications ?
                <img src={Spinner} alt="Spinner" />
                :
                <div className="input-field col s6">
                    <h6 className="mbh-green-text">Choose Qualification to Edit</h6>
                    <select id="chooseQualDropDown" onChange={handleQualChange}>
                        <option value="Choose">Choose</option>
                        {
                            allQualifications.map((qualification, index) => (
                                <option key={index} value={qualification.id}>{`${qualification.AntaId} ${capitaliseEachWordOfQualName(qualification)}`}</option>
                            ))
                        }
                    </select>
                </div>
            }
            <br/><br/>
           <section>       
            {
               (allQualifications.length && chosenQual !== -1) &&
               <div>
                   <h6 className="mbh-green-text">Edit Selected Qualification</h6>
                    <form id="addQualForm">
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="Name" name="Name" type="text" defaultValue={allQualifications[chosenQual].Name} className="validate" autoComplete="new-password" onInput={(e) => updateQualsArray(e, chosenQual)} />
                                <label htmlFor="Name">Name</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="AntaId" name="AntaId" type="text" className="validate" defaultValue={allQualifications[chosenQual].AntaId} autoComplete="new-password" onInput={(e) => updateQualsArray(e, chosenQual)} />
                                <label htmlFor="AntaId">AntaId</label>
                            </div>                  
                            <div className="input-field col s6">
                                <input id="Cost" name="Cost" type="text" className="validate" defaultValue={allQualifications[chosenQual].Cost} autoComplete="new-password" onInput={(e) => {updateQualsArray(e, chosenQual); validateCostField(e)}} />
                                <label htmlFor="Cost">Cost</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="Package" name="Package" type="text" className="validate" defaultValue={allQualifications[chosenQual].Package} autoComplete="new-password" onInput={(e) => updateQualsArray(e, chosenQual)} />
                                <label htmlFor="Package">Package</label>
                            </div>
                            <div className="input-field col s6">
                                <input id="Level" name="Level" type="text" className="validate" defaultValue={allQualifications[chosenQual].Level} autoComplete="new-password" onInput={(e) => {updateQualsArray(e, chosenQual); validateCostField(e)}} />
                                <label htmlFor="Level">Level</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="Electives" name="Electives" type="text" className="validate" defaultValue={allQualifications[chosenQual].Electives} onInput={(e) => {updateQualsArray(e, chosenQual); validateCostField(e)}} />
                                <label htmlFor="Electives">Electives</label>
                            </div>
                            <div className="input-field col s6">
                                <select name="Superseded" onChange={(e) => updateQualsArray(e, chosenQual)}>
                                    <option value="false" selected={!allQualifications[chosenQual].Superseded ? true : false}>False</option>
                                    <option value="true" selected={allQualifications[chosenQual].Superseded ? true : false}>True</option>
                                </select>
                                <label htmlFor="Superseded">Superseded</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s6">
                                <select id="DeliveryMode" name="DeliveryMode"  onChange={(e) => updateQualsArray(e, chosenQual)}>
                                    <option value="10" selected={allQualifications[chosenQual].DeliveryMode===10?true:false}>Classroom</option>
                                    <option value="20" selected={allQualifications[chosenQual].DeliveryMode===20?true:false}>Online</option>
                                    <option value="30" selected={allQualifications[chosenQual].DeliveryMode===30?true:false}>Work-based</option>
                                    <option value="40" selected={allQualifications[chosenQual].DeliveryMode===40?true:false}>Mixed Mode</option>
                                </select>
                                <label htmlFor="DeliveryMode">Delivery Mode</label>
                            </div>
                            <div className="input-field col s6">
                                <input id="ProgramRecognitionId" name="ProgramRecognitionId" type="text" maxLength="2" defaultValue={allQualifications[chosenQual].ProgramRecognitionId} onInput={(e) => updateQualsArray(e, chosenQual)} />
                                <label htmlFor="ProgramRecognitionId">Program Recognition Id</label>
                            </div> 
                        </div>
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="LevelOfEducationId" name="LevelOfEducationId" type="text" maxLength="3" autocomplete="off" autoComplete="new-password" defaultValue={allQualifications[chosenQual].LevelOfEducationId} onInput={(e) => updateQualsArray(e, chosenQual)} />
                                <label htmlFor="LevelOfEducationId">Level of Education Id</label>
                            </div>
                            <div className="input-field col s6">
                                <input id="FieldOfEducationId" name="FieldOfEducationId" type="text" maxLength="4" defaultValue={allQualifications[chosenQual].FieldOfEducationId} onInput={(e) => updateQualsArray(e, chosenQual)} />
                                <label htmlFor="FieldOfEducationId">Field Of Education Id</label>
                            </div>                  
                        </div>
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="ANZSCO" name="ANZSCO" type="text" maxLength="6" autocomplete="off" autoComplete="new-password" defaultValue={allQualifications[chosenQual].ANZSCO} onInput={(e) => updateQualsArray(e, chosenQual)} />
                                <label htmlFor="ANZSCO">ANZSCO</label>
                            </div>
                            <div className="input-field col s6">
                                <select id="VETFlag" name="VETFlag" onChange={(e) => updateQualsArray(e, chosenQual)}>
                                    <option value="N" selected={allQualifications[chosenQual].VETFlag === "N" ? true : false}>No</option>
                                    <option value="Y" selected={allQualifications[chosenQual].VETFlag === "Y" ? true : false}>Yes</option>
                                </select>
                                <label htmlFor="VETFlag">VET Flag</label>
                            </div>
                        </div>
                        <div className="row">
                            <p className="col s6">
                                <label>
                                    <input type="checkbox" id="IsPartQual" name="IsPartQual" onChange={(e) => updateQualsArray(e, chosenQual)} defaultChecked={allQualifications[chosenQual].IsPartQual} />
                                    <span>Is A Part Qual</span>
                                </label>
                            </p>
                        </div>
                        <div className="edit-quals__delete-qual col s6">
                            <button className="btn mbh-red right" onClick={(e) => handleDeleteQualification(e, allQualifications[chosenQual])}>Delete Qualification</button>
                            <span id="deleteQualMsg" className="mbh-red-text"></span>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <p style ={{color: "#999"}}>Description</p>
                                <TextEditor id="Description" name="Description" initValue={allQualifications[chosenQual].Description} rowIndex={chosenQual} updateDescriptionField={updateDescriptionField} descriptionField="Description" addOrEditQual="edit" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <p style ={{color: "#999"}}>Qualification Description</p>
                                <TextEditor id="CoursesDescription" name="CoursesDescription" initValue={allQualifications[chosenQual].CoursesDescription} rowIndex={chosenQual} updateDescriptionField={updateDescriptionField} descriptionField="CoursesDescription" addOrEditQual="edit" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <p style ={{color: "#999"}}>Training Delivery Description</p>
                                <TextEditor id="TrainingDeliveryDescription" name="TrainingDeliveryDescription" initValue={allQualifications[chosenQual].TrainingDeliveryDescription} rowIndex={chosenQual} updateDescriptionField={updateDescriptionField} descriptionField="TrainingDeliveryDescription" addOrEditQual="edit" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <p style ={{color: "#999"}}>Assessment Support Description</p>
                                <TextEditor id="AssessmentSupportDescription" name="AssessmentSupportDescription" initValue={allQualifications[chosenQual].AssessmentSupportDescription} rowIndex={chosenQual} updateDescriptionField={updateDescriptionField} descriptionField="AssessmentSupportDescription" addOrEditQual="edit" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <p style ={{color: "#999"}}>Assessment Timeframe Description</p>
                                <TextEditor id="AssessmentTimeframeDescription" name="AssessmentTimeframeDescription" initValue={allQualifications[chosenQual].AssessmentTimeframeDescription} rowIndex={chosenQual} updateDescriptionField={updateDescriptionField} descriptionField="AssessmentTimeframeDescription" addOrEditQual="edit" />
                            </div>
                        </div>
                </form>
            </div>
            }
           </section>
           {
            (allQualifications.length && chosenQual !== -1) &&
            <section>
                <h6 className="mbh-green-text edit-quals__comp-heading">Qualification's Competencies</h6>
                <form id="editCompsForm">
               {    
                    filteredCompetencyArrays.length &&
                   filteredCompetencyArrays[0].map((comp, index) => (
                    <div key={index}>
                      
                        {
                            comp.Competency.Superseded === false &&
                            <div className = "edit-quals__card">
                                <label className = "edit-quals__available-competencies">
                                    <div>
                                        <input type="checkbox" className="filled-in" defaultChecked={(comp.QualId === allQualifications[chosenQual].Id)?true:false} onInput={(e) => handleCheckboxChange(e, comp, allQualifications[chosenQual].Id)}/>
                                        <span>{comp.Competency.CompID} {comp.Competency.compDsc}</span>
                                    </div>
                                    <button className="btn-floating btn-small mbh-red right"><i className="material-icons" onClick={(e) => handleDeleteCompetency(e, comp, "attached")}>delete</i></button>
                                </label>
                                <span id={`deleteCompMsg${comp.Competency.Id}`} className="mbh-red-text"></span>
                            </div>
                        }
                       
                    </div>
                   ))
                }
                <h6 className="mbh-green-text edit-quals__comp-heading">Available Competencies</h6>
                {
                    filteredCompetencyArrays.length &&
                    filteredCompetencyArrays[1].map((comp, index) => (
                        <div key={index}>
                          
                            {
                                comp.Superseded === false &&
                                <div className = "edit-quals__card">
                                    <label className = "edit-quals__available-competencies">
                                        <div>
                                            <input type="checkbox" className="filled-in" defaultValue={assignedCompetencies} onInput={(e) => handleCheckboxChange(e, comp, allQualifications[chosenQual].Id)}/>
                                            <span>{comp.CompID} {comp.compDsc}</span>
                                        </div>
                                        <button className="btn-floating btn-small mbh-red right"><i className="material-icons" onClick={(e) => handleDeleteCompetency(e, comp, "available")}>delete</i></button>
                                    </label>
                                    <span id={`deleteCompMsg${comp.Id}`} className="mbh-red-text"></span>
                                </div>
                            }
                           
                        </div>
                       ))
                }
                </form>
            </section>
            }
        </div>
    )
}

export default EditQualifications
