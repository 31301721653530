import React, {useState, useRef, useEffect} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DatePickerComp = ({tabindex, name, defaultDate, handleDateUpdate, object, index}) => {
    const [startDate, setStartDate] = useState(defaultDate);

    useEffect(() => {
        setStartDate(defaultDate)
    }, [defaultDate])
    
    const handleUpdate = (name, date, obj, index) => {
        
        setStartDate(date);
        if(name === 'certDate'){
            handleDateUpdate(date, obj, index);
        } else if (name === 'StartDateTime' || name ==='EndDateTime'){
            handleDateUpdate(name, date);
        }
        else{
            //competency start and end dates
            handleDateUpdate(name, date, obj, index);
        }
    }

    // Close the calendar when user using 'Tab' key to tab off the custom input
    const startRef = useRef();

    const handleOnKeyDown = (e) => {
        if (e.which === 9) startRef.current.setOpen(false, true);
    }


    return (
        <div>
            <DatePicker 
                name={name} 
                autoComplete="new-password" 
                dateFormat="dd/MM/yyyy" 
                showYearDropdown 
                showMonthDropdown 
                selected={startDate} 
                onChange={date => { handleUpdate(name, date, object, index);}}
                tabIndex={tabindex}
                ref={startRef}
                onKeyDown = {(e) => handleOnKeyDown(e)}
            />
        </div>
    )
}

export default DatePickerComp