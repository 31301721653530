import React, { useEffect, useState} from 'react';
import './ContactDetailsForm.scss';
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import { setPostageForm } from '../../functions/setPostageForm';
import M from 'materialize-css';
import { copyResidentialIntoPostal, updatePostalAddress, updateResidentialAddress } from '../../functions/updateStudentAddress';


const ContactDetailsForm = ({ contactObject, handleChange }) => {

    useEffect(()=>{
        var elemSelect = document.querySelectorAll('select');
        var instanceSelect = M.FormSelect.init(elemSelect, {});
        var elemDatePicker = document.querySelectorAll('.datepicker');
        var instancesDatePicker = M.Datepicker.init(elemDatePicker, {format: 'dd/mm/yyyy', yearRange: 80});
        
    }, []);
      
    useEffect(() => {
        const DOMContentLoaded_event = document.createEvent("Event")
        DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true)
        window.document.dispatchEvent(DOMContentLoaded_event)
    }, []);

   
    const stateUrl = `${baseUrl}/api/states`;
    const [ isLoadingState, state, stateError ] = useDataFetch(stateUrl)
    

    return(
        <section className="contact-form" >
             <h5 className="mbh-green-text">Personal Details</h5>
             <div className="row">
                <div className="input-field col s6">
                    <input id="title" type="text" name="Title" onChange={handleChange} />
                    <label htmlFor="title">Title</label>
                </div>
                <div className="input-field col s12 m6">
                    <input id="first_name" type="text" name="FirstName" autoComplete="new-password" onChange={handleChange} />
                    <label htmlFor="first_name">First Name</label>
                </div>
                <div className="input-field col s12 m6">
                    <input id="other_name" type="text" name="Other" autoComplete="new-password" onChange={handleChange} />
                    <label htmlFor="other_name">Other Name</label>
                </div>
                <div className="input-field col s12 m6">
                    <input id="last_name" type="text" name="Surname" autoComplete="new-password" onChange={handleChange} />
                    <label htmlFor="last_name">Last Name</label>
                </div>
                <div className="input-field col s12 m6">
                    <input placeholder="dd/mm/yyyy" id="DOB" type="date" name="DOB" onChange={handleChange} />
                    <label htmlFor="DOB">Date of Birth</label>
                </div>
                <div className="input-field col s12 m6">
                    {/* <input id="Sex" type="text" name="Sex"  onChange={handleChange} />
                    <label htmlFor="Sex">Gender</label> */}
                    <select id="Sex" onChange={handleChange}  autoComplete="new-password" name="Sex">
                            <option  value="" disabled selected>Please Select</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Other">Other</option>
                    </select>
                    <label htmlFor="Sex">Gender</label>
                </div>
                <div className="input-field col s12 m6">
                    <input id="saStudentId" type="text" name="SaStudentId"  onChange={handleChange} />
                    <label htmlFor="saStudentId">Unique Student Identifier (USI) *</label>
                </div>
                <div className="input-field col s12">
                   <p>* To obtain a USI, apply for one <a href="https://www.usi.gov.au/" target="_blank" rel="noopener noreferrer">here</a></p>
                </div>
            </div>
           
            <h5 className="mbh-green-text">Contact Details</h5>
            <div className="row">
                <div className="input-field col s12 m6">
                    <input id="phone" type="text" className="validate" minLength="8" name="Phone"  autoComplete="new-password" onChange={handleChange} />
                    <label htmlFor="phone">Phone</label>
                    <span className="helper-text" data-error="Invalid Phone Number"></span>
                </div>
                <div className="input-field col s12 m6">
                    <input id="mobile" type="text" className="validate" minLength="9" name="Mobile" autoComplete="new-password"  onChange={handleChange} />
                    <label htmlFor="mobile">Mobile</label>
                    <span className="helper-text" data-error="Invalid Mobile Number"></span>
                </div>
                
                <div className="input-field col s12 m6">
                    <input id="email1" type="email" className="validate" name="Email" autoComplete="new-password"  onChange={handleChange} />
                    <label htmlFor="email">Company Email</label>
                    <span className="helper-text" data-error="Invalid Email"></span>
                </div>
                <div className="input-field col s12 m6">
                    <input id="email2" type="email" className="validate" name="AltEmail" autoComplete="new-password"  onChange={handleChange}/>
                    <label htmlFor="email2">Personal Email</label>
                    <span className="helper-text" data-error="Invalid Email"></span>
                </div>
                <div className="input-field col s12 m6">
                    <input id="organisation" type="text" name="Organisation"  autoComplete="new-password" onChange={handleChange} />
                    <label htmlFor="organisation">Organisation</label>
                </div>
                <div className="input-field col s12 m6">
                    <input id="position" type="text" name="Position" autoComplete="new-password"  onChange={handleChange} />
                    <label htmlFor="position">Position</label>
                </div>
            </div>

            <h5 className="mbh-green-text">Residential Address</h5>
            <div className="row">                       
                <div className="input-field col s12">
                    <input id="addressField" type="text" name="AddressField" autoComplete="new-password" onBlur={() => updateResidentialAddress(contactObject)} />
                    <label htmlFor="addressField">Address</label>
                </div>
                <div className="input-field col s12 m8">
                    <input id="addressBuilding" type="text" name="AddressBuilding" autoComplete="new-password" onChange={handleChange} />
                    <label htmlFor="addressBuilding">Building/PropertyName</label>
                </div>
                <div className="input-field col s6 m4">
                    <input id="addressUnitNo" type="text" name="AddressUnitNo" autoComplete="new-password" onChange={handleChange} />
                    <label id="addressUnitNoLabel" htmlFor="addressUnitNo">Flat/Unit No</label>
                </div>
                <div className="input-field col s6 m4">
                    <input id="addressStreetNo" type="text" name="AddressStreetNo" autoComplete="new-password" onChange={handleChange} />
                    <label id="addressStreetNoLabel" htmlFor="addressStreetNo">Street No</label>
                </div>
                <div className="input-field col s12 m8">
                    <input id="AddressStreet" type="text"  name="AddressStreet" autoComplete="new-password" onChange={handleChange} />
                    <label id="addressStreetLabel" htmlFor="AddressStreet">Street Name</label>
                </div>
                <div className="input-field col s12 m4">
                    <input id="suburb" type="text" name="Suburb" autoComplete="new-password" onChange={handleChange} />
                    <label id="suburbLabel" htmlFor="suburb">Suburb</label>
                </div>               
                <div className="input-field col s6 m4">
                    <select id="state-territory" onChange={handleChange} autoComplete="new-password" name="State">
                    <option  value="" disabled selected>State</option>
                    {                          
                        state.map((state, i) => {
                            return <option key={i} defaultValue={state.Id} value={state.Id} >{state.StateCode?state.StateCode:state.Description}</option>
                        })
                    }
                    </select>
                    <label></label>
                </div>
                <div className="input-field col s6 m4">
                    <input id="postcode" type="text" className="validate" minLength="4" maxLength="4" name="Postcode" autoComplete="new-password" onChange={handleChange} />
                    <label id="postcodeLabel" htmlFor="postcode">Postcode</label>
                </div>
            </div>

             <h5 className="mbh-green-text contact-form__postal-heading">Postal Address</h5>
            <label className="contact-form__postal-checkbox">
                <input type="checkbox" className="filled-in contact-form__postal-checkbox" onClick={() => {
                    setPostageForm()
                    copyResidentialIntoPostal(contactObject)
                    }} name="postalAddress" autoComplete="new-password" />
                <span>Postal address same as residential address</span>
            </label>
            <br/><br/>
             <div id="postalSection" className="row">
                <div className="input-field col s12">
                    <input id="postalAddressField" type="text" name="AddressField" autoComplete="new-password" onBlur={() => updatePostalAddress(contactObject)} />
                    <label htmlFor="postalAdddressField">Address</label>
                </div>                                     
                <div className="input-field col s12 m8">
                    <input id="postalAddressBuilding" type="text" name="AddressBuilding_Pos" autoComplete="new-password" onChange={handleChange} />
                    <label htmlFor="postaladdressBuilding">Building/PropertyName</label>
                </div>
                <div className="input-field col s6 m4">
                    <input id="postalAddressUnitNo" type="text" name="AddressUnitNo_Pos" autoComplete="new-password" onChange={handleChange} />
                    <label id="postalAddressUnitNoLabel" htmlFor="AddressStreetNo_Pos">Flat/Unit No</label>
                </div>
                <div className="input-field col s6 m4">
                    <input id="postalAddressStreetNo" type="text" name="AddressStreet_Pos" autoComplete="new-password" onChange={handleChange} />
                    <label id="postalAddressStreetNoLabel" htmlFor="postalAddressStreetNo">Street No</label>
                </div>
                <div className="input-field col s12 m8">
                    <input id="postalAddressStreet" type="text"  name="AddressStreet_Pos" autoComplete="new-password" onChange={handleChange} />
                    <label id="postalAddressStreetLabel" htmlFor="postalAddressStreet">Street Name</label>
                </div>
                <div className="input-field col s12 m4">
                    <input id="postalSuburb" type="text" name="Suburb_Pos" autoComplete="new-password" onChange={handleChange} />
                    <label id="postalSuburbLabel" htmlFor="postalSuburb">Suburb</label>
                </div>
                <div className="input-field col s6 m4">
                    <select id="postalState"onChange={handleChange} name="State_Pos" autoComplete="new-password">
                    <option defaultValue="" disabled selected>State</option>
                    { 
                            state.map((state, i) => {
                            return <option key={i} defaultValue={state.Id} value={state.Id} >{state.StateCode?state.StateCode:state.Description}</option>
                        })
                    }
                    </select>
                    <label></label>
                </div>
                <div className="input-field col s6 m4">
                    <input id="postalPostcode" type="text" className="validate" minLength="4" maxLength="4" name="Postcode_Pos" autoComplete="new-password" onChange={handleChange} />
                    <label id="postalPostcodeLabel" htmlFor="postalPostcode">Postcode</label>
                </div>
            </div>
        </section>
    );

    
}

export default ContactDetailsForm;