import React, { useState } from 'react'
import './ManageFeeWaiverCodes.scss'
import Spinner from '../../assets/spinner.svg'
import { useDataFetch } from '../../hooks/useDataFetch'
import { baseUrl } from '../../functions/globalVariables'
import M from 'materialize-css'
import { useThemeContext } from '../../ThemeContext'
import { useHistory } from 'react-router-dom'

const ManageFeeWaiverCodes = () => {

    let history = useHistory()

    const { authToken } = useThemeContext()

    const [newCode, setNewCode] = useState({
        WaiverCode: "",
        WaiverStrategy: 0,
        WaiverDesc: "",
        IsDisabled: false
    })

    const codesUrl = `${baseUrl}/api/waivercode?$filter=IsDisabled eq false`
    const [isLoading, waiverCodes, error] = useDataFetch(codesUrl)

    if (error) {
        return <>Network Error</>
    }

    const addCode = (e) => {
        e.preventDefault()
        fetch(`${baseUrl}/api/waivercode`, {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(newCode)
        })
        .then(response => {response.json(); window.location.reload()})
        .catch(err => {
            console.log(err);
            M.toast({ html: "Error adding a new code", displayLength: 2000 })
        })
    }

    const updateCode = (e) => {
        const { name, value } = e.target
        const index = e.target.dataset.index
        let currentCode = { ...waiverCodes[index], [name]: value }

        console.log(JSON.stringify(currentCode))

        fetch(`${baseUrl}/api/waivercode/${currentCode.WaiverCodeId}`, {
            method: "PUT",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(currentCode)
        })
        .catch(err => {
            console.log(err);
            M.toast({ html: "Error updating code", displayLength: 2000 })
        })
    }

    const deleteCode = (e) => {
        e.preventDefault()
        const index = e.target.dataset.index
        let currentCode = waiverCodes[index]
        currentCode.IsDisabled = true
        
        fetch(`${baseUrl}/api/waivercode/${currentCode.WaiverCodeId}`, {
            method: "PUT",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(currentCode)
        })
        .then(response => {response.json(); window.location.reload()})
        .catch(err => {
            console.log(err);
            M.toast({ html: "Error deleting code", displayLength: 2000 })
        })
    }

    return (
        isLoading ?
        <img src={Spinner} alt="Spinner" />
        :
        <div className="manage-codes">
            <article className="manage-codes__header">
                <h3 className="mbh-green-text">Manage Fee/Waiver Codes</h3>
                <div className="manage-codes__btns">
                    <a className="btn-floating mbh-green" onClick={() => history.push("/admin-portal/admin")}><i className="material-icons notextselect">close</i></a>
                </div>
            </article>
            {
                waiverCodes.length ?
                <div>
                    <form>
                    {
                        waiverCodes.map((code, index) => (
                                <div key={code.WaiverCodeId} className="row">
                                    <div className="col s12 m10">
                                        <div class="input-field col s6 m3">
                                            <input id={`${code.WaiverCode}${code.WaiverCodeId}`} name="WaiverCode" type="text" className="validate" defaultValue={code.WaiverCode} data-index={index} onChange={updateCode}/>
                                            <label for={`${code.WaiverCode}${code.WaiverCodeId}`} className={code.WaiverCode.length ? "active" : ""}>Code</label>
                                        </div>
                                        <div class="input-field col s6 m3">
                                            <input id={`${code.WaiverStrategy}${code.WaiverCodeId}`} name="WaiverStrategy" type="number" className="validate" min="0" max="1" defaultValue={code.WaiverStrategy ? 1 : 0} data-index={index} onChange={updateCode} />
                                            <label for={`${code.WaiverStrategy}${code.WaiverCodeId}`} className={code.WaiverStrategy.toString().length ? "active" : ""}>Strategy</label>
                                        </div>
                                        <div class="input-field col s12 m6">
                                            <input id={`${code.WaiverDescription}${code.WaiverCodeId}`} name="WaiverDesc" type="text" className="validate" defaultValue={code.WaiverDesc} data-index={index} onChange={updateCode} />
                                            <label for={`${code.WaiverDesc}${code.WaiverCodeId}`} className={code.WaiverDesc.length ? "active" : ""}>Description</label>
                                        </div>
                                    </div>
                                    <div className="col s12 m2">
                                        <button className="btn-floating mbh-red" style={{marginRight:"5px"}} data-index={index} onClick={deleteCode}><i className="material-icons notextselect" data-index={index}>delete</i></button>
                                    </div>
                                </div>
                            ))
                        }
                        </form>
                </div>
                :
                null
            }
            <div className="manage-codes__add-section">
                <form>
                    <div className="row">
                        <div className="col s12 m10">
                            <div class="input-field col s6 m3">
                                <input id="add-code" type="text" className="validate" maxLength="10" onChange={(e) => setNewCode(prev => ({ ...prev, WaiverCode: e.target.value }))} />
                                <label for="code">Code</label>
                            </div>
                            <div class="input-field col s6 m3">
                                <input id="add-strategy" type="number" className="validate" min="0" max="1" onChange={(e) => setNewCode(prev => ({ ...prev, WaiverStrategy: e.target.value }))} />
                                <label for="waiver-strategy">Strategy</label>
                            </div>
                            <div class="input-field col s12 m6">
                                <input id="add-desc" type="text" className="validate" onChange={(e) => setNewCode(prev => ({ ...prev, WaiverDesc: e.target.value }))} />
                                <label for="description">Description</label>
                            </div>
                        </div>
                        <div className="col s12 m2">
                            <button className="btn-floating mbh-green" onClick={addCode}><i className="material-icons notextselect">add</i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ManageFeeWaiverCodes
