import React from 'react'
import mbhLogo from '../../assets/mbh-logo.png';

const StudentMenu = ({menuStyle, menuSmall, history}) => {
    return (
        <div>
            <ul className= {`mbh-green-text ${menuStyle}`} >
                    {
                        !menuSmall ?
                          <li className="student-portal__menu-item"> <img src={mbhLogo} className="student-portal__logo" alt="mbh logo" /></li>
                          : <li className="student-portal__menu-item white-text">.</li>
                    }
                    {/* <li id="itemHome" className="student-portal__menu-item" onClick={() => history.push('/student-portal')}><i className="material-icons student-portal__menu-icon">home</i>{menuSmall? "": " Home"}</li> */}
                    <li id="itemProfile" className="student-portal__menu-item" onClick={() => history.push('/student-portal/profile')}><i className="material-icons student-portal__menu-icon">person</i>{menuSmall? "": " Profile"}</li>
                    <li id="itemQuals" className="student-portal__menu-item" onClick={() => history.push('/student-portal/qualifications')}><i className="material-icons student-portal__menu-icon">school</i>{menuSmall? "": " Qualifications"}</li>
                    <li id="itemPlan" className="student-portal__menu-item" onClick={() => history.push('/student-portal/training-plan')}><i className="material-icons student-portal__menu-icon">assignment</i>{menuSmall? "": " Training Plan"}</li>
                    {/* <li className="student-portal__menu-item"><i className="material-icons student-portal__menu-icon">announcement</i>{menuSmall? "": " Notifications"}</li> */}
                    <li className="student-portal__menu-item" onClick={() => history.push('/student-portal/events')}><i className="material-icons student-portal__menu-icon">insert_invitation</i>{menuSmall? "": " Upcoming Events"}</li>
                    <li className="student-portal__menu-item" onClick={(e) => {history.push('/'); window.sessionStorage.clear()}}><i className="material-icons student-portal__menu-icon">exit_to_app</i>{menuSmall? "": " Logout"}</li>
                </ul>
        </div>
    )
}

export default StudentMenu
