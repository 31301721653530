//keyField will be a property from the array object to use as a key, for example the "Id"
export const arrayToObject = (array) =>
    array.reduce((obj, item) => {
    obj[item.Competency.Id] = item
    return obj
}, {})

export const arrayToObjectWithKey = (array, keyField) =>
    array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
}, {})