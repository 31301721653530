import React, {useEffect} from 'react'
import './NavBar.scss';
import mbhLogo from '../../assets/mbh-logo.png'
import { ACTIONS } from '../../App';
import M from 'materialize-css';

const NavBar = ({ history }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        var instances = M.Sidenav.init(elems, { edge: 'right' });
    }, [])

    const imgClass = window.location.pathname !== "/mark-attendance" ? "navBar__logo" : 'navBar__logo-attendance'
    return (
        <div className="navBar">
             <figure className="navBar__fig">
                <img src={mbhLogo} className={`responsive-img ${imgClass}`} alt="mbh logo" onClick={() => history.push('/')} />
            </figure>
            {
                //don't show menu items when on mark attendance page
                window.location.pathname !=="markAttendance" &&
                <nav className="nav-wrapper">
                    <a href="#" data-target="slide-out" className="sidenav-trigger right hide-on-med-and-up"><i className="material-icons">menu</i></a>
                    <ul className="right hide-on-small-only">
                        <li onClick={() => history.push('/')}>Home</li>
                        <li onClick={() => history.push('/qualifications')}>Qualifications</li>
                        <li onClick={() => history.push('/career-pathways')}>Career Pathways</li>
                        
                        {
                            window.location.pathname === '/login' ?
                            <li onClick={() => history.push('/register')}><span id="register" className="mbh-green white-text">Register</span></li>
                            :
                            <li onClick={() => history.push('/login')}><span id="login" className="mbh-green white-text" style={{whiteSpace: "nowrap"}}>Student Portal</span></li>
                        }
                    </ul>
                </nav>
                }
                {
                //don't show menu items when on mark attendance page
                window.location.pathname !=="markAttendance" &&
                <ul id="slide-out" className="sidenav sidenav-close">
                        <li onClick={() => history.push('/')}>Home</li>
                        <li onClick={() => history.push('/qualifications')}>Qualifications</li>
                        
                        {
                            window.location.pathname === '/login' ?
                            <li onClick={() => history.push('/register')}><span className="mbh-green-text">Register</span></li>
                            :
                            <li onClick={() => history.push('/login')}><span className="mbh-green-text">Student Portal</span></li>

                        }
                </ul>
            }
        </div>
    )
}

export default NavBar;
