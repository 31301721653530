import React, {useState, useEffect} from 'react'
import './AdminLocationsDelivery.scss'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetchRefresh } from '../../hooks/useDataFetchRefresh';
import { useDataFetch } from '../../hooks/useDataFetch';
import {regionsObject, getIDfromRegion, getRegionfromID} from '../../functions/getRegions';
import M from 'materialize-css';
import Spinner from '../../assets/spinner.svg'

const AdminLocationsDelivery = ({authToken, currOffice}) => {
   
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        M.updateTextFields();

        var elemsModal = document.querySelectorAll('.modal');
        var instancesModal = M.Modal.init(elemsModal, {});
    })


    const [refreshData, setRefreshData] = useState(false);
    const [officeObj, setOfficeObj] = useState({});
    const deliveryLocationsUrl = `${baseUrl}/api/deliverylocations?&$Filter=IsDisabled ne true&$orderBy=DeliveryLocation`;
    const [isLoadingLocations, deliveryLocations, error] = useDataFetchRefresh(deliveryLocationsUrl, authToken, refreshData, setRefreshData);

    // Grabbing all training regions to fill a dropdown list
    const trainingRegionsUrl = `${baseUrl}/api/trainingregions?$orderBy=Name asc`;
    const [isLoadingRegions, trainingRegions, errorRegions] = useDataFetch(trainingRegionsUrl, authToken);

    const [filteredLocations, setFilteredLocations] = useState([]);

    useEffect(() => {
        if (deliveryLocations.length) {
            setFilteredLocations(deliveryLocations)
        }
    }, [deliveryLocations])

    useEffect(() => {
        if (currOffice !== undefined && currOffice > 0) {
            const filtered = deliveryLocations.filter(location => location.OfficeId == currOffice)
            setFilteredLocations(filtered)
        }

        if (currOffice == "choose") {
            setFilteredLocations(deliveryLocations)
        }
    }, [currOffice])

    const stateUrl = `${baseUrl}/api/states`;
    const [ isLoadingState, state, stateError ] = useDataFetch(stateUrl)

    const officeLocationsUrl = `${baseUrl}/api/offices?&$Filter=IsDisabled eq false&$orderBy=Name`;
    const [isLoadingOffice, officeLocations, errorOffice] = useDataFetch(officeLocationsUrl);

    const onChooseOffice = (e) => {
        if(document.getElementById("updateDeliveryLocation")){
            document.getElementById("updateDeliveryLocation").reset();
        }

        if(document.getElementById("UpdateDeliveryOfficeSection")){
            document.getElementById("UpdateDeliveryOfficeSection").classList.remove("hide");
        }

        if (e.target.value > 0){
            let obj = filteredLocations.filter(o => o.Id == e.target.value);
            setOfficeObj(obj)
        }else{
            if(document.getElementById("updateDeliveryLocation")){
                document.getElementById("updateDeliveryLocation").reset();
            }
        }
    }
    const addNewDelOfficeForm = (e) => {
        if(document.getElementById("SelectDeliveryOfficeSection")){
            document.getElementById("SelectDeliveryOfficeSection").classList.add("hide");
        }
        if(document.getElementById("UpdateDeliveryOfficeSection")){
            document.getElementById("UpdateDeliveryOfficeSection").classList.add("hide");
        }
        if(document.getElementById("AddDeliveryOfficeSection")){
            document.getElementById("AddDeliveryOfficeSection").classList.remove("hide");
        }
    }

    const cancelAddNewOfficeForm = (e) => {
        if(document.getElementById("SelectDeliveryOfficeSection")){
            document.getElementById("SelectDeliveryOfficeSection").classList.remove("hide");
        }
        if(document.getElementById("UpdateDeliveryOfficeSection")){
            document.getElementById("UpdateDeliveryOfficeSection").classList.remove("hide");
        }
        if(document.getElementById("AddDeliveryOfficeSection")){
            document.getElementById("AddDeliveryOfficeSection").classList.add("hide");
        }

        if(document.getElementById("addDeliveryOfficeLocation")){
            document.getElementById("addDeliveryOfficeLocation").reset();
        }
    }

    const updateOfficeLocation = (e, obj) =>{
        const { name, value } = e.target;       
        
        obj[0][name] = value
    
        fetch(`${baseUrl}/api/deliverylocations/${obj[0].Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(obj[0])
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const addOfficeObj = {
        "DeliveryLocation": "",
        "OfficeId": 0,
        "Suburb": "",
        "Postcode": "",
        "Country": "",
        "VetLoc": "",
        "TrainingRegionId": 0,
        "IsDisabled":false,
        "StateId": ""
    }
    const updateAddOfficeObj = (e) => {
        const { name, value } = e.target;
        addOfficeObj[name] = value;
    }

    const addOfficeLocation = (e) =>{

        let errorString = "Please Enter values for: ";
        let hasError = false;

        if (document.getElementById("OfficeName2").value == ""){
           errorString += "Name ";
           hasError = true;
        }
        if (document.getElementById("Office2").value == ""){
            errorString += "Office ";
            hasError = true;
        }
        if (document.getElementById("OfficeSuburb2").value == ""){
            errorString += "Suburb ";
            hasError = true;
        }
        if (document.getElementById("OfficeStateId2").value == ""){
            errorString += "State ";
            hasError = true;
        }
        if (document.getElementById("OfficePostcode2").value == ""){
            errorString += "Postcode ";
            hasError = true;
        }
        if (document.getElementById("OfficeRegionId2").value == ""){
            errorString += "Region ";
            hasError = true;
        }    

        if(hasError){
            M.toast({html: errorString})
        }
        else{
            fetch(`${baseUrl}/api/deliverylocations`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(addOfficeObj)
            })
            .then(response => {response.json(); window.location.reload();})
            .catch(err => {console.log(err); window.location.reload();})

            if(document.getElementById("SelectDeliveryOfficeSection")){
                document.getElementById("SelectDeliveryOfficeSection").classList.remove("hide");
            }
            if(document.getElementById("UpdateDeliveryOfficeSection")){
                document.getElementById("UpdateDeliveryOfficeSection").classList.remove("hide");
            }
            if(document.getElementById("AddDeliveryOfficeSection")){
                document.getElementById("AddDeliveryOfficeSection").classList.add("hide");
            }
            if(document.getElementById("addDeliveryOfficeLocation")){
                document.getElementById("addDeliveryOfficeLocation").reset();
            }
            
            setRefreshData(true)
        }
    }

    
    const deleteOfficeLocation = (e, obj) =>{
        obj[0]["IsDisabled"] = true;
      
       fetch(`${baseUrl}/api/deliverylocations/${obj[0].Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(obj[0])
        })
        .then(response => response.json())
        .catch(err => console.log(err))

        if(document.getElementById("UpdateDeliveryOfficeSection")){
            document.getElementById("UpdateDeliveryOfficeSection").classList.add("hide");
        }

        document.getElementById("chooseDelOfficeDropDown").value = "choose";
        setRefreshData(true)
    }

    return (
        <div className="admin-delivery-locations">
            {
                isLoadingRegions || isLoadingLocations ?
                <img src={Spinner} alt="Spinner" />
                :
                <>
                <h5 className="mbh-green-text">Delivery Locations</h5>

                <div id="SelectDeliveryOfficeSection" className="admin-office-locations__select-add row">
                    <div className="col s10">
                        <select id="chooseDelOfficeDropDown" onChange={onChooseOffice}>
                            <option value="choose" selected>Select an Office</option>
                            {
                                filteredLocations.map((location, index) => (
                                    <option key={index} value={location.Id}>{location.DeliveryLocation}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col s2">
                        <a className="btn-floating mbh-green right" onClick={addNewDelOfficeForm}>
                            <i className="material-icons">add</i>
                        </a>
                    </div>
            </div>
            {
                    officeObj[0] != undefined && !isLoadingOffice &&
                    <div id="UpdateDeliveryOfficeSection" className="admin-office-locations__update-office">
                        <form id="updateDeliveryLocation">
                            <div className="row">
                                <div className="input-field col s4">
                                    <input id="VetLoc" name="VetLoc" type="text" defaultValue={officeObj[0].VetLoc?officeObj[0].VetLoc:""} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                    <label htmlFor="VetLoc">VetLoc</label>
                                </div>
                                <div className="input-field col s4">
                                    <input  name="DeliveryLocation" type="text" defaultValue={officeObj[0].DeliveryLocation} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                    <label htmlFor="DeliveryLocation">Name</label>
                                </div>
                                <div className="input-field col s4">
                                    <select id="officeId" onChange={(e) => updateOfficeLocation(e, officeObj)} autoComplete="new-password" name="OfficeId" value={officeObj[0].OfficeId?officeObj[0].OfficeId:""}>
                                    {                          
                                        officeLocations.map((office, i) => {
                                            return <option key={i} defaultValue={office.OfficeId}  value={office.OfficeId} >{office.Name}</option>
                                        })
                                    }
                                    </select>
                                    <label>Program Location</label>
                                </div> 
                            </div>
                            <div className="row">
                                <div className="input-field col s3">
                                    <input name="Suburb" type="text" defaultValue={officeObj[0].Suburb?officeObj[0].Suburb:""} autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                    <label htmlFor="Suburb">Suburb</label>
                                </div>
                                <div className="input-field col s3">
                                    <select onChange={(e) => updateOfficeLocation(e, officeObj)} name="StateId" value={officeObj[0].StateId?officeObj[0].StateId:""}>
                                    <option disabled>Choose State</option>
                                    {                          
                                        state.map((state, i) => {
                                            return <option key={i} defaultValue={state.Id} value={state.Id}>{state.StateCode?state.StateCode:state.Description}</option>
                                        })
                                    }
                                    </select>
                                    <label>State</label>
                                </div> 
                                <div className="input-field col s3">
                                    <input name="Postcode" type="text" defaultValue={officeObj[0].Postcode?officeObj[0].Postcode:""} maxLength="4" autoComplete="new-password" onChange={(e) => updateOfficeLocation(e, officeObj)} />
                                    <label htmlFor="Postcode">Postcode</label>
                                </div> 
                                <div className="input-field col s3">
                                    <select id="Country" onChange={(e) => updateOfficeLocation(e, officeObj)} name="Country" value={officeObj[0].Country?officeObj[0].Country:""}>
                                        <option disabled>Choose Country</option>
                                        <option defaultValue={"1101"} value={"1101"}>Australia</option>
                                    </select>
                                    <label>Country</label>
                                </div>  
                            </div> 
                                <div className="row">
                                    <div className="input-field col s6">
                                        <select onChange={(e) => updateOfficeLocation(e, officeObj)} name="TrainingRegionId" value={officeObj[0].TrainingRegionId?officeObj[0].TrainingRegionId:""}>
                                    
                                        {                          
                                            Object.keys(regionsObject).map((region, i) => {
                                                return <option key={i} defaultValue={getIDfromRegion(region)} value={getIDfromRegion(region)}>{region}</option>
                                            })
                                        }
                                        </select>
                                        <label>Region</label>
                                    </div>   
                                </div>
                        </form>
                        <button data-target="modal2" className="btn-floating mbh-red modal-trigger right">
                            <i className="material-icons">delete</i>
                        </button>
                        
                        {/* <!-- Modal Structure --> */}
                        <div id="modal2" className="modal">
                            <div className="modal-content">
                                <p>Are you sure you want to delete this Office?</p>
                            </div>
                            <div className="modal-footer">
                                <a  className="modal-close btn-flat">No</a>
                                <a  className="modal-close mbh-green white-text btn-flat" onClick={(e) => deleteOfficeLocation(e, officeObj)}>Yes</a>
                            </div>
                        </div>
                    </div>
                }

                
                <div id="AddDeliveryOfficeSection" className="admin-office-locations__update-office hide">
                    <form id="addDeliveryOfficeLocation">
                        <div className="row">
                            <div className="input-field col s4">
                                <input id="VetLoc" name="VetLoc" type="text" autoComplete="new-password" onChange={updateAddOfficeObj} />
                                <label htmlFor="VetLoc">VetLoc</label>
                            </div>
                            <div className="input-field col s4">
                                <input id="OfficeName2" name="DeliveryLocation" type="text" autoComplete="new-password" onChange={updateAddOfficeObj} />
                                <label htmlFor="DeliveryLocation">Name</label>
                            </div>
                            <div className="input-field col s4">
                                <select id="Office2" onChange={updateAddOfficeObj} name="OfficeId">
                                <option>Select Location</option>
                                {                          
                                    officeLocations.map((office, i) => {
                                        return <option key={i} defaultValue={office.OfficeId} value={office.OfficeId}>{office.Name}</option>
                                    })
                                }
                                </select>
                                <label>Program Location</label>
                            </div> 
                            </div>
                            <div className="row">
                                <div className="input-field col s3">
                                    <input id="OfficeSuburb2" name="Suburb" type="text"  autoComplete="new-password" onChange={updateAddOfficeObj} />
                                    <label htmlFor="Suburb">Suburb</label>
                                </div>
                                <div className="input-field col s3">
                                    <select id="OfficeStateId2" onChange={updateAddOfficeObj} name="StateId">
                                    <option disabled>Choose State</option>
                                    {                          
                                        state.map((state, i) => {
                                            return <option key={i} defaultValue={state.Id} value={state.Id}  >{state.StateCode?state.StateCode:state.Description}</option>
                                        })
                                    }
                                    </select>
                                    <label>State</label>
                                </div> 
                                <div className="input-field col s3">
                                    <input id="OfficePostcode2" name="Postcode" type="text" autoComplete="new-password" onChange={updateAddOfficeObj} />
                                    <label htmlFor="Postcode">Postcode</label>
                                </div> 
                                <div className="input-field col s3">
                                    <select id="OfficeCountry2" onChange={updateAddOfficeObj} name="Country">
                                        <option disabled>Choose Country</option>
                                        <option defaultValue={"1101"} value={"1101"}>Australia</option>
                                    </select>
                                    <label>Country</label>
                                </div>  
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <select id="OfficeRegionId2" onChange={updateAddOfficeObj} name="TrainingRegionId">
                                    <option>Select Region</option>
                                    {                          
                                        Object.keys(regionsObject).map((region, i) => {
                                            return <option key={i} defaultValue={getIDfromRegion(region)} value={getIDfromRegion(region)}>{region}</option>
                                        })
                                    }
                                    </select>
                                    <label>Region</label>
                                </div>   
                            </div>
                            
                        </form>
                        <div className="col s2">
                        <a className="btn-floating mbh-green right" style={{marginLeft:"5px"}} onClick={cancelAddNewOfficeForm}>
                            <i className="material-icons">close</i>
                        </a>
                        <a className="btn-floating mbh-green right" onClick={(e) => addOfficeLocation(e)}>
                            <i className="material-icons">save</i>
                        </a>
                    </div>
                </div>

                </>
            }
        </div>
    )
}

export default AdminLocationsDelivery
