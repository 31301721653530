import React, { useEffect } from 'react';
import M from 'materialize-css';
import './Signature.scss';


const Signature = () => {

    useEffect(() => {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
    })
    
    return (
        <section className="signature">
            <h5 className="mbh-green-text">Student Declaration and Consent</h5>
            <p className="signature__checkbox-group-title">Please check the box to confirm your consent</p>
            <div className="row">
                <label className="signature__checkbox">
                    <input id="consent1" type="checkbox" className="filled-in" />
                    <span>I declare that the information I have provided to the best of my knowledge is true and correct.</span>
                </label>
            </div>
            <div className="row">
                <label className="signature__checkbox">
                    <input id="consent2" type="checkbox" className="filled-in" />
                    <span>I consent to the collection, use and disclosure of my personal information in accordance with the <a class="modal-trigger" href="#modal1">Privacy Notice</a> above.</span>
                </label>
            </div>
            <div className="row">
                <label className="signature__checkbox">
                    <input id="consent3" type="checkbox" className="filled-in" />
                    <span>I consent to M.B.H Management conducting an existing USI search where verification errors have occurred.</span>
                </label>
            </div>
            <br/><br/>
            <div id="modal1" class="modal">
                <div class="modal-content">
                    <h4>Privacy Notice &amp; Student Declaration</h4>
                    <h5>Privacy Notice</h5>
                    <p>
                        Under the Data Provision Requirements 2012, M.B.H Management Pty Ltd is required to collect
                        personal information about you and to disclose that personal information to the National Centre
                        for Vocational Education Research Ltd (NCVER).
                        <br />
                        <br />
                        Your personal information (including the personal information contained on this enrolment form
                        and your training activity data) may be used or disclosed by M.B.H Management Pty Ltd for
                        statistical, regulatory and research purposes. M.B.H Management Pty Ltd may disclose your
                        personal information for these purposes to third parties, including:
                    </p>
                    <br />
                    <ul className="signature__modal-list">
                        <li>School- if you are a secondary student undertaking VET, including a school-based apprenticeship or traineeship</li>
                    </ul>
                    <br />
                    <p>Employer - if you are enrolled in training paid by your employer:</p>
                    <br />
                    <ul className="signature__modal-list">
                        <li>Commonwealth and State or Territory government departments and authorised agencies</li>
                        <li>Organisations conducting student surveys: and researchers</li>
                    </ul>
                    <br />
                    <p>NCVER</p>
                    <p>Personal information disclosed to NCVER may be used or disclosed for the following purposes:</p>
                    <br />
                    <ul className="signature__modal-list">
                        <li>Issuing statements of attainment or qualification, and populating authenticated VET transcripts</li>
                        <li>Facilitating statistics and research relating to education, including surveys</li>
                        <li>Understanding how the VET market operates, for policy, workforce planning and consumer information; and administering VET, including programme administration, regulation, monitoring and evaluation.</li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button class="modal-close btn-flat mbh-green white-text">Done</button>
                </div>
            </div>
        </section>
    )
}

export default Signature
