
import React from 'react'
import { Editor } from '@tinymce/tinymce-react';

//This is being used in EditQualifications.jsx to update the qualification description
const UneditableTextEditor = ({id, name, initValue}) => {

    return (
        <Editor
        id={id}
        textareaName={name}
        apiKey='m5prz8cni9i2jpps17uh57guhzgk7hbsf8320rxcop7wka2m'
        value={initValue}
        init={{
          height: 300,
          menubar: false,
          inline: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | link | help'
        }}
        onEditorChange={(newText) => newText = initValue}
      />
    )
}

export default UneditableTextEditor