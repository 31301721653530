import React, {useState} from 'react'
import './AdminDuplicates.scss'
import { useDataFetch } from '../../hooks/useDataFetch';
import { useDataFetchRefresh } from '../../hooks/useDataFetchRefresh';
import { baseUrl } from '../../functions/globalVariables';
import Spinner from '../../assets/spinner.svg';
import { dateTimeToDateString } from '../../functions/dateTimeToDateString';
import M from 'materialize-css';
import { useThemeContext } from '../../ThemeContext';

const AdminDuplicates = () => {

    const { authToken } = useThemeContext()

    const [refreshData, setRefreshData] = useState(false);

    const studentUrl = `${baseUrl}/api/students?$filter=IsDisabled eq false&$orderBy=Surname`;
    //const [isLoadingStudents, allStudents, error] = useDataFetch(studentUrl, authToken);
    const [isLoadingStudents, allStudents, error] = useDataFetchRefresh(studentUrl, authToken, refreshData, setRefreshData);
    
    //create an array of student name objects?
    const studentNames = [];
    studentNames.push(allStudents.map(s=>(s.FirstName.trim() + " " + s.Surname.trim())));

    //create an array of duplicate student names
    const duplicateNames = [];
    for (let i=0; i<studentNames[0].length; i++){
        if (studentNames[0][i]===studentNames[0][i+1]){
            duplicateNames.push(studentNames[0][i])
        }
    }
   
    //get all studentsqualifications that are currently active
    const studentQualUrl = `${baseUrl}/api/studentqualifications?$filter=Active eq true`;
    const [isLoadingStudentQualActive, studentQualActive, errorSQ] = useDataFetch(studentQualUrl, authToken);

     //create an array of the active student id's
     const activeStudents = [];
     activeStudents.push(studentQualActive.map(s => s.StudentId))

    function onDragStart(e) {
        e.dataTransfer.setData("studentFromId", e.target.id);
    }

    const onDragOver = (e) => {
        e.preventDefault();
    }


    const checkAPIResponse = (data, status) => {
        if (status === 200){
            if(data.value !== undefined){
                M.toast({html: data.value, classes: 'mbh-green', displayLength: 1000});
                setRefreshData(true);
               
            }
        }
    }

    const onDrop = (e, studentToId) => {
        e.preventDefault();
       
        const studentFromId = e.dataTransfer.getData("studentFromId");
        const destination = document.getElementById(studentToId);
        destination.appendChild(document.getElementById(studentFromId));

        const mergeObject = {
            "FromID": null,
            "ToID": null
        }

        mergeObject["FromID"] = +studentFromId;
        mergeObject["ToID"] = studentToId;
        
        let status;
        fetch(`${baseUrl}/api/students/mergestudents`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(mergeObject)
        })
        .then(response =>  {
            status = response.status;
            checkAPIResponse("", status)
            return response.json()
        })
        .then(data => checkAPIResponse(data, status))
        .catch(err => console.log("errors" ,err))

       
    }

    return (
        <div className="admin-duplicates">
            <h3>Merge Duplicate Students</h3>
            <h6>Drag the student you want to merge from to the student you want to merge to.</h6>
            <br />
            <div className="admin-duplicates__body">
            {
                allStudents.length ?
                allStudents.map((student, index) => (
                    <div className="admin-duplicates__card"
                        key={index} 
                        id={student.Id}
                        style={{display: duplicateNames.includes(student.FirstName.trim() + " " + student.Surname.trim())?"inline":"none"}}
                        draggable="true" 
                        onDragStart = {(e) => onDragStart(e)}
                        onDrop={(e) => onDrop(e, student.Id)}
                        onDragOver={onDragOver} 
                    >
                        <span className="bold">{student.FirstName} {student.Surname}</span>
                        <span>{student.Id}</span>
                        <span>{student.Email}</span>
                        <span>{dateTimeToDateString(student.DOB, 2)}</span>
                        <span><span className="mbh-red-text center" style={{display: activeStudents[0].includes(student.Id)?"inline":"none"}}> * Active Qualification * </span></span>
                    </div>
                    ))
                    : isLoadingStudents?<img src={Spinner} alt="Spinner"/>:<p>No students found</p>
                } 
            </div>
        </div>
    )
}

export default AdminDuplicates
