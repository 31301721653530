import { useState, useEffect } from 'react'

export const useDoubleDataFetch = (url1, url2, fetchData, setFetchData, token) => {

    const [isLoading1, setIsLoading1] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [error, setError] = useState(null)
    const [firstURLData, setfirstURLData] = useState([])
    const [secondURLData, setsecondURLData] = useState([])

    useEffect(() => {

        if(!fetchData) return;
        
        setIsLoading1(true)
        fetch(url1, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => response.json())
            .then(data => {setfirstURLData(data.value);setFetchData(false);setIsLoading1(false)})
            .catch(error => {console.log(error);setIsLoading1(false)})
        
        setIsLoading2(true)
        fetch(url2, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => response.json())
            .then(data => {setsecondURLData(data.value);setFetchData(false);setIsLoading2(false)})
            .catch(error => {console.log(error);setIsLoading2(false)})       

    }, [fetchData])

    const isLoading = !isLoading1 && !isLoading2? false: true;
    return [ isLoading, firstURLData, secondURLData, error ]

}