import React, {useState, useEffect} from 'react';
import './StepForm.scss';
import ContactDetailsForm from '../ContactDetailsForm/ContactDetailsForm';
import VETForm from '../VETForm/VETForm';
import Signature from '../Signature/Signature';
import Spinner from '../../assets/spinner.svg';
import { useDataFetch } from '../../hooks/useDataFetch';
import { useDataPost } from '../../hooks/useDataPost';
import { formValidation } from '../../functions/formValidation';
import { baseUrl } from '../../functions/globalVariables';
import M from 'materialize-css';
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';

const StepForm = ({history}) => {

    useEffect(()=>{
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
       
    });


    const studentsUrl = `${baseUrl}/api/students`;
    const VETUrl = `${baseUrl}/api/vetquestions?$expand=Group,Answers&$orderBy=OrderIndex`;
    const qualificationsUrl = `${baseUrl}/api/qualifications?$filter=Superseded eq false`;
    const studentQualificationUrl = `${baseUrl}/api/studentqualifications`;
    const studentVETUrl = `${baseUrl}/api/studentvetquestions/addlist`;

    const [ isLoadingQual, qualifications, qualError ] = useDataFetch(qualificationsUrl)
    const [ isLoadingVET, vetQuestions, vetError ] = useDataFetch(VETUrl)

    
    const [showStep, setShowStep] = useState([true, false, false, false]);
    
    const showHideSteps = (show1, show2, show3, show4) => {
        setShowStep([show1, show2, show3, show4]);
    }
    
    //State for Qualification the student has selected
    const[qualObject, setQualObject] = useState({
        StudentId: null,
        QualId: null,
        Funding: null,
        Active: true
    });

   
    // State for ContactDetailsForm
    const [contactObject, setContactObject] = useState({ 
        "Id": 0,
        "SaStudentId": "",
        "Title": "",
        "FirstName": "",
        "Surname": "",
        "Organisation": "",
        "Position": "",
        "Address": "",
        "AddressBuilding": null,
        "AddressUnitNo": null,
        "AddressStreetNo": "",
        "AddressStreet": null,
        "AddressPOBox": null,
        "Address2": null,
        "Suburb": "",
        "State": "",
        "Postcode": "",
        "Address_Pos": null,
        "AddressBuilding_Pos": null,
        "AddressUnitNo_Pos": null,
        "AddressStreetNo_Pos": null,
        "AddressStreet_Pos": null,
        "AddressPOBox_Pos": null,
        "Address2_Pos": null,
        "Suburb_Pos": null,
        "State_Pos": null,
        "Postcode_Pos": null,
        "HomePhone": null,
        "Phone": "",
        "Mobile": "",
        "Fax": "",
        "Email": "",
        "AltEmail": "",
        "DOB": null,
        "Sex": null,
        "Location": null,
        "Other": null,
        "AvetmissDate": null,
        "CountryId": null,
        "PppBookingId": null,
        "TuitionFee": null,
        "Consent": true,
        "UniqueIdentifier": null,
        "CreatedAt": null                         
    });

    const handleChange = e => {
        const { name, value, checked } = e.target;
        contactObject[name] = value
    };

   

    const [submitData, setSubmitData] = useState(false)
    const [submitQualData, setSubmitQualData] = useState(false)
    const [submitStudentVetData, setSubmitStudentVetData] = useState(false)

    // State for VETForm

    const tempVetObject = {}
    const [vetObject, setVetObject] = useState({})

    //Contact Detail Post
    const [isLoadingStudentId, studentData, errorStudentData] = useDataPost(studentsUrl, contactObject, submitData, setSubmitData, 'POST')
    //Qualification Post
    const [isLoadingStudentQual, studentQualification, errorStudentQualification] = useDataPost(studentQualificationUrl, qualObject, submitQualData, setSubmitQualData, 'POST')
    //VET Questions Post
    const [isLoadingStudentVet, studentVETData, errorStudentVETData] = useDataPost(studentVETUrl, Object.values(vetObject), submitStudentVetData, setSubmitStudentVetData, 'POST', history)

    const studentId = studentData.Id;

    //If email or USI already exists the POST will return an error
    //the below captures it, returns message and allows user to try again
    useEffect(()=>{
        if(studentData.error){
            M.toast({html: studentData.error.innererror.message, displayLength: 2000});
            showHideSteps(true, false, false, false);
            document.getElementById("createStudentBtn").disabled = false;
        }else{
            if(studentData.Id!==undefined){
                showHideSteps(false, true, false, false);
            }
        }
    },[studentData])

    const handleQualChange = e => {
        if (studentData.Id === undefined) return

        const { name, value } = e.target;
        
        if (name === 'qualName'){
            qualObject.QualId = value;
            qualObject.StudentId = studentData.Id;  
            if(value>0){
                document.getElementById('QualFundingPanel').style.display = 'block';
            }
        }
        if (name === 'qualFunding'){
            qualObject.Funding = value;
        }
            
    };

    const validateQualObject = () => {
        if (qualObject.QualId === null) {
            return false;
        }

        return true;
    }

    const validateConsent = () => {
        if(document.getElementById("consent1").checked && document.getElementById("consent2").checked && document.getElementById("consent3").checked){
            return true;
        }else{
            return false;
        }
    }
    
    const submitVetandQualData = (e) => {
        e.preventDefault();
       
        const hasConsented = validateConsent();
        if (hasConsented){
            document.getElementById("finishRegistrationBtn").disabled = true;
            validateQualObject() && setSubmitQualData(true)
            setSubmitStudentVetData(true);
        }else{
            M.toast({html: 'Please consent to all 3 terms', displayLength: 2000});
        }
        
    }

    useEffect(() => {
        if (vetQuestions === undefined || studentData.Id === undefined) return

        for (let i  = 0; i < vetQuestions.length; i++) {
            const name = vetQuestions[i].Text;
            tempVetObject[name] = {
                StudentId: studentData.Id,
                VetQuestionId: vetQuestions[i].Id,
                Answers: [],
            }
        }

        setVetObject(tempVetObject)
    }, [vetQuestions, studentId])
    

    const handleVetChange = (e, vetObject) => {

        if (vetObject === undefined) return

        const { name, value, type, checked } = e.target;
       
        if (type === 'checkbox') {
            if (checked) {
                vetObject[name].Answers = [...vetObject[name].Answers, { VetAnswerId: value, Text: null }]
            } else {
                const filteredArray = vetObject[name].Answers.filter(item => item.VetAnswerId !== value)
                vetObject[name].Answers = filteredArray
            }

        } else if (type === 'text' || type === 'date') {
            vetObject[name].Answers = [{ VetAnswerId: null, Text: value }]
        } else {
            vetObject[name].Answers = [{ VetAnswerId: value, Text: null }]
        }
    };

   
   
    return(
        <div className="step-form container">
            <h3 className="step-form__heading">Registration</h3>
            <p>Register for one of our qualifications by filling out the form below.</p>
            <form action="">
                <ul className="step-form__stepper">
                    <li className="step-form__step step-form__step--1">
                        <div className="step-form__step-title" onClick={() => showHideSteps(true, false, false, false)}>
                            <span className="step-form__step-number btn-floating mbh-green" >1</span>
                            <span>Contact Details</span>
                        </div>
                        <div className="step-form__step-body" style={{display:showStep[0]?"block":"none"}}>
                            <div className="step-form__step-content">
                                <ContactDetailsForm contactObject={contactObject} handleChange={handleChange} />
                            </div>
                            <div className="step-form__step-action">
                                <button id="createStudentBtn" className="mbh-green btn" onClick={(e) => 
                                    {
                                        e.preventDefault();

                                        if (!formValidation(contactObject)) {
                                           
    
                                        }else{
                                            document.getElementById("createStudentBtn").disabled = true;
                                            setSubmitData(true);
                                        }
                                        
                                    }}>CONTINUE</button>
                            </div>
                        </div>
                    </li>
                    <li className="step-form__step step-form__step--2">
                        <div className="step-form__step-title" onClick={() => studentData.Id !== undefined && showHideSteps(false, true, false, false)}>
                            <span className="step-form__step-number btn-floating mbh-green" >2</span>
                            <span>Select Qualification</span>
                        </div>
                        <div className="step-form__step-body" style={{display:showStep[1]?"block":"none"}}>
                            <div className="step-form__step-content">
                                { 
                                isLoadingQual || isLoadingStudentQual ? <img src={Spinner} alt="Spinner"/>:
                                <div className="row">
                                    <div className="input-field col s12 l8">
                                        <select name="qualName" onChange={handleQualChange}>
                                            <option defaultValue="" disabled selected>Choose a Qualification</option>
                                            { 
                                                qualifications.map((qualification, i) => {
                                                return <option key={i} defaultValue={qualification.Id} value={qualification.Id}>{`${qualification.AntaId} ${capitaliseEachWordOfQualName(qualification)}`}</option>
                                                })
                                            }
                                        </select>
                                        <label></label>
                                    </div>
                                    <div className="input-field col s12 l4" id="QualFundingPanel" style={{display:"none"}}>
                                        <select name="qualFunding" onChange={handleQualChange} >
                                            <option defaultValue="" disabled selected>Choose a Funding Option</option>
                                            <option defaultValue="" value="Government Funded">Government Funded</option>
                                            <option defaultValue="" value="Self Funded">Self Funded</option>
                                            <option defaultValue="" value="Don't know yet">Don't know yet</option>
                                        </select>
                                        <label></label>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="step-form__step-action">
                                {/* <a className="btn-flat transparent mbh-green-Text" onClick={() => showHideSteps(true, false, false, false)}>BACK</a> */}
                                <a className="btn mbh-green" onClick={() => {
                                        if(qualObject.QualId != null && (qualObject.Funding=== null || qualObject.Funding=== undefined)){
                                            M.toast({html: "Please select a funding option.", displayLength: 2000});
                                        }else{
                                            showHideSteps(false, false, true, false);
                                        }
                                    }} style={{margin:"0"}}>CONTINUE</a>
                            </div>
                        </div>
                    </li>
                    <li className="step-form__step step-form__step--3">
                        <div className="step-form__step-title" onClick={() => studentData.Id !== undefined && showHideSteps(false, false, true, false)}>
                            <span className="step-form__step-number btn-floating mbh-green"  >3</span>
                            <span> Government Data Collection</span>
                        </div>
                        <div className="step-form__step-body" style={{display:showStep[2]?"block":"none"}}>
                            <div className="step-form__step-content">
                           {
                               isLoadingVET || isLoadingStudentId 
                                    ?<img src={Spinner} alt=""/>
                                    :<VETForm vetQuestions={vetQuestions} vetObject={vetObject} handleVetChange={handleVetChange} />
                           }
                            </div>
                            <div className="step-form__step-action">
                                <a className="btn-flat transparent mbh-green-Text" onClick={() => showHideSteps(false, true, false, false)}>BACK</a>
                                <a className="btn mbh-green" onClick={() => {
                                   
                                    showHideSteps(false, false, false, true);
                                    
                                    }} style={{margin:"0"}}>CONTINUE</a>
                            </div>
                        </div>
                    </li>
                    <li className="step-form__step step-form__step--4">
                        <div className="step-form__step-title" onClick={() => studentData.Id !== undefined && showHideSteps(false, false, false, true)}>
                            <span className="step-form__step-number btn-floating mbh-green" >4</span>
                            <span>Signature</span>
                        </div>
                        <div className="step-form__step-body" style={{display:showStep[3]?"block":"none"}}>
                            <div className="step-form__step-content">
                                <Signature />
                            </div>
                            <div>
                                {
                                    isLoadingStudentVet ? 
                                    <img src={Spinner} alt=""/>
                                    :
                                    <div className="step-form__step-action">
                                        <a className="btn-flat transparent mbh-green-Text" onClick={() => showHideSteps(false, false, true, false)}>BACK</a>
                                        <button id="finishRegistrationBtn"className="btn mbh-green" name="action" onClick={(e) => {submitVetandQualData(e)}}
                                        >
                                                Finish
                                            <i className="material-icons right">send</i>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </li>
                </ul> 
            </form>
        </div>
    );
}

export default StepForm;