import React, {useState} from 'react'
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import './EventsAttendanceConfirmation.scss'
import moment from 'moment'

const EventsAttendanceConfirmation = ({eventId, authToken}) => {

    const eventsRegUrl = `${baseUrl}/api/eventregistrations?$filter=EventID eq ${eventId}`;
    const [isLoadingEventsReg, eventsReg, eventsRegErr] = useDataFetch(eventsRegUrl, authToken);

    const studentUrl = `${baseUrl}/api/students`;
    const [isLoadingStudents, students, error] = useDataFetch(studentUrl, authToken)   

    const markAttendance = (e, id) =>{

        let eventObj = eventsReg.filter(event => event.Id === id)[0];
      
          let timestamp = null;
        if (e.target.checked === true)
            timestamp = moment().format();

        eventObj["HasAttended"] = e.target.checked;
        eventObj["AttendanceDate"] = timestamp;

      fetch(`${baseUrl}/api/eventregistrations/${eventObj.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(eventObj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const markAcceptance = (e, id) =>{

        console.log(e.target.checked)
        let eventObj = eventsReg.filter(event => event.Id === id)[0];
      
          let timestamp = null;
       

        eventObj["AttendanceConfirmed"] = e.target.checked;

      fetch(`${baseUrl}/api/eventregistrations/${eventObj.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(eventObj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }
    
    return (
        <div className="events-attendance-confirmation row">
            <div className="col s12">
                <h5 className="mbh-green-text">Attendance</h5>
                
                <table className="events-attendance-confirmation__table">
                    <thead>
                        <tr>
                            <th className="bold">Student Name</th>
                            <th className="bold center">Attendance Marked</th>
                            <th className="bold center">Confirm Attendance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingEventsReg && !isLoadingStudents && eventsReg.length?
                            eventsReg.map((event, index) => {
                                let currentStudent = students.filter(student => student.Id === event.StudentID);
                                let FullName = currentStudent[0].FirstName + " " + currentStudent[0].Surname;
                                return(
                                <tr key={event.Id}>    
                                    <td className=""><span name="StudentID">{FullName}</span></td>   
                                  

                                    <td className="events-attendance-confirmation__column-small">
                                        <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                            <input name="NotYetStarted" type="checkbox" className="filled-in " defaultChecked={event.HasAttended?true:false} onChange={(e) => markAttendance(e, event.Id)} />
                                            <span></span>
                                        </label>
                                    </td>
                                    <td className="events-attendance-confirmation__column-small">
                                        <label style={{padding:"0",display:"flex", justifyContent:"center"}}>
                                            <input name="IsCompleted" type="checkbox" className="filled-in "  defaultChecked={event.AttendanceConfirmed?true:false} onChange={(e) => markAcceptance(e, event.Id)} />
                                            <span></span>
                                        </label>
                                    </td>
                                                                 
                                </tr>
                                )
                                })
                            :
                            null
                        }
                        
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EventsAttendanceConfirmation
