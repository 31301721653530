import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import Spinner from '../../assets/spinner.svg'
import M from 'materialize-css';
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';


const EventsCreateType = ({ setCurrentPage }) => {

    let history = useHistory()
    const { authToken } = useThemeContext()

    const eventTypesUrl = `${baseUrl}/api/eventtypes`;
    const [isLoading, eventTypes, error] = useDataFetch(eventTypesUrl, authToken)

    const [allTypes, setAllTypes] = useState(null)

    useEffect(() => {
        if (eventTypes.length && !allTypes) {
            setAllTypes(eventTypes)
        }
    }, [eventTypes])

    const updateEventType = (e, obj) => {
       
        obj.Type = e.target.value;
     
        fetch(`${baseUrl}/api/eventtypes/${obj.Id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const addEventType = () => {

       const newEventTypeDescription = document.getElementById("newEventTypeDescription").value;

       if(newEventTypeDescription.trim() != ""){
          
           fetch(`${baseUrl}/api/eventtypes`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({ Type: newEventTypeDescription })
            })
            .then(response => response.json())
            .then(data => setAllTypes([...allTypes, { Id: data.Id, Type: newEventTypeDescription }]))
            .catch(err => console.log(err))

            


            document.getElementById("newEventTypeDescription").value="";
       } else {
            M.toast({html: "Please provide a description", displayLength: 2000});
       }
    }

    const deleteEventType = (obj) => {

        const filteredTypes = allTypes.filter(type => type.Type !== obj.Type)
        setAllTypes(filteredTypes)

        fetch(`${baseUrl}/api/eventtypes/${obj.Id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    return (
        <div>
            <article className="events-summary__header">
                <h3 className="mbh-green-text">Event Types</h3>
                <div className="events-summary__header--btns">
                    <a className="btn-floating mbh-green" onClick={() => {setCurrentPage("summary"); history.push('/admin-portal/events')}} style={{marginRight: "5px"}}><i className="material-icons notextselect">arrow_back</i></a>
                    <a className="btn-floating mbh-green" onClick={() => {setCurrentPage("calendar"); history.push('/admin-portal/events/calendar')}}><i className="material-icons notextselect">event</i></a>
                </div>
            </article>
            {
                isLoading ?
                <img src={Spinner} alt="Spinner" />
                :
                <table className="admin-program-streams__table">
                    <thead>
                        <tr>
                            <th className="bold">Description</th>
                            <th className="bold"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {   
                            allTypes &&
                            allTypes.map((type) => (
                                <tr key={type.Id}>
                                    <td><input name="Description" type="text" defaultValue={type.Type} onChange={(e) => updateEventType(e, type)} /></td>   
                                    <td className="admin-program-streams__table-column--id" title="DELETE"> <a className="btn-flat" style={{padding:"0",display:"flex", justifyContent:"center"}} onClick={() => deleteEventType(type)}><i className="material-icons admin-program-streams__delete mbh-red-text">delete</i></a></td>                               
                                </tr>
                            ))
                        }
                        <tr>
                            <td><input id="newEventTypeDescription" className="col s9" type="text" placeholder="add new event type" autoComplete="new-password"></input></td>
                            <td><a className="btn-floating mbh-green right" onClick={addEventType}><i className="material-icons" style={{userSelect:"none"}}>add</i></a></td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    )
}

export default EventsCreateType
