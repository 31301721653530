import { useState, useEffect } from 'react'
import { baseUrl } from '../functions/globalVariables';
import { ACTIONS } from '../App';

export const useDataPost = (url, object, submitData, setSubmitData, method, history) => {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        
        if (!submitData) return
       
        setIsLoading(true)
        fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: url === `${baseUrl}/api/studentvetquestions/addlist` ? JSON.stringify({"List": object}) : JSON.stringify(object),
        })
        .then(response => response.json())
        .then(data => {
            setData(data);
            setIsLoading(false);
            setSubmitData(false);
            if (history !== undefined) {
                history.push('/registration-confirmation')
            }
        })
        .catch(err => {
            setError(err);
            setIsLoading(false);
            setSubmitData(false);
        })
    }, [url, submitData])

    return [ isLoading, data, error ]

}
