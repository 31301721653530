import React, {useState, useEffect} from 'react';
import { useDataFetch } from '../../hooks/useDataFetch';
import { baseUrl } from '../../functions/globalVariables';
import Spinner from '../../assets/spinner.svg';
import './StudentQualifications.scss';
import M from 'materialize-css';
import { useDataFetchRefresh } from '../../hooks/useDataFetchRefresh';
import { dateTimeToDateString } from '../../functions/dateTimeToDateString';
import DatePickerComp from '../DatePickerComp/DatePickerComp';
import AdministerStudentMenuItems from '../AdministerStudentMenuItems/AdministerStudentMenuItems';
import { sortStudentCompetenciesTable } from '../../functions/sortStudentCompetenciesTable';
import ShowSortArrow from '../ShowSortArrow/ShowSortArrow';
import { useThemeContext } from '../../ThemeContext';
import { arrayToObjectWithKey } from '../../functions/arrayToObject'
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';

const StudentQualifications = () => {

    const { studentID, selectedStudentId, authToken, userRole } = useThemeContext()

    let currentStudentId = userRole === "Administrator" ? selectedStudentId : studentID
    
    const [refreshData, setRefreshData] = useState(false);

    useEffect(()=>{
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        var elemsModal = document.querySelectorAll('.modal');
        var instancesModal = M.Modal.init(elemsModal, {});
    });

    //fetch qualifications available to register in -FOR REGISTER A NEW QUALIFICATION SECTION
    const qualificationsUrl = `${baseUrl}/api/qualifications?$filter=Superseded eq false`;
    const [ isLoading, qualifications, error ] = useDataFetch(qualificationsUrl, authToken);

    //fetch details of selected qualification-FOR REGISTER A NEW QUALIFICATION SECTION
    let specificQualUrl ="";
    const [qualSelected, setQualSelected] = useState("");
    if(qualSelected !==""){
        specificQualUrl = `${baseUrl}/api/qualifications?$filter=Id eq ${qualSelected}`;
    }
    const [ isLoadingQual, qualificationDetails, qualError ] = useDataFetch(specificQualUrl, authToken);

    //fetch all qualifications that are not disabled
    const allStudentQualsUrl = `${baseUrl}/api/studentqualifications?$expand=*&$filter=StudentId eq ${currentStudentId} and Disabled eq false`;
    const [ isLoadingAll, allQuals, errorAll ] = useDataFetchRefresh(allStudentQualsUrl, authToken,refreshData, setRefreshData);
   

    //fetch pending qualifications
    const studentPendingUrl = `${baseUrl}/api/studentqualifications?$expand=Qualification&$filter=StudentId eq ${currentStudentId} and ConfirmedCost eq false and CertificateIssued ne 'Yes' and Disabled eq false`;
    const [ isLoadingPending, pendingQuals, errorPending ] = useDataFetchRefresh(studentPendingUrl, authToken,refreshData, setRefreshData);

    //fetch completed qualifications - FOR QUALIFICATION COMPETENCIES SECTION - no longer just completed
    // const studentQualUrl = `${baseUrl}/api/studentqualifications?$expand=Qualification&$filter=StudentId eq ${currentStudentId} and CertificateIssued eq 'Yes'`;
    const studentQualUrl = `${baseUrl}/api/studentqualifications?$expand=Qualification&$filter=StudentId eq ${currentStudentId} and Disabled eq false`;
    const [ isLoadingCompQual, completedQuals, errorCompQual ] = useDataFetchRefresh(studentQualUrl, authToken,refreshData, setRefreshData);
    
    //fetch compentencies for qualifications - FOR QUALIFICATION COMPETENCIES SECTION - no longer just completed
    // const studentCompetetenciesUrl = `${baseUrl}/api/studentcompetencies?$filter=StudentId eq ${currentStudentId} and StudentQualification/CertificateIssued eq 'Yes' & $expand=*`
    const studentCompetetenciesUrl = `${baseUrl}/api/studentcompetencies?$filter=StudentId eq ${currentStudentId} & $expand=*`
    const [ isLoadingComp, studentCompletedCompetencies, errorComp ] = useDataFetchRefresh(studentCompetetenciesUrl, authToken,refreshData, setRefreshData);
   
    //Make sure the student can't register for a qual they're already registered in - FOR REGISTER A NEW QUALIFICATION SECTION
    const registeredQuals = allQuals.length ? allQuals.filter(qual => qual.CertificateIssued !== "Withdrawn").map(qual => qual.Qualification.Id) : [];
    const filteredQuals = qualifications.length ? qualifications.filter(qual => !registeredQuals.includes(qual.Id)) : [];
    
    //Get the delivery location options for the dropdown
    const deliveryLocationUrl = `${baseUrl}/api/deliverylocations?&$Filter=IsDisabled ne true&$orderBy=DeliveryLocation`;
    const [ isLoadingDL, deliveryLocation, errorDL ] = useDataFetch(deliveryLocationUrl)

    //Get the program location options for the dropdown
    const programLocationUrl = `${baseUrl}/api/offices?&$orderBy=Name&$Filter=IsDisabled eq false`;
    const [ isLoadingPL, programLocation, errorPL ] = useDataFetch(programLocationUrl)

    //Get the program stream options for the dropdown
    const programStreamsUrl = `${baseUrl}/api/progstreams`;
    const [ isLoadingPS, programStreams, errorPS ] = useDataFetch(programStreamsUrl)

    const waiverCodesUrl = `${baseUrl}/api/waivercode`
    const [ isLoadingCodes, waiverCodes, errorCodes] = useDataFetch(waiverCodesUrl)

    const [filteredWaiverCodes, setFilteredWaiverCodes] = useState([])

    useEffect(() => {
        if (waiverCodes.length && allQuals.length) {
            const allWaiverCodes = arrayToObjectWithKey(allQuals, "WaiverCodeId")
            const filteredCodes = waiverCodes.filter(code => {
                // If a code has been assigned to a student but is currently disabled, add it to the list with an asterisk
                // This will make sure records are always correct
                if (!code.IsDisabled) {
                    return true
                } else if (allWaiverCodes[code.WaiverCodeId] !== undefined && code.IsDisabled) {
                    code.WaiverDesc += " *"
                    return true
                }

                return false
            })

            setFilteredWaiverCodes(filteredCodes)
        }
    }, [waiverCodes, allQuals])


    // This will keep hold of which table column we want to sort
    const [sortConfig, setSortConfig] = useState({
        fieldToSort: null,
        direction: null
    });
    
    const [sortedCompetencies, setSortedCompetencies] = useState([])

    const requestSort = (fieldToSort) => {
        let direction = 'ascending';
        if (sortConfig.fieldToSort === fieldToSort && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.fieldToSort === fieldToSort && sortConfig.direction === 'descending') {
            direction = null;
        }
        setSortConfig({ fieldToSort, direction });

        if (direction === null) {
            setSortedCompetencies(studentCompletedCompetencies)
        }
    }

    const handleAcceptQuote = (object) => {
        object['ConfirmedCost'] = true;
        object['Active'] = true;
      
        fetch(`${baseUrl}/api/studentqualifications/${object.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(object)
        })
        .then(response => {
            response.json()
            window.location.reload()
        })
        .catch(err => {
            console.log(err)
            window.location.reload()
        })
    }

    const handleCancelQuote = (object) => {
        object['Disabled'] = true;
      
        fetch(`${baseUrl}/api/studentqualifications/${object.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(object)
        })
        .then(response => {
            response.json()
            window.location.reload()
        })
        .catch(err => {
            console.log(err)
            window.location.reload()
        })
    }

    const handleQualChange = e => {
        const { name, value } = e.target;
        setQualSelected(value);

        if(value>0){
            document.getElementById('QualFundingPanel').style.display = 'block';
        }
    };


    //handle change to qualification results
    const handleQualUpdate = (e, qual, index)=> {
       
        const {name, value, type, checked} = e.target;
        const obj = { 
            "TotalFees": 0,
            "Id": 0,
            "Active": false,
            "Disabled": false,
            "StudentId": 0,
            "QualId": 0,
            "SaStudentId": null,
            "PASNumber": null,
            "TrainerID": null,
            "DeliveryLocId": null,
            "OfficeId": null,
            "WaiverCodeId": null,
            "CertificateAttainedDate": null,
            "CertificateIssued": "No",
            "CertificateId": null,
            "QuoteId": null,
            "QuoteCreatedAt": null,
            "QuoteAcceptedBy": null,
            "QuoteAcceptedAt": null,
            "PlanAcceptedBy": null,
            "PlanAcceptedAt": null,
            "ContractId": null,
            "ContractCreatedAt": null,
            "TrainingContractId": null,
            "ProgStreamId": null,
            "Funding": null,
            "TuitionFee": null,
            "Subsidy": null,
            "OtherSubsidy": null,
            "ConfirmedCost": false,
            "Comments": null           
        }    

        for (const [key, value] of Object.entries(obj)) {
            obj[key] = qual[key]
        }

        if(type ==='select-one'){
            obj[name] = value;
            allQuals[index][name] = value;
        }else if(name ==='Active'){
            obj[name] = checked;
            allQuals[index].Active = checked;
        }else if(name ==='TuitionFee' || name ==='Subsidy' || name ==="OtherSubsidy"){
             obj[name] = parseFloat(value);
             allQuals[index][name] = parseFloat(value);
        }else{
            obj[name] = value;
            allQuals[index][name] = value;
        }

        if (obj["WaiverCodeId"] === "none") {
            obj["WaiverCodeId"] = null
        }

        if (value === "null") {
            obj[name] = null
            allQuals[index][name] = null;
        }

        if (name === 'CertificateIssued' && value === 'Withdrawn'){
            obj["Active"] = false;
        }
       
        fetch(`${baseUrl}/api/studentqualifications/${qual["Id"]}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
        if(name ==='TuitionFee' || name ==='Subsidy' || name ==="OtherSubsidy" || name === 'CertificateIssued'){
            setRefreshData(true);
        }
    }

    //handle change to qualification results
    const handleQualDateUpdate = (date, qual, index)=> {
    
        const obj = { 
            "TotalFees": 0,
            "Id": 0,
            "Active": false,
            "Disabled": false,
            "StudentId": 0,
            "QualId": 0,
            "SaStudentId": null,
            "PASNumber": null,
            "DeliveryLocId": null,
            "OfficeId": null,
            "WaiverCodeId": null,
            "CertificateAttainedDate": null,
            "CertificateIssued": "No",
            "CertificateId": null,
            "QuoteId": null,
            "QuoteCreatedAt": null,
            "ContractId": null,
            "ContractCreatedAt": null,
            "TrainingContractId": null,
            "ProgStreamId": null,
            "Funding": null,
            "TuitionFee": null,
            "Subsidy": null,
            "OtherSubsidy": null,
            "ConfirmedCost": false,
            "Comments": null
        }      

        for (const [key, value] of Object.entries(obj)) {
            obj[key] = qual[key]
        }

        obj['CertificateAttainedDate'] = date;
        allQuals[index].CertificateAttainedDate = date;
       
        fetch(`${baseUrl}/api/studentqualifications/${qual["Id"]}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    // for editing competency results
    const resultsUrl = `${baseUrl}/api/CompetencyResults`;
    const [ isLoadingResults, results, resultsError ] = useDataFetch(resultsUrl)

    const handleChange = (e, date, detailsToUpdate, index) => {
        
        let type="";
        let name="";
        let value="";
        if(e === 'startDate' || e ==='finishDate'){
            name = e;
            value = date;
        }else{
            type = e.target.type;
            name = e.target.name;
            value = e.target.value;
        }
        
        const obj = { 
            "Id": 0,
            "BookingId": 0,
            "CourseId": "",
            "StudentId": 0,
            "CompetencyId": 0,
            "StudentQualificationId": 0,
            "ResultId": 0,
            "SaStudentId": "",
            "PppContract": "",
            "PppCourseSiteId": null,
            "ActivityStartDate": null,
            "ActivityEndDate": null
        }

        for (const [key, value] of Object.entries(obj)) {
            obj[key] = detailsToUpdate[key]
        }

        if(type ==='select-one'){
            obj["ResultId"] = parseInt(value);
            studentCompletedCompetencies[index].ResultId = value;
        }else{
            if(name === "startDate"){
                obj['ActivityStartDate'] = value;
                studentCompletedCompetencies[index].ActivityStartDate = value;
            }else {
                obj['ActivityEndDate'] = value;
                studentCompletedCompetencies[index].ActivityEndDate = value;
            }
        }

        fetch(`${baseUrl}/api/studentcompetencies/${detailsToUpdate["Id"]}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const [qualRegistered, setqualRegistered] = useState(false);

    const handleRegisterQual = (sId, qId) => {
              
        const qualObject = {
            "StudentId": null,
            "QualId": null, 
            "Funding": null,
            "Active": true
        }
        
        const funding = document.getElementById('QualFunding').value;
       
        if (funding ==="Choose a Funding Option"){
            M.toast({html: "Please select a funding option.", displayLength: 2000});
            return;
        }
        
        qualObject["StudentId"] = sId;
        qualObject["QualId"] = qId;
        qualObject["Funding"] = funding;

        fetch(`${baseUrl}/api/studentqualifications`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(qualObject)
        })
        .then(() => userRole !== "Administrator" && sendEmailToAdmins(qId))
        .catch(err => console.log(err))
        setRefreshData(true)
        setqualRegistered(true)
    }

    const handleGenerateQuote = (qual) =>{
        const tuition = document.getElementById("tuitionFee").value;
        const quoteID = document.getElementById("quoteId").value;

        if(tuition ==="" || quoteID===""){
            M.toast({html: "Please fill out both Tuition Fee and Quote ID fields.", displayLength: 2000});
        }else{        
            const obj = { 
                "TotalFees": 0,
                "Id": 0,
                "Active": false,
                "Disabled": false,
                "StudentId": 0,
                "QualId": 0,
                "SaStudentId": null,
                "PASNumber": null,
                "DeliveryLocId": null,
                "OfficeId": null,
                "CertificateAttainedDate": null,
                "CertificateIssued": "No",
                "CertificateId": null,
                "QuoteId": null,
                "QuoteCreatedAt": null,
                "ContractId": null,
                "ContractCreatedAt": null,
                "TrainingContractId": null,
                "ProgStreamId": null,
                "Funding": null,
                "TuitionFee": null,
                "Subsidy": null,
                "OtherSubsidy": null,
                "ConfirmedCost": false,
                "Comments": null           
            }    

            for (const [key, value] of Object.entries(obj)) {
                obj[key] = qual[key]
            }
             obj["TuitionFee"] = parseFloat(tuition);
             obj["QuoteId"] = quoteID;
             obj["Active"] = true;
     
            fetch(`${baseUrl}/api/studentqualifications/${qual.Id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                body: JSON.stringify(obj)
            })
            .then(response => {
                response.json()
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
                window.location.reload()
            })
        }
    }

    const sendEmailToAdmins = (qualId) => {
        if (allQuals.length) {
            const qual = qualifications.filter(qual => qual.Id === +qualId)[0]

            fetch(`${baseUrl}/api/auth/RegisterStudentQual`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                body: JSON.stringify({
                    StudentId: studentID,
                    Qualification: `${qual.AntaId} ${capitaliseEachWordOfQualName(qual)}`,
                    CreatedAt: Date.parse(Date.now())
                })
            })
            .catch(err => {
                console.log(err); 
                M.toast({html: "An error has occurred", displayLength: 1500})
            })
        }
    }

    useEffect(() => {
        if (studentCompletedCompetencies.length) {
            setSortedCompetencies(studentCompletedCompetencies)
        }
    }, [studentCompletedCompetencies])


    useEffect(() => {
        if (sortConfig.fieldToSort !== null) {
            if (sortConfig.direction !== null) {
                setSortedCompetencies(sortStudentCompetenciesTable(studentCompletedCompetencies, sortConfig))
            } else {
                setSortedCompetencies(studentCompletedCompetencies)
            }
        }
    }, [sortConfig])
   
    return (
        
        <div className="student-qualifications">
          
           {
               userRole === 'Administrator'? <AdministerStudentMenuItems page="Qualifications" />:null
           }
           
            {
                allQuals.length?
                <h3 className="hide-on-small-only">Qualifications{userRole==='Administrator'? ` for ${allQuals[0].Student.FirstName} ${allQuals[0].Student.Surname}`: ""}</h3>
                :<h3 className="hide-on-small-only">Qualifications</h3>
            }

            {/* List all quals for student */}
            { 
            allQuals.length?
            <div className=" student-qualifications__all ">
                <h5 className="mbh-green-text">Qualifications</h5>
                <div className="collection-item student-qualifications__qualification student-qualifications__qualification--qualification student-qualifications__border-header" >
                    <span className="bold">Code</span>
                    <span className="bold collection-item ">Name</span>
                    <span className="bold">Quote Id</span>
                    <span className="bold">Contract Id</span>
                    <span className="bold">TCID</span>
                    <span className="bold">Cert Issued</span>
                    <span className="bold">Cert Id</span>
                    <span className="bold">Cert Date</span>         
                    <span className="bold">Active</span>  
                </div>
                {
                    allQuals.map((quals, index) => {
                       
                         return(
                            <div key={index} className="collection-item student-qualifications__qualification student-qualifications__qualification--qualification student-qualifications__border-row" >
                                <span className="collection-item">{quals.Qualification.AntaId}</span>
                                <span className="collection-item">{quals.Qualification.Name}</span>
                                {
                                    userRole === 'Student'?
                                    <span>{quals.QuoteId?quals.QuoteId:"none"}</span>
                                    :<input type="text" defaultValue = {quals.QuoteId} name="QuoteId" autoComplete="new-password" onChange={(e) => handleQualUpdate(e, quals, index)}/>
                                }
                                {
                                    userRole === 'Student'?
                                    <span>{quals.ContractId?quals.ContractId:"none"}</span>
                                    :<input type="text" defaultValue = {quals.ContractId} name="ContractId" autoComplete="new-password" onChange={(e) => handleQualUpdate(e, quals, index)}/>
                                }      
                                  {
                                    userRole === 'Student'?
                                    <span>{quals.TrainingContractId?quals.TrainingContractId:"none"}</span>
                                    :<input type="text" maxLength={10} defaultValue = {quals.TrainingContractId} name="TrainingContractId" autoComplete="new-password" onChange={(e) => handleQualUpdate(e, quals, index)}/>
                                }                                
                                {
                                    userRole === 'Student'?
                                    <span>{quals.CertificateIssued}</span>
                                    :   <select id="CertificateIssued" onChange={(e) => handleQualUpdate(e, quals, index)} autoComplete="new-password" name="CertificateIssued">
                                            <option  value="" disabled selected>{quals.CertificateIssued}</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                            <option value="Withdrawn">Withdrawn</option>
                                        </select>
                                }
                                {
                                    userRole === 'Student'?
                                    <span>{quals.CertificateId}</span>
                                    :<input type="text" defaultValue = {quals.CertificateId} name="CertificateId" autoComplete="new-password" onChange={(e) => handleQualUpdate(e, quals, index)}/>
                                }
                              
                                {
                                    userRole === 'Student'?
                                    <span>{dateTimeToDateString(quals.CertificateAttainedDate, 2)}</span>
                                    :<DatePickerComp name="certDate" defaultDate={Date.parse(dateTimeToDateString(quals.CertificateAttainedDate, 1))}  handleDateUpdate={handleQualDateUpdate} object={quals} index={index}/>
                                }
                               
                                {
                                    userRole === 'Student'?
                                    <span>{quals.Active? "Yes": "No"}</span>
                                    :<label className="student-qualifications__checkbox">
                                        <input className="student-qualifications__checkbox filled-in" 
                                        type="checkbox" 
                                        name="Active" 
                                        defaultChecked={quals.Active?true:false} 
                                        disabled={quals.CertificateIssued === "Withdrawn"?true:false} 
                                        onClick={(e) => handleQualUpdate(e, quals, index)} 
                                        />
                                        <span></span>
                                    </label>
                                }

                                {
                                    quals.CertificateIssued !== "Yes" && (
                                    <button title='Delete'
                                        className="student-qualifications__deletebutton btn-floating btn-small mbh-red right" 
                                        onClick={() => handleCancelQuote(quals)}>
                                        <i className="material-icons">delete</i>
                                    </button>
                                    )                                    
                                }

                            </div>
                        )
                    })
                }
            </div>
            :null
            }
        {/* Funding table */}
        { 
            allQuals.length && userRole === 'Administrator'?
            <>
                <h5 className="mbh-green-text">Funding</h5>
                <table>
                    <thead className="student-qualifications__border-header">
                        <tr>
                            <th className="bold">Code</th>
                            <th className="bold">Name</th>
                            <th className="bold">Funding</th>
                            <th className="bold">Waiver</th>
                            <th className="bold">PAS Number</th>
                            <th className="bold">Program Stream</th>
                            <th className="bold">Program Location</th>   
                        </tr>    
                    </thead>
                    <tbody>
                        {
                            allQuals.map((quals, index) => {
                       
                                return(
                                    <tr key={index} className="student-qualifications__border-header" >
                                        <td>{quals.Qualification.AntaId}</td>
                                        <td>{quals.Qualification.Name}</td>
                                        <td>
                                            <select id="Funding" onChange={(e) => handleQualUpdate(e, quals, index)} autoComplete="new-password" name="Funding" >
                                                <option disabled></option>
                                                <option defaultValue="Government Funded" selected={quals.Funding==="Government Funded"?true:false} value="Government Funded">Government Funded</option>
                                                <option defaultValue="Self Funded" selected={quals.Funding==="Self Funded"?true:false} value="Self Funded">Self Funded</option>
                                                <option defaultValue="Don't know yet" selected={quals.Funding==="Don't know yet"?true:false} value="Don't know yet">Don't know yet</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select id="WaiverCodeId" onChange={(e) => handleQualUpdate(e, quals, index)} autoComplete="new-password" name="WaiverCodeId" value={quals.WaiverCodeId ? quals.WaiverCodeId : "none"}>
                                                <option value="none">None</option>
                                                {
                                                    filteredWaiverCodes.map(code => {
                                                        return <option key={code.WaiverCodeId} value={code.WaiverCodeId}>{code.WaiverCode} {code.WaiverDesc}</option>
                                                    })
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <input id="PASNumber" type="text" onChange={(e) => handleQualUpdate(e, quals, index)} defaultValue={quals.PASNumber} autoComplete="new-password" name="PASNumber" />
                                        </td>
                                        <td>
                                            <select id="ProgStreamId" onChange={(e) => handleQualUpdate(e, quals, index)} autoComplete="new-password" name="ProgStreamId">
                                                <option value="null"></option>
                                                {                          
                                                    programStreams.map((progStream, i) => {
                                                    return <option key={i} defaultValue={progStream.ID} value={progStream.ID} selected={quals.ProgStreamId===progStream.ID?true:false}>{progStream.Description}</option>
                                                    })
                                                }
                                            </select>
                                        </td>                    
                                        <td>
                                            <select id="OfficeId" onChange={(e) => handleQualUpdate(e, quals, index)} autoComplete="new-password" name="OfficeId">
                                                <option></option>
                                                {                          
                                                    programLocation.map((progLoc, i) => {
                                                    return <option key={i} defaultValue={progLoc.OfficeId} value={progLoc.OfficeId} selected={quals.OfficeId===progLoc.OfficeId?true:false}>{progLoc.Name}</option>
                                                    })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
            :null
            }

        {/* Fees table */}
        { 
            allQuals.length?
            <div className="student-qualifications__all">
                <h5 className="mbh-green-text">Student Fees</h5>
                <div className="collection-item student-qualifications__qualification student-qualifications__qualification--fees student-qualifications__border-header" >
                    <span className="bold">Code</span>
                    <span className="bold">Name</span>
                    <span className="bold student-qualifications__qualification--fee-item">Tuition Fee</span>
                    <span className="bold student-qualifications__qualification--fee-item">Subsidy</span>
                    <span className="bold student-qualifications__qualification--fee-item">Other Subsidy</span>        
                    <span className="bold student-qualifications__qualification--fee-item">Total Fees</span>      
                </div>
                {
                    allQuals.map((quals, index) => {
                       
                         return(
                            <div key={index} className="collection-item student-qualifications__qualification student-qualifications__qualification--fees student-qualifications__border-row" >
                                <span className="collection-item">{quals.Qualification.AntaId}</span>
                                <span className="collection-item">{quals.Qualification.Name}</span>
                                {
                                    userRole === 'Student'?
                                    <span className="collection-item student-qualifications__qualification--fee-item">{quals.TuitionFee?parseFloat(quals.TuitionFee).toFixed(2):"0"}</span>
                                    :<input style={{textAlign:"right"}} type="text" defaultValue = {quals.TuitionFee?parseFloat(quals.TuitionFee).toFixed(2):0} name="TuitionFee" autoComplete="new-password" onChange={(e) => handleQualUpdate(e, quals, index)}/>
                                }
                                {
                                     userRole === 'Student'?
                                    <span className="collection-item student-qualifications__qualification--fee-item">{quals.Subsidy?parseFloat(quals.Subsidy).toFixed(2):"0"}</span>
                                    :<input style={{textAlign:"right"}} type="text" defaultValue = {quals.Subsidy?parseFloat(quals.Subsidy).toFixed(2):"0"} name="Subsidy" autoComplete="new-password" onChange={(e) => handleQualUpdate(e, quals, index)}/>
                                }
                                {
                                    userRole === 'Student'?
                                    <span className="collection-item student-qualifications__qualification--fee-item">{quals.OtherSubsidy?parseFloat(quals.OtherSubsidy).toFixed(2):"0"}</span>
                                    :<input style={{textAlign:"right"}} type="text" defaultValue = {quals.OtherSubsidy?parseFloat(quals.OtherSubsidy).toFixed(2):"0"} name="OtherSubsidy" autoComplete="new-password" onChange={(e) => handleQualUpdate(e, quals, index)}/>
                                }
                                            
                                <span className="collection-item student-qualifications__qualification--fee-item">{parseFloat(quals.TotalFees).toFixed(2)}</span>
                            </div>
                        )
                    })
                }
            </div>
            :null
        }

        {/* Delivery Location table */}
        { 
            allQuals.length?
            <div className="student-qualifications__all">
                <h5 className="mbh-green-text">Delivery Details</h5>
                <div className="collection-item student-qualifications__qualification student-qualifications__qualification--delivery student-qualifications__border-header" >
                    <span className="bold">Code</span>
                    <span className="bold collection-item ">Name</span>
                          
                    <span className="bold">Delivery Location</span>  
                </div>
                {
                    allQuals.map((quals, index) => {
                       
                         return(
                            <div key={index} className="collection-item student-qualifications__qualification student-qualifications__qualification--delivery student-qualifications__border-row" >
                                <span className="collection-item">{quals.Qualification.AntaId}</span>
                                <span className="collection-item">{quals.Qualification.Name}</span>
                                
                                {
                                    userRole === 'Student'?
                                    
                                        <span>
                                         {
                                            deliveryLocation.map((loc) => {
                                                return quals.DeliveryLocId===loc.Id && loc.DeliveryLocation
                                            })
                                         }
                                        </span>
                                    :   <select id="deliveryLocId" onChange={(e) => handleQualUpdate(e, quals, index)} autoComplete="new-password" name="DeliveryLocId">
                                            <option></option>
                                            {                          
                                                deliveryLocation.map((deliveryLoc, i) => {
                                                return <option key={i} defaultValue={deliveryLoc.Id} value={deliveryLoc.Id} selected={quals.DeliveryLocId===deliveryLoc.Id?true:false}>{deliveryLoc.DeliveryLocation}</option>
                                                })
                                            }
                                        </select>
                                }
                               
                            </div>
                        )
                    })
                }
            </div>
            :null
            }

            <br/>
            {/*** QUALIFICATION COMPETENCIES  ***/}
            {
                completedQuals.length ?
                <div className="student-qualifications__completed">
                    
                    <h5 className="mbh-green-text">Qualification Competencies</h5>
                    {
                        completedQuals.map((qualification, index) => {
                           
                        return(
                            <div key={index}>
                                <p>
                                    <span style={{fontWeight:"bold", fontSize:"1.1rem"}}>{qualification.Qualification.AntaId} {qualification.Qualification.Name} </span>
                                    <span>  {qualification.CertificateId?`Certificate ID: ${qualification.CertificateId}`:"Certificate not attained yet."}  {`Issued: ${dateTimeToDateString(qualification.CertificateAttainedDate, 2)}`}</span>
                                </p>
                                <div className="student-qualifications__competency-table" >
                                    <div className="collection-item student-qualifications__competency student-qualifications__border-header" >
                                        <span className="bold student-qualifications__competency--field-1 table-column" onClick={() => requestSort("CompID")}>Code <ShowSortArrow columnFieldName="CompID" sortConfig={sortConfig} /></span>
                                        <span className="bold student-qualifications__competency--field-2 table-column" onClick={() => requestSort("compDsc")}>Units Enrolled <ShowSortArrow columnFieldName="compDsc" sortConfig={sortConfig} /></span>
                                        <span className="bold student-qualifications__competency--field-3 table-column" onClick={() => requestSort("ActivityStartDate")}>Start <ShowSortArrow columnFieldName="ActivityStartDate" sortConfig={sortConfig} /></span>
                                        <span className="bold student-qualifications__competency--field-4 table-column" onClick={() => requestSort("ActivityEndDate")}>Finish <ShowSortArrow columnFieldName="ActivityEndDate" sortConfig={sortConfig} /></span>
                                        <span className="bold student-qualifications__competency--field-5 table-column" onClick={() => requestSort("Result")}>Result <ShowSortArrow columnFieldName="Result" sortConfig={sortConfig} /></span>
                                    </div>
                                    <div>
                                        {
                                        sortedCompetencies.map((competency, index) => {                                       
                                            return(
                                                qualification.Id === competency.StudentQualificationId &&
                                                <div key={index} className="collection-item student-qualifications__competency student-qualifications__border-row" style={{paddingTop:userRole==='Student'?'1rem':'0', paddingBottom:userRole==='Student'?'1rem':'0'}}>
                                                    <span className="student-qualifications__competency--field-1">{competency.Competency.CompID}</span>
                                                    <span className="student-qualifications__competency--field-2">{competency.Competency.compDsc}</span>
                                                    {
                                                        userRole === 'Student'?
                                                        <span className="student-qualifications__competency--field-3">{dateTimeToDateString(competency.ActivityStartDate, 2)}</span>
                                                        :<span className="student-qualifications__competency--field-3">
                                                            <DatePickerComp tabindex ="1" name="startDate" defaultDate={Date.parse(dateTimeToDateString(competency.ActivityStartDate, 1))}  handleDateUpdate={handleChange} object={competency} index={index}/>
                                                        </span>
                                                   }
                                                     
                                                    {
                                                       userRole === 'Student'?
                                                       <span className="student-qualifications__competency--field-4">{dateTimeToDateString(competency.ActivityEndDate, 2)}</span>
                                                       :<span className="student-qualifications__competency--field-4">
                                                           <DatePickerComp tabindex ="2" name="finishDate" defaultDate={Date.parse(dateTimeToDateString(competency.ActivityEndDate, 1))}  handleDateUpdate={handleChange} object={competency}  index={index}/>
                                                        </span>

                                                    }
                                                    {
                                                        userRole === 'Student'?
                                                            <span className="student-qualifications__competency--field-5">{competency.Result.Result}</span>
                                                            :<span className="student-qualifications__competency--field-5">
                                                                <select id="result" onChange={(e) => handleChange(e, "", competency, index)} autoComplete="new-password" name="Result">
                                                                    <option  value="" disabled selected>{competency.Result.Result}</option>
                                                                    {                          
                                                                        results.map((result, i) => {
                                                                            return <option key={i} defaultValue={result.Id} value={result.Id} >{result.Result}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </span>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                                <br/><br/>
                            </div>
                        )}
                        )
                    }
                </div>
                :null
            }

            {/* Pending qualifications - *** only show if there are any */}
        
           {
                pendingQuals.length ?
                <div>
                    <br/>
                    <h5 className="mbh-green-text">Pending Qualifications</h5>
                    { 
                        pendingQuals.map((pending, index) => 
                        pending.QuoteId===null?
                        <div className="student-qualifications__pending" key={index}> 
                            <h6 key={pending.Id}>{pending.Qualification.AntaId} {pending.Qualification.Name} awaiting quote. </h6>
                            <div className="student-qualifications__pending--button-container">
                                {
                                    userRole === 'Administrator'?
                                    <div className="student-qualifications__pending--manual-quote">
                                        <div className="input-field">
                                            <input id="tuitionFee" type="text" defaultValue={pending.TuitionFee ? pending.TuitionFee : ""} name="TuitionFee" autoComplete="new-password"/>
                                            <label htmlFor="tuitionFee">Tuition Fee</label>
                                        </div>
                                        <div className="input-field">
                                            <input id="quoteId" type="text" defaultValue = {pending.QuoteId} name="QuoteId" autoComplete="new-password" />
                                            <label htmlFor="quoteId">Quote Id</label>
                                        </div>
                                        <button className="btn mbh-green student-qualifications__pending--btn" onClick={() => handleGenerateQuote(pending)}>Generate</button>
                                    </div>
                                    :null
                                }
                            
                                <button className="btn mbh-red student-qualifications__pending--btn" onClick={() => handleCancelQuote(pending)}>Cancel</button>
                            </div>
                        </div>
                        :
                        <div className="student-qualifications__pending" key={index}> 
                            <p>The quote for <strong>{pending.Qualification.AntaId} {pending.Qualification.Name}</strong> is ${pending.TuitionFee ? pending.TuitionFee.toFixed(2) : '0'} </p>
                            <div className="student-qualifications__pending--btn-group">
                                <button className="btn mbh-green student-qualifications__pending--btn" onClick={() => handleAcceptQuote(pending)}>Accept Quote</button>
                                <button className="btn mbh-red student-qualifications__pending--btn" onClick={() => handleCancelQuote(pending)}>Cancel</button>
                            </div>
                        </div>
                        )
                    }
                </div>
                :null
           }

            <br/><br/>

            {/*** REGISTER FOR A NEW QUALIFICATION ***/}
            {
            isLoadingAll?
            <img src={Spinner} alt=""/>
            :
                <div className="student-qualifications__add-qual">
                <h5 className="mbh-green-text">Register a New Qualification</h5>
                    {   <div className="row">
                            <div className="input-field col s12 l8">
                                <select name="qualName" onChange={handleQualChange}>
                                    <option value="" disabled selected>Choose a qualification</option>
                                    { 
                                        filteredQuals.map((qualification, i) => {
                                        return <option key={i} defaultValue={qualification.Id} value={qualification.Id}>{qualification.AntaId} {qualification.Name}</option>
                                        })
                                        }
                                </select>
                                <label></label>
                            </div>
                            <div className="input-field col s12 l4" id="QualFundingPanel" style={{display:"none"}}>
                                <select id="QualFunding" name="qualFunding" >
                                    <option defaultValue="" disabled selected>Choose a Funding Option</option>
                                    <option defaultValue="" value="Government Funded">Government Funded</option>
                                    <option defaultValue="" value="Self Funded">Self Funded</option>
                                    <option defaultValue="" value="Don't know yet">Don't know yet</option>
                                </select>
                                <label></label>
                            </div>
                        </div>
                    }
                    {
                        qualificationDetails.length?
                        <div className="student-qualifications__description">                    
                            <a className="modal-trigger mbh-green-text white btn" href="#modal1"><i className="material-icons right mbh-green-text">description</i>Description</a>
                            <div id="modal1" className="modal student-training-plan__modal">
                                <div className="modal-content">
                                    <h6 className="mbh-green-text">Description</h6>
                                    <div dangerouslySetInnerHTML={{__html: qualificationDetails[0].Description}}></div>
                                    <br/>
                                </div>
                                <div className="modal-footer">
                                    <button className="modal-close mbh-green-text btn-flat">OK</button>
                                </div>
                            </div>
                        </div>
                        :null
                    }
                
                    <br/><br/>
                    <button className="btn right" style={{ display: qualSelected?'block':'none'}} onClick={() => handleRegisterQual(currentStudentId, qualSelected)}>Register</button>
                    <br/><br/>
                    <p id="regQualMessage" style={{ display: qualRegistered?'block':'none'}} className="right">Qualification now registered and awaiting quote!</p>
                </div>
            }   
           
        </div>
    )
}

export default StudentQualifications
