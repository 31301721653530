import React from 'react'
import TeamSpirit from '../../assets/illustrations/team-spirit.svg'
import YouTubeTutorial from '../../assets/illustrations/tutorial.svg'
import Graduation from '../../assets/illustrations/graduation.svg'
import CareerProgress from '../../assets/illustrations/career-progress.svg'
import ProjectTeam from '../../assets/illustrations/project-team.svg'

const StageTwoCertIIIBizAdmin = ({ history }) => {
    return (
        <>
        <section className="career-pathways__stage-two--section">
                <article className="career-pathways__stage-two--subheadings">
                    <h3 id="path-two">Pathway to Uni or Workplace Training</h3>
                    <h4 id="path-two-stage-one"><span className="mbh-green-text bold">Stage One</span><br/><br/>BSB30120 Certificate III in Business</h4>                                 
                </article>
            </section>
            <section className="career-pathways__stage-two--pathway-section">
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={YouTubeTutorial} alt="YouTube Tutorial" />
                    </figure>
                    <p>Attend Certificate III Qualifications face-to-face and online</p>
                </article>
                <hr />
                <article className="career-pathways__article reverse-flex">
                    <p>Mentored through real life assessments</p>
                    <figure>
                        <img className="responsive-img" src={TeamSpirit} alt="Team Spirit" />
                    </figure>
                </article>
                <hr />
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={ProjectTeam} alt="Graduation" />
                    </figure>
                    <p>Mentor Cert II people through their assessments</p>
                </article>
                <hr />
                <article className="career-pathways__article career-pathways__graduation">
                    <p>Gain your qualification</p>
                    <figure>
                        <img className="responsive-img" src={Graduation} alt="Graduation" />
                    </figure>
                    <button className="btn mbh-green" onClick={() => history.push('/qualifications/certificate-III-in-business')}>View Qualification</button>  
                </article>
                <hr />
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={CareerProgress} alt="Career Progress" />
                    </figure>
                    <p>Support by us for promotion or a new job then move into uni or push on to project management</p>
                </article>
                <hr />
            </section>
        </>
    )
}

export default StageTwoCertIIIBizAdmin
