import React from 'react';
import './Value.scss';
import Management from '../../assets/illustrations/management.svg'
import HomePageButtons from '../HomePageButtons/HomePageButtons';


const Value = ({ history }) => {
    return (
        <section className="value center">
            <h3>What do we do?</h3>
            <article className="value__container container">
                <article className="value__article">
                    <p className="value__p">
                        No job? Not currently working in projects? Don't worry, we work with a variety of industry partners 
                        (e.g. employment agencies, local employers, community groups and associations) to create community 
                        based projects for you to obtain the qualification that gets you in the door and your career on the 
                        upward path. Check out our career pathways or contact us for more.
                    </p>
                    <HomePageButtons history={history} />
                </article>
                <figure className="value__fig">
                    <img src={Management} className="responsive-img" alt="Management illustration from undraw" />
                </figure>
            </article>
        </section>
    );
}

export default Value;