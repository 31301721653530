import React, { useEffect } from 'react'
import StageOne from '../../components/StageOne/StageOne'
import PathOneStages from '../../components/PathOneStages/PathOneStages'
import M from 'materialize-css'

import './CareerPathways.scss'
import PathToUniOrWorkplace from '../../components/PathToUniOrWorkplace/PathToUniOrWorkplace'

const CareerPathways = ({ history }) => {

    var pathOne, pathOneStageOne, pathOneStageTwo, pathOneStageThree, pathOneStageFour, 
        pathTwo, pathTwoStageOne, pathTwoStageTwo, pathTwoStageThree;

    useEffect(() => {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, {});
        pathOne = document.getElementById('path-one')
        pathOneStageOne = document.getElementById('path-one-stage-one')
        pathOneStageTwo = document.getElementById('path-one-stage-two')
        pathOneStageThree = document.getElementById("path-one-stage-three")
        pathOneStageFour = document.getElementById("path-one-stage-four")

        pathTwo = document.getElementById('path-two')
        pathTwoStageOne = document.getElementById('path-two-stage-one')
        pathTwoStageTwo = document.getElementById('path-two-stage-two')
        pathTwoStageThree = document.getElementById("path-two-stage-three")
    }, [])

    const showHideSubStages = pathSelected => {
        const pathOneStages = document.querySelectorAll('.path-one')
        const pathTwoStages = document.querySelectorAll('.path-two')

        if (pathSelected === 'path-one') {
            if (pathOneStages[0].classList.contains('hide')) {
                pathOneStages.forEach(stage => stage.classList.remove('hide'))
            } else {
                pathOneStages.forEach(stage => stage.classList.add('hide'))
            }

            // Hide Path Two sub stages if they aren't already
            if (!pathTwoStages[0].classList.contains('hide')) {
                pathTwoStages.forEach(stage => stage.classList.add('hide'))
            }

        } else {
            if (pathTwoStages[0].classList.contains('hide')) {
                pathTwoStages.forEach(stage => stage.classList.remove('hide'))
            } else {
                pathTwoStages.forEach(stage => stage.classList.add('hide'))
            }

            // Hide path one stages if they aren't already
            if (!pathOneStages[0].classList.contains('hide')) {
                pathOneStages.forEach(stage => stage.classList.add('hide'))
            }
        }
    }

    const onStageSelect = (stageToScrollTo, pathSelected) => {
        stageToScrollTo.scrollIntoView({behavior: "smooth"})
        showHideSubStages(pathSelected)
    }
    
    return (
        <section className="career-pathways-containing-section">
            <div id="scrollNav" className="col hide-on-small-only m3 l2">
                <ul className="section table-of-contents">
                    <li className="stage" onClick={() => onStageSelect(pathOne, 'path-one')}>Pathway to Project Management Glory</li>
                        <li className="stage sub-stage path-one hide" onClick={() => onStageSelect(pathOneStageOne, 'path-one')}>Stage One<br /><span className="career-pathways-containing-section__item">BSB20120 Certificate II in Workplace Skills</span></li>
                        <li className="stage sub-stage path-one hide" onClick={() => onStageSelect(pathOneStageTwo, 'path-one')}>Stage Two<br /><span className="career-pathways-containing-section__item">BSB30120 Certificate III in Business</span></li>
                        <li className="stage sub-stage path-one hide" onClick={() => onStageSelect(pathOneStageThree, 'path-one')}>Stage Three<br /><span className="career-pathways-containing-section__item">BSB40920 Certificate IV in Project Management Practice</span></li>
                        <li className="stage sub-stage path-one hide" onClick={() => onStageSelect(pathOneStageFour, 'path-one')}>Stage Four<br /><span className="career-pathways-containing-section__item">BSB50820 Diploma of Project Management</span></li>
                    <li className="stage" onClick={() => onStageSelect(pathTwo, 'path-two')}>Pathway to Uni or Workplace Training</li>
                        <li className="stage sub-stage path-two hide" onClick={() => onStageSelect(pathTwoStageOne, 'path-two')}>Stage One<br /><span className="career-pathways-containing-section__item">BSB30120 Certificate III in Business</span></li>
                        <li className="stage sub-stage path-two hide" onClick={() => onStageSelect(pathTwoStageTwo, 'path-two')}>Stage Two<br /><span className="career-pathways-containing-section__item">University</span></li>
                        <li className="stage sub-stage path-two hide" onClick={() => onStageSelect(pathTwoStageThree, 'path-two')}>Stage Three<br /><span className="career-pathways-containing-section__item">Certificate IV in Workplace Training</span></li>
                </ul>
            </div>
            <section className="career-pathways container">
                <h2>Career Pathways</h2>
                <article className="career-pathways__btn-container">
                    <button className="btn mbh-black lighten-4 mbh-black-text" onClick={() => history.push("/smart-and-skilled")}>Smart and Skilled</button>
                </article>
                <StageOne history={history} />
                <PathOneStages history={history} />
                <PathToUniOrWorkplace history={history} />
            </section>
        </section>
    )
}

export default CareerPathways
