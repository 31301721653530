import React from 'react'
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../Footer/Footer'
import Guide from '../Guide/Guide';

const RenderRoute = ({ Component, history, renderType, componentType }) => {
    return (
        <>
            {
                renderType === 'All' ?
                <>
                <NavBar history={history} />
                {/* This will turn into whatever component gets passed in */}
                {
                    Component
                }
                <Guide history={history} />
                <Footer history={history} />
                </>
                :
                <>
                    <NavBar history={history} />
                    {
                        Component
                    }
                    <Footer history={history} />
                </>
            }
            
        </>
    )
}

export default RenderRoute
