import React from 'react'
import TeamSpirit from '../../assets/illustrations/team-spirit.svg'
import YouTubeTutorial from '../../assets/illustrations/tutorial.svg'
import Graduation from '../../assets/illustrations/graduation.svg'

const StageOne = ({ history }) => {
    return (
        <section className="career-pathways__stages">
            <article className="career-pathways__stage-one--headings">
                <h3 id="path-one">Pathway to Project Management Glory</h3>
                <h4 id="path-one-stage-one"><span className="mbh-green-text bold">Stage One</span><br/><br/>BSB20120 Certificate II in Workplace Skills</h4>
            </article>
            <section className="career-pathways__stage-one--section">
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={YouTubeTutorial} alt="YouTube Tutorial" />
                    </figure>
                    <p>Learn face to face and online</p>
                </article>
                <hr />
                <article className="career-pathways__article reverse-flex">
                    <p>Be supported by peers and mentored by project management students as well as mbh staff through a real life project for your assessment</p>
                    <figure>
                        <img className="responsive-img" src={TeamSpirit} alt="Team Spirit" />
                    </figure>
                </article>
                <hr />
                <article className="career-pathways__article career-pathways__graduation">
                    <p>Gain your qualification</p>
                    <figure>
                        <img className="responsive-img" src={Graduation} alt="Graduation" />
                    </figure>
                    <button className="btn" onClick={() => history.push('/qualifications/certificate-II-in-workplace-skills')}>View Qualification</button>
                </article>
                <hr />
            </section>
        </section>
    )
}

export default StageOne
