const stateToCode = {
    "NSW": "01", 
    "VIC": "02", 
    "QLD": "03", 
    "SA": "04",
    "WA": "05",
    "TAS": "06", 
    "NT": "07", 
    "ACT": "08"
}

// This is for updating the info from the registration form

export const updateResidentialAddress = (contactObject) => {
    setTimeout(() => { 
        const street = document.getElementById('AddressStreet')
        
        if (street.defaultValue.length) {
            contactObject["AddressStreet"] = document.getElementById('AddressStreet').value
            contactObject["AddressStreetNo"] = document.getElementById('addressStreetNo').value
            contactObject["Suburb"] = document.getElementById('suburb').value
            contactObject["Postcode"] = document.getElementById('postcode').value
            contactObject["AddressUnitNo"] = document.getElementById('addressUnitNo').value
            contactObject["State"] = stateToCode[document.getElementsByClassName('dropdown-trigger')[0].value];
        }

    }, 500);
}

export const updatePostalAddress = (contactObject) => {
    setTimeout(() => { 
        const postalStreet = document.getElementById('postalAddressStreet')
        
        if (postalStreet.defaultValue.length) {

            contactObject["AddressStreet_Pos"] = document.getElementById('postalAddressStreet').value
            contactObject["AddressStreetNo_Pos"] = document.getElementById('postalAddressStreetNo').value
            contactObject["Suburb_Pos"] = document.getElementById('postalSuburb').value
            contactObject["Postcode_Pos"] = document.getElementById('postalPostcode').value
            contactObject["AddressUnitNo_Pos"] = document.getElementById('postalAddressUnitNo').value
            contactObject["State_Pos"] = stateToCode[document.getElementsByClassName('dropdown-trigger')[1].value];

        }
    }, 500);
}

export const copyResidentialIntoPostal = (contactObject) => {
    
        const residentialStreet = document.getElementById('AddressStreet')
        
        if (residentialStreet.defaultValue.length) {
            contactObject["AddressStreet_Pos"] = document.getElementById('AddressStreet').value
            contactObject["AddressStreetNo_Pos"] = document.getElementById('addressStreetNo').value
            contactObject["Suburb_Pos"] = document.getElementById('suburb').value
            contactObject["Postcode_Pos"] = document.getElementById('postcode').value
            contactObject["AddressUnitNo_Pos"] = document.getElementById('addressUnitNo').value
            contactObject["State_Pos"] = stateToCode[document.getElementsByClassName('dropdown-trigger')[0].value];

        }
   
}


// This is for updating information in the student portal

export const updateStudentResidentialAddress = (url, token, detailsToUpdate, setRefreshAddressState) => {
    setTimeout(() => { 
        const residentialStreet = document.getElementById('AddressStreet')
        
        if (residentialStreet.defaultValue.length) {
            detailsToUpdate["AddressStreet"] = document.getElementById('AddressStreet').value
            detailsToUpdate["AddressStreetNo"] = document.getElementById('addressStreetNo').value
            detailsToUpdate["Suburb"] = document.getElementById('suburb').value
            detailsToUpdate["Postcode"] = document.getElementById('postcode').value
            detailsToUpdate["AddressUnitNo"] = document.getElementById('addressUnitNo').value
            detailsToUpdate["State"] = document.getElementById('HiddenState').value;
           
            fetch(url, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
                body: JSON.stringify(detailsToUpdate)
            })
            .then(response => response.json())
            .catch(err => console.log(err))

            setRefreshAddressState(true)
        }
    }, 500);


}

export const updateStudentPostalAddress = (url, token, detailsToUpdate, setRefreshAddressState) => {
    setTimeout(() => { 
        const postalStreet = document.getElementById('postalAddressStreet')
        
        if (postalStreet.defaultValue.length) {
            detailsToUpdate["AddressStreet_Pos"] = document.getElementById('postalAddressStreet').value
            detailsToUpdate["AddressStreetNo_Pos"] = document.getElementById('postalAddressStreetNo').value
            detailsToUpdate["Suburb_Pos"] = document.getElementById('postalSuburb').value
            detailsToUpdate["Postcode_Pos"] = document.getElementById('postalPostcode').value
            detailsToUpdate["AddressUnitNo_Pos"] = document.getElementById('postalAddressUnitNo').value
            detailsToUpdate["State_Pos"] = document.getElementById('HiddenStatePos').value;

            fetch(url, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(detailsToUpdate)
            })
            .then(response => response.json())
            .catch(err => console.log(err))

            setRefreshAddressState(true)
        }

    }, 500);
}

export const copyStudentResidentialIntoPostal = (url, token, detailsToUpdate) => {
    const residentialStreet = document.getElementById('AddressStreet')
        
    if (residentialStreet.defaultValue.length) {
        detailsToUpdate["AddressStreet_Pos"] = document.getElementById('AddressStreet').value
        detailsToUpdate["AddressStreetNo_Pos"] = document.getElementById('addressStreetNo').value
        detailsToUpdate["Suburb_Pos"] = document.getElementById('suburb').value
        detailsToUpdate["Postcode_Pos"] = document.getElementById('postcode').value
        detailsToUpdate["AddressUnitNo_Pos"] = document.getElementById('addressUnitNo').value
        detailsToUpdate["State_Pos"] = stateToCode[document.getElementsByClassName('dropdown-trigger')[0].value];

        fetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(detailsToUpdate)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }
}
