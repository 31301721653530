import React, { useState, useEffect } from 'react'
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName'
import M from 'materialize-css';

const EventsAssignQual = ({ qual, emailToUse, studentQuals, onStudentSelect }) => {

    const [associatedQuals, setAssociatedQuals] = useState([])
    const [showAssignQualDropdown, setShowAssignQualDropdown] = useState(false)
    const [isStudentAdded, setIsStudentAdded] = useState(false)

    useEffect(() => {
        if (studentQuals.length) {
            const studentId = qual.Student.Id
            const filteredArray = studentQuals.filter(qual => qual.Student.Id === studentId)
            setAssociatedQuals(filteredArray)
        }
    }, [studentQuals])

    // Initialise dropdown
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    });

    const handleAssignQual = () => setShowAssignQualDropdown(!showAssignQualDropdown)

    return (
        <div className="event-create-group__student-list--student-card-container">
            {
                isStudentAdded ?
                <button className="btn-floating mbh-red" onClick={(e) => onStudentSelect(e, qual.Student, setIsStudentAdded, 'delete')}><i className="small material-icons">close</i></button>
                :
                <button className="btn-floating mbh-green" onClick={(e) => onStudentSelect(e, qual.Student, setIsStudentAdded, 'add')}><i className="small material-icons">add</i></button>

            }
            <div key={qual.Student.Id} className="center-align event-create-group__student-list--student-card">
                <h6 className="mbh-green-text">{`${qual.Student.FirstName} ${qual.Student.Surname}`}</h6>
                <p>{emailToUse ? `<${emailToUse}>` : "No Email Provided"}</p>
            </div>
            
                {   
                    <div className="event-create-group__student-list--student-card-container__dropdown-container">
                        {
                            associatedQuals.length ?
                            (
                                associatedQuals.length === 1 ?
                                <div className="center-align">
                                    <h6 className="mbh-green-text">Associated Qual:</h6>
                                    <br />
                                    <p>{associatedQuals[0].Qualification.AntaId} {capitaliseEachWordOfQualName(associatedQuals[0].Qualification)}</p>
                                </div>
                                :
                                (
                                    !showAssignQualDropdown ?
                                    <button className="btn-floating mbh-green" onClick={handleAssignQual}><i className="material-icons notextselect">assignment</i></button>
                                    :
                                    (
                                        <>
                                            <h6 className="mbh-green-text">Associated Qual:</h6>
                                            <div className="event-create-group__student-list--student-card-container__dropdown-container--dropdown">
                                                <select id="StudentQualSelect" value="0">
                                                    <option value="0" disabled>Select a Qualification</option>
                                                    {
                                                        associatedQuals.map(qual => (
                                                            <option key={qual.Id} value={qual.Id}>{qual.Qualification.AntaId} {capitaliseEachWordOfQualName(qual.Qualification)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </>
                                    )
                                )
                            )
                            :
                            <p>No associated quals</p>
                            
                            
                        }
                    </div>

                }   
            

        </div>

    )
}

export default EventsAssignQual
