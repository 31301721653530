import React from 'react'
import Certification from '../../assets/illustrations/certification.svg'
import ViewOtherCourses from '../../components/ViewOtherCourses/ViewOtherCourses'
import TeamSpirit from '../../assets/illustrations/team-spirit.svg'
import YouTubeTutorial from '../../assets/illustrations/tutorial.svg'
import Graduation from '../../assets/illustrations/graduation.svg'
import CareerProgress from '../../assets/illustrations/career-progress.svg'
import ProjectTeam from '../../assets/illustrations/project-team.svg'
import '../DiplomaInPM/DiplomaInPM.scss'

const CertIIIBizAdminEdu = ({ history }) => {
    return (
        <section className="diplomaInPM">
            <h2>Certificate III in Business Administration (Education)</h2>
            <article className="diplomaInPM__article">
                <figure className="diplomaInPM__figure">
                    <img src={Certification} alt="Certification unDraw illustration" className="responsive-img" />
                </figure>
                <article className="diplomaInPM__description">
                    <p>The qualification includes fundamentals of workplace activities and then spans all phases of a project from concept 
                        through to close-out. You will be allocated a role in the project and inherit its associated responsibilities across 
                        the project lifecycle.
                    </p>
                    <div className="diplomaInPM__btn-group">
                        <button className="btn mbh-black lighten-4 mbh-black-text diplomaInPM__btn-group--1" onClick={() => history.push('/contact-us')}>Contact Us</button>
                        <button className="btn mbh-green diplomaInPM__btn-group--2" onClick={() => history.push('/register')}>Register</button>
                    </div>
                </article>
            </article>
            <article className="diplomaInPM__involve">
                <h3>What does it involve?</h3>
                <p>
                    The unique element of mbh's delivery of qualifcation content is the matching of Cert II, Cert III, Cert IV and Diploma
                    level people on the one qualification program. This enables more experienced learners to practice mentoring and leading project 
                    teams while they work on a real world project with their less experienced fellow students.
                    <br /><br />
                    The core knowledge transfer elements of mbh's qualifications provide you with a range of fundamental skills, theoretical 
                    knowledge and practical applications to assist you in participating in business, working in a project team and/or managing 
                    the project as its leader. The content has been developed by experienced practitioners with over 50 years of combined 
                    experience in business management, project management and directorships of organisations.                    
                    <br /><br />
                    It will assist with your understanding of business and project management terminology and methodologies.  Project teams 
                    are the engine room for meeting the objectives for an organisation. Additional focus is made on human interactions termed 
                    as stakeholder and communications management.                
                </p>
            </article>
            <section className="diplomaInPM__course-details">
                <article className="diplomaInPM__course-details--article column-reverse-flex">
                    <article>
                        <p>Attend Cert III qualification face-to-face and online</p>
                    </article>
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={YouTubeTutorial} alt="YouTube Tutorial" />
                    </figure>
                </article>
                <hr />
                <article className="diplomaInPM__course-details--article">
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={TeamSpirit} alt="Team Spirit" />
                    </figure>
                    <article>
                        <p>Mentored through real life assessments</p>
                    </article>
                </article>
                <hr />
                <article className="diplomaInPM__course-details--article column-reverse-flex">
                    <article>
                        <p>Mentor Cert II people through their assessments</p>
                    </article>
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={ProjectTeam} alt="Graduation" />
                    </figure>
                </article>
                <hr />
                <article className="diplomaInPM__course-details--article">
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={Graduation} alt="Graduation" />
                    </figure>
                    <article>
                        <p>Gain your qualification</p>
                    </article>
                </article>
                <hr />
                <article className="diplomaInPM__course-details--article column-reverse-flex">
                    <article>
                        <p>Support by us for promotion or a new job then move into uni or push on to project management</p>
                    </article>
                    <figure className="diplomaInPM__course-details--figure">
                        <img className="responsive-img" src={CareerProgress} alt="Career Progress" />
                    </figure>
                </article>
                <hr />
            </section>
            <ViewOtherCourses history={history} />
        </section>
    )
}

export default CertIIIBizAdminEdu
