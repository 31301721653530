import React, {useState, useEffect} from 'react'
import './LogIn.scss';
import { baseUrl } from '../../functions/globalVariables';
import { Redirect } from 'react-router-dom';
import FormSpinner from '../../assets/form-spinner.svg'
import qs from 'qs'

const LogIn = ({ history }) => {

    const [isLoggingIn, setIsLoggingIn] = useState(false)

    const [hasUserForgottenPassword, setHasUserForgttenPassword] = useState(false)

    const isAuth = !!sessionStorage.getItem('mbhToken')
    let redirectUrl = '/student-portal'
    const userRole = window.sessionStorage.getItem('mbhUserRole')

    if (userRole === 'Administrator') {
        redirectUrl = '/admin-portal'
    } else {
        redirectUrl = '/student-portal'
    }

    const loginUser = (e) => {

        console.time("loginUser")

        e.preventDefault();

        const email = document.getElementById('email').value
        const password = document.getElementById('password').value

        if (!email.trim().length || !password.trim().length) {
            setIsLoggingIn(false)
            return;
        }
        
        var details = {
            'username': email,
            'password': password,
            'grant_type': 'password'
        };

        const errorMsgEl = document.getElementById("logInErrorMsg");
        
        fetch(`${baseUrl}/api/auth/token`, {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: qs.stringify(details)
        })
        .then(response =>  response.json())
        .then(data => {
            //if there is no error, save the studentid and token to sessionStorage and redirect to student portal. Otherwise show error.
            
            if(data.error === undefined){
                window.sessionStorage.setItem('mbhToken', data.access_token);
                window.sessionStorage.setItem('mbhStudentId', data.StudentId);
                window.sessionStorage.setItem('mbhUserRole', data.Role);
                window.sessionStorage.setItem('mbhUserName', data.userName);
                errorMsgEl.style.display = "none";

                if (window.sessionStorage.getItem('mbhUserRole') === 'Administrator') {
                    redirectUrl = '/admin-portal'
                    history.push(redirectUrl)
                } else {
                    redirectUrl = '/student-portal/training-plan'
                    history.push(redirectUrl)
                }
                
                setIsLoggingIn(false)
                

            } else{
                errorMsgEl.style.display = "block";
                errorMsgEl.textContent = data.error_description;
                setIsLoggingIn(false)
            }

            console.timeEnd("loginUser")
        })
        .catch(err => {
            console.log(err);
            errorMsgEl.style.display = "block";
            errorMsgEl.textContent = "Something went wrong";
            setIsLoggingIn(false);

        })
    }

    return (
       
        isAuth ?  
        <Redirect to={redirectUrl} />
        : 
        <section className="logIn">
            <div className="logIn__card container">
                <div className="logIn__card--register">
                    <p>Don't have an account?</p>
                    <button id="register-btn" className="btn-large transparent mbh-green-text" 
                            style={{ boxShadow: 'none' }}
                            onClick={() => history.push('/register')
                        }
                    >
                        Register
                    </button>
                </div>
                <h3 className="center">Log In</h3>
                <form>
                    <div className="row logIn__row">
                        <div className="input-field col s12">
                            <input id="email" type="email" className="validate" />
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="password" type="password" className="validate" />
                            <label htmlFor="password">Password</label>
                        </div>
                        <span id="logInErrorMsg" style={{display:"none", color: "red"}}>Error text</span>
                    </div>
                    <div className="logIn__button-container">
                        {
                            isLoggingIn ?
                            <figure>
                                <img src={FormSpinner} alt="Form spinner" />
                            </figure>
                            :
                            <button className="btn-large mbh-green" onClick={(e) => {setIsLoggingIn(true); loginUser(e)}}>Log In</button>
                        }
                        <button className="btn-large transparent mbh-green-text" 
                                    style={{ boxShadow: 'none' }}
                                    onClick={() => history.push("/forgot-password")}
                            >Forgot password</button>
                    </div>
                    <hr />
                    <button className="btn-large transparent mbh-green-text" 
                            style={{ boxShadow: 'none' }}
                            onClick={() => history.push('/privacy')
                        }
                    >Privacy</button>
                </form>
            </div>
        </section>
        
    )
}

export default LogIn
