
import React from 'react'
import { Editor } from '@tinymce/tinymce-react';

//This is being used in EditQualifications.jsx to update the qualification description
const TextEditor = ({id, name, initValue, rowIndex, updateDescriptionField, handleDescriptionChange, descriptionField, addOrEditQual, object}) => {

    const handleEditorChange = (content) => {
      if (addOrEditQual === 'edit') {
        updateDescriptionField(rowIndex, content, descriptionField);

      } else if (addOrEditQual === 'add') {
        handleDescriptionChange(name, content)
      } else if (addOrEditQual === 'createEvent') {
        console.log(name, content)
      } else if (addOrEditQual === 'comments'){
        handleDescriptionChange(content, object)
      }
    }

    return (
        <Editor
        id={id}
        textareaName={name}
        apiKey='m5prz8cni9i2jpps17uh57guhzgk7hbsf8320rxcop7wka2m'
        initialValue={initValue}
        init={{
          height: 300,
          menubar: false,
          inline: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | link | help'
        }}
        onEditorChange={(newText) => handleEditorChange(newText)}
      />
    )
}

export default TextEditor