import React from 'react'
import Benevolent from '../../assets/backup_logos/benevolent.PNG'
import Optus from '../../assets/backup_logos/optus.png'
import SmithFamily from '../../assets/backup_logos/smith-family.PNG'
import Uniphi from '../../assets/backup_logos/uniphi.png'
import Shoalhaven from '../../assets/logos/shoalhaven.gif'
import Westpac from '../../assets/logos/westpac.gif'
import CBRE from '../../assets/backup_logos/CBRE.PNG'

import './Clients.scss'


const Clients = () => {
    return (
        <div className="clients container">
            <h3 className="center">Our Clients</h3>
            <div className="clients__body">
                <figure className="clients__fig">
                    <img src={Optus} className="responsive-img" alt="Optus logo" />
                </figure>
                <figure className="clients__fig">
                    <img src={SmithFamily} className="responsive-img" alt="Smith Family logo"/>
                </figure>
                <figure className="clients__fig">
                    <img src={Uniphi} className="responsive-img" alt="UniPhi logo" />
                </figure>
                <figure className="clients__fig">
                    <img src={Shoalhaven} className="responsive-img" alt="Shoalhaven logo"/>
                </figure>
                <figure className="clients__fig">
                    <img src={Westpac} className="responsive-img" alt="Westpac logo" />
                </figure>
                <figure className="clients__fig">
                    <img src={CBRE} className="responsive-img" alt="CBRE logo" />
                </figure>
                <figure className="clients__fig clients__fig--7">
                    <img src={Benevolent} className="responsive-img" alt="Benevolent logo" />
                </figure>
            </div>
        </div>
    )
}

export default Clients