import React from 'react'
import './Conditions.scss';

const Conditions = ({ dispatch, setSelectedTestimonials }) => {

    return (
        <div className="conditions">
            <div className="conditions__body container">
                <h3 className="conditions__heading ">Enrolment Conditions</h3>
                <h5 className="mbh-green-text conditions__subheading">How to Enrol</h5>
                <p>You can register by contacting mbh course co-ordinator on 1300 79 48 68 or <a href="https://prerelease.mbhtraining.com.au/#/register">register online now</a></p>
                <h5 className="mbh-green-text conditions__subheading">Confirmation of Enrolment</h5>
                <p>A confirmation of enrolment will be forwarded to you via email. If you have not received confirmation of your enrolment 5 days before the qualification starts, please contact mbh to ensure that you are enrolled.</p>
                <h5 className="mbh-green-text conditions__subheading">Cancellations and Reschedules</h5>
                <p> 
                    All monies will be refunded if notice of cancellation of qualification attendance is made one week prior to qualification commencement.
                    <br/><br/>
                    If a cancellation is made inside one week then a qualification credit is applied for attendance at a later date. Once qualification attendance has been made, no refunds are applicable if assessments are not completed.
                </p>
                <h5 className="mbh-green-text conditions__subheading">Payment</h5>
                <p>
                    All prices, unless otherwise stated, include materials, marking of assessments, luncheons and/or teas and coffees for delegates.
                    <br/><br/>
                    Payments for the entire qualification fee or a deposit of $1,500 (whichever is the lower) is required up front for all mbh qualifications. Final payments are due immediately following the completion of face to face training.
                    <br/><br/>
                    Certificates will not be issued until all outstanding monies have been remitted.
                    <br/><br/>
                    Payment can be made by cheque, credit card or direct bank transfer.
                </p>
                <h5 className="mbh-green-text conditions__subheading">Conditions</h5>
                <p>
                    Unforeseen circumstances may necessitate the appointment of a different lecturer/speaker to those indicated in associated documentation.
                    <br/><br/>
                    mbh reserves the right to cancel or discontinue any qualification for whatever reason. In this event, delegates who may have registered for such a qualification will be informed and all fees will be refunded in full. On some qualifications, the number of seats is limited and acceptance will be based on a first-come, first-served basis.
                    <br/><br/>
                    mbh reserves the right to make changes to the qualification syllabi, schedules, fees and venues to meet changed circumstances. However, mbh will make every effort to communicate any changes to our clients as early as possible.
                </p>
                <h5 className="mbh-green-text conditions__subheading">Unique Student Identifier (USI)</h5>
                <p>
                    January 1st 2015 new legislation requires all students who are studying a nationally recognised training in Australia to apply for a Unique Student Identifier (USI).
                    <br/><br/>
                    It is a requirement that students provide their USI. RTO's cannot issue your certificate unless you have provided your USI.
                    <br/><br/>
                    Your USI will link to an online account where you will be able to access your training records.
                    <br/><br/>
                    To obtain your USI go to <a href="https://www.usi.gov.au/students/get-a-usi" target="_blank" rel="noopener noreferrer">https://www.usi.gov.au/students/get-a-usi</a>
                </p>
            </div>
           
        </div>
    )
}

export default Conditions
