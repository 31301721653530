import { useState, useEffect } from 'react'

export const useFetchStudents = (url, token, shouldFetchData) => {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
        
    useEffect(() => {

        if (!shouldFetchData) {
            return;
        }

        const fetchData = async () => {
           
            setIsLoading(true)
            try {
                let response;
                if (token !== undefined){
                    response = await fetch(url, {headers: {'Authorization': `Bearer ${token}`}})
                }else
                {
                    response = await fetch(url)
                }
                
                const json = await response.json()
                json.value? setData(json.value) : setData(json)
                setIsLoading(false)
            } catch(error) {
                setError(error)
                setIsLoading(false)
            }
        }
        fetchData();
        // return () => ac.abort(); 
    }, [url])

    return [ isLoading, data, error ]

}