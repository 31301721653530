import React from 'react'
import './CodeOfPractice.scss'

const CodeOfPractice = () => {
    return (
        <section className="codeOfPractice container">
            <h3 className="codeOfPractice__heading">Code Of Practice</h3>
            <article className="codeOfPractice__body">

                <p>At mbh, our training qualification adhere to the following standards that have been drafted by AQTF. There are systems in place to ensure quality control is maintained throughout the company to deliver quality services to our clients</p>

                <h5>Systems for quality training and assessment</h5>

                <p>Continuous improvement is an integral part of mbh with systems and procedures in place to ensure communication from clients and staff are captured and reviewed ongoing. Quality control is maintained throughout the company for all areas of the business and all training material and assessments are created using defined learning strategies that are inline with Adult Education principles, industry best practices and AQTF standards.
                </p>

                <h5>Compliance with legislative responsibilities</h5>

                <p>mbh is committed to ensuring the health, safety and welfare of its employees and all other persons. We aim to fulfil our statutory duties with regard to legislation.
                </p>

                <h5>Effective financial management</h5>

                <p>We have in place secure financial practices to ensure the protection of clients and any funds that are paid in advance. mbh complete an annual audit by a qualified CPA</p>

                <h5>Effective administration and records management</h5>

                <p>
                    All student records are deemed confidential and cannot be released to anyone other than the student. However, where a company or entities has paid for the qualification and the student has signed a declaration to give authorisation they may be released. Student data is collected in accordance with AVETMISS and AQTF requirements.
                    
                    Confidentiality is a priority of mbh and all records are maintained in a secure location. Training records are kept for a period of 30 years.
                </p>

                <h5>Recognition of qualifications issued by other RTO’s</h5>

                <p>mbh will recognise other qualifications that have been issued be other RTO’s through out Australia , evidence must be provided and mbh reserve the right to contact the RTO to gain authentication.
                </p>

                <h5>Recognition of prior learning</h5>

                <p>Recognition of Prior Learning can be achieved if a participant can provide the relevant certificates or experiences that cover the competencies that mbh are offering on completion of the qualification and can successfully complete mbh competency tests relating to that qualification.
                </p>

                <h5>Access and equity and client service</h5>

                <p>mbh will where possible ensure access is available to all people regardless of their age, gender, culture and background. Some qualification at mbh require a minimum of HSC level English or equivalent and a high level of numeracy , we may reserve the right to forward you onto other agencies if these standards are not successfully met.
                

                mbh will ensure that clients receive a training booklet that provides information relevant to the qualification and the competencies that can be achieved, including a desired pathway to achieve Certificates and Diplomas in certain areas.

                mbh understand the importance of ensuring the welfare of our clients and staff , we endeavour to provide the following services:
                    <ul className="codeOfPractice__list">
                        <li>- Client support</li>
                        <li>- Grievance and disciplinary procedures</li>
                        <li>- Special need support will be provided to students and staff where applicable</li>
                    </ul>

                </p>
                <h5>The competence of RTO staff</h5>

                <p>Our training staff are assessed on a regular basis to ensure compliance to the training packages and continuing staff development is maintained to the levels of the qualification/assessments they train.
                </p>
                
                <h5>Issuing AQF qualifications and statements of attainments</h5>

                <p>Statement of attainments and certificates will be issued only on successful completion of qualification and assessments as specified by the training packages the RTO adhere to.
                </p>
                
                <h5>Use of national state territory logos</h5>

                <p>These are adhered to by mbh and will only be used as specified by the rules of usage from the company, department or government industry body who issues them.
                </p>

                <h5>Ethical marketing and advertising</h5>

                <p>mbh adhere to the requirements of the AQTF with regards to the advertising material, we are a company that prides itself on it ethical beliefs and our vision.
                </p>
            </article>
        </section>
    )
}

export default CodeOfPractice
