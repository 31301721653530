export const getCurrentQual = (qualId, studentQuals) => {
    const currentQual = studentQuals.filter(qual => qual.QualId === qualId)

    return currentQual[0]
}

export const getQualsAwaitingQuotes = (studentQuals) => {
    const quals = studentQuals.filter(qual => {
        return (
            !qual.Qualification.ConfirmedCost 
            && qual.Qualification.CertificateIssued !== "Yes" 
            && !qual.Qualification.Disabled
        )
    })

    return
}