import React, {useState} from 'react'
import './AdministerStudent.scss'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import M from 'materialize-css'
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';

const AdministerStudent = () => {

    let history = useHistory()
    const { selectedStudentId, authToken } = useThemeContext()
    
    const studentUrl = `${baseUrl}/api/students/${selectedStudentId}`;
    const [isLoadingStudents, currentStudent, error] = useDataFetch(studentUrl, authToken)

    const checkIfRegisteredUrl = `${baseUrl}/api/students/isstudentregistered(Id=${selectedStudentId})`;
    const [isLoadingCheck, checkIfRegistered, errorCheck] = useDataFetch(checkIfRegisteredUrl, authToken)

    const validateNatFormsUrl = `${baseUrl}/api/natforms/validatedates/${selectedStudentId}?$orderBy=AntaId`;
    const [isLoadingValNat, validateNatforms, errorValNat] = useDataFetch(validateNatFormsUrl, authToken)

    const validateSTSUrl = `${baseUrl}/api/stsbulkquotedata/validatedata/${selectedStudentId}`;
    const [isLoadingSTS, validateSTS, errorSTS] = useDataFetch(validateSTSUrl, authToken)

    const [regEmailSent, setRegEmailSent] = useState(false);
   
    const studentObject = {
        "StudentId": selectedStudentId
     }

    const checkAPIResponse = (data, status) => {
        if (status === 200){
            if (data !=="status"){
                M.toast({html: data, classes: 'mbh-green', displayLength: 2000});    
            }     
        }else{
            if(data.Message){
                M.toast({html: data.Message, displayLength: 2000});
            }
        }
    }

    const sendSetPasswordEmail = () => {
        if (!regEmailSent){
            setRegEmailSent(true)
      
            let status;
            document.getElementById("sendRegEmailBtn").classList.remove("administer-student__card--red");
            document.getElementById("sendRegEmailBtn").classList.add("administer-student__card--grey");

            fetch(`${baseUrl}/api/auth/registerstudent`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`
                        },
                body: JSON.stringify(studentObject)
            })
            .then(response => {
                status = response.status;
                checkAPIResponse("status", status)
                return response.json()
            })
            .then(data => checkAPIResponse(data, status))
            .catch(err => console.log("Error: ", err)) 

            setTimeout(function() {
                document.getElementById("sendRegEmailBtn").classList.remove("administer-student__card--grey");
                document.getElementById("sendRegEmailBtn").classList.add("administer-student__card--red");
                setRegEmailSent(false);
            }, 2000);
        }
    }

    return (
        <div className="administer-student row">
            <h3 className="mbh-green-text">Actions for student {currentStudent.FirstName} {currentStudent.Surname}</h3>
            <div className="col m12 l6">

            <div className="administer-student__card administer-student__card--green" onClick={(e) => {history.push('/admin-portal/administer-student/student-profile')}}><i className="material-icons student-portal__menu-icon medium">person</i>View Student Details</div>
            <div className="administer-student__card administer-student__card--green" onClick={(e) => {history.push('/admin-portal/administer-student/student-training-plan')}}><i className="material-icons student-portal__menu-icon medium">assignment</i>View Training Plan</div>
            <div className="administer-student__card administer-student__card--green" onClick={(e) => {history.push('/admin-portal/administer-student/student-qualifications')}}><i className="material-icons student-portal__menu-icon medium">school</i>View Qualifications</div>
            
            {/*** registration email ***/}
            {
                checkIfRegistered === true?
                null
                :<div id="sendRegEmailBtn" className="administer-student__card administer-student__card--red"  onClick={sendSetPasswordEmail}><i className="material-icons student-portal__menu-icon medium">email</i>Send Registration Email</div>
            }
            </div>

            {/*** warning messages ***/}
            {  (validateNatforms.length || validateSTS.length) ? 
                <div className="col m12 l6">
                    <div className="administer-student__card-no-hover administer-student__card-no-hover--red">
                        <div className="administer-student__card-no-hover--heading">                
                            <i className="material-icons student-portal__menu-icon medium">warning</i>Warnings
                        </div>
                        
                        {
                            validateSTS.length ?
                            <div>
                                <h5>STS File potential issues</h5>
                                {
                                    validateSTS.map((qual, index) => (
                                        <div>
                                            <p key={index}>Qualification <strong>{qual.AntaId}</strong>: </p>
                                            {qual.DOB === null && <p className="black-text">- Date of birth missing</p>}
                                            {qual.StartDate === null && <p className="black-text">- Start date is missing</p>}
                                            {qual.EndDate === null && <p className="black-text">- End date is missing</p>}
                                            {qual.ATSI === null && <p className="black-text">- Aboriginal or Torres Strait Islander field missing</p>}
                                            {qual.ResidencyStatusAnswer === null && <p className="black-text">- Residency Status missing</p>}
                                            {qual.WorkInNSWAnswer === null && <p className="black-text">- Do you work in NSW field missing</p>}
                                            {qual.TraineeAnswer === null && <p className="black-text">- Registered NSW apprentice or trainee field missing</p>}
                                            {qual.SocialHousingAnswer === null && <p className="black-text">- Social housing field missing </p>}
                                            {qual.LTUAnswer === null && <p className="black-text">- Unemployed for 52 weeks or more field missing</p>}
                                            <br />
                                        </div>
                                    ))
                                }
                            </div>
                            :null
                        }

                        {  
                            validateNatforms.length ?
                            <div>
                                <h5>NAT File potential issues</h5>
                                    {
                                        validateNatforms.map((issue, index, arr)=>(
                                            <div key={index}> 
                                                {
                                                    index > 0 && issue.AntaId === arr[index-1].AntaId ? null: <p>Qualification <strong>{issue.AntaId}</strong>:</p>
                                                }
                                                <p className="black-text">{issue.CompetencyId}:  <span>{issue.Reason}</span></p>
                                            </div>
                                        ))
                                    }
                                    <br/>
                            </div>
                            :null
                        }

                        
                    </div>     
                </div>
                :null
            }

        </div>
    )
}

export default AdministerStudent
