import React from 'react'

const Checkboxes = ({ currentQuestion, answersForCurrentQuestion, handleVetChange, answerObject, answer }) => {

    return (
        answersForCurrentQuestion.length ?
        <input type="checkbox" 
            name={currentQuestion.Text} 
            data-questionid={answersForCurrentQuestion[0].Id} 
            defaultChecked={answerObject[answer.Id] !== undefined?true:false} 
            className="filled-in" 
            value={answer.Id} 
            onClick={handleVetChange} />
        :
        <input type="checkbox" name={currentQuestion.Text} className="filled-in" value={answer.Id} onChange={handleVetChange} />
        
    )
}

export default Checkboxes
