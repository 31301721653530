import React from 'react';
import './Header.scss';
import Group from '../../assets/illustrations/group.svg'
import HomePageButtons from '../HomePageButtons/HomePageButtons';

const Header = ({ history })=> {
    return(
        <section className="header">
            <div className="header__box">
                <figure className="header__fig">
                    <img src={Group} className="responsive-img" alt="Group illustration from undraw" />
                </figure>
                <div className="header__text">
                    <h2 className="center-align mbh-green-text ">Let us guide your career</h2>
                    <div className="header__info">
                        <p className="header__info--mobile"> 
                        mbh is a registered training provider and has been delivering nationally recognised qualifications to people across all walks of life since 2003.
                        Come and join us at our locations across the Shoalhaven, Illawarra and Greater Sydney. Check out our <span className="hyperlink" onClick={() => history.push('/career-pathways')}>career pathways</span>, call 1300 79 48 68 or <span className="hyperlink" onClick={() => history.push('/contact-us')}>contact us</span> for further information.
                        </p>
                        <p className="header__info--desktop">
                            mbh is a registered training provider and has been delivering nationally recognised qualifications to a diverse range of people since 2003. 
                            mbh's mission is to grow individual careers. We take people from all walks of life and kick start or re-energise their working life.  
                            <br/><br/>
                            The pinnacle point of this is project management but you can start with 
                            the fundamentals of business and work your way up.  We see managing by project as a way of working 
                            unmatched by other methods and project management as the pinnacle management discipline for achieving 
                            goals. 
                            <br/><br/> Come and join us at our locations across the Shoalhaven, Illawarra and Greater Sydney. Check out our <span className="hyperlink" onClick={() => history.push('/career-pathways')}>career pathways</span>, call 1300 79 48 68 or <span className="hyperlink" onClick={() => history.push('/contact-us')}>contact us</span> for further information.
                        </p>
                    </div>
                    <HomePageButtons history={history} />
                </div>
            </div>
        </section>
    );
}

export default Header;