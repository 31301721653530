import React from 'react'
import CareerPathways from '../../assets/career-pathways.gif'
import CareerProgress from '../../assets/illustrations/career-progress.svg'
import StandOut from '../../assets/illustrations/stand-out.svg'
import './WhyMBH.scss'

const WhyMBH = ({ history }) => {
    return (
        <section className="why-mbh container">
            <h2>About Us</h2>
            <article className="why-mbh__blurb">
                <figure>
                    <img src={StandOut} alt="Stand out" className="responsive-img" />
                </figure>
                <p>mbh (M.B.H. Management Pty Ltd) is a Registered Training Organisation and specialises in the improvement of workplace, business and project management capability within the private and public sector. </p>
            </article>
            <h4>Select a pathway to find out more</h4>
            <figure className="why-mbh__figure">
                <img src={CareerPathways} alt="Career pathways" className="responsive-img" usemap="#workmap" />
                {/* First bubble */}
                <div className="why-mbh__figure--markers" style={{ top: "42%", left: "12%"}} onClick={() => history.push('/qualifications/certificate-II-in-workplace-skills')}></div>

                {/* Pathway to uni or workplace training */}
                <div className="why-mbh__figure--markers" style={{ top: "22%", left: "37%"}} onClick={() => history.push('/qualifications/certificate-III-in-business')}></div>
                <div className="why-mbh__figure--markers" style={{ top: "10%", left: "57.5%"}} onClick={() => history.push('/career-pathways')}></div>
                <div className="why-mbh__figure--markers" style={{ top: "36%", left: "58%"}} onClick={() => history.push('/career-pathways')}></div>

                {/* Pathway to project management glory */}
                <div className="why-mbh__figure--markers" style={{ top: "64%", left: "38%"}} onClick={() => history.push('/qualifications/certificate-III-in-business')}></div>
                <div className="why-mbh__figure--markers" style={{ top: "64%", left: "52.5%"}} onClick={() => history.push('/qualifications/certificate-IV-in-project-management')}></div>
                <div className="why-mbh__figure--markers" style={{ top: "64%", left: "67%"}} onClick={() => history.push('/qualifications/diploma-of-project-management')}></div>
            </figure>
            <article className="why-mbh__article">
                <article className="why-mbh__article--description">
                    <p>
                        Staring into the abyss?
                        <br />
                        <br />
                        At a loose end?
                        <br />
                        <br />
                        Register with mbh training and we'll guide you to a new career
                    </p>
                    <button className="btn" onClick={() => history.push('/qualifications')}>View Qualifications</button>
                </article>
                <figure className="why-mbh__article--figure">
                    <img src={CareerProgress} alt="Career progress" className="responsive-img" />
                </figure>
            </article>
        </section>
    )
}

export default WhyMBH
