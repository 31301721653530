import React from 'react'
import Updates from '../../assets/illustrations/updates.svg'
import SharedGoals from '../../assets/illustrations/shared-goals.svg'
import Launching from '../../assets/illustrations/launching.svg'
import ProjectCompleted from '../../assets/illustrations/project-completed.svg'

const StageFourDiplomaPM = ({ history }) => {
    return (
        <section id="path-one-stage-four" className="career-pathways__stages">
            <article className="career-pathways__stage-four--headings">
                <h4><span className="mbh-green-text bold">Stage Four</span><br/><br/>BSB50820 Diploma of Project Management</h4>
            </article>
            <section className="career-pathways__stage-two--pathway-section">
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={Updates} alt="Updates" />
                    </figure>
                    <p>Attend diploma qualification delivered by trainers who have been there done that</p>
                </article>
                <hr />
                <article className="career-pathways__article reverse-flex">
                    <p>Lead a project to successful delivery</p>
                    <figure>
                        <img className="responsive-img" src={ProjectCompleted} alt="Project Completed" />
                    </figure>
                </article>
                <hr />
                <article className="career-pathways__article">
                    <figure>
                        <img className="responsive-img" src={SharedGoals} alt="Shared Goals" />
                    </figure>
                    <p>Be mentored by our experienced PM consultants</p>
                </article>
                <hr />
                <article className="career-pathways__article career-pathways__graduation">
                    <p>Gain your Diploma in project management and start running projects every day</p>
                    <figure>
                        <img className="responsive-img" src={Launching} alt="Launching" />
                    </figure>
                    <div className="career-pathways__buttons-container">
                        <button className="btn career-pathways__stage-four--headings__button mbh-black lighten-5 black-text" onClick={() => history.push('/contact-us')}>Smart and Skilled</button>
                        <button className="btn career-pathways__stage-four--headings__button mbh-green" onClick={() => history.push('/qualifications/diploma-of-project-management')}>View Qualification</button>
                    </div>
                </article>
                <hr />
            </section>
        </section>
    )
}

export default StageFourDiplomaPM
