export const filterCompetencyArrays = (assignedCompetencies, allCompetencies, qualId) => {
    const filteredAssignedCompetencies = assignedCompetencies.filter(comp => comp.QualId === qualId)

    const assignedCompetenciesObject = {}
    for (let i = 0; i < filteredAssignedCompetencies.length; i++) {
        const compId = filteredAssignedCompetencies[i].Competency.CompID
        assignedCompetenciesObject[compId] = true
    }

    const filteredAllCompetencies = allCompetencies.filter(comp => assignedCompetenciesObject[comp.CompID] === undefined)

    return [filteredAssignedCompetencies, filteredAllCompetencies]
}