import React, {useState, useEffect} from 'react';
import './StudentProfile.scss';
import { useDataFetch } from '../../hooks/useDataFetch';
import { useDataPost } from '../../hooks/useDataPost';
import { baseUrl } from '../../functions/globalVariables';
import VetForm from '../VETForm/VETForm';
import M from 'materialize-css';
import { createVETQuestionObject } from '../../functions/createVETQuestionObject';
import { setPostageForm } from '../../functions/setPostageForm';
import { copyStudentResidentialIntoPostal, updateStudentPostalAddress, updateStudentResidentialAddress } from '../../functions/updateStudentAddress';
import Spinner from '../../assets/spinner.svg'
import { dateTimeToDateString } from '../../functions/dateTimeToDateString';
import AdministerStudentMenuItems from '../AdministerStudentMenuItems/AdministerStudentMenuItems';
import StateDropdown from '../StateDropdown/StateDropdown';
import { useThemeContext } from '../../ThemeContext';

const StudentProfile = ({route}) => {

    const { studentID, selectedStudentId, authToken, userRole } = useThemeContext()

    let currentStudentID = userRole === "Administrator" ? selectedStudentId : studentID

    useEffect(() => {
        var elems = document.querySelectorAll('.tooltipped');
        var instances = M.Tooltip.init(elems, {});
        var elemDropdown = document.querySelectorAll('select');
        var instancesDropdown = M.FormSelect.init(elemDropdown, {});
        M.updateTextFields();
    })

    //the below code is for triggering the address finder API code
    useEffect(() => {
        const DOMContentLoaded_event = document.createEvent("Event")
        DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true)
        window.document.dispatchEvent(DOMContentLoaded_event)
    });
    

    const stateUrl = `${baseUrl}/api/states`;
    const [ isLoadingState, state, stateError ] = useDataFetch(stateUrl)

    const studentUrl = `${baseUrl}/api/students/${currentStudentID}`;
    const [ isLoadingStudent, studentDetails, error ] = useDataFetch(studentUrl, authToken);   
    
    const VETUrl = `${baseUrl}/api/vetquestions?$expand=Group,Answers&$orderBy=OrderIndex`;
    const [ isLoadingVET, vetQuestions, vetError ] = useDataFetch(VETUrl, authToken)

    const VETStudentUrl = `${baseUrl}/api/studentVETQuestions/getbystudentid(id=${currentStudentID})?$expand=Answers`;
    const [ isLoadingStudentVET, studentVetAnswers, studentVetError ] = useDataFetch(VETStudentUrl, authToken)
    
    const [vetObject, setVetObject] = useState({})

    useEffect(() => {
        const tempVetObject = createVETQuestionObject(vetQuestions, currentStudentID)
        setVetObject(tempVetObject)
    }, [vetQuestions, currentStudentID])

    const handleVetChange = (e) => {

        if (vetObject === undefined) return;

        const { name, value, type, checked } = e.target;   

        let studentQuestionId ="";
        let studentAnswerId = "";
        if(type ==='select-one'){
            studentQuestionId = e.target.options[e.target.selectedIndex].getAttribute("data-questionid");
            studentAnswerId = e.target.options[e.target.selectedIndex].getAttribute("data-answerid");
        }else{
            studentQuestionId = e.target.getAttribute("data-questionid");
            studentAnswerId = e.target.getAttribute("data-answerid")
        }

        if (type === 'checkbox') {
            if (checked) {
                    vetObject[name].Answers = [...vetObject[name].Answers, { VetAnswerId: parseInt(value), Text: null }]
            } else {
                const filteredArray = vetObject[name].Answers.filter(item => { return item.VetAnswerId !== +value; })
                vetObject[name].Answers = filteredArray
            }

        } else if (type === 'text' || type === 'date') {
            if(studentAnswerId){
                vetObject[name].Answers = [{ Id: parseInt(studentAnswerId), VetAnswerId: null, Text: value }]
            }else{
                vetObject[name].Answers = [{ VetAnswerId: null, Text: value }]
            }
            
        } else {
            if(studentAnswerId){
                vetObject[name].Answers = [{ Id: parseInt(studentAnswerId), VetAnswerId: parseInt(value), Text: null }]
            }else{
                vetObject[name].Answers = [{ VetAnswerId: parseInt(value), Text: null }]
            }
            
        }

        let url = ""
        let method = ""
      
        if (studentQuestionId) {
            url = `${baseUrl}/api/studentvetquestions/${studentQuestionId}`
            method = 'PUT'
            vetObject[name].Id = studentQuestionId
        } else {
            url = `${baseUrl}/api/studentvetquestions`
            method = 'POST'
        }

        vetObject[name].VetQuestionText = name

        fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(vetObject[name])
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    };

    //function to check whether USI or email already exists and return error to user.
    const handleupdateStudentResponse = (data, name, value) =>{
        
        if(data.error){
            M.toast({html: data.error.innererror.message, displayLength: 2000});
        }
    }
    
    const updateStudentDetails = (e, url, token, detailsToUpdate) => {
        
        const { name, value, type, checked } = e.target;
   
       let prevValue;
       if (type === 'checkbox') {
            prevValue=detailsToUpdate["IsDisabled"];
            if (checked){//student enabled
                detailsToUpdate["IsDisabled"] = false;
            }else{//student disabled
                detailsToUpdate["IsDisabled"] = true;
            }
        }
        else{
            prevValue=detailsToUpdate[name];
            detailsToUpdate[name] = value
        }
        
        fetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(detailsToUpdate)
        })
        .then(response => response.json())
        .then(data => handleupdateStudentResponse(data, name, prevValue))
        .catch(err => console.log(err))
    }

    

    const [areChangesSaved, setAreChangesSaved] = useState(false)
    const [isLoadingSavedChanges, savedChanges, saveError] = useDataPost(studentUrl, studentDetails, areChangesSaved, setAreChangesSaved, 'PUT')

    const [showPersonalDetails, setShowPersonalDetails] = useState(true);
    const [showVetDetails, setShowVetDetails] = useState(false);

    const [refreshAddressState, setRefreshAddressState] = useState(false);

    return (
        isLoadingStudent ?
        <div>
            <p>Loading your info...</p>
            <img src={Spinner} alt="Spinner" />
        </div>
        :
        <div className="student-profile">       
        {
            userRole === 'Administrator'? <AdministerStudentMenuItems page="Profile" /> : null
        } 
            <div className="student-profile__heading-box">
               <div className="student-profile__heading">
                    <h3>Personal Details</h3>
                    <a onClick={() => setShowPersonalDetails(!showPersonalDetails)}>
                        <i className="medium material-icons mbh-green-text student-profile__dropdown-icon">{showPersonalDetails?'arrow_drop_up':'arrow_drop_down'}</i>
                    </a>
                </div>
                {
                    userRole === 'Administrator'?
                    <div className="search-bar__switch switch">
                        <span>Disable</span>
                        <label>
                            <input type="checkbox" defaultChecked={studentDetails.IsDisabled?false:true} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)}/>
                            <span className="lever"></span>
                        </label>
                        <span>Enable</span>
                    </div>
                    :null
                }
                
            </div>
            
            <br/>
            
           <div style={{display:showPersonalDetails?"block":"none"}}>
                <div className="row">
                    <h5 className="mbh-green-text col s3">Contact Details</h5>
                </div>
                <div className="row">
                    <div className="input-field col s6 m6">
                        <input id="title" type="text" className="validate" name="Title" defaultValue = {studentDetails.Title} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="title">Title</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="first_name" type="text" name="FirstName"  defaultValue = {studentDetails.FirstName} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="first_name">First Name</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="other_name" type="text" name="Other"  defaultValue = {studentDetails.Other} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="other_name">Other Name</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="last_name" type="text" name="Surname" defaultValue = {studentDetails.Surname} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="last_name">Last Name</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="phone" type="text" className="validate" minLength="8" name="Phone" defaultValue = {studentDetails.Phone} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="mobile" type="text" className="validate" minLength="9" name="Mobile" defaultValue = {studentDetails.Mobile}onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="mobile">Mobile</label>
                    </div>                
                    <div className="input-field col s12 m6">
                        <input id="email1" type="email" className="validate" name="Email" defaultValue = {studentDetails.Email} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="email">Company Email</label>
                        <span className="helper-text" data-error="Invalid Email"></span>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="email2" type="email" className="validate" name="AltEmail" defaultValue = {studentDetails.AltEmail} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)}/>
                        <label htmlFor="email2">Personal Email</label>
                        <span className="helper-text" data-error="Invalid Email"></span>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="organisation" type="text" name="Organisation" defaultValue = {studentDetails.Organisation} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="organisation">Organisation</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="position" type="text" name="Position" defaultValue = {studentDetails.Position} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="position">Position</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="DOB" type="date" name="DOB" defaultValue = {dateTimeToDateString(studentDetails.DOB, 1)} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label htmlFor="DOB">Date of Birth</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <select id="Sex" onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)}  autoComplete="new-password" name="Sex">
                            <option  value="" disabled selected>{studentDetails.Sex}</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Other">Other</option>
                        </select>
                        <label htmlFor="Sex">Gender</label>
                    </div>
                    <div className="input-field col s12 m6">
                    <input id="saStudentId" type="text" name="SaStudentId" defaultValue = {studentDetails.SaStudentId} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                    <label htmlFor="saStudentId">Unique Student Identifier (USI)</label>
                </div>
                <div className="input-field col s12">
                   <p>To obtain a USI, apply for one <a href="https://www.usi.gov.au/" target="_blank" rel="noopener noreferrer">here</a></p>
                </div>
                </div>
                
                <div className="row">
                    <h5 className="mbh-green-text col s6">Residential Address</h5>
                </div>
                <div className="row">                       
                    <div className="input-field col s12">
                        <input id="addressField2" type="text" name="AddressField" autoComplete="new-password" onBlur={() => updateStudentResidentialAddress(studentUrl, authToken, studentDetails, setRefreshAddressState)} />
                        <label htmlFor="addressField2">Address</label>
                    </div>
                    <div className="input-field col s12 m8">
                        <input id="addressBuilding" type="text" name="AddressBuilding" defaultValue = {studentDetails.AddressBuilding? studentDetails.AddressBuilding: ""} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="addressBuildingLabel" htmlFor="addressBuilding">Building/PropertyName</label>
                    </div>
                    <div className="input-field col s6 m4">
                        <input id="addressUnitNo" type="text" name="AddressUnitNo" defaultValue = {studentDetails.AddressUnitNo? studentDetails.AddressUnitNo: ""} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="addressUnitNoLabel" htmlFor="addressUnitNo">Flat/Unit No</label>
                    </div>
                    <div className="input-field col s6 m4">
                        <input id="addressStreetNo" type="text" name="AddressStreetNo" defaultValue={studentDetails.AddressStreetNo} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="addressStreetNoLabel" htmlFor="addressStreetNo">Street No</label>
                    </div>
                    <div className="input-field col s12 m8">
                        <input id="AddressStreet" type="text"  name="AddressStreet" defaultValue={studentDetails.AddressStreet} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="addressStreetLabel" htmlFor="AddressStreet">Street Name</label>
                    </div>
                    <div className="input-field col s12 m4">
                        <input id="suburb" type="text" name="Suburb" defaultValue={studentDetails.Suburb} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="suburbLabel" htmlFor="suburb">Suburb</label>
                    </div>               
                    <div className="input-field col s6 m4">
                       <StateDropdown name="State" updateStudentDetails={updateStudentDetails} studentDetails={studentDetails} studentUrl={studentUrl} authToken={authToken} state={state} refreshAddressState={refreshAddressState} setRefreshAddressState={setRefreshAddressState} />
                    </div>
                    <div className="input-field col s12 m4 hide">
                        <input id="HiddenState" type="text" name="hiddenState" />
                    </div>
                    <div className="input-field col s6 m4">
                        <input id="postcode" type="text" className="validate" minLength="4" maxLength="4"  name="Postcode" defaultValue={studentDetails.Postcode} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="postcodeLabel" htmlFor="postcode">Postcode</label>
                    </div>
                </div>

                <div className="row">
                    <h5 className="mbh-green-text col s6">Postal Address</h5>
                </div>
                <label className="contact-form__postal-checkbox">
                <input type="checkbox" className="filled-in contact-form__postal-checkbox" onClick={() => {
                    setPostageForm()
                    copyStudentResidentialIntoPostal(studentUrl, authToken, studentDetails)
                    }} name="postalAddress" autoComplete="new-password" />
                    <span>Postal address same as residential address</span>
                </label>
                <br/><br/>
                <div id="postalSection" className={`row`}>                                     
                    <div className="input-field col s12">
                        <input id="postalAddressField2" type="text" name="postalAddressField" autoComplete="new-password" defaultValue={studentDetails.AddressBuilding_Pos} onBlur={() => updateStudentPostalAddress(studentUrl, authToken, studentDetails, setRefreshAddressState)} />
                        <label htmlFor="postalAddressField2">Address</label>
                    </div>
                    <div className="input-field col s12 m8">
                        <input id="postalAddressBuilding" type="text" name="AddressBuilding_Pos" defaultValue={studentDetails.AddressBuilding_Pos} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="postalAddressBuildingLabel" htmlFor="postaladdressBuilding">Building/PropertyName</label>
                    </div>
                    <div className="input-field col s6 m4">
                        <input id="postalAddressUnitNo" type="text" name="AddressUnitNo_Pos" defaultValue={studentDetails.AddressUnitNo_Pos} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="postalAddressUnitNoLabel" htmlFor="postaladdressUnitNo">Flat/Unit No</label>
                    </div>
                    <div className="input-field col s6 m4">
                        <input id="postalAddressStreetNo" type="text" name="AddressStreetNo_Pos" defaultValue={studentDetails.AddressStreetNo_Pos} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="postalAddressStreetNoLabel" htmlFor="postaladdressStreetNo">Street No</label>
                    </div>
                    <div className="input-field col s12 m8">
                        <input id="postalAddressStreet" type="text"  name="AddressStreet_Pos" defaultValue={studentDetails.AddressStreet_Pos} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="postalAddressStreetLabel" htmlFor="postalAddressStreet">Street Name</label>
                    </div>
                    <div className="input-field col s12 m4">
                        <input id="postalSuburb" type="text" name="Suburb_Pos" defaultValue={studentDetails.Suburb_Pos} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="postalSuburbLabel" htmlFor="postalSuburb">Suburb</label>
                    </div>
                    <div className="input-field col s6 m4">
                       <StateDropdown name="State_Pos" updateStudentDetails={updateStudentDetails} studentDetails={studentDetails} authToken={authToken} studentUrl={studentUrl} state={state} refreshAddressState={refreshAddressState} setRefreshAddressState={setRefreshAddressState} />
                    </div>
                    <div className="input-field col s12 m4 hide">
                        <input id="HiddenStatePos" type="text" name="hiddenStatePos" />
                    </div>
                    <div className="input-field col s6 m4">
                        <input id="postalPostcode" type="text" className="validate" minLength="4" maxLength="4" name="Postcode_Pos" defaultValue={studentDetails.Postcode_Pos} onChange={(e) => updateStudentDetails(e, studentUrl, authToken, studentDetails)} />
                        <label id="postalPostcodeLabel" htmlFor="postalPostcode">Postcode</label>
                    </div>
                </div>
           </div>

           <div className="student-profile__heading">
                <h3>VET Details</h3>
                <a  onClick={() => setShowVetDetails(!showVetDetails)}>
                    <i className="medium material-icons mbh-green-text student-profile__dropdown-icon">{showVetDetails?'arrow_drop_up':'arrow_drop_down'}</i>
                </a>
            </div>
            <div className="student-profile__personal-details" style={{display:showVetDetails?"block":"none"}}>
                <VetForm vetQuestions={vetQuestions} vetObject={vetObject} studentVetAnswers={studentVetAnswers} handleVetChange={handleVetChange} VETStudentUrl={VETStudentUrl} route={route} />
            </div>
        
        </div>
    )
}

export default StudentProfile
