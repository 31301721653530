import React, { useState } from 'react'
import { baseUrl } from '../../functions/globalVariables'
import { useDataFetch } from '../../hooks/useDataFetch'
import EventsAddButton from '../EventsAddButton/EventsAddButton'
import Spinner from '../../assets/spinner.svg'
import './EventsSummary.scss'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useThemeContext } from '../../ThemeContext'

const EventsSummary = ({ setCurrentPage }) => {

    let history = useHistory()
    const { authToken, userRole } = useThemeContext()

    let routingPortal = "admin-portal";
    if (userRole === "Student")
        routingPortal = "student-portal";

    const [isAttendanceMarked, setIsAttendanceMarked] = useState(false)

    const eventsSummaryUrl = `${baseUrl}/api/events?$orderBy=StartDateTime&$filter=IsCancelled eq false`;
    const [isLoading, events, error] = useDataFetch(eventsSummaryUrl, authToken)

    const trainersUrl = `${baseUrl}/api/trainers`;
    const [isLoadingTrainers, trainers, errorTrainers] = useDataFetch(trainersUrl, authToken)

    // Go here to learn more about formatting dates with an object: https://www.tabnine.com/academy/javascript/how-to-format-date/

    const [todaysEvents, setTodaysEvents] = useState([])
    const [upcomingEvents, setUpcomingEvents] = useState([])
    
    useEffect(() => {
        if (events.length) {
            const todaysDate = dayjs().format()
            const filterTodaysEvents = events.filter(event => dayjs(event.StartDateTime).format("YYYY-MM-DD") === todaysDate.substring(0, 10));
            const filterUpcomingEvents = events.filter(event => dayjs(event.StartDateTime).isAfter(todaysDate, 'day'));

            setTodaysEvents(filterTodaysEvents)
            setUpcomingEvents(filterUpcomingEvents)
        }
    }, [events])
   
    let eventsRegUrl ="";
    if (userRole === 'Student' && events.length > 0)
        eventsRegUrl = `${baseUrl}/api/eventregistrations`;
    const [isLoadingEventsReg, eventsReg, eventsRegErr] = useDataFetch(eventsRegUrl, authToken);
   
    const studentId = parseInt(window.sessionStorage.getItem('mbhStudentId'));
    const filteredEventsReg = eventsReg.filter(reg => reg.StudentID === studentId);
  
    let eventsRegIdList=[];
    filteredEventsReg.map(reg => eventsRegIdList.push(reg.EventID))  

    const formatEventDate = (startDate, endDate, typeOfField) => {
        if (typeOfField === "Date") {
            return new Date(startDate).toLocaleString('en-US', {
                weekday: 'long', // long, short, narrow
                day: 'numeric', // numeric, 2-digit
                month: 'long', // numeric, 2-digit, long, short, narrow
            })
        } else if (typeOfField === "Time") {
            const formattedStartDate = new Date(startDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })
            const formattedEndDate = new Date(endDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })

            return `${formattedStartDate} - ${formattedEndDate}`
        }
    }

    const displayTrainerName = (trainerId) => {
        const trainer = trainers.filter(trainer => trainer.Id === trainerId)[0]
        return trainer ? `${trainer.FirstName} ${trainer.Surname}` : "No Trainer Selected"
    }

    const markAttendance = (hasAttended, eventId) =>{

        let eventObj = filteredEventsReg.filter(event => event.EventID === eventId)[0];

        let timestamp = null;
        if (hasAttended)
            timestamp = dayjs().format();

        eventObj["HasAttended"] = hasAttended;
        eventObj["AttendanceDate"] = timestamp;

        fetch(`${baseUrl}/api/eventregistrations/${eventObj.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(eventObj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))

        setIsAttendanceMarked(hasAttended);
    }

    return (
        <section className="events-summary">
            {
                isLoading || isLoadingTrainers || !trainers.length ?
                <img src={Spinner} alt="Spinner" />
                :
                <>
                    <article className="events-summary__header">
                        <h3 className="mbh-green-text">Today's Events</h3>
                        {
                            userRole === "Student" ?
                            <div className="events-summary__header--btns">
                                <a className="btn-floating mbh-green" onClick={() => {history.push('/student-portal/events/calendar')}}><i className="material-icons notextselect">event</i></a>
                            </div>
                            :
                            <div className="events-summary__header--btns">
                                <a className="btn-floating mbh-green" onClick={() => { setCurrentPage("summary"); history.push('/admin-portal/events/calendar')}} style={{marginRight:"5px"}}><i className="material-icons notextselect">event</i></a>
                                <a className='btn-floating mbh-green' onClick={() => {setCurrentPage("summary"); history.push('/admin-portal/events/report')}} style={{marginRight:"5px"}}><i className="material-icons notextselect">assignment</i></a>
                                <a className='btn-floating mbh-green' onClick={() => {setCurrentPage("summary"); history.push('/admin-portal/events/create')}}><i className="material-icons notextselect">add</i></a>
                                <EventsAddButton />
                            </div>
                        }
                    </article>
                    {/* for Students, check if they are registered in any of today's events. If so, check if they've marked attendance already or not  */}
                    {   userRole === 'Student'?
                            todaysEvents.length ?
                            todaysEvents.map((event, i) => {
                                let regEventObj = filteredEventsReg.filter(f => f.EventID === event.Id)[0];
                                return(  
                                    <div key={i}>
                                    { 
                                        regEventObj !== undefined?
                                        <article  className="events-summary__today">
                                            <article className="events-summary__today--class-details">
                                                <h6 className="mbh-green-text">{event.Subject}</h6>
                                                <p>Date: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Date')}</p>
                                                <p>Time: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Time')}</p>
                                                <p>Taught by: {displayTrainerName(event.TrainerID)}</p>
                                            </article>
                                            {
                                                regEventObj.HasAttended?
                                                <article className="events-summary__today--attending-class">
                                                    <button className="btn disabled">Attending Class</button>
                                                    <button className="btn mbh-red events-summary__today--cancel-btn" onClick={() => markAttendance(false, event.Id)}>Cancel</button>
                                                </article>
                                                :
                                                <article className="events-summary__today--mark-attendance">
                                                    <button className="btn mbh-green events-summary__today--attendance-btn" onClick={() => markAttendance(true, event.Id)}>Mark Attendance</button>
                                                </article>
                                        }
                                        </article>
                                        : 
                                        <>
                                            <h5>There are no events on today</h5>
                                            <br />
                                            <br />
                                        </>
                                    }
                                    </div>
                                    )
                                })
                            :
                            <>
                                <h5>There are no events on today</h5>
                                <br />
                                <br />
                            </>
                        :
                        <div>
                           { 
                           //administrator
                                todaysEvents.length ?
                                todaysEvents.map((event, i) => {
                                    return(  
                                        <div key={i}>
                                            <article  className="events-summary__today events-summary__today--admin" onClick={() => { setCurrentPage("summary"); history.push(`/admin-portal/events/event-details/${event.Id}`)}}>
                                                <article className="events-summary__today--class-details">
                                                    <h6 className="mbh-green-text">{event.Subject}</h6>
                                                    <p>Date: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Date')}</p>
                                                    <p>Time: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Time')}</p>
                                                    {/* <p>Taught by: {displayTrainerName(event.TrainerID)}</p> */}
                                                </article>
                                            </article>
                                           
                                        </div>
                                        )
                                    })
                                :
                                <>
                                    <h5>There are no events on today</h5>
                                    <br />
                                    <br />
                                </>
                           }
                        </div>
                    }

                    {/* upcoming events */}
                    <h4 className="mbh-green-text">Upcoming Events</h4>
                    <article className="events-summary__upcoming" >
                        {   
                            upcomingEvents.length ?
                            upcomingEvents.map((event, i) => {
                                if (userRole === 'Student' && !eventsRegIdList.includes(event.Id)) 
                                    return

                                return (
                                    <article key={event.Id} className="events-summary__upcoming--event" onClick={() => { setCurrentPage("summary"); history.push(`/${routingPortal}/events/event-details/${event.Id}`)}}>
                                        <h6 className="mbh-green-text">{event.Subject}</h6>
                                        <p>Date: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Date')}</p>
                                        <p>Time: {formatEventDate(event.StartDateTime, event.EndDateTime, 'Time')}</p>
                                        {userRole === 'Student'?<p>Taught by: {displayTrainerName(event.TrainerID)}</p>:null}
                                    </article>
                                )
                            })
                            :
                            <h5>There are no upcoming events</h5>
                        }
                    </article>
                </>
            }
        </section>
    )
}

export default EventsSummary
