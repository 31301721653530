import React, { useState } from 'react'
import { useEffect } from 'react'
import UneditableTextEditor from '../UneditableTextEditor/UneditableTextEditor'
import './CopyTableHTML.scss'

const CopyTableHTML = ({ competencies }) => {

    const [tableHTML, setTableHTML] = useState(null)
    const [isTableForCertificate, setIsTableForCertificate] = useState(true)

    // This will be used in a filter to ensure the correct competencies are collected
    const resultsToFilterFor = {
        "Competent": true,
        "Credit transfer": true,
        "Recognition of current competency granted": true,
        "Recognition of prior learning granted": true
    }

    const closeTable = () => setTableHTML(null)

    const toggleTableOutput = () => {
        setIsTableForCertificate(!isTableForCertificate)
    }

    const copyTable = () => {
        let tableToCopy = document.querySelector(".cert-table");
        let percentWidthOfTable = isTableForCertificate ? 100 : 80
        let htmlString = tableToCopy.outerHTML.replace(` class="hide cert-table"`, ` class="cert-table" style="width: ${percentWidthOfTable}%; font-size: 11pt" border="0" cellpadding="6" cellspacing="0" align="center"`)
        setTableHTML(htmlString)
    }

    // Whenever the toggle is switched, regenerate the table
    useEffect(() => {
        if (tableHTML) {
            copyTable()
        }
    }, [isTableForCertificate])


    return (
    <div className="copy-table">
        {
            !tableHTML ?
            <button className="btn mbh-black lighten-4 black-text" onClick={copyTable}>Copy Table</button>
            :
            <button className="btn-floating mbh-red" onClick={closeTable}><i className="material-icons notextselect">close</i></button>
        }
        {
            tableHTML &&
            <>
                <div className="copy-table__html">
                    <p>Copy the table below and paste it where needed</p>
                    <div class="switch">
                        <label>
                            Statement of Attainment
                            <input type="checkbox" onChange={toggleTableOutput} checked={isTableForCertificate}/>
                            <span class="lever"></span>
                            Certificate
                        </label>
                    </div>
                    <br/>
                    <UneditableTextEditor id="tableHTML" name="tableHTML" initValue={tableHTML} />
                </div>
                
            </>
        }

        <table className="hide cert-table">
            {
                isTableForCertificate ?
                <tbody>
                <tr>
                    <td style={{fontWeight: "bold"}}>Year</td>
                    <td style={{fontWeight: "bold"}}>Code</td>
                    <td style={{fontWeight: "bold"}}>Units Enrolled</td>
                    <td style={{fontWeight: "bold"}}>Result</td>
                </tr>
                {
                    competencies.length &&
                    competencies.filter(competency => resultsToFilterFor[competency.Result.Result] !== undefined).map(competency => {
                        let yearCompleted = "2022"
                        if (competency.ActivityEndDate) {
                            yearCompleted = new Date(competency.ActivityEndDate).getFullYear()
                        }
                        return (
                            <tr>
                                <td>{yearCompleted}</td>
                                <td>{competency.Competency.CompID}</td>
                                <td>{competency.Competency.compDsc} </td>
                                <td>{competency.Result.Result}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
            :
            <tbody>
                {
                    competencies.length &&
                    competencies.filter(competency => resultsToFilterFor[competency.Result.Result] !== undefined).map(competency => {
                        return (
                            <tr>
                                <td style={{width: "37%", textAlign: "right", verticalAlign: "top"}}>{competency.Competency.CompID}</td>
                                <td style={{width: "63%"}}>{competency.Competency.compDsc} </td>
                            </tr>
                        )
                    })
                }
            </tbody>
            }
        </table>
    </div>
    )
}

export default CopyTableHTML