import React, {useEffect} from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faFacebook, faBlogger } from '@fortawesome/free-brands-svg-icons'
import {
    HashRouter as Router,
  } from "react-router-dom";

const Footer = ({ history }) => {

    useEffect(()=>{
        if (window.location.pathname !== 'testimonials') {
            window.scrollTo(0, 0)
        }
    })

    return(
        <Router>
        <footer className="footer mbh-black lighten-1 white-text center-align">
            <div className="container footer__container">
                <div className="footer__grid">
                        <div className="footer__links footer__links--1">
                            <p id="home" className="footer__link" onClick={() => history.push('/')}>Home</p>
                            <p id="aboutUs" className="footer__link" onClick={() => history.push('/about-us')}>About Us</p>
                            <p id="bookingForm" onClick={() => history.push('/register')} className="footer__link">Book Online</p>
                        </div>
                        <div className="footer__links footer__links--2">
                            <p id="qualifications" onClick={() => history.push('/qualifications')} className="footer__link">Qualifications</p>
                            <p id="testimonials" onClick={() => history.push('/testimonials')} className="footer__link">Testimonials</p>
                            <p id="conditions" onClick={() => history.push('/conditions')} className="footer__link">Enrolment Conditions</p>
                            <p id="privacy" className="footer__link" onClick={() => history.push('/privacy')}>Privacy</p>
                           
                        </div>
                        <div className="footer__links footer__links--3">
                            <p id="codeOfPractice" onClick={() => history.push('/code-of-practice')} className="footer__link">Code of Practice</p>
                            <p id="caseStudies" onClick={() => history.push('/case-studies')} className="footer__link">Case Studies</p>
                            <a href="https://smartandskilled.nsw.gov.au/" target="_blank" rel="noopener noreferrer" className="footer__link">Smart &amp; Skilled NSW</a>
                        </div>
                    <div className="footer__social">
                        <a href="https://www.facebook.com/Mbh-training-490781297730313/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="footer__favicon" icon={faFacebook} /></a>
                        <a href="https://au.linkedin.com/company/m-b-h" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="footer__favicon" icon={faLinkedin} /></a>
                        <a href="http://mbhtraining.blogspot.com.au/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="footer__favicon" icon={faBlogger} /></a>
                    </div>
                </div>
                <div className="footer__copyright">
                    <p>copyright 2014, mbh training, ph: 1300 79 48 68, info@mbhtraining.com.au</p>
                    <p>M.B.H Management Pty Ltd, RTO ID: 90884</p>
                </div>
                
            </div>
            
        </footer>
        </Router>
    )
    
}

export default Footer;