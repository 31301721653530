import React, {useState} from 'react'
import './ForgotPassword.scss'
import { baseUrl } from '../../functions/globalVariables';
import FormSpinner from '../../assets/form-spinner.svg'
import M from 'materialize-css';

const ForgotPassword = ({history}) => {

    const [isSuccess, setIsSuccess] = useState(false)
    const [isSendingLink, setIsSendingLink] = useState(false)

    const [email, setEmail] = useState("")

    const isEmailValid = () =>{
        const emailInput = document.getElementById('email');

        if (emailInput.classList.contains("invalid") || email.trim().length === 0) {
            M.toast({html: 'Please enter a valid email address', displayLength: 2000});
            return false
        }

        return true
    }

    const sendResetPasswordEmail = (e) => {
        e.preventDefault()
        if (!isEmailValid()) return
        
        setIsSendingLink(true)
        fetch(`${baseUrl}/api/auth/ForgetPassword`, {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: email })
        })
        .then(response => {
            if (response.status === 200) {
                setIsSuccess(true)
            }
            setIsSendingLink(false)
        })
        .catch(err => {console.log("Error: ", err); setIsSendingLink(false)}) 
    }

    return (
        <section className="forgot-password">
            <div className="forgot-password__card container" style={{display:isSuccess?'none':'block'}}>
                <h4 className="center">Forgot Password</h4>
                <br/>
                <form className="forgot-password__form">
                    <div className="row forgot-password__row">
                        <div className="input-field col s12">
                            <input id="email" type="email" className="validate" onChange={(e) => {setEmail(e.target.value.trim()); console.log(e.target.value)}} />
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    {
                        isSendingLink ?
                        <figure>
                            <img src={FormSpinner} alt="Form spinner" />
                        </figure>
                        :
                        <button className="btn-large mbh-green" type='submit' onClick={(e) => sendResetPasswordEmail(e)}>Send Reset Link</button>
                    }
                    <hr />
                    <button className="btn-large transparent mbh-green-text" 
                            style={{ boxShadow: 'none' }}
                            onClick={() => history.push('/privacy')}
                    >
                        Privacy
                    </button>
                </form>
            </div>
            <div className="forgot-password__card forgot-password__success container" style={{display:isSuccess?'block':'none'}}>
                <h5>Success! Check your email for a reset link</h5>
                <br/><br/>
                <div>
                    <p>Didn't get a link?</p>
                    <br/>
                    <button 
                        className="btn-large mbh-green" 
                        style={{ boxShadow: 'none' }}
                        onClick={() => setIsSuccess(false)}
                    >
                        Resend Link
                    </button>
                </div>
                <hr />
                <p 
                    className="btn-large transparent mbh-green-text" 
                    style={{ boxShadow: 'none' }}
                    onClick={() => history.push('/login')}
                >
                    LOG IN
                </p>
            </div>
        </section>
    )
}

export default ForgotPassword
