import React from 'react';
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import Celebration from '../../assets/illustrations/celebration.svg';
import './StudentPortalHome.scss';
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';

const StudentPortalHome = () => {

    let history = useHistory()
    const { studentID, authToken } = useThemeContext()

    const studentUrl = `${baseUrl}/api/students/${studentID}`;
    const [ isLoading, studentDetails, error ] = useDataFetch(studentUrl, authToken);

    //Check whether there is a pending qualification to display under information
    const studentQualificationsUrl = `${baseUrl}/api/studentqualifications?$expand=Qualification&$filter=StudentId eq ${studentID} and CertificateIssued ne 'Yes' and Disabled eq false`;
    const [ isLoadingPending, studentQualifications, errorPending ] = useDataFetch(studentQualificationsUrl, authToken);

    return (
        <div className="student-portal-home">
            <div className="student-portal-home__welcome">
                <div>
                    <h3>Welcome {studentDetails.FirstName} {studentDetails.Surname}</h3>
                    <p>This is your Student Portal. <br/><br/> Come back here anytime to manage your qualifications and personal details.</p>
                </div>
                <figure>
                    <img src={Celebration} alt="Celebration" className="responsive-img"/>
                </figure>
            </div>
            <div className="student-portal-home__body">
                <article>
                    <h4>Information:</h4>
                    <ul>
                        {
                            studentQualifications.length ?
                            studentQualifications.map((qualification, index) => (
                                qualification.QuoteId===null?
                                <li key={index}>Your chosen qualification <strong>{qualification.Qualification.AntaId} {qualification.Qualification.Name}</strong> is awaiting a quote.</li>
                                :
                                    !qualification.ConfirmedCost?
                                    <li>
                                        The quote for <strong>{qualification.Qualification.AntaId} {qualification.Qualification.Name}</strong> is available and ready to be accepted in the  
                                        <span className="student-portal-home__link" onClick={() => history.push('/student-portal/training-plan')}> Training Plan</span> page.
                                    </li>
                                    :
                                    <li></li>
                            )):null
                        }
                    </ul>
                </article>
            </div>
        </div>
    )
}

export default StudentPortalHome
