import React from 'react'
import './RegConfirmation.scss'
import Celebration from '../../assets/illustrations/celebration.svg';

const RegConfirmation = () => {
    return (
        <div className="reg-confirmation container center">
            <br/><br/>
            <h3 className="hide-on-small-only">Thank you for registering! We will get in touch soon.</h3>
            <h5 className="hide-on-med-and-up"> Thank you for registering!</h5>
            <h5 className="hide-on-med-and-up"> We will get in touch soon.</h5>
            <br/><br/>
           <figure className="reg-confirmation__fig ">
                <img src={Celebration} alt="Celebration" className="responsive-img"/>
            </figure>
        </div>
    )
}

export default RegConfirmation
