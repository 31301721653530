import React, {useState, useEffect} from 'react'
import './DragDropZone.scss';
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import { arrayToObject } from '../../functions/arrayToObject';
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName';
import M from 'materialize-css';
import { dateTimeToDateString } from '../../functions/dateTimeToDateString';
import DatePickerComp from '../DatePickerComp/DatePickerComp';
import Spinner from '../../assets/spinner.svg';
import dayjs from 'dayjs'
import CopyTableHTML from '../CopyTableHTML/CopyTableHTML';
import { useThemeContext } from '../../ThemeContext';

const DragDropZone = ({studentCompetencies, studentCoreElective, currentQualification, hoursUnitsObject, setHoursUnitsObject, antaID, qualName}) => {
    
    const { authToken, userRole, studentID, selectedStudentId } = useThemeContext()

    const currentStudentID = userRole === "Administrator" ? selectedStudentId : studentID
    
    const [isLoadingDrag, setIsLoadingDrag] = useState(false)

    const [hasAcceptedPlan, setHasAcceptedPlan] = useState(false)
    const [dateOfAcceptance, setDateOfAcceptance] = useState("")
    const [personWhoAccepted, setPersonWhoAccepted] = useState("")

    const [showTableToCopy, setShowTableToCopy] = useState(false)

    const getFormattedDateOfAcceptance = () => dayjs(currentQualification.PlanAcceptedAt).format("dddd, MMMM D YYYY, h:mm a")

    const competencyArrayToObject = (array) =>
    array.reduce((obj, item) => {
      obj[item.CompetencyId] = item
      return obj
    }, {})

    //calculate Total Number of hours, units and nominal hours per unit
    const calcUnitHours = (studentCoreElective) => {
        let hours = 0;
        let units = 0;
       
        if (studentCompetencies.length && studentCoreElective.length){
            const isStandardObj = competencyArrayToObject(studentCoreElective)
            
           
            for (let i=0; i<studentCompetencies.length; i++){
               if (isStandardObj[studentCompetencies[i].CompetencyId]){
                    if (isStandardObj[studentCompetencies[i].CompetencyId].IsStandard){
                        hours += studentCompetencies[i].Competency.NomHrs; 
                        units += 1;
                    }
                }
            }
        }

        return { totalHours: hours, totalUnits: units, hrsPerUnit: Math.round(hours/units) }
    }

    useEffect(()=>{
        var elemSelect = document.querySelectorAll('select');
        var instanceSelect = M.FormSelect.init(elemSelect, {});        
    });

    useEffect(() => {
        if (currentQualification) {
            if (currentQualification.PlanAcceptedBy && currentQualification.PlanAcceptedAt) {
                setPersonWhoAccepted(currentQualification.PlanAcceptedBy)                
                setDateOfAcceptance(getFormattedDateOfAcceptance())
                setHasAcceptedPlan(true)
            } else {
                setPersonWhoAccepted("")                
                setDateOfAcceptance("")
                setHasAcceptedPlan(false)
            }
        }
    }, [currentQualification])

    useEffect(() => {
        if (studentCompetencies.length) {
            setHoursUnitsObject(Object.assign({}, hoursUnitsObject, {[antaID]: calcUnitHours(studentCoreElective)}))
        }
    }, [studentCompetencies])


    //Object used to post an elective competency when it added to the student's competencies.
    const compObject = {
        "BookingId": 0,
        "CourseId": "redundant",
        "StudentId": 0,
        "CompetencyId": 0,
        "StudentQualificationId": 0,
        "ResultId": 10,
        "SaStudentId": null,
        "PppContract": "No",
        "PppCourseSiteId": "",
        "ActivityStartDate": null,
        "ActivityEndDate": null
    }   

    const getCoreElective = (qualId, compId) => {
        let type;
        let IsStandard;
        for(let i=0; i<studentCoreElective.length; i++)
        {
            if(studentCoreElective[i].QualId === qualId && studentCoreElective[i].CompetencyId === compId){
                type= studentCoreElective[i].IsCore? "Core": "Elective"
                
                IsStandard = studentCoreElective[i].IsStandard ? true : false
                return { 'type': type, 'IsStandard': IsStandard }
            }
        }
    }

    let reducedStudentCompetencies = []   

    if (studentCompetencies.length) {
        reducedStudentCompetencies = studentCompetencies.map(competency => {
            const coreElectiveObject = getCoreElective(competency.StudentQualification.QualId, competency.Competency.Id)
            let object;
            if(coreElectiveObject=== undefined){
                object = competency;
            }else{
                object = { ...competency, 'coreElective': coreElectiveObject.type, 'IsStandard': coreElectiveObject.IsStandard }
            }
             
            return object
        })
    }
    
    // Grabbing all available competencies for current qual that have not been assigned to the student yet
    let availableCompUrl = "";
    if (currentQualification){
        availableCompUrl = `${baseUrl}/api/qualificationcompetencies?$expand=Qualification,Competency&$filter=QualId eq ${currentQualification.QualId}`;
    }
    const [ isLoadingAllComp, qualCompetencies, errorAllComp ] = useDataFetch(availableCompUrl, authToken);

    const tempObject = arrayToObject(reducedStudentCompetencies)
    const availableCompetencies = qualCompetencies.filter(comp => tempObject[comp.CompetencyId] === undefined)

    // Saving dragged element
    const [draggedElement, setDraggedElement] = useState(null)
    const [currentDivId, setCurrentDivId] = useState(null)

    const [indexDraggedEl, setIndexDraggedEl] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)


    const resultsUrl = `${baseUrl}/api/CompetencyResults`;
    const [ isLoadingResults, results, resultsError ] = useDataFetch(resultsUrl)
    
    const onDragStart = (ev) => {
        ev.dataTransfer.effectAllowed = "copyMove";
        ev.dataTransfer.setData("text", ev.target.id);
        const divId = ev.target.dataset.id;
        setIdToDelete(ev.target.dataset.idtodelete)
        setIndexDraggedEl(ev.target.dataset.index)
        setDraggedElement(ev.target)
        setCurrentDivId(divId)
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
    }

    const onDrop = (ev, divId) => {
        setIsLoadingDrag(true)
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        const destination = document.getElementById(divId);
        destination.appendChild(document.getElementById(data));
        
        if (currentDivId === 'assigned' && divId === 'div1') {    
            deleteCompetency(idToDelete);          
            draggedElement.dataset.id = 'unassigned'
            availableCompetencies.push(reducedStudentCompetencies[ev.target.dataset.index])
            reducedStudentCompetencies.splice(ev.target.dataset.index, 1);
        } else if (currentDivId === 'unassigned' && divId === 'div2') {
            //The object for the competency to be added needs the StudentId, CompetencyId and StudentQualificationId
            compObject.StudentId = currentStudentID;
            if(availableCompetencies.length){
                compObject.CompetencyId = availableCompetencies[indexDraggedEl].CompetencyId;
            }
            if (currentQualification.Id) {
                compObject.StudentQualificationId = currentQualification.Id;
            }
            addCompetency(compObject)
            draggedElement.dataset.id = 'assigned'
            reducedStudentCompetencies.push(compObject);
            availableCompetencies.splice(ev.target.dataset.index, 1)
        }

        setIsLoadingDrag(false);
        
    }

    const addCompetency = (object) =>{
        fetch(`${baseUrl}/api/studentcompetencies`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(object)
        })
        .then(response => {
            response.json()
            window.location.reload()
        })
        .catch(err => {
            console.log(err)
            M.toast({html: "Something went wrong", displayLength: 2000})
        })
    }

    const deleteCompetency = (id) => {
        fetch(`${baseUrl}/api/studentcompetencies/${id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
        .catch(err => console.log(err))
    }

    const handleChange = (e, date, detailsToUpdate, index) => {
        
        let type="";
        let name="";
        let value="";
        if (e === 'startDate' || e ==='finishDate') {
            name = e;
            value = date;
        } else {
            type = e.target.type;
            name = e.target.name;
            value = e.target.value;
        }
        
        const obj = { 
            "Id": 0,
            "BookingId": 0,
            "CourseId": "",
            "StudentId": 0,
            "CompetencyId": 0,
            "StudentQualificationId": 0,
            "ResultId": 0,
            "SaStudentId": "",
            "PppContract": "",
            "PppCourseSiteId": null,
            "ActivityStartDate": null,
            "ActivityEndDate": null
        }
        for (const [key, value] of Object.entries(obj)) {
            obj[key] = detailsToUpdate[key]
        }

      if(type ==='select-one'){
            obj["ResultId"] = parseInt(value);
            reducedStudentCompetencies[index].ResultId = value;
        }else{
            if(name === "startDate"){
                obj['ActivityStartDate'] = value;
                reducedStudentCompetencies[index].ActivityStartDate = value;
            }else {
                obj['ActivityEndDate'] = value;
                reducedStudentCompetencies[index].ActivityEndDate = value;
            }
        }
        fetch(`${baseUrl}/api/studentcompetencies/${detailsToUpdate["Id"]}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .catch(err => console.log(err))
    }

    const handleAcceptTrainingPlan = (acceptStatus) => {

        if (acceptStatus === "accepted") {
            currentQualification.PlanAcceptedBy = window.sessionStorage.getItem("mbhUserName")
            currentQualification.PlanAcceptedAt = dayjs().format()
            setPersonWhoAccepted(currentQualification.PlanAcceptedBy)
            setDateOfAcceptance(getFormattedDateOfAcceptance())
        } else {
            currentQualification.PlanAcceptedBy = null
            currentQualification.PlanAcceptedAt = null
            setPersonWhoAccepted("")
            setDateOfAcceptance("")
        }

        fetch(`${baseUrl}/api/studentqualifications/${currentQualification.Id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify(currentQualification)
        })
        .then(() => {
            if (acceptStatus === "accepted") {
                // Only send an email if a student has accepted the training plan
                if (userRole !== "Administrator") {
                    sendEmailToAdmins(currentQualification)
                }
                setHasAcceptedPlan(true)
            } else {
                setHasAcceptedPlan(false)
            }
        })
        .catch(err => console.log(err))
    }

    const sendEmailToAdmins = (qual) => {
        fetch(`${baseUrl}/api/auth/AcceptTrainingPlan`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            body: JSON.stringify({
                StudentId: studentID,
                Qualification: `${qual.Qualification.AntaId} ${capitaliseEachWordOfQualName(qual.Qualification)}`,
                AcceptedAt: qual.Qualification.AcceptedAt
            })
        })
        .catch(err => {
            console.log(err); 
            M.toast({html: "An error has occurred", displayLength: 1500})
        })
    }

    return (
        <div className="drag-drop">
            {
                isLoadingDrag || isLoadingAllComp ? <img src={Spinner} alt="Spinner"/>
                :
                <div className="drag-drop__section">
                    <h5 className="mbh-green-text">Summary of Results</h5>
                    {
                        userRole === "Administrator" &&
                        <CopyTableHTML competencies={reducedStudentCompetencies} />
                    }
                    <h6>Competencies for {antaID} {qualName}</h6>
                    <div className="collection drag-drop__results">
                        <div className="collection-item drag-drop__result" >
                            <span className="bold">Code</span>
                            <span className="bold" >Units Enrolled</span>
                            <span className="bold">Type</span>
                            <span className="bold">Start</span>
                            <span className="bold">Finish</span>
                            <span className="bold">Result</span>
                        </div>
                        <div id="div2" >
                    
                        {
                            reducedStudentCompetencies.map((competency, index) => {
                            
                                const isDraggable = competency.coreElective === 'Elective' && (competency.Result.Result === "Continuing enrolment" || competency.Result.Result === "Not Yet Started") ? true : false

                                return (
                                    <div key={index} className="collection-item drag-drop__result drag-drop__draggable" draggable={isDraggable}  data-id={'assigned'} data-index={index} data-idtodelete={competency.Id} id={`dragA${index}`} onDragStart = {(e) => onDragStart(e)} >
                                        <span>{competency.Competency.CompID}</span>
                                        <span>{competency.Competency.compDsc}</span>
                                        <span>{competency.coreElective}</span>
                                        {
                                            userRole === 'Student' || hasAcceptedPlan ?
                                            <span>{dateTimeToDateString(competency.ActivityStartDate, 2)}</span>
                                            :<DatePickerComp tabindex ="1" name={`startDate`} defaultDate={Date.parse(dateTimeToDateString(competency.ActivityStartDate, 1))}  handleDateUpdate={handleChange} object={competency} index={index} fieldToUpdate={"ActivityStartDate"} />
                                        }
                                        {
                                            userRole === 'Student' || hasAcceptedPlan ?
                                            <span>{dateTimeToDateString(competency.ActivityEndDate, 2)}</span>
                                            :<span className="student-qualifications__competency--field-4">
                                                <DatePickerComp tabindex ="2" name={`finishDate`} defaultDate={Date.parse(dateTimeToDateString(competency.ActivityEndDate, 1))}  handleDateUpdate={handleChange} object={competency} index={index} fieldToUpdate={"ActivityEndDate"} />
                                            </span>
                                        }
                                        {
                                            userRole === 'Student' || hasAcceptedPlan ?
                                            <span>{competency.Result.Result}</span>
                                            :<div>
                                                <select id="result" onChange={(e) => handleChange(e, "", competency, index)} autoComplete="new-password" name="Result" type="select-one">
                                                    <option  value="" disabled selected>{competency.Result.Result}</option>
                                                    {   
                                                        // Sort results into alphabetical order before mapping them to the dropdown                       
                                                        results
                                                            .sort((a, b) => {
                                                                let textA = a.Result.toUpperCase();
                                                                let textB = b.Result.toUpperCase();
                                                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                                            })
                                                            .map((result, i) => {
                                                            return <option key={i} defaultValue={result.Id} value={result.Id} >{result.Result}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                        {
                                            !hasAcceptedPlan &&
                                            <span className="drag-drop-icon">
                                                {
                                                    isDraggable &&
                                                    <i className="material-icons">open_with</i>
                                                }
                                            </span>
                                        }
                                    </div>
                                )
                            })
                        }
                            
                        </div>
                        {
                            !hasAcceptedPlan &&
                            <div id="EnrolledElectives"className="drag-drop__drop-area"  data-id={'div1'} onDragOver={(e)=>onDragOver(e)} onDrop={(e)=>{onDrop(e, "div2")}}>
                                <p className="center mbh-green-text" data-id={'div1'} >DROP COMPETENCY HERE</p>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                !hasAcceptedPlan &&
                ( 
                    isLoadingDrag ? <img src={Spinner} alt="Spinner"/>
                    :
                    <>
                        <h6>Available Competencies</h6>
                        <form id="electivesSection">

                            <div id="div1" className="collection drag-drop__results" data-id={'div2'} onDragOver={(e)=>onDragOver(e)} onDrop={(e)=>{onDrop(e, "div1")}}>
                                <div id="availableElectives" className="drag-drop__drop-area" data-id={'div2'} >
                                    <p className="center mbh-green-text" data-id={'div2'} >DROP COMPETENCY HERE</p>
                                </div>
                            {
                                availableCompetencies.map((competency, index) => {
                                        return(
                                            <div key={index} className="collection-item drag-drop__result drag-drop__draggable available-electives" draggable data-id={'unassigned'} data-index={index} id={`dragB${index}`} onDragStart = {(e) => onDragStart(e)} >
                                                <span>{competency.Competency.CompID}</span>
                                                <span>{competency.Competency.compDsc}</span>
                                                <span>{competency.IsCore ? 'Core' : 'Elective'}</span>
                                                <span>{dateTimeToDateString(competency.ActivityStartDate, 2)}</span>
                                                <span>{dateTimeToDateString(competency.ActivityEndDate, 2)}</span>
                                                <span></span>
                                                <span className="drag-drop__draggable-icon"><i className="material-icons">open_with</i></span>
                                            </div>
                                        )
                                    })
                            }
                            </div>
                        </form>
                    </> 
                )
            }
            <br />
            <br />
            <div>
            {
                !hasAcceptedPlan ?
                <div className="center-align">
                    <button className="btn mbh-green" onClick={() => handleAcceptTrainingPlan("accepted")}>Accept Training Plan</button>
                </div>
                :
                <>
                    <div className="center-align">
                        <button className="btn" disabled>Training Plan Accepted</button>
                        <button className="btn mbh-red" onClick={() => handleAcceptTrainingPlan("cancel")}>Cancel</button>
                    </div>
                    <div className="drag-drop__plan-acceptance">
                        <div className="drag-drop__plan-acceptance--info">
                            <i className="medium material-icons mbh-green-text notextselect">person</i>
                            <p className="mbh-green-text">Approved By:</p>
                            <p>{personWhoAccepted}</p>
                        </div>
                        <div className="drag-drop__plan-acceptance--info">
                            <i className="medium material-icons mbh-green-text notextselect">access_time</i>
                            <p className="mbh-green-text">Approved At:</p>
                            <p>{dateOfAcceptance}</p>
                        </div>
                    </div>
                </>
            }
            </div>            

        </div>
    )
}

export default DragDropZone
