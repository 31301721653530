import { useState, useEffect } from 'react'

export const useCurrentQualFetch = (url, token, currentQualId) => {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
        
    useEffect(() => {

        // const ac = new AbortController();
           
            setIsLoading(true)
            fetch(url, {
                headers: {'Authorization': `Bearer ${token}`}
            })
            .then(response => response.json())
            .then(data => {
                setData(data.value)
                setIsLoading(false)
            })
            .catch(err => setError(err))
        // return () => ac.abort(); 
    }, [url, currentQualId])

    return [ isLoading, data, error ]

}