import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../functions/globalVariables';
import { useDataFetch } from '../../hooks/useDataFetch';
import Spinner from '../../assets/spinner.svg'
import M from 'materialize-css';
import './EventsTrainers.scss'
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';

const Trainers = ({ setCurrentPage }) => {

    let history = useHistory()
    const { authToken } = useThemeContext()

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        M.updateTextFields();
    })
    
    const trainersURL = `${baseUrl}/api/trainers`;
    const [ isLoading, trainers, error ] = useDataFetch(trainersURL, authToken);
    const [existingTrainers, setExistingTrainers] = useState([])
    const [deletedTrainers, setDeletedTrainers] = useState([])

    const [toggleIsDisabledFilter, setToggleIsDisabledFilter] = useState(false)
    const [showAddForm, setShowAddForm] = useState(false)
    const [hasPayrateError, setHasPayrateError] = useState(false)


    const [currentTrainer, setCurrentTrainer] = useState(null)


    useEffect(() => {
        if (trainers.length) {
            if (toggleIsDisabledFilter) {
                setDeletedTrainers(trainers.filter(trainer => trainer.IsDisabled))
            } else {
                setExistingTrainers(trainers.filter(trainer => !trainer.IsDisabled))
            }
        }
    }, [trainers, toggleIsDisabledFilter])


    const handleIsDisabledToggle = () => {
        setToggleIsDisabledFilter(!toggleIsDisabledFilter)
        setShowAddForm(false)
        setCurrentTrainer(null)
    }

    const isPayrateFieldValid = () => {
        if (hasPayrateError) {
            M.toast({html: 'Payrate must be a number'})
            return false
        }

        return true
    }

    const [trainerObj, setTrainerObj] = useState({
        Title: null,
        FirstName: null,
        Surname: null,
        Email: null,
        Address: null,
        Mobile: null,
        Phone: null,
        Payrate: null,
        Qualifications: null,
        IsDisabled: false,
    })

    const populateTrainerObj = () => {
        const keys = Object.keys(trainerObj)
        const keysLength = keys.length

        const tempObj = {}
        for (let i = 0; i < keysLength; i++) {
            tempObj[keys[i]] = currentTrainer[keys[i]]
        }

        setTrainerObj(tempObj)
    }

    const resetTrainerObj = () => {
        if (currentTrainer) {
            const keys = Object.keys(trainerObj)
            const keysLength = keys.length
    
            for(let i = 0; i < keysLength; i++) {
                trainerObj[keys[i]] = null
            }
    
            setCurrentTrainer(null)
        }
    }

    useEffect(() => {
        if (currentTrainer) {
            populateTrainerObj()
        }
    }, [currentTrainer])

    const onTrainerSelect = (e) => {
        const trainerId = e.target.value
        let selectedTrainer = []
        if (toggleIsDisabledFilter) {
            selectedTrainer = deletedTrainers.filter(trainer => trainer.Id == trainerId)
            if (selectedTrainer.length) {
                setCurrentTrainer(selectedTrainer[0])
            }
        } else {
            selectedTrainer = existingTrainers.filter(trainer => trainer.Id == trainerId)
            if (selectedTrainer.length) {
                setCurrentTrainer(selectedTrainer[0])
            }
        }
    }

    const onTrainerInfoChange = (e) => {
        const { name, value } = e.target
        if (name === "Payrate") {
            if (isNaN(value)) {
                M.toast({html: 'Payrate must be number'})
                setHasPayrateError(true)
                return
            }
            setHasPayrateError(false)
            setTrainerObj(prev => ({...prev, [name]: +value }))
        } else {
            setTrainerObj(prev => ({...prev, [name]: value }))
        }
    }

    const handleEnableTrainer = (e) => {
        const { name, checked } = e.target
        setTrainerObj(prev => ({...prev, [name]: !checked }))
    }

    console.log(trainerObj)

    const createNewTrainer = () => {
        if (!isPayrateFieldValid()) return

        fetch(`${baseUrl}/api/trainers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(trainerObj)
        })
        .then(response => { response.json(); setShowAddForm(false); window.location.reload()})
        .catch(err => { console.log(err); setShowAddForm(false); window.location.reload()})
    }

    const saveTrainer = () => {
        if (!isPayrateFieldValid()) return

        fetch(`${baseUrl}/api/trainers/${currentTrainer.Id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                Id: currentTrainer.Id,
                ...trainerObj
            })
        })
        .then(response => { response.json(); setCurrentTrainer(null); window.location.reload()})
        .catch(err => { console.log(err); setCurrentTrainer(null); window.location.reload()})
    }

    const deleteTrainer = () => {
        fetch(`${baseUrl}/api/trainers/${currentTrainer.Id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({...trainerObj, IsDisabled: true, Id: currentTrainer.Id})
        })
        .then(response => { response.json(); setCurrentTrainer(null); window.location.reload()})
        .catch(err => { console.log(err); setCurrentTrainer(null); window.location.reload()})
    }



    return (
        <section className="events-trainers">
            <article className="events-summary__header">
                <h3 className="mbh-green-text">Trainers</h3>
                <div className="events-summary__header--btns">
                    <a className="btn-floating mbh-green" onClick={() => {setCurrentPage("summary"); history.push('/admin-portal/events')}} style={{marginRight: "5px"}}><i className="material-icons notextselect">arrow_back</i></a>
                    <a className="btn-floating mbh-green" onClick={() => {setCurrentPage("calendar"); history.push('/admin-portal/events/calendar')}}><i className="material-icons notextselect">event</i></a>
                </div>
            </article>

            {
                isLoading ?
                <img src={Spinner} alt="Spinner" />
                :
                (
                    !showAddForm &&
                    <>
                        <div className="switch">
                            <label>
                                <input type="checkbox" id="cbAcceptance" name="acceptance" onChange={handleIsDisabledToggle}/>
                                <span className="lever"></span>
                            </label>
                            <span>Show Deleted Trainers</span>
                        </div>
                        <br />
                        <article className="row">
                            <div className="events-trainers__dropdown input-field col s9">
                                <select id="trainers" onChange={onTrainerSelect} autoComplete="new-password" name="trainersSelect">
                                    <option value="None">Select a Trainer</option>
                                    {                          
                                        toggleIsDisabledFilter ?
                                        deletedTrainers.map((trainer, i) => {
                                            return <option key={trainer.Id} defaultValue={trainer.Id} value={trainer.Id}>{`${trainer.FirstName} ${trainer.Surname}`}</option>
                                        })
                                        :
                                        existingTrainers.map((trainer, i) => {
                                            return <option key={trainer.Id} defaultValue={trainer.Id} value={trainer.Id}>{`${trainer.FirstName} ${trainer.Surname}`}</option>
                                        })
                                    }
                                </select>
                                <label>Trainers</label>
                            </div>
                            <div className="events-trainers__add col s3">
                                <a className="btn-floating mbh-green" onClick={() => {setShowAddForm(true); resetTrainerObj()}} style={{marginRight:"5px"}}><i className="material-icons notextselect">add</i></a>
                            </div>
                        </article>
                    </>
                )
            }
            {
                currentTrainer && !showAddForm &&
                <>
                    <div className="row">
                        <div className="input-field col s6 m2">
                            <input id="Title" name="Title" type="text" value={trainerObj.Title ? trainerObj.Title : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Title">Title</label>
                        </div>
                        <div className="input-field col s12 m5">
                            <input  name="FirstName" type="text" value={trainerObj.FirstName ? trainerObj.FirstName : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="FirstName">First Name</label>
                        </div>
                        <div className="input-field col s12 m5">
                            <input  name="Surname" type="text" value={trainerObj.Surname ? trainerObj.Surname : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Surname">Surname</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input id="Email" name="Email" type="text" value={trainerObj.Email ? trainerObj.Email : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Email">Email</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input id="Phone" name="Phone" type="text" value={trainerObj.Phone ? trainerObj.Phone : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Phone">Phone</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input  name="Mobile" type="text" value={trainerObj.Mobile ? trainerObj.Mobile : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Mobile">Mobile</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input  name="Address" type="text" value={trainerObj.Address ? trainerObj.Address : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Address">Address</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input  name="Payrate" type="text" value={trainerObj.Payrate ? trainerObj.Payrate : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Payrate">Payrate</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input  name="Qualifications" type="text" value={trainerObj.Qualifications ? trainerObj.Qualifications : ""} autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Qualifications">Qualifications</label>
                        </div>
                        {
                            currentTrainer.IsDisabled &&
                            <div class="row">
                                <p className="col">
                                    <label>
                                        <input type="checkbox" name="IsDisabled" onChange={handleEnableTrainer} />
                                        <span>Enable Trainer?</span>
                                    </label>
                                </p>
                            </div>
                        }
                    </div>
                    <div className="col s6 m3 right">
                        {
                            !currentTrainer.IsDisabled &&
                            <a className="btn-floating mbh-red right" onClick={deleteTrainer}><i className="material-icons notextselect">delete</i></a>
                        }
                        
                        <a className="btn-floating mbh-green right" onClick={saveTrainer} style={{marginRight: "30px"}}><i className="material-icons notextselect">save</i></a>
                    </div>
                </>
            }
            {
                showAddForm &&
                <>
                    <h4>New Trainer</h4>
                    <div className="row">
                        <div className="input-field col s12 m2">
                            <input id="Title" name="Title" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Title">Title</label>
                        </div>
                        <div className="input-field col s12 m5">
                            <input  name="FirstName" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="FirstName">First Name</label>
                        </div>
                        <div className="input-field col s12 m5">
                            <input  name="Surname" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Surname">Surname</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input id="Email" name="Email" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Email">Email</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input id="Phone" name="Phone" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Phone">Phone</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input  name="Mobile" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Mobile">Mobile</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input  name="Address" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Address">Address</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input  name="Payrate" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Payrate">Payrate</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input  name="Qualifications" type="text" autoComplete="new-password" onInput={onTrainerInfoChange} />
                            <label htmlFor="Qualifications">Qualifications</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 m3 right">
                            <a className="btn-floating mbh-green right" onClick={() => setShowAddForm(false)}><i className="material-icons notextselect">close</i></a>
                            <a className="btn-floating mbh-green right" onClick={createNewTrainer} style={{marginRight:"5px"}}><i className="material-icons notextselect">save</i></a>
                        </div>
                    </div>
                </>
            }
        </section>
    )
}

export default Trainers
