import React, { useEffect } from 'react'
import './TrainingPlanInfoBoxes.scss'
import M from 'materialize-css'

const TrainingPlanInfoBoxes = ({ currentQual }) => {

    useEffect(()=>{
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
    })

    // Parses HTML string to React Elements
    var parse = require('html-react-parser');

    return (
        <div className="training-plan-info__section training-plan-info__info-section">
            <div className="card training-plan-info__info">
                <h5 className="mbh-green-text">Training Delivery</h5>
                <a className="modal-trigger" href="#modal2"><i className="material-icons small mbh-green-text">info_outline</i></a>
                <div id="modal2" className="modal training-plan-info__modal">
                    <div className="modal-content">
                        <h5 className="left-align mbh-green-text">Training Delivery</h5>
                        <br />
                        <div className="left-align">
                        {
                            currentQual.TrainingDeliveryDescription !== null 
                            ?
                            parse(currentQual.TrainingDeliveryDescription.toString())
                            :
                            "No Information Available"
                        }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-close mbh-green-text btn-flat">OK</button>
                    </div>
                </div>
            </div>
            <div className="card training-plan-info__info">
                <h5 className="mbh-green-text">Assessment Support</h5>
                <a className="modal-trigger" href="#modal3"><i className="material-icons small mbh-green-text">info_outline</i></a>
                <div id="modal3" className="modal training-plan-info__modal">
                    <div className="modal-content">
                        <h5 className="left-align mbh-green-text">Assessment Support</h5>
                        <br />
                        <div className="left-align">
                        {
                            currentQual.AssessmentSupportDescription !== null 
                            ?
                            parse(currentQual.AssessmentSupportDescription.toString())
                            :
                            "No Information Available"
                        }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-close mbh-green-text btn-flat">OK</button>
                    </div>
                </div>
            </div>
            <div className="card training-plan-info__info">
                <h5 className="mbh-green-text">Assessment Timeframe</h5>
                <a className="modal-trigger" href="#modal4"><i className="material-icons small mbh-green-text">info_outline</i></a>
                <div id="modal4" className="modal training-plan-info__modal">
                    <div className="modal-content">
                        <h5 className="left-align mbh-green-text">Assessment Timeframe</h5>
                        <br />
                        <div className="left-align">
                        {
                            currentQual.AssessmentTimeframeDescription !== null 
                            ?
                            parse(currentQual.AssessmentTimeframeDescription.toString())
                            :
                            "No Information Available"
                        }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-close mbh-green-text btn-flat">OK</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrainingPlanInfoBoxes
