import React from 'react'
import { useEffect, useState } from 'react'
import { useDataFetch } from '../../hooks/useDataFetch'
import './EditCompetencies.scss'
import M from 'materialize-css'
import { baseUrl } from '../../functions/globalVariables'
import Spinner from '../../assets/spinner.svg';
import { capitaliseEachWordOfQualName } from '../../functions/capitaliseEachWordOfQualName'
import { useThemeContext } from '../../ThemeContext'

const EditCompetencies = () => {

    const { authToken } = useThemeContext()

    const qualCompetenciesUrl = `${baseUrl}/api/qualificationcompetencies?$expand=Qualification,Competency`
    const [isLoadingQualCompetencies, allQualCompetencies, error] = useDataFetch(qualCompetenciesUrl, authToken)

    const competenciesUrl = `${baseUrl}/api/competencies`
    const [isLoadingCompetencies, allCompetencies, competenciesError] = useDataFetch(competenciesUrl, authToken)


    const [qualToFilterBy, setQualToFilterBy] = useState('Choose')
    const [showSuperseded, setShowSuperseded] = useState(false)
    const [filteredCompetencies, setFilteredCompetencies] = useState([])
    
    // Holds the individual qualifications that competencies can be filtered by
    const [qualifications, setQualifications] = useState([])

    useEffect(() => {
        if (allQualCompetencies.length) {
            setFilteredCompetencies(allQualCompetencies)
            
            const quals = allQualCompetencies.map(comp => `${comp.Qualification.AntaId} ${capitaliseEachWordOfQualName(comp.Qualification)}`)
            const filteredQuals = quals.filter((comp, i) => quals.indexOf(comp) === i)
            setQualifications(filteredQuals)
        }
    }, [allQualCompetencies])

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    })

    useEffect(() => {

        if (allQualCompetencies.length) {

            let newCompetenciesArray = allQualCompetencies.filter(qualComp => {
                console.log(qualComp.Competency.Superseded, showSuperseded)
                return qualComp?.Competency?.Superseded === showSuperseded
            })
          
            if (qualToFilterBy !== 'Choose') {
                newCompetenciesArray = newCompetenciesArray.filter(competency => `${competency.Qualification.AntaId} ${capitaliseEachWordOfQualName(competency.Qualification)}` === qualToFilterBy)
            }

            setFilteredCompetencies(newCompetenciesArray)
        }

        
    }, [allQualCompetencies, qualToFilterBy, showSuperseded])

    const updateCompetenciesArray = (e, rowIndex) => {

        const { type, name, value } = e.target;

        const rows = document.getElementById('competenciesTable').rows;
        const currentRow = rows[rowIndex + 1]

        let url = ''
        let endpoint = ''
        let currentCompetency = null

        if (type === 'select-one') {
            if (name === 'Superseded' || name === 'VetFlag') {
                const compId = filteredCompetencies[rowIndex].CompetencyId
                currentCompetency = allCompetencies.filter(comp => comp.Id === compId)
                if (name === 'Superseded') {
                    currentCompetency[0][name] = value === 'true' ? true : false;
                    // filteredCompetencies[rowIndex].Superseded = value === 'true' ? true : false;
                } else {
                    currentCompetency[0][name] = value
                    // filteredCompetencies[rowIndex].VetFlag = value
                }
                url = `${baseUrl}/api/competencies/${compId}`
                endpoint = 'competencies'

            } else if (name === 'IsCore' || name === 'IsStandard') {
                const compId = filteredCompetencies[rowIndex].Id
                filteredCompetencies[rowIndex][name] = value === 'true' ? true : false;
                url = `${baseUrl}/api/qualificationcompetencies/${compId}`
                endpoint = 'qualCompetencies'
            }

        } else {
            const compId = filteredCompetencies[rowIndex].CompetencyId
            currentCompetency = allCompetencies.filter(comp => comp.Id === compId)
            currentCompetency[0][name] = value
            url = `${baseUrl}/api/competencies/${compId}`
            endpoint = 'competencies'
        }

        fetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
            },
            body: endpoint === 'qualCompetencies' ? JSON.stringify(filteredCompetencies[rowIndex]) : JSON.stringify(currentCompetency[0])
        })
        .then(response => response.json())
        .catch(err => console.log(err))

    }

    return (
        <section className="edit-comps">
            {
                isLoadingQualCompetencies ? <img src={Spinner} alt="Spinner"/>
                :
                <>
                    <h2>Edit Competencies</h2>
                    <div className="input-field col s6">
                        <p className="mbh-green-text">Filter Competencies by Qualification <span style={{fontSize:"11px"}}>(add/remove a competency to a qualification in the Edit Qualifications page)</span></p>
                        <div className="row">
                            <div className="col s12 m6">
                                <select onChange={(e) => setQualToFilterBy(e.target.value)}>
                                    <option value="Choose">Choose a Qualification</option>
                                    {
                                        qualifications.map((qual, index) => {
                                            return <option key={`${qual.AntaId}_${index}`} value={qual}>{qual.trim()}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col s6">
                                <select onChange={(e) => setShowSuperseded(e.target.value === "true")} value={showSuperseded}>
                                    <option value="true">Superseded</option>
                                    <option value="false">Not Superseded</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <section className="edit-comps__all-comps">
                        <table id="competenciesTable">
                            <thead>
                                <tr>
                                    <th>CompID</th>
                                    <th>CompDsc</th>
                                    <th>CompEducationId</th>
                                    <th>Core/Elective</th>
                                    <th>IsStandard</th>
                                    <th>NominalHours</th>
                                    <th>VetFlag</th>
                                    <th>Superseded</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    filteredCompetencies.map((competency, index) => (
                                        <tr key={competency.Id}>
                                            <td><input name="CompId" type="text" defaultValue={competency.Competency.CompID} onChange={(e) => updateCompetenciesArray(e, index, competency)} /></td>
                                            <td className="edit-comps__comp-dsc enlarge"><input name="compDsc" type="text" defaultValue={competency.Competency.compDsc} onChange={(e) => updateCompetenciesArray(e, index, competency)} /></td>
                                            <td><input name="CompeducationId" type="text" defaultValue={competency.Competency.CompeducationId} maxLength="6" onChange={(e) => updateCompetenciesArray(e, index, competency)} /></td>
                                            <td>
                                                <select name="IsCore" onChange={(e) => updateCompetenciesArray(e, index, competency)}>
                                                    <option value="true" selected={competency.IsCore ? true : false}>Core</option>
                                                    <option value="false" selected={!competency.IsCore ? true : false}>Elective</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="IsStandard" onChange={(e) => updateCompetenciesArray(e, index, competency)}>
                                                    <option value="true" selected={competency.IsStandard ? true : false}>True</option>
                                                    <option value="false" selected={!competency.IsStandard ? true : false}>False</option>
                                                </select>
                                            </td>
                                            <td><input name="NomHrs" type="text" defaultValue={competency.Competency.NomHrs} onChange={(e) => updateCompetenciesArray(e, index, competency)} /></td>
                                            <td>
                                                <select name="VetFlag" onChange={(e) => updateCompetenciesArray(e, index, competency)}>
                                                    <option value="Y" selected={competency.Competency.VetFlag !== 'Y' ? true : false}>Y</option>
                                                    <option value="N" selected={competency.Competency.VetFlag !== 'Y' ? true : false}>N</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="Superseded" onChange={(e) => updateCompetenciesArray(e, index, competency)}>
                                                    <option value="true" selected={competency.Competency.Superseded ? true : false}>True</option>
                                                    <option value="false" selected={!competency.Competency.Superseded ? true : false}>False</option>
                                                </select>
                                            </td>
                                            {/* <td>{competency.Qualification.Superseded.toString()}</td> */}
                                        </tr>
                                    ))
                                    }
                            </tbody>
                            </table>
                    </section>
                </>
            }
        </section>
    )
}

export default EditCompetencies
